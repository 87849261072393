import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { LineWave, TailSpin } from "react-loader-spinner";
import { useAuth } from "../Context/AuthContext";
import { IoIosCheckmarkCircle } from "react-icons/io";

const VerifyingStep = ({ verified, verifyingLoading, setSection }) => {
  const { t } = useTranslation();

  const renderSection = () => {
    if (verifyingLoading) {
      return (
        <>
          <MDBCol className="d-flex justify-content-center" size="12">
            <LineWave
              height="120"
              width="120"
              color="blue"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{
                marginTop: -50,
                textAlign: "center",
                marginRight: -20,
              }}
              wrapperClass=""
              visible={true}
            />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p style={{ fontFamily: "SSMedium", fontSize: 23 }}>
              Verifying identity
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                fontFamily: "SSRegular",
                fontSize: 17,
                textAlign: "center",
                width: 350,
              }}
            >
              We are verifying your identity to ensure safety on our platform.
              This will take 30 seconds.
            </p>
          </MDBCol>
        </>
      );
    } else if (!verifyingLoading && verified) {
      return (
        <>
          <MDBCol className="d-flex justify-content-center" size="12">
            <IoIosCheckmarkCircle
              style={{
                marginTop: 20,
                fontSize: 40,
              }}
              color="blue"
            />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p style={{ fontFamily: "SSMedium", fontSize: 23, marginTop: 10 }}>
              You're ready to go!
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                fontFamily: "SSRegular",
                fontSize: 17,
                textAlign: "center",
                width: 350,
              }}
            >
              We've verified your identity, you can now continue to the next
              step, and provide your banking payout details to receive your
              earnings.
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <div
              style={{
                height: 55,
                width: 410,
                backgroundColor: "blue",
                borderRadius: 7,
                marginTop: 20,
                textAlign: "center",
                fontFamily: "SSRegular",
                color: "white",
                fontSize: 18,
                paddingTop: 14,
                cursor: "pointer",
                opacity: 1,
              }}
              onClick={() => setSection("Step3Digital")}
              className="d-flex justify-content-center"
            >
              <p>Continue</p>
            </div>
          </MDBCol>
        </>
      );
    } else if (!verifyingLoading && !verified) {
      return (
        <>
          {" "}
          <MDBCol className="d-flex justify-content-center" size="12">
            <RiCloseLine
              style={{ marginTop: 20, fontSize: 40, color: "red" }}
            />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p style={{ fontFamily: "SSMedium", fontSize: 23, marginTop: 10 }}>
              Identity check failed
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                fontFamily: "SSRegular",
                fontSize: 17,
                textAlign: "center",
                width: 350,
              }}
            >
              Unfortunately we couldn't verify your identity successfully. If
              you'd like to try again,{" "}
              <p
                className="d-inline"
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setSection("Step1")}
              >
                click here.
              </p>{" "}
              If you're still facing issues verifying your identity{" "}
              <p
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                className="d-inline"
              >
                get in touch with our team.
              </p>
            </p>
          </MDBCol>
        </>
      );
    }
  };

  return (
    <>
      <MDBRow>{renderSection()}</MDBRow>
    </>
  );
};

export default withRouter(VerifyingStep);
