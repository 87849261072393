import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";

import "react-phone-number-input/style.css";
import MainMenu from "../Layout/MainMenu";
import { FaRegCompass, FaRegStar } from "react-icons/fa";
import { BsBackpack } from "react-icons/bs";
import { IoFilterSharp } from "react-icons/io5";
import SideNav from "../Layout/SideNav";
import TopBar from "../Layout/TopBar";
import Categories from "./Categories";
import Templates from "./Templates";
import { useAuth } from "../Context/AuthContext";

const CompanySettings = () => {
  const { companyName, website, companySize, companyType, workspaceID } =
    useAuth();
  const categories = [1, 2, 3, 4, 5, 6, 7, 9, 3, 4, 5, 6, 7, 9];
  const [companyNameValue, setCompanyNameValue] = useState(companyName);
  const [websiteValue, setWebsiteValue] = useState(website);
  const [companySizeValue, setCompanySizeValue] = useState(companySize);
  const [companyTypeValue, setCompanyTypeValue] = useState(companyType);
  const [saved, setSaved] = useState(false);

  return (
    <div
      style={{
        width: 1300,
        height: 440,
        backgroundColor: "white",
        marginTop: 120,
        borderRadius: 10,
        boxShadow:
          "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
        display: "flex",
        paddingTop: 30,
        color: "black",
        border: "1px solid #eaecf0",
      }}
    >
      <div
        style={{
          width: 500,
          paddingLeft: 25,
          fontFamily: "SSMedium",
        }}
      >
        <p style={{ fontSize: 19, fontWeight: "700" }}>Settings</p>
        <p style={{ fontSize: 14, marginTop: -12, opacity: 0.7 }}>
          Settings for your organization
        </p>
      </div>
      <div style={{ width: 800 }}>
        <p style={{ fontFamily: "SSMedium", fontSize: 14 }}>Company Name</p>
        <input
          style={{
            height: 40,
            width: "93%",
            marginLeft: 0,
            border: "1px solid rgba(155, 158, 163, 0.5)",
            borderRadius: 6,
            display: "inline-block",
            color: "black",
            marginTop: -11,
            backgroundColor: "white",
            fontFamily: "SSMedium",
            paddingLeft: 17,
            fontSize: 13,
            paddingTop: 3,
            opacity: 1,
            boxShadow:
              "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          }}
          value={companyNameValue}
          name="CompanyName"
          onChange={(event) => setCompanyNameValue(event.target.value)}
          placeholder="acme inc"
        />
        <p
          style={{
            fontFamily: "SSMedium",
            fontSize: 14,
            marginTop: 17,
          }}
        >
          Website
        </p>
        <input
          style={{
            height: 40,
            width: "93%",
            marginLeft: 0,
            border: "1px solid rgba(155, 158, 163, 0.5)",
            borderRadius: 6,
            display: "inline-block",
            color: "black",
            marginTop: -11,
            backgroundColor: "white",
            fontFamily: "SSMedium",
            paddingLeft: 17,
            fontSize: 13,
            paddingTop: 3,
            opacity: 1,
            boxShadow:
              "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          }}
          value={websiteValue}
          name="Website"
          onChange={(event) => setWebsiteValue(event.target.value)}
          placeholder="https://mycompany.com"
        />
        <p
          style={{
            fontFamily: "SSMedium",
            fontSize: 14,
            marginTop: 17,
          }}
        >
          Company Type
        </p>
        <select
          style={{
            height: 40,
            width: "93%",
            marginLeft: 0,
            border: "1px solid rgba(155, 158, 163, 0.5)",
            borderRadius: 6,
            display: "inline-block",
            color: "black",
            marginTop: -11,
            backgroundColor: "white",
            fontFamily: "SSMedium",
            paddingLeft: 17,
            fontSize: 13,
            paddingTop: 3,
            opacity: 1,
            boxShadow:
              "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          }}
          value={companyTypeValue}
          name="Company Type"
          onChange={(event) => setCompanyTypeValue(event.target.value)}
        >
          <option value={null}>None Selected</option>
          <option value="Agency">Agency/Consultant</option>
          <option value="Brand">Brand</option>
        </select>
        <p
          style={{
            fontFamily: "SSMedium",
            fontSize: 14,
            marginTop: 17,
          }}
        >
          Team Size
        </p>
        <select
          style={{
            height: 40,
            width: "93%",
            marginLeft: 0,
            border: "1px solid rgba(155, 158, 163, 0.5)",
            borderRadius: 6,
            display: "inline-block",
            color: "black",
            marginTop: -11,
            backgroundColor: "white",
            fontFamily: "SSMedium",
            paddingLeft: 17,
            fontSize: 13,
            paddingTop: 3,
            opacity: 1,
            boxShadow:
              "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          }}
          value={companySizeValue}
          name="Company Size"
          onChange={(event) => setCompanySizeValue(event.target.value)}
        >
          <option value={null}>None Selected</option>
          <option value="1">Individual</option>
          <option value="2-10">2-10</option>
          <option value="10-49">10-49</option>
          <option value="50-100">50-500</option>
          <option value="500+">500+</option>
        </select>

        <MDBCol className="d-flex justify-content-end" size="12">
          <div
            onClick={() => {
              if (
                companyNameValue &&
                companyTypeValue &&
                companySizeValue &&
                websiteValue
              ) {
                firebase
                  .firestore()
                  .collection("workspace")
                  .doc(workspaceID)
                  .update({
                    website: websiteValue,
                    companyName: companyNameValue,
                    companySize: companySizeValue,
                    companyType: companyTypeValue,
                  })
                  .then(() => {
                    setSaved(true);
                    setTimeout(() => {
                      setSaved(false);
                    }, 3000);
                  });
              }
            }}
            style={{
              height: 40,
              width: 160,
              borderRadius: 6,
              border: "1px solid blue",
              backgroundColor: "blue",
              color: "white",
              fontSize: 14,
              paddingTop: 9,
              textAlign: "center",
              fontFamily: "SSMedium",
              cursor: "pointer",
              marginRight: 40,
              marginTop: 20,
              fontWeight: "700",
              opacity:
                companyNameValue &&
                companyTypeValue &&
                companySizeValue &&
                websiteValue
                  ? 1
                  : 0.5,
            }}
          >
            {saved ? <p>Saved!</p> : <p>Save</p>}
          </div>
        </MDBCol>
      </div>
    </div>
  );
};

export default withRouter(CompanySettings);
