import React, { Component, useState, useEffect } from "react";
import "../index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import DashboardFooter from "../Layout/DashboardFooter";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import { BiChevronDown, BiLinkAlt } from "react-icons/bi";
// import Heading from "./Heading";
import { FiArrowUpRight, FiRefreshCw, FiChevronRight } from "react-icons/fi";
import { FaChevronRight } from "react-icons/fa";

const About = ({ email, popUpVisible, setPopUpVisible, ref, seconds }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [uploadOptionsVisible, setUploadOptionsVisible] = useState(false);
  const [uploading, setUploading] = useState(true);
  const [youtubeLink, setYoutubeLink] = useState(null);
  const [uploadingArray, setUploadingArray] = useState([]);
  const [historyArray, setHistoryArray] = useState([]);
  const [processingArray, setProcessingArray] = useState([]);
  const [videoVisible, setVideoVisible] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [freeTrialUsed, setFreeTrialUsed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  useEffect(() => {
    if (firebase.auth().currentUser) {
      const uid = firebase.auth().currentUser.uid;

      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .onSnapshot((doc) => {
          setFreeTrialUsed(doc.data().freeTrialUsed);
        });

      firebase
        .firestore()
        .collection("customers")
        .doc(uid)
        .collection("subscriptions")
        .where("status", "==", "active")
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            let initActive = [];
            querySnapshot.forEach((doc) => {
              console.log(doc.data());
              initActive.push(doc.data());
            });
            if (initActive.length > 0) {
              setSubscribed(true);
            }
          } else {
            setSubscribed(false);
          }
        });
      console.log(uid);
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .collection("tasks")
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            let initArray = [];
            let processArray = [];
            let uploadArray = [];
            querySnapshot.forEach((doc) => {
              console.log(doc.data());
              if (doc.data().status === "Complete") {
                initArray.push(doc.data());
              } else if (
                doc.data().status !== "Error" &&
                doc.data().status !== "Complete" &&
                doc.data().status !== "Uploading"
              ) {
                setUploading(false);
                processArray.push(doc.data());
              } else if (doc.data().status === "Uploading") {
                setUploading(true);
                uploadArray.push(doc.data());
              }
            });

            console.log("The init array is,", initArray);
            setHistoryArray(initArray);
            setProcessingArray(processArray);

            setUploadingArray(uploadArray);
          }
        });
    }
  }, [firebase.auth().currentUser]);

  useEffect(() => {
    setPopUpVisible(false);
  }, []);

  useEffect(() => {
    console.log("Uploading array is", uploadingArray);
  }, [uploadingArray]);

  useEffect(() => {
    setYoutubeLink("");
  }, [modalVisible]);

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            background: "white",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 95,
            }}
            size="12"
          >
            <MDBRow
              style={{
                marginTop: 0,
                position: "fixed",
                backgroundColor: "white",
                zIndex: 100,
              }}
            >
              <DashboardNavBar
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
                email={email}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                seconds={seconds}
                ref={ref}
              />
            </MDBRow>
            <MDBCol
              className="d-flex justify-content-center"
              style={{ marginTop: 90 }}
              size="12"
            >
              <MDBRow style={{ width: 1140, paddingLeft: 20 }}>
                <p style={{ fontSize: 49, fontFamily: "PPBold" }}>About us</p>
                <p
                  style={{
                    fontSize: 20,
                    fontFamily: "PPMedium",
                    width: "90%",
                    marginTop: 8,
                  }}
                >
                  Hey there! Welcome to Cloneshort. We’re a small team of AI
                  experts, youtubers and TikTokers with experience blowing up
                  brands like yours on TikTok. We built Cloneshort to automate
                  this process to help thousands of companies grow faster on
                  TikTok.
                </p>

                <p
                  style={{
                    fontSize: 20,
                    fontFamily: "PPMedium",
                    width: "90%",
                    marginTop: 20,
                  }}
                >
                  So, why should you trust us? We’ve built YouTube channels that
                  have 1 million+ subscribers and more than 10 million views a
                  month. We know how to get views consistently.
                </p>

                <p
                  style={{
                    fontSize: 20,
                    fontFamily: "PPMedium",
                    width: "90%",
                    marginTop: 20,
                  }}
                >
                  TikTok is the new playground for brands. But let’s be honest,
                  not everyone has the time to post content daily. That’s why we
                  started Cloneshort. We’ve taken our secret sauce and opened it
                  up to brands like you. You get to sit back and watch the magic
                  happen.
                </p>

                <p
                  style={{
                    fontSize: 20,
                    fontFamily: "PPMedium",
                    width: "90%",
                    marginTop: 20,
                  }}
                >
                  Here's the deal: our AI is good. But we also use human editors
                  to perfect the results. It dives deep into what's trending and
                  what your audience digs. Then, poof! Out comes a TikTok video
                  that’s got your name written all over it. Basically, we’re
                  like your backstage crew, but for TikTok.
                </p>

                <p
                  style={{
                    fontSize: 20,
                    fontFamily: "PPMedium",
                    width: "90%",
                    marginTop: 20,
                  }}
                >
                  Think of us as your very own video marketing team.
                </p>
              </MDBRow>
            </MDBCol>

            {firebase.auth().currentUser ? null : (
              <>
                {/* <Explainer />
                {/* <Process /> */}
                {/* <Steps />
                <Pricing />
                <div style={{ marginTop: 50 }}></div>
                <Questions />  */}
              </>
            )}
          </MDBCol>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(About);
