import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import TopMenu from "../Layout/TopMenu";
import Collapsible from "react-collapsible";
import MenuDesktop from "../Layout/MenuDesktop";
import { MDBRow, MDBCol } from "mdbreact";
import { IoSearch } from "react-icons/io5";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import ReactInputVerificationCode from "react-input-verification-code";
import DashboardNavBar from "../Layout/DashboardNavBar";
import DashboardFooter from "../Layout/DashboardFooter";
import { FaRegCircleQuestion } from "react-icons/fa6";
import Match from "./Match";
import axios from "axios";
import DashboardFooterMobile from "../Layout/DashboardFooterMobile";

import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiArrowRightSLine,
  RiQuestionLine,
} from "react-icons/ri";
import mixpanel from "mixpanel-browser";
import { HiLightningBolt, HiSortAscending } from "react-icons/hi";
import ReactStars from "react-stars";

import { firebase } from "../../../firebase/config";

import { SkyLightStateless } from "react-skylight";
import { TiTick } from "react-icons/ti";
import Scroll from "react-scroll";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { matches } from "lodash";
import Slide from "react-reveal/Slide";
import Testimonial from "./Testimonial";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import Faq from "react-faq-component";
import PricingBox from "./PricingBox";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import DashboardNavBarMobile from "../Layout/DashboardNavBarMobile";

function checkPlanExists(plans, tier, planType) {
  // Convert tier and planType to their corresponding values
  const name = `${tier} Plan`;
  const interval = planType === "Weekly" ? "month" : "year";

  if (!plans) {
    return null;
  }

  // Check if the plan exists in the array
  return plans.some((plan) => plan.name === name && plan.interval === interval);
}

function PriceHeading({
  tier,
  planType,
  subscribedPlans,
  setIsOpen,
  price,
  monthly,
}) {
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [active, setActive] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (checkPlanExists(subscribedPlans, tier, planType)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [subscribedPlans, tier, planType]);

  const priceCalculator = (tier) => {
    switch (tier) {
      case "Basic":
        return planType === "Weekly" ? 39 : 29;
      case "Professional":
        return planType === "Weekly" ? 119 : 100;
      case "Enterprise":
        return planType === "Weekly" ? 500 : 299;
    }
  };

  const pricingToStripe = (price) => {
    switch (price) {
      case 39:
        return "price_1Nlu2EKXhAhuLzvpeX8cZe2d";
      case 29:
        return "price_1Nlu2EKXhAhuLzvpmuqoDhRz";
      case 119:
        return "price_1Nlu2JKXhAhuLzvpJ0ohCIwK";
      case 100:
        return "price_1Nlu2JKXhAhuLzvpCIuS5FZT";
      case 299:
        return "price_1Nlu2NKXhAhuLzvpGdrLINui";
      case 500:
        return "price_1Nlu2NKXhAhuLzvpGdrLINui";
    }
  };

  const startSubscription = async (tier) => {
    setSubscriptionLoading(true);
    const uid = firebase.auth().currentUser.uid;
    const docRef = await firebase
      .firestore()
      .collection("customers")
      .doc(uid)
      .collection("checkout_sessions")
      .add({
        price: pricingToStripe(priceCalculator(tier)),
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        allow_promotion_codes: true,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  const buttonText = (plan) => {
    switch (plan) {
      case "Free":
        return "Get monthly TikTok's";
        break;
      case "Weekly":
        return "Get weekly TikTok's";
        break;
      case "Daily":
        return "Get daily TikTok's";
        break;
      case "Enterprise":
        return "Managed TikTok";
        break;
    }
  };

  const subheadingText = (plan) => {
    switch (plan) {
      case "Starter":
        return "Just getting started with video ads. Create a few to get going.";
        break;
      case "Growing":
        return "For small businesses needing more affordable but effective video ads.";
        break;
      case "Scale":
        return "For growth teams testing video ad concepts faster than ever.";
        break;
      case "Enterprise":
        return "For global businesses testing ads at scale across regions/platforms";
        break;
    }
  };

  const includedText = (plan) => {
    switch (plan) {
      case "Starter":
        return "10 video ads. 20 templates included";
        break;
      case "Growing":
        return "30 video ads. 100 templates included";
        break;
      case "Scale":
        return "200 videos ads. Unlock all templates.";
        break;
      case "Enterprise":
        return "200+ video ads. Custom templates.";
        break;
    }
  };

  const pricingText = (plan) => {
    switch (plan) {
      case "Starter":
        return "60";
        break;
      case "Growing":
        return "150";
        break;
      case "Scale":
        return "750";
        break;
      case "Enterprise":
        return "Custom";
        break;
    }
  };

  return (
    <MDBRow>
      <MDBCol size="12">
        <p
          style={{
            fontSize: 35,
            fontFamily: "SSMono",
            marginTop: -5,
          }}
        >
          {tier}
        </p>
      </MDBCol>
      <MDBCol size="12">
        <p
          style={{
            fontSize: 15,
            fontFamily: "SSRegular",
            marginTop: -19,
            opacity: 0.7,
            width: 250,
          }}
        >
          {subheadingText(tier)}
        </p>
      </MDBCol>
      <MDBCol size="12">
        <div
          style={{
            fontSize: 54,
            fontFamily: "SSMedium",
            marginTop: -12,
            color: "#30312c",
            display: "flex",
          }}
        >
          <p
            style={{ marginLeft: tier === "Enterprise" ? 0 : 0 }}
            className="d-inline"
          >
            {tier !== "Enterprise" ? (monthly ? "10%" : "10%") : "Custom"}
          </p>
          {tier !== "Enterprise" && (
            <p
              style={{
                fontSize: 12,
                fontFamily: "SSSemiBold",
                marginTop: 30,
                marginLeft: 13,
              }}
            >
              of ad spend on
              <br />
              our videos
            </p>
          )}
        </div>
        <p style={{ fontFamily: "SSSemiBold", fontSize: 15, marginTop: -15 }}>
          {tier !== "Enterprise"
            ? `${price.ads} credits. ${price.templates} templates included.`
            : "1000+ credits. Custom templates."}
        </p>
        <p style={{ fontFamily: "SSMedium", fontSize: 13, marginTop: -13 }}>
          One credit generates up to 60 seconds.{" "}
          <FaRegCircleQuestion style={{ marginLeft: 5, marginTop: -2 }} />
        </p>
      </MDBCol>
      <MDBCol size="12">
        <div
          style={{
            width: 319,
            marginLeft: -20,
            height: 80,
            borderRadius: 0,
            marginTop: 10,
            background: "blue",
            paddingTop: subscriptionLoading ? 17 : 0,
            cursor: "pointer",
          }}
          className="d-flex justify-content-center"
          onClick={() => {
            history.push("/auth");
          }}
        >
          <p
            style={{
              marginTop: 27,
              fontFamily: "PPMedium",
              color: "white",
              fontSize: 19,
              display: "flex",
              marginLeft: -6,
            }}
          >
            {tier === "Enterprise" ? "Get in touch" : "Try it free"}
          </p>
        </div>
      </MDBCol>
    </MDBRow>
  );
}

export default withRouter(PriceHeading);
