import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import { AiOutlinePlayCircle, AiOutlineRight } from "react-icons/ai";
import "react-circular-progressbar/dist/styles.css";
import { BiRightArrowAlt } from "react-icons/bi";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import { firebase } from "../../../firebase/config";
import { TailSpin } from "react-loader-spinner";
import Section1Mobile from "./Section1Mobile";
import Section2Mobile from "./Section2Mobile";
import Section3Mobile from "./Section3Mobile";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";

const HeadingMobile = ({ setIsOpen, requestLoading }) => {
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState(2);
  const [selectedPackage, setSelectedPackage] = useState(2);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const goBack = () => {
    history.goBack();
  };

  if (requestLoading === true) {
    return null;
  }

  const calculatePrice = (selectedPackage) => {
    switch (selectedPackage) {
      case 1:
        return t("priceString1");
      case 2:
        return t("priceString2");
      case 3:
        return t("priceString3");
    }
  };

  const trackCheckoutEvent = (selectedPackage) => {
    let priceValue;

    switch (selectedPackage) {
      case 1:
        priceValue = 10;
        break;
      case 2:
        priceValue = 15;
        break;
      case 3:
        priceValue = 25;
        break;
      default:
        // Handle unexpected package numbers or add a default value if needed
        console.error("Invalid package selected");
        return;
    }

    // Assuming mixpanel is already initialized and available
    mixpanel.track("Started Checkout", { price: priceValue });
  };

  const startPayment = async () => {
    setLoading(true);
    trackCheckoutEvent(selectedPackage);

    // Determine the current language from i18n
    const currentLanguage = i18n.language;

    // Define price mapping based on the language
    const prices = {
      en: [20, 25, 30], // Prices in USD
      ko: [26500, 33000, 39000], // Prices in KRW
      ja: [3000, 3800, 4400], // Prices in JPY
    };

    // Determine the currency based on the language
    const currencies = {
      en: "USD",
      ko: "KRW",
      ja: "JPY",
    };

    // Select price and currency based on the selected package and current language
    let price = prices[currentLanguage][selectedPackage - 1];
    let currency = currencies[currentLanguage];

    const uid = firebase.auth().currentUser.uid;
    const successUrlBase = window.location.origin;
    const successUrlParams = `?price=${price}&currency=${currency}`;
    const successUrl = successUrlBase + successUrlParams;
    const cancelUrl = window.location.origin + "/checkout";

    const docRef = await firebase
      .firestore()
      .collection("customers")
      .doc(uid)
      .collection("checkout_sessions")
      .add({
        mode: "payment",
        price: calculatePrice(selectedPackage), // One-time price created in Stripe
        success_url: successUrl,
        cancel_url: cancelUrl,
        automatic_tax: true,
        allow_promotion_codes: true,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  const renderSection = (section) => {
    switch (section) {
      case 1:
        return <Section1Mobile setSection={setSection} section={section} />;
      case 2:
        return (
          <Section2Mobile
            selectedPackage={selectedPackage}
            setSelectedPackage={setSelectedPackage}
          />
        );
      case 3:
        return <Section3Mobile />;
    }
  };

  return (
    <MDBRow
      style={{
        color: "white",
        paddingTop: 140,
        width: "100vw",
        paddingLeft: 10,
      }}
    >
      <MDBCol style={{ cursor: "pointer" }} size="12">
        <NavLink
          to="/"
          exact
          style={{
            textDecoration: "underline",
            display: "inline-block",
            fontSize: "3.8vw",
            fontFamily: "MADemiBold",
            color: "black",
          }}
        >
          {t("HomeNav")}
        </NavLink>
        <AiOutlineRight
          style={{
            fontSize: "2vw",
            marginLeft: 6,
            marginRight: 6,
            color: "black",
          }}
          className="d-inline"
        />
        <p
          onClick={() => goBack()}
          style={{
            textDecoration: "underline",
            display: "inline-block",
            fontSize: "3.8vw",
            fontFamily: "MADemiBold",
            color: "black",
          }}
        >
          {t("CheckoutNav")}
        </p>
      </MDBCol>
      {renderSection(section)}

      {/* <MDBCol
        style={{ marginTop: section === 1 ? 0 : 20 }}
        className="d-flex justify-content-center"
        size="12"
      >
        <div>
          <div
            style={{
              height: "2.6vw",
              width: "2.6vw",
              backgroundColor: "#502274",
              borderRadius: 200,
              display: "inline-block",
              opacity: section === 1 ? 1 : 0.4,
            }}
          ></div>
          <div
            style={{
              height: "2.6vw",
              width: "2.6vw",
              backgroundColor: "#502274",
              borderRadius: 200,
              display: "inline-block",
              marginLeft: 10,
              opacity: section === 2 ? 1 : 0.4,
            }}
          ></div>
          <div
            style={{
              height: "2.6vw",
              width: "2.6vw",
              backgroundColor: "#502274",
              borderRadius: 200,
              display: "inline-block",
              marginLeft: 10,
              opacity: section === 3 ? 1 : 0.4,
            }}
          ></div>
        </div>
      </MDBCol>

      <MDBCol className="d-flex justify-content-center" size="12">
        <div
          onClick={() => {
            if (section === 1) {
              window.scrollTo({
                top: 0,
              });
              setSection(2);
            } else {
              startPayment();
            }
          }}
          style={{
            height: 54,
            width: "90vw",
            border: "2px solid #502274",
            borderRadius: 100,
            marginTop: 19,
            backgroundColor: "#502274",
            cursor: !loading && "pointer",
            opacity: loading ? 0.7 : 1,
          }}
          className="d-flex justify-content-center align-items-center"
        >
          {loading ? (
            <TailSpin
              height={30}
              width={30}
              color="white"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{ margin: 0 }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            <p
              style={{
                fontFamily: "CSBold",
                fontSize: 15,
                display: "inline-block",
                color: "white",
                zIndex: 300,
                margin: 0,
              }}
            >
              {section == 1 ? t("checkoutContinue") : t("pricingButton")}
              <BiRightArrowAlt
                style={{
                  marginTop: -1.9,
                  fontSize: 18,
                  color: "white",
                }}
                className="d-inline"
              />
            </p>
          )}
        </div>
      </MDBCol> */}
    </MDBRow>
  );
};

export default withRouter(HeadingMobile);
