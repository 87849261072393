import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import DashboardFooter from "../Layout/DashboardFooter";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import Heading from "./Heading";
import { TailSpin } from "react-loader-spinner";
import mixpanel from "mixpanel-browser";

const Checkout = ({ email, popUpVisible, setPopUpVisible, ref, seconds }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [videoLoading, setVideoLoading] = useState(false);

  useEffect(() => {
    mixpanel.track("User arrived at checkout page.");
  }, []);

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          height: "100vh",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#E8E8E8",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: "30vh",
            }}
            size="12"
          >
            <MDBRow style={{ marginTop: 0 }}>
              <DashboardNavBar
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
                email={email}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                seconds={seconds}
                ref={ref}
              />
            </MDBRow>

            {videoLoading ? (
              <div
                className="d-flex justify-content-center"
                style={{ height: "100vh" }}
              >
                <TailSpin
                  height="50"
                  width="50"
                  color="#fff"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{ marginTop: 340 }}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <Heading setIsOpen={setIsOpen} />
                </MDBCol>
              </>
            )}
          </MDBCol>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Checkout);
