import React, { createContext, useState, useEffect, useContext } from "react";
import { firebase } from "../../../firebase/config";

const LoadingContext = createContext();

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
  const [requestLoading, setRequestLoading] = useState(true);
  const [landingLoading, setLandingLoading] = useState(true);
  const [checkoutLoading, setCheckoutLoading] = useState(true);

  return (
    <LoadingContext.Provider
      value={{
        checkoutLoading,
        requestLoading,
        landingLoading,
        setRequestLoading,
        setCheckoutLoading,
        setLandingLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContext;
