import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation, useHistory } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSignUpModal } from "../Context/SignUpModal";
import { RiCloseFill, RiDeleteBin6Line } from "react-icons/ri";
import {
  FiCheck,
  FiChevronRight,
  FiCopy,
  FiHeadphones,
  FiMail,
  FiPlus,
} from "react-icons/fi";
import { FaCheck, FaLink } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useAuth } from "../Context/AuthContext";
import { TailSpin } from "react-loader-spinner";
import VideoInstructions from "./VideoInstructions";
import UploadSection from "./UploadSection";

const CloneModal = ({ ugc }) => {
  const { cloneModalOpen, setCloneModalOpen } = useSignUpModal();
  const [section, setSection] = useState(1);

  const { projects, workspaceID } = useAuth();
  const [projectName, setProjectName] = useState("");
  const [loading, setLoading] = useState(false);
  const [projectsAdded, setProjectsAdded] = useState([]);
  const history = useHistory();

  console.log("UGC is", ugc);

  const renderSection = (section) => {
    switch (section) {
      case 1:
        return <VideoInstructions setSection={setSection} />;
      case 2:
        return <UploadSection setSection={setSection} />;
    }
  };

  useEffect(() => {
    setProjectName("");
    setLoading(false);
  }, [cloneModalOpen]);

  const createProject = () => {
    if (projectName && workspaceID) {
      setLoading(true);
      firebase
        .firestore()
        .collection("projects")
        .add({
          ugc: [ugc],
          projectName: projectName,
          workspaceID: workspaceID,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(), // Generate timestamp
        })
        .then((docRef) => {
          // Retrieve the projectID ID after adding the document
          const projectID = docRef.id;
          firebase
            .firestore()
            .collection("workspace")
            .doc(workspaceID)
            .update({
              projects: firebase.firestore.FieldValue.arrayUnion(projectID),
            })
            .then(() => {
              setCloneModalOpen(false);
              history.push(
                `/project?projectID=${projectID}&projectName=${projectName}`
              );
            });

          // Update the user document with the workspaceID
        });
    }
  };

  function convertServerTimestampToDate(serverTimestamp) {
    // Convert timestamp to milliseconds
    const milliseconds = serverTimestamp.toMillis();

    // Create a new Date object using the milliseconds
    const dateObject = new Date(milliseconds);

    // Format the date to "M/D/YY" format
    const formattedDate = dateObject.toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "2-digit",
    });

    return formattedDate;
  }

  useEffect(() => {
    setProjectsAdded([]);
  }, [cloneModalOpen]);
  return (
    <>
      <SkyLightStateless
        hideOnOverlayClicked={true}
        onOverlayClicked={async () => {
          setCloneModalOpen(false);
        }}
        onCloseClicked={async () => {
          setCloneModalOpen(false);
        }}
        isVisible={cloneModalOpen}
        overlayStyles={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9000,
          backgroundColor: "#181616",
          opacity: 0.6,
        }}
        dialogStyles={{
          zIndex: 9000,
          position: "fixed",
          width: "215px",
          minHeight: "60px",
          marginTop: "1%",
          top: "0%",
          height: 0,
          backgroundColor: "transparent",
          border: "0px solid #30312c",
          boxShadow: "none",
          left: "0%",
          right: "23%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        closeButtonStyle={{
          color: "#30312c",
          right: -90,
          position: "absolute",
          marginTop: 6,
          zIndex: 90000,
          height: 0,
          fontSize: 50,
          display: "none",
        }}
      >
        <div
          style={{
            paddingBottom: 0,
            width: 700,
            background: "white",
            borderRadius: 8,
            zIndex: 90000,
            paddingLeft: 0,
          }}
          className="backy60"
        >
          <MDBRow>
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                style={{
                  fontFamily: "PPBold",
                  textAlign: "center",
                  marginTop: 27,
                  fontSize: 25,
                  color: "#2f1878",
                }}
              >
                Create your clone
              </p>
            </MDBCol>
            <MDBCol size="12">
              <div
                style={{
                  paddingBottom: 0,
                  width: 700,
                  borderRadius: 9,
                  overflow: "hidden",
                  height: 700,
                  marginTop: -50,
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    paddingBottom: 0,
                    width: 700,
                    borderRadius: 20,
                    objectFit: "cover",
                    verticalAlign: "top",
                    aspectRatio: 9 / 16,
                    backgroundColor: "transparent",
                  }}
                >
                  {renderSection(section)}
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </SkyLightStateless>
    </>
  );
};

export default withRouter(CloneModal);
