import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

const Signup = ({ setIsOpen, isOpen }) => {
  const [loginEmail, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [finishedReset, setFinishedReset] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [signUpMode, setSignUpMode] = useState(true);
  const [verificationError, setVerificationError] = useState(false);
  const [profile, setProfile] = useState("");
  const location = useLocation();
  const { t } = useTranslation();

  const googleProvider = new firebase.auth.GoogleAuthProvider();
  useEffect(() => {
    if (
      firebase.auth().currentUser &&
      !firebase.auth().currentUser.emailVerified
    ) {
      setIsOpen(true);
    }
  }, [firebase.auth().currentUser]);

  useEffect(() => {
    let query = window.location.search; // Without React Router
    const queryParams = new URLSearchParams(query);
    const id = queryParams.get("id");
    const name = queryParams.get("name");
    let profile;
    if (name) {
      setProfile(name.toLowerCase());
    } else if (id) {
      setProfile(id.toLowerCase());
    }
  }, [isOpen]);

  useEffect(() => {
    setLoginEmail("");
    setPassword("");
    setError("");
  }, [isOpen]);

  const signIn = async (Email, Password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(Email, Password)
      .then(() => {
        mixpanel.track("User signed in", {
          "Sign in method": "Email",
        });
        if (firebase.auth()?.currentUser?.emailVerified === true) {
          setIsOpen(false);
          setError(null);
        } else {
          setError(null);
        }
      })
      .catch((signUpError) => {
        // Sign up failed
        setError(formatErrorMessage(signUpError.message));
      });
  };

  const signUp = async (Email, Password) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(Email, Password)
      .then(async (userCredential) => {
        // Send verification email
        mixpanel.track("User created", {
          "Sign up method": "Email",
        });

        userCredential.user
          .sendEmailVerification()
          .then(() => {
            console.log("Verification email sent.");
          })
          .catch((verificationError) => {
            console.error(
              "Error sending verification email:",
              verificationError
            );
          });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "completeRegistration",
          event_id: uuidv4(), // Replace 'currency' with the transaction currency code
        });

        // Save user data to Firestore
        const uid = userCredential.user.uid;

        if (window.tolt) {
          window.tolt.signup(Email);
        }
        const signUpTime = firebase.firestore.FieldValue.serverTimestamp(); // Record the sign-up time

        return firebase.firestore().collection("users").doc(uid).set({
          email: Email,
          uid: uid,
          credits: 1,
          signUpTime: signUpTime, // Add this line
        });
      })
      .then(() => {
        window.scrollTo(0, 0);
        setError(null);
      })
      .catch((signUpError) => {
        // Sign up failed
        setError(formatErrorMessage(signUpError.message));
      });
  };

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then(async (result) => {
        if (result.user) {
          const user = result.user;
          const uid = user.uid;

          // Check if the user exists in Firestore
          const doc = await firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .get();

          if (!doc.exists) {
            // Handle new user logic
            mixpanel.track("User created", {
              "Sign up method": "Google",
            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "completeRegistration",
              event_id: uuidv4(), // Replace 'currency' with the transaction currency code
            });

            if (window.tolt) {
              window.tolt.signup(user.email);
            }

            const signUpTime = firebase.firestore.FieldValue.serverTimestamp(); // Record the sign-up time

            // Save the new user data to Firestore
            await firebase.firestore().collection("users").doc(uid).set({
              email: user.email,
              uid: uid,
              credits: 1,
              signUpTime: signUpTime, // Add this line
            });
          } else {
            // Handle existing user logic
            mixpanel.track("User signed in", {
              "Sign in method": "Google",
            });
          }

          // UI updates, like closing modal, resetting errors
          setIsOpen(false);
          setError(null);
        }
      })
      .catch((error) => {
        // Handle sign-in errors here
        setError(error.message);
      });
  }, []);

  const onSubmitGoogle = async () => {
    firebase.auth().signInWithRedirect(googleProvider);
  };

  function formatErrorMessage(errorMessage) {
    // Remove "Firebase:" prefix if it exists
    let formattedMessage = errorMessage.startsWith("Firebase:")
      ? errorMessage.replace("Firebase:", "").trim()
      : errorMessage;

    // Remove error code in parentheses at the end
    formattedMessage = formattedMessage.replace(/\(.*?\)\.?$/, "").trim();

    return formattedMessage;
  }

  return (
    <>
      <SkyLightStateless
        hideOnOverlayClicked={true}
        onOverlayClicked={async () => {
          if (
            firebase.auth().currentUser &&
            !firebase.auth().currentUser.emailVerified
          ) {
            return null;
          } else {
            setIsOpen(false);
          }
        }}
        onCloseClicked={async () => {
          if (
            firebase.auth().currentUser &&
            !firebase.auth().currentUser.emailVerified
          ) {
            return null;
          } else {
            setIsOpen(false);
          }
        }}
        isVisible={isOpen}
        overlayStyles={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9000,
          backgroundColor: "#181616",
          opacity: 0.6,
        }}
        dialogStyles={{
          zIndex: 9000,
          position: "fixed",
          width: "430px",
          minHeight: "60px",
          marginTop: "1%",
          top: "7%",
          height: "380",
          backgroundColor: "transparent",
          border: "0px solid #30312c",
          boxShadow: "none",
          left: "5%",
          right: "0%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        closeButtonStyle={{
          color: "#2f1878",
          right: -90,
          position: "absolute",
          marginTop: 6,
          zIndex: 90000,
          height: 0,
          fontSize: 50,
          display: "none",
        }}
      >
        <div
          style={{
            paddingBottom: 50,
            width: 360,
            backgroundColor: "#fff",
            borderRadius: 8,
            zIndex: 90000,
            paddingLeft:
              (resetPassword && finishedReset) ||
              firebase.auth()?.currentUser?.emailVerified === false
                ? 0
                : 20,
          }}
          className="backy60"
        >
          {resetPassword ? (
            finishedReset ? (
              <MDBRow style={{ paddingLeft: 0 }}>
                <MDBCol size="12" className="d-flex justify-content-center">
                  <HiOutlineMailOpen style={{ marginTop: 45, fontSize: 50 }} />
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontWeight: "900",
                      fontFamily: "PPMedium",
                      fontSize: 22,
                      opacity: 1,
                      lineHeight: 1.2,
                      textAlign: "center",
                      color: "#2f1878",
                      display: "flex",
                      marginTop: 20,
                    }}
                  >
                    Check your email
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PPMedium",
                      fontSize: 15,
                      color: "#9b9ea3",
                      opacity: 1,
                      lineHeight: 1.6,
                      marginTop: 10,
                      textAlign: "center",
                    }}
                  >
                    We've sent an password reset link to
                    <br />
                    your inbox
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PPMedium",
                      fontSize: 15,
                      color: "#2f1878",
                      opacity: 1,
                      lineHeight: 1.6,
                      marginTop: 16,
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setResetPassword(false);
                    }}
                  >
                    <BiLeftArrowAlt
                      style={{ fontSize: 21, marginRight: 0, marginTop: -1 }}
                      className="d-inline"
                    />{" "}
                    Back to login
                  </p>
                </MDBCol>
              </MDBRow>
            ) : (
              <MDBRow style={{ paddingLeft: 20 }}>
                <MDBCol className="d-flex justify-content-start" size="12">
                  <p
                    style={{
                      fontWeight: "900",
                      fontFamily: "PPMedium",
                      fontSize: 26,
                      opacity: 1,
                      lineHeight: 1.2,
                      textAlign: "center",
                      color: "#2f1878",
                      display: "flex",
                      marginTop: 30,
                    }}
                  >
                    Reset your password
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-start" size="12">
                  <p
                    style={{
                      fontFamily: "PPMedium",
                      fontSize: 15,
                      color: "#9b9ea3",
                      opacity: 1,
                      lineHeight: 1.5,
                      marginTop: -7,
                    }}
                  >
                    Choose a new password for your <br />
                    UGCME account to sign in.
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-start" size="12">
                  <input
                    style={{
                      height: 45,
                      width: "89%",
                      marginLeft: 0,
                      border: "1px solid rgba(155, 158, 163, 0.5)",
                      borderRadius: 4,
                      display: "inline-block",
                      color: "#2f1878",
                      marginTop: -3,
                      backgroundColor: "white",
                      fontFamily: "PPMedium",
                      paddingLeft: 17,
                      fontSize: 15,
                      paddingTop: 3,
                    }}
                    value={loginEmail}
                    name="Email"
                    onChange={(event) => setLoginEmail(event.target.value)}
                    placeholder="Email address"
                  />
                </MDBCol>
                <MDBCol className="d-flex justify-content-start" size="12">
                  <div
                    style={{
                      height: 50,
                      width: "89%",
                      marginLeft: 0,
                      borderRadius: 4,
                      display: "inline-block",
                      color: "#2f1878",
                      marginTop: 12,
                      backgroundColor: "#2f1878",
                      fontFamily: "PPMedium",
                      fontSize: 13,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      firebase
                        .auth()
                        .sendPasswordResetEmail(loginEmail)
                        .then(() => {
                          setFinishedReset(true);
                        });
                    }}
                    className="d-flex justify-content-center"
                  >
                    <p
                      style={{
                        color: "white",
                        marginTop: 14.9,
                        fontFamily: "PPMedium",
                        fontSize: 15,
                      }}
                    >
                      Reset Password
                    </p>
                  </div>
                </MDBCol>
                {error && (
                  <MDBCol size="12" className="d-flex justify-content-center">
                    <p
                      style={{
                        fontFamily: "PPMedium",
                        fontSize: 15,
                        marginTop: 14,
                        color: "red",
                        marginLeft: -34,
                        textAlign: "center",
                      }}
                    >
                      {error}
                    </p>
                  </MDBCol>
                )}
                <MDBCol size="12">
                  <p
                    style={{
                      fontFamily: "PPMedium",
                      fontSize: 15,
                      marginTop: 14,
                      color: "#9b9ea3",
                    }}
                  >
                    Go back to
                    <p
                      style={{
                        marginLeft: 5,
                        color: "#2f1878",
                        fontFamily: "PPMedium",
                        cursor: "pointer",
                      }}
                      onClick={() => setResetPassword(false)}
                      className="d-inline"
                    >
                      sign in
                    </p>
                  </p>
                </MDBCol>

                <MDBCol size="12">
                  <p
                    style={{
                      fontFamily: "PPMedium",
                      fontSize: 13,
                      marginTop: 24,
                      color: "#9B9EA3",
                    }}
                  >
                    Choose a new password that's at least six
                    <br />
                    characters in length and fairly complex.
                  </p>
                </MDBCol>
              </MDBRow>
            )
          ) : firebase.auth()?.currentUser?.emailVerified === false ? (
            <MDBRow>
              <MDBCol size="12" className="d-flex justify-content-center">
                <HiOutlineMailOpen
                  style={{ marginTop: 45, fontSize: 50, color: "#2f1878" }}
                />
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="12">
                <p
                  style={{
                    fontWeight: "900",
                    fontFamily: "PPMedium",
                    fontSize: 22,
                    opacity: 1,
                    lineHeight: 1.2,
                    textAlign: "center",
                    color: "#2f1878",
                    display: "flex",
                    marginTop: 20,
                  }}
                >
                  Check your email
                </p>
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="12">
                <p
                  style={{
                    fontFamily: "PPMedium",
                    fontSize: 15,
                    color: "#2f1878",
                    opacity: 1,
                    lineHeight: 1.6,
                    marginTop: 10,
                    textAlign: "center",
                  }}
                >
                  We've sent an email verification link to
                  <br />
                  {firebase.auth().currentUser
                    ? firebase.auth().currentUser.email
                    : null}
                </p>
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="12">
                <div
                  style={{
                    height: 50,
                    width: "89%",
                    marginLeft: 0,
                    borderRadius: 4,
                    display: "inline-block",
                    color: "#2f1878",
                    marginTop: 12,
                    backgroundColor: "#2f1878",
                    fontFamily: "PPMedium",
                    fontSize: 13,
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    const user = firebase.auth().currentUser;
                    await user.reload();
                    console.log(user.emailVerified);
                    if (user.emailVerified) {
                      setIsOpen(false);
                    } else {
                      setVerificationError(true);
                    }
                  }}
                  className="d-flex justify-content-center"
                >
                  <p
                    style={{
                      color: "white",
                      marginTop: 14.9,
                      fontFamily: "PPMedium",
                      fontSize: 15,
                    }}
                  >
                    I've verified my email
                  </p>
                </div>
              </MDBCol>
              {verificationError && (
                <MDBCol size="12" className="d-flex justify-content-center">
                  <p
                    style={{
                      fontFamily: "PPMedium",
                      fontSize: 15,
                      marginTop: 14,
                      color: "red",
                      textAlign: "center",
                    }}
                  >
                    Your email isn't verified yet
                  </p>
                </MDBCol>
              )}
              <MDBCol className="d-flex justify-content-center" size="12">
                <p
                  style={{
                    fontFamily: "PPMedium",
                    fontSize: 15,
                    color: "#2f1878",
                    opacity: 1,
                    lineHeight: 1.6,
                    marginTop: 16,
                    textAlign: "center",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    firebase.auth().currentUser.sendEmailVerification();
                  }}
                >
                  Resend verification link
                </p>
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="12">
                <p
                  style={{
                    fontFamily: "PPMedium",
                    fontSize: 15,
                    color: "#2f1878",
                    opacity: 1,
                    lineHeight: 1.6,
                    marginTop: 16,
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    firebase.auth().signOut();
                  }}
                >
                  <BiLeftArrowAlt
                    style={{ fontSize: 21, marginRight: 0, marginTop: -1 }}
                    className="d-inline"
                  />{" "}
                  Back to login
                </p>
              </MDBCol>
            </MDBRow>
          ) : (
            <MDBRow style={{ paddingLeft: 20 }}>
              <MDBCol className="d-flex justify-content-center" size="12">
                <p
                  style={{
                    fontWeight: "900",
                    fontFamily: "PPMedium",
                    fontSize: 17,
                    opacity: 1,
                    lineHeight: 1.2,
                    textAlign: "center",
                    color: "#2f1878",
                    display: "flex",
                    marginTop: 30,
                    marginLeft: -50,
                  }}
                >
                  {signUpMode ? "Sign up" : "Sign in"}
                </p>
              </MDBCol>
              <MDBCol className="d-flex justify-content-start" size="12">
                <div
                  style={{
                    height: 45,
                    width: "89%",
                    backgroundColor: "white",
                    boxShadow:
                      "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                    marginTop: 9,
                    cursor: "pointer",
                    borderRadius: 4,
                  }}
                  onClick={() => onSubmitGoogle()}
                  className="d-flex justify-content-center"
                >
                  <img
                    src={require("./Google.png")}
                    style={{
                      position: "absolute",
                      height: 37,
                      left: 35,
                      marginTop: 3,
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "PPBold",
                      color: "#2f1878",
                      marginTop: 11,
                      fontSize: 16,
                      opacity: 0.8,
                    }}
                  >
                    Continue with Google
                  </p>
                </div>
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="12">
                <p
                  style={{
                    fontFamily: "PPMedium",
                    color: "#2f1878",
                    marginLeft: -50,
                    marginTop: 5,
                    opacity: 0.6,
                  }}
                >
                  or
                </p>
              </MDBCol>

              <MDBCol className="d-flex justify-content-start" size="12">
                <input
                  style={{
                    height: 45,
                    width: "89%",
                    marginLeft: 0,
                    borderRadius: 4,
                    display: "inline-block",
                    color: "#2f1878",
                    marginTop: -6,
                    backgroundColor: "white",
                    fontFamily: "PPMedium",
                    paddingLeft: 17,
                    fontSize: 15,
                    paddingTop: 2,
                    border: "1px solid rgba(155, 158, 163, 0.5)",
                  }}
                  value={loginEmail}
                  name="Email"
                  onChange={(event) => setLoginEmail(event.target.value)}
                  placeholder="Email address"
                />
              </MDBCol>
              <MDBCol className="d-flex justify-content-start" size="12">
                <input
                  style={{
                    height: 45,
                    width: "89%",
                    marginLeft: 0,
                    border: "1px solid rgba(155, 158, 163, 0.5)",
                    borderRadius: 4,
                    display: "inline-block",
                    color: "#2f1878",
                    marginTop: 10,
                    backgroundColor: "white",
                    fontFamily: "PPMedium",
                    paddingLeft: 17,
                    fontSize: 15,
                    paddingTop: 2,
                  }}
                  value={password}
                  type="password"
                  name="Password"
                  onChange={(event) => setPassword(event.target.value)}
                  placeholder="Password"
                />
              </MDBCol>
              <MDBCol className="d-flex justify-content-start" size="12">
                <div
                  style={{
                    height: 50,
                    width: "89%",
                    marginLeft: 0,
                    borderRadius: 4,
                    display: "inline-block",
                    color: "#2f1878",
                    marginTop: 12,
                    backgroundColor: "#2f1878",
                    fontFamily: "PPMedium",
                    fontSize: 13,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (signUpMode) {
                      signUp(loginEmail, password);
                    } else {
                      signIn(loginEmail, password);
                    }
                  }}
                  className="d-flex justify-content-center"
                >
                  <p
                    style={{
                      color: "white",
                      marginTop: 14.9,
                      fontFamily: "PPMedium",
                      fontSize: 15,
                    }}
                  >
                    {signUpMode ? "Create account" : "Sign in"}
                  </p>
                </div>
              </MDBCol>
              {error && (
                <MDBCol size="12" className="d-flex justify-content-center">
                  <p
                    style={{
                      fontFamily: "PPMedium",
                      fontSize: 15,
                      marginTop: 14,
                      color: "red",
                      marginLeft: -34,
                      textAlign: "center",
                      width: 290,
                    }}
                  >
                    {error}
                  </p>
                </MDBCol>
              )}
              <MDBCol size="12">
                <p
                  style={{
                    fontFamily: "PPMedium",
                    fontSize: 15,
                    marginTop: 14,
                    color: "#9b9ea3",
                  }}
                >
                  Already have an account?
                  <p
                    style={{
                      marginLeft: 5,
                      color: "#2f1878",
                      fontFamily: "PPMedium",
                      cursor: "pointer",
                    }}
                    onClick={() => setSignUpMode(!signUpMode)}
                    className="d-inline"
                  >
                    {!signUpMode ? "Sign up" : "Log in"}
                  </p>
                </p>
              </MDBCol>
              {signUpMode ? (
                <MDBCol size="12">
                  <p
                    style={{
                      fontFamily: "PPMedium",
                      fontSize: 13,
                      marginTop: -4,
                      color: "#9B9EA3",
                    }}
                  >
                    By continuing, you agree to UGCME's{" "}
                    <NavLink
                      exact
                      to="terms-of-service"
                      className="d-inline"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        opacity: 1,
                        color: "#9B9EA3",
                      }}
                    >
                      Terms
                      <br /> of Service
                    </NavLink>
                    . Read our{" "}
                    <NavLink
                      exact
                      to="privacy-policy"
                      className="d-inline"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "#9B9EA3",
                      }}
                    >
                      Privacy Policy
                    </NavLink>
                    .
                  </p>
                </MDBCol>
              ) : (
                <MDBCol size="12">
                  <p
                    style={{
                      fontFamily: "PPMedium",
                      fontSize: 13,
                      marginTop: -4,
                      color: "#9B9EA3",
                    }}
                  >
                    Have you forgotten your password?{" "}
                    <p
                      className="d-inline"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => setResetPassword(true)}
                    >
                      Click
                      <br /> here
                    </p>{" "}
                    to reset your password.
                  </p>
                </MDBCol>
              )}
            </MDBRow>
          )}
        </div>
      </SkyLightStateless>
    </>
  );
};

export default withRouter(Signup);
