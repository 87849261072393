import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import "react-circular-progressbar/dist/styles.css";
import { firebase } from "../../../firebase/config";

const Section3 = ({ setIsOpen, requestLoading }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  if (requestLoading === true) {
    return null;
  }

  return (
    <>
      <MDBCol className="d-flex justify-content-center" size="12">
        <p
          onClick={() => goBack()}
          style={{
            display: "inline-block",
            fontFamily: "CSBold",
            fontSize: 18,
            color: "rgb(255, 215, 0)",
          }}
        >
          Premium Feature
        </p>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <p
          onClick={() => goBack()}
          style={{
            display: "inline-block",
            fontFamily: "CSBold",
            fontSize: 35,
            marginTop: -10,
          }}
        >
          Unlock hundreds of celebrities
        </p>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <p
          onClick={() => goBack()}
          style={{
            display: "inline-block",
            fontFamily: "CSMedium",
            fontSize: 18,
            textAlign: "center",
            marginTop: -15,
          }}
        >
          Purchase credits to unlock hundreds of A-List celebrities and
          templates.
        </p>
      </MDBCol>
      <MDBCol
        className="d-flex justify-content-center"
        style={{ cursor: "pointer", marginTop: 10 }}
        size="12"
      ></MDBCol>
    </>
  );
};

export default withRouter(Section3);
