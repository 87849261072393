import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import LazyLoad from "react-lazyload";

const ActorSection = ({
  addItem,
  publicActors,
  updateActorFields,
  setVoiceID,
  videoItems,
  updateProjectThumbnail,
}) => {
  const items = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 6, 7, 8, 9, 10, 11, 12, 13,
    14,
  ];

  return (
    <MDBRow style={{ marginTop: 75, marginLeft: 0, overflowY: "hidden" }}>
      <MDBCol size="12">
        <p
          style={{
            fontSize: 18,
            color: "#fff",
            fontFamily: "SSBold",
            opacity: 0.9,
          }}
        >
          Choose an AI actor
        </p>
      </MDBCol>
      <MDBCol size="12">
        <p
          style={{
            fontSize: 18,
            color: "#fff",
            fontFamily: "SSBold",
            opacity: 0.9,
          }}
        >
          Public actors
        </p>
      </MDBCol>
      <MDBCol style={{ marginTop: -6 }} size="12">
        <MDBRow
          style={{
            overflowY: "scroll",
            height: "82vh",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            width: "100%",
            paddingBottom: 30,
            WebkitOverflowScrolling: "touch",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {publicActors.map((actor, index) => {
            return (
              <MDBCol
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  marginTop: index > 1 ? 20 : 0,
                  marginBottom: 0,
                }}
                size="6"
              >
                <img
                  src={`https://image.mux.com/${actor.playbackID}/animated.gif`}
                  style={{
                    width: "9vw",
                    aspectRatio: "9 / 16",
                    borderRadius: 3,
                    cursor: "pointer",
                    marginTop: 0,
                  }}
                  onClick={() => {
                    const hasActorItems = videoItems.some(
                      (item) => item.type === "actor"
                    );
                    if (hasActorItems) {
                      updateActorFields(
                        `https://image.mux.com/${actor.playbackID}/thumbnail.png`,
                        actor.transparentThumbnail,
                        actor.ugcID,
                        actor.name,
                        actor.transparentURL
                      );
                      updateProjectThumbnail(
                        `https://image.mux.com/${actor.playbackID}/thumbnail.png`
                      );
                      setVoiceID(actor.voiceID);
                    } else {
                      addItem({
                        // videoURL: actor.videoURL,
                        videoURL: "",
                        // Replace with the URL of the second video
                        videoRef: React.createRef(),
                        startTime: 0,
                        initialStartTime: 0,
                        endTime: 17,
                        initialEndTime: 17,
                        trimStart: 0,
                        trimEnd: 17,
                        x: "50%",
                        y: "50%",
                        width: "100%",
                        height: "100%",
                        layerIndex: 2,
                        type: "actor",
                        thumbnail: `https://image.mux.com/${actor.playbackID}/thumbnail.png`,
                        transparentThumbnail: actor.transparentThumbnail,
                        ugcID: actor.ugcID,
                        name: actor.name,
                        voiceID: actor.voiceID,
                        rotation: 0,
                        transparentURL: actor.transparentURL,
                        volume: 1,
                      });
                      updateProjectThumbnail(
                        `https://image.mux.com/${actor.playbackID}/thumbnail.png`
                      );
                      setVoiceID(actor.voiceID);
                    }
                  }}
                />
              </MDBCol>
            );
          })}
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(ActorSection);
