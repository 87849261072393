import React, { useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { TiTick } from "react-icons/ti";
import PriceHeading from "./PriceHeading";
import { MdOutlineOutlinedFlag } from "react-icons/md";
import { AiTwotoneExperiment } from "react-icons/ai";
import { FaRegSmile } from "react-icons/fa";
import { CgShutterstock } from "react-icons/cg";
import { RiUserVoiceLine } from "react-icons/ri";
import { TbTimelineEvent } from "react-icons/tb";
import { BsLightningCharge } from "react-icons/bs";
import { LiaCloneSolid } from "react-icons/lia";
import { IoCodeOutline } from "react-icons/io5";

const Pricing = ({ subscribed, discountRate, subscribedPlans, setIsOpen }) => {
  const [value, setValue] = useState(25);
  const [monthly, setMonthly] = useState(false);
  const [sliderValues, setSliderValues] = useState({
    Starter: 0,
    Growing: 0,
    Scale: 0,
  });

  const handleChange = (event) => {
    setValue(Number(event.target.value));
  };

  const handleSliderChange = (tier, newValue) => {
    setSliderValues((prevValues) => ({
      ...prevValues,
      [tier]: newValue,
    }));
  };

  const featureIcons = {
    "20 tested & effective UGC templates": AiTwotoneExperiment,
    "30+ expressive AI Actors": FaRegSmile,
    "1mn+ stock videos and images": CgShutterstock,
    "Realistic AI voices in 27+ languages": RiUserVoiceLine,
    "Powerful video editor with subtitles.": TbTimelineEvent,
    "Fast generation": BsLightningCharge,
    "Faster generation": BsLightningCharge,
    "Fastest generation": BsLightningCharge,
    "200 tested, effective UGC templates": AiTwotoneExperiment,
    "Create 3 custom AI actors": LiaCloneSolid,
    "All tested, effective UGC templates": AiTwotoneExperiment,
    "Create 20 custom AI actors": LiaCloneSolid,
    "Unlimited custom AI actors": LiaCloneSolid,
    "API access - automate marketing": IoCodeOutline,
  };

  const plans = [
    {
      tier: "Starter",
      features: [
        "20 tested & effective UGC templates",
        "30+ expressive AI Actors",
        "1mn+ stock videos and images",
        "Realistic AI voices in 27+ languages",
        "Powerful video editor with subtitles.",
        "Fast generation",
      ],
    },
    {
      tier: "Growing",
      features: [
        "200 tested, effective UGC templates",
        "30+ expressive AI Actors",
        "1mn+ stock videos and images",
        "Realistic AI voices in 27+ languages",
        "Powerful video editor with subtitles.",
        "Faster generation",
        "Create 3 custom AI actors",
      ],
    },
    {
      tier: "Scale",
      features: [
        "All tested, effective UGC templates",
        "30+ expressive AI Actors",
        "1mn+ stock videos and images",
        "Realistic AI voices in 27+ languages",
        "Powerful video editor with subtitles.",
        "Faster generation",
        "Create 20 custom AI actors",
      ],
    },
    {
      tier: "Enterprise",
      features: [
        "All tested, effective UGC templates",
        "30+ expressive AI Actors",
        "1mn+ stock videos and images",
        "Realistic AI voices in 27+ languages",
        "Powerful video editor with subtitles.",
        "Fastest generation",
        "Unlimited custom AI actors",
        "API access - automate marketing",
      ],
    },
  ];

  const pricesArray = {
    Starter: [
      { 10: { monthly: 60, annual: 48, templates: 20, ads: 10 } },
      { 20: { monthly: 120, annual: 96, templates: 20, ads: 20 } },
    ],
    Growing: [
      { 30: { monthly: 150, annual: 120, templates: 200, ads: 30 } },
      { 60: { monthly: 300, annual: 240, templates: 200, ads: 60 } },
      { 90: { monthly: 450, annual: 360, templates: 200, ads: 90 } },
    ],
    Scale: [
      { 250: { monthly: 750, annual: 600, templates: "All", ads: 250 } },
      { 375: { monthly: 1125, annual: 900, templates: "All", ads: 375 } },
      { 500: { monthly: 1500, annual: 1200, templates: "All", ads: 500 } },
      { 1000: { monthly: 3000, annual: 2400, templates: "All", ads: 1000 } },
    ],
  };

  return (
    <>
      <MDBRow style={{ marginTop: 50 }}>
        <MDBCol className="d-flex justify-content-center" size="12">
          <p
            style={{
              fontFamily: "SSBold",
              fontSize: 80,
              marginTop: 30,
              textAlign: "center",
              color: "black",
              lineHeight: 1,
            }}
          >
            Pricing
          </p>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <p
            style={{
              color: "#30312c",
              fontFamily: "SSMono",
              fontSize: 23,
              textAlign: "center",
              marginTop: 20,
            }}
          >
            Winning video ads. Affordable for anyone.
          </p>
        </MDBCol>
      </MDBRow>
      <MDBRow
        style={{ marginTop: 30 }}
        className="d-flex justify-content-center"
      >
        <MDBCol className="d-flex justify-content-center" size="12">
          <MDBRow
            className="d-flex justify-content-center"
            style={{
              width: 1320,
              marginTop: 10,
              color: "#30312c",
              paddingRight: 0,
              paddingLeft: 0,
              marginLeft: -20,
            }}
          >
            {plans.map((plan, index) => (
              <MDBCol size="3" key={index}>
                <div
                  style={{
                    paddingLeft: 20,
                    fontFamily: "PPMedium",
                    paddingLeft: 20,
                    width: 320,
                    paddingTop: 40,
                    borderRadius: 0,
                    border: "1px solid blue",
                    backgroundColor: "#fff",
                    height: 900,
                  }}
                >
                  <PriceHeading
                    subscribedPlans={subscribedPlans}
                    planType="Monthly"
                    tier={plan.tier}
                    setIsOpen={setIsOpen}
                    monthly={monthly}
                    price={
                      plan.tier !== "Enterprise" &&
                      Object.values(
                        pricesArray[plan.tier][sliderValues[plan.tier]]
                      )[0]
                    }
                  />
                  {plan.tier !== "Enterprise" && (
                    <div
                      style={{
                        borderBottom: "1px solid blue",
                        height: 70,
                        width: 319,
                        marginLeft: -20,
                      }}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{
                          marginTop: 48,
                          color: "blue",
                          width: "100%",
                          paddingLeft: 20,
                          paddingRight: 20,
                        }}
                      >
                        {pricesArray[plan.tier]?.map((step) => {
                          const [key, value] = Object.entries(step)[0];
                          return (
                            <p
                              style={{
                                fontFamily: "SSMono",
                                marginTop: -26,
                                color: "black",
                              }}
                            >
                              {key}
                            </p>
                          );
                        })}
                      </div>

                      <div
                        className="d-flex justify-content-center"
                        style={{ width: "100%" }}
                      >
                        <input
                          type="range"
                          min={0}
                          max={
                            pricesArray[plan.tier]
                              ? pricesArray[plan.tier].length - 1
                              : 1
                          }
                          value={sliderValues[plan.tier]}
                          onChange={(e) =>
                            handleSliderChange(
                              plan.tier,
                              Number(e.target.value)
                            )
                          }
                          style={{ width: 270, borderRadius: 0 }}
                        />
                      </div>
                    </div>
                  )}
                  <p
                    style={{
                      marginTop: 37,
                      fontSize: 16,
                      fontFamily: "SSSemiBold",
                      paddingBottom: 22,
                    }}
                  >
                    Features
                  </p>
                  {plan.features.map((feature, index) => {
                    const IconComponent = featureIcons[feature];

                    return (
                      <div
                        key={index}
                        style={{ marginTop: index === 0 ? -25 : 12 }}
                      >
                        <div
                          style={{
                            height: 22,
                            width: 22,
                            borderRadius: 200,
                            border: "0px solid white",
                            paddingTop: 1.7,
                            display: "inline-block",
                          }}
                          className="d-flex justify-content-center"
                        >
                          {IconComponent && (
                            <IconComponent style={{ fontSize: 24 }} />
                          )}
                        </div>
                        <p
                          style={{
                            display: "inline-block",
                            position: "absolute",
                            marginTop: -19.5,
                            marginLeft: 28,
                            fontSize: 15,
                            fontFamily: "SSMedium",
                          }}
                        >
                          {feature}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default Pricing;
