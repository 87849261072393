import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSignUpModal } from "../Context/SignUpModal";
import { RiCloseFill } from "react-icons/ri";
import { FiCheck, FiCopy, FiHeadphones, FiMail, FiPlus } from "react-icons/fi";
import { FaLink } from "react-icons/fa";

const Invite = () => {
  const [inviteEmail, setInviteEmail] = useState("");
  const [password, setPassword] = useState("");
  const { invitesOpen, setInvitesOpen } = useSignUpModal();
  const [error, setError] = useState("");
  const [finishedReset, setFinishedReset] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [signUpMode, setSignUpMode] = useState(true);
  const [verificationError, setVerificationError] = useState(false);
  const [planType, setPlanType] = useState("Monthly");
  const [profile, setProfile] = useState("");

  const plans = [
    {
      title: "Free",
      price: "$0",
      features: [
        "1 Free Credit",
        "1 Space Seat",
        "120+ Public Avatars",
        "300+ Public Voices",
        "Photo Avatar",
        "Generative AI Outfit",
        "Avatar FaceSwap",
        "400+ Video Templates",
      ],
    },
    {
      title: "Creator",
      price: "$179",
      features: [
        "1 Free Credit",
        "1 Space Seat",
        "120+ Public Avatars",
        "300+ Public Voices",
        "Photo Avatar",
        "Generative AI Outfit",
        "Avatar FaceSwap",
        "400+ Video Templates",
      ],
    },
    {
      title: "Business",
      price: "$539",
      features: [
        "1 Free Credit",
        "1 Space Seat",
        "120+ Public Avatars",
        "300+ Public Voices",
        "Photo Avatar",
        "Generative AI Outfit",
        "Avatar FaceSwap",
        "400+ Video Templates",
      ],
    },
    {
      title: "Enterprise",
      price: "Let's talk",
      features: [
        "1 Free Credit",
        "1 Space Seat",
        "120+ Public Avatars",
        "300+ Public Voices",
        "Photo Avatar",
        "Generative AI Outfit",
        "Avatar FaceSwap",
        "400+ Video Templates",
      ],
    },
  ];

  return (
    <>
      <SkyLightStateless
        hideOnOverlayClicked={true}
        onOverlayClicked={async () => {
          setInvitesOpen(false);
        }}
        onCloseClicked={async () => {
          setInvitesOpen(false);
        }}
        isVisible={invitesOpen}
        overlayStyles={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9000,
          backgroundColor: "#181616",
          opacity: 0.6,
        }}
        dialogStyles={{
          zIndex: 9000,
          position: "fixed",
          width: "430px",
          minHeight: "60px",
          marginTop: "1%",
          top: "14%",
          height: 500,
          backgroundColor: "transparent",
          border: "0px solid #30312c",
          boxShadow: "none",
          left: "0%",
          right: "24%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        closeButtonStyle={{
          color: "#30312c",
          right: -90,
          position: "absolute",
          marginTop: 6,
          zIndex: 90000,
          height: 0,
          fontSize: 50,
          display: "none",
        }}
      >
        <div
          style={{
            paddingBottom: 50,
            width: 850,
            background: "linear-gradient(180deg, #F0F8FF 0%, #FFF 100%)",
            borderRadius: 8,
            height: 470,
            zIndex: 90000,
            paddingLeft:
              (resetPassword && finishedReset) ||
              firebase.auth()?.currentUser?.emailVerified === false
                ? 0
                : 20,
            display: "flex",
          }}
          className="backy60"
        >
          <div
            style={{
              width: 425,
              height: 470,
              paddingLeft: 15,
              paddingTop: 40,
              paddingRight: 30,
            }}
          >
            <p style={{ fontFamily: "PPBold", color: "#0c41ab", fontSize: 25 }}>
              Create UGC together
            </p>
            <p
              style={{
                fontFamily: "PPMedium",
                color: "#0c41ab",
                fontSize: 14,
                marginTop: -10,
              }}
            >
              Make magic together. Invite your team to work together on creative
              briefs, or build shared libraries of reference ads and insights.
            </p>
            <div
              style={{ backgroundColor: "black", height: 260, width: 380 }}
            ></div>
          </div>
          <div
            style={{
              width: 425,
              height: 470,
              backgroundColor: "white",
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              paddingLeft: 25,
              paddingTop: 40,
            }}
          >
            <p style={{ fontFamily: "PPBold", color: "#0c41ab", fontSize: 25 }}>
              Invite your team
            </p>
            <div
              style={{
                marginTop: 20,
                width: 340,
                height: 120,
                backgroundColor: "#F0F8FF",
                borderRadius: 7,
                paddingLeft: 20,
                paddingTop: 15,
              }}
            >
              <p
                style={{
                  fontFamily: "PPMedium",
                  color: "#0c41ab",
                  fontSize: 14,
                }}
              >
                <FiMail
                  style={{ marginRight: 8, fontSize: 17, marginTop: -2 }}
                />
                Invite by email
              </p>
              <input
                style={{
                  height: 40,
                  width: "93%",
                  marginLeft: 0,
                  border: "1px solid rgba(155, 158, 163, 0.5)",
                  borderRadius: 6,
                  display: "inline-block",
                  color: "#30312c",
                  marginTop: -11,
                  backgroundColor: "white",
                  fontFamily: "PPMedium",
                  paddingLeft: 17,
                  fontSize: 13,
                  paddingTop: 3,
                  opacity: 0.7,
                  boxShadow:
                    "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                }}
                value={inviteEmail}
                name="Email"
                onChange={(event) => setInviteEmail(event.target.value)}
                placeholder="email@example.com"
              />
              <p
                style={{
                  fontSize: 10,
                  fontFamily: "PPMedium",
                  color: "#0c41ab",
                  marginTop: 10,
                }}
              >
                <FiPlus style={{ marginRight: 3, marginTop: -1.3 }} />
                Add another
              </p>
            </div>
            <hr
              style={{
                width: 339,
                backgroundColor: "#F0F0F0",
                marginLeft: 0,
                marginTop: 30,
              }}
            />
            <p
              style={{
                textAlign: "center",
                backgroundColor: "white",
                padding: 10,
                marginTop: -36,
                position: "absolute",
                marginLeft: 150,
                fontFamily: "PPMedium",
                fontSize: 13,
                opacity: 0.7,
              }}
            >
              OR
            </p>
            <div
              style={{
                marginTop: 20,
                width: 340,
                height: 110,
                backgroundColor: "#F0F8FF",
                borderRadius: 7,
                paddingLeft: 20,
                paddingTop: 15,
              }}
            >
              <p
                style={{
                  fontFamily: "PPMedium",
                  color: "#0c41ab",
                  fontSize: 14,
                }}
              >
                <FaLink
                  style={{
                    marginRight: 8,
                    fontSize: 17,
                    marginTop: -2,
                    opacity: 0.7,
                  }}
                />
                Share invitation link
              </p>

              <input
                style={{
                  height: 40,
                  width: "93%",
                  marginLeft: 0,
                  border: "1px solid rgba(155, 158, 163, 0.5)",
                  borderRadius: 6,
                  display: "inline-block",
                  color: "#30312c",
                  marginTop: -11,
                  backgroundColor: "white",
                  fontFamily: "PPMedium",
                  paddingLeft: 17,
                  fontSize: 13,
                  paddingTop: 3,
                  opacity: 0.7,
                  boxShadow:
                    "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                }}
                value="https://app.magicbrief.com/invite/user?code=404cb3ab-f948-44d8-a2f9-83c47f30e7da"
                name="Email"
                onChange={(event) => setInviteEmail(event.target.value)}
                placeholder="email@example.com"
                disabled
              />
              <div
                style={{
                  height: 40,
                  width: 100,
                  backgroundColor: "white",
                  position: "absolute",
                  marginLeft: "46%",
                  marginTop: -40,
                  border: "1px solid rgba(155, 158, 163, 0.5)",
                  borderTopRightRadius: 6,
                  borderBottomRightRadius: 6,
                  cursor: "pointer",
                }}
                className="d-flex justify-content-center"
              >
                <FiCopy style={{ marginTop: 11, fontSize: 17 }} />
              </div>
            </div>
            <MDBCol
              style={{ paddingTop: 20, paddingRight: 40 }}
              className="d-flex justify-content-end"
              size="12"
            >
              <div
                style={{
                  height: 40,
                  width: 90,
                  borderRadius: 6,
                  border: "2px solid #F0F8FF",
                  marginRight: 10,
                  textAlign: "center",
                  fontFamily: "PPMedium",
                  fontSize: 14,
                  paddingTop: 9,
                  cursor: "pointer",
                  boxShadow:
                    "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                }}
                onClick={() => setInvitesOpen(false)}
              >
                <p>Cancel</p>
              </div>
              <div
                style={{
                  height: 40,
                  width: 120,
                  borderRadius: 6,
                  border: "1px solid #0c41ab",
                  backgroundColor: "#0c41ab",
                  color: "white",
                  fontSize: 14,
                  paddingTop: 9,
                  textAlign: "center",
                  fontFamily: "PPMedium",
                  cursor: "pointer",
                }}
              >
                <p>Send Invites</p>
              </div>
            </MDBCol>
          </div>
        </div>
      </SkyLightStateless>
    </>
  );
};

export default withRouter(Invite);
