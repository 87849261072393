import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import { FaRegCompass, FaRegStar } from "react-icons/fa";

const PackTemplates = () => {
  const categories = [
    "Pre-Order",
    "Testimonial",
    "What I Ordered",
    "Myth",
    "Weight Loss",
    "Nutrition",
    "Su Metabolism",
    "Detox",
    "Hormones",
    "Nutrition",
    "Su Metabolism",
    "Detox",
    "Hormones",
    "Hormones",
    "Nutrition",
    "Su Metabolism",
    "Detox",
    "Hormones",
  ];
  return categories.map((item) => {
    return (
      <MDBCol size="4">
        <div
          style={{
            height: 250,
            width: "25vw",
            backgroundColor: "white",
            marginBottom: 30,
            borderRadius: 9,
            boxShadow:
              "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          }}
        >
          <MDBRow>
            <MDBCol style={{ paddingTop: 10 }} size="5">
              <img
                style={{
                  height: 230,
                  width: "100%",
                  borderRadius: 8,
                  marginLeft: 10,
                  objectFit: "cover",
                }}
                src={require("./valentine.jpg")}
              />
            </MDBCol>
            <MDBCol style={{ paddingTop: 10, marginLeft: 0 }} size="7">
              <MDBRow style={{ marginTop: 10 }}>
                <MDBCol size="12">
                  <p
                    style={{
                      fontFamily: "PPMedium",
                      fontSize: 17,
                      color: "#2f1878",
                    }}
                  >
                    Valentines day
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontFamily: "PPMedium",
                      fontSize: 13,
                      color: "black",
                      marginTop: -15,
                      fontWeight: "600",
                      opacity: 0.5,
                    }}
                  >
                    20 VIDEOS
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontFamily: "PPMedium",
                      fontSize: 14,
                      marginTop: 5,
                      color: "#2f1878",
                      opacity: 0.8,
                    }}
                  >
                    Get ready for the big day of love with this Valentines Day
                    pack.
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <NavLink exact to="/packs?packID=1234">
                    <div
                      style={{
                        height: 50,
                        width: "95%",
                        marginTop: 13,
                        borderRadius: 5,
                        boxShadow:
                          "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                        textAlign: "center",
                        fontFamily: "PPMedium",
                        paddingTop: 13.5,
                        fontWeight: "500",
                        backgroundColor: "white",
                        border: "1px solid #F0F0F0",
                      }}
                    >
                      <p
                        style={{
                          marginTop: 0,
                          color: "#2f1878",
                        }}
                      >
                        View Pack
                      </p>
                    </div>
                  </NavLink>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </div>
      </MDBCol>
    );
  });
};

export default withRouter(PackTemplates);
