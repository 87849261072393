import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";

const CongratModalMobile = ({ setIsOpen, isOpen, profileID }) => {
  const { t } = useTranslation();

  return (
    <>
      <SkyLightStateless
        hideOnOverlayClicked={true}
        onOverlayClicked={async () => {
          setIsOpen(false);
        }}
        onCloseClicked={async () => {
          setIsOpen(false);
        }}
        isVisible={isOpen}
        overlayStyles={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9000,
          opacity: 0.6,
          backgroundColor: "rgba(24, 22, 22, 0.6)", // Semi-transparent background
          backdropFilter: "blur(10px)", // Blur effect for the background elements
        }}
        dialogStyles={{
          zIndex: 9000,
          position: "fixed",
          width: "100vw",
          minHeight: "60px",
          marginTop: "0%",
          top: "0%",
          height: "100vh",
          boxShadow: "none",
          left: "0%",
          right: "0%",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "rgba(18, 17, 20, 0.04)", // Semi-transparent white for the glass effect
          border: "1px solid transparent", // Slight border for depth
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for more depth
          backdropFilter: "blur(6px)", // Apply blur to the dialog for the glass effect
        }}
        closeButtonStyle={{
          color: "white",
          right: 650,
          position: "absolute",
          marginTop: 90,
          zIndex: 9600,
          fontSize: 50,
          height: 60,
          width: 60,
          display: "none",
        }}
      >
        <OutsideClickHandler
          onOutsideClick={async () => {
            setIsOpen(false);
          }}
        >
          <div
            style={{
              width: "90vw",
              paddingBottom: "9vw",
              backgroundColor: "#201e23",
              borderRadius: 8,
              zIndex: 90000,
              paddingLeft: 0,
              top: "22%",
              position: "absolute",
              left: "0%",
              right: "0%",
              marginLeft: "auto",
              marginRight: "auto",
              border: "1px solid #505050",
            }}
            className="backy60"
          >
            <MDBRow>
              <MDBCol className="d-flex justify-content-center" size="12">
                <p
                  style={{
                    fontFamily: "CSBold",
                    color: "white",
                    fontSize: "6vw",
                    marginTop: 40,
                    textAlign: "center",
                  }}
                >
                  {t("deleteCloneTitle")}
                </p>
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="12">
                <hr
                  style={{
                    height: 4,
                    width: 60,
                    backgroundColor: "white",
                    color: "white",
                    marginTop: -8,
                  }}
                />
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="12">
                <p
                  style={{
                    fontFamily: "CSMedium",
                    color: "white",
                    fontSize: "3.8vw",
                    marginTop: 10,
                    textAlign: "center",
                    width: "70vw",
                    lineHeight: 1.4,
                    opacity: 0.9,
                    marginTop: -1,
                  }}
                >
                  {t("deleteCloneSubtitle")}
                </p>
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="12">
                <MDBRow>
                  <div
                    style={{
                      height: 50,
                      width: 140,
                      border: "2px solid white",
                      borderRadius: 100,
                      marginTop: 12,
                      backgroundColor: "white",
                      cursor: "pointer",
                      marginRight: 9,
                    }}
                    onClick={() => setIsOpen(false)}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <p
                      style={{
                        fontFamily: "CSMedium",
                        fontSize: 16,
                        display: "inline-block",
                        color: "black",
                        zIndex: 300,
                        margin: 0,
                      }}
                    >
                      {t("cancelDeleteCloneButton")}
                    </p>
                  </div>
                  <div
                    style={{
                      height: 50,
                      width: 140,
                      border: "2px solid red",
                      borderRadius: 100,
                      marginTop: 12,
                      backgroundColor: "red",
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      try {
                        if (firebase.auth().currentUser) {
                          await firebase
                            .firestore()
                            .collection("profiles")
                            .doc(profileID)
                            .delete()
                            .then(() => setIsOpen(false));
                        }
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <p
                      style={{
                        fontFamily: "CSMedium",
                        fontSize: 16,
                        display: "inline-block",
                        color: "white",
                        zIndex: 300,
                        margin: 0,
                      }}
                    >
                      {t("deleteCloneButton")}
                    </p>
                  </div>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </div>
        </OutsideClickHandler>
      </SkyLightStateless>
    </>
  );
};

export default withRouter(CongratModalMobile);
