import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation, useHistory } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen, HiSwitchHorizontal } from "react-icons/hi";
import { BiColorFill, BiHelpCircle, BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSignUpModal } from "../Context/SignUpModal";
import { TfiDownload } from "react-icons/tfi";
import {
  RiCloseFill,
  RiDeleteBin5Line,
  RiDeleteBin7Line,
  RiRedPacketLine,
  RiTrophyLine,
} from "react-icons/ri";
import {
  FiCheck,
  FiCopy,
  FiCreditCard,
  FiHeadphones,
  FiMail,
  FiPlus,
  FiShoppingBag,
} from "react-icons/fi";
import {
  FaDownload,
  FaLink,
  FaRegBuilding,
  FaRegCopy,
  FaRegImage,
} from "react-icons/fa";
import { useDetectClickOutside } from "react-detect-click-outside";
import { AiOutlineLogout } from "react-icons/ai";
import { useAuth } from "../Context/AuthContext";
import { HexColorPicker } from "react-colorful";
import {
  TbAlignBoxCenterMiddle,
  TbAlignBoxLeftMiddle,
  TbAlignBoxRightMiddle,
  TbAlignCenter,
  TbAlignLeft,
  TbAlignRight,
  TbBoxAlignLeft,
} from "react-icons/tb";
import { TailSpin } from "react-loader-spinner";
import { LuPen } from "react-icons/lu";

const DownloadPopup = ({
  projectModalOpen,
  setProjectModalOpen,
  campaignID,
  setIsOpen,
}) => {
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (projectModalOpen) {
        setProjectModalOpen(false);
      }
    },
  });

  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const history = useHistory();
  const deletecampaign = async () => {
    setDeleteLoading(true);
    try {
      if (campaignID) {
        await firebase
          .firestore()
          .collection("campaigns")
          .doc(campaignID)
          .delete()
          .then(() => {
            setDeleteLoading(false);
            setProjectModalOpen(false);
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const duplicate = async (campaignID) => {
    try {
      setDuplicateLoading(true);

      // Reference to the campaigns collection
      const campaignsCollection = firebase.firestore().collection("campaigns");

      // Get the document to duplicate
      const campaignDocRef = campaignsCollection.doc(campaignID);
      const campaignDocSnap = await campaignDocRef.get();

      if (campaignDocSnap.exists) {
        // Get the data of the document to duplicate
        const campaignData = campaignDocSnap.data();

        // Generate a new unique ID using uuidv4
        const newcampaignID = uuidv4();

        // Update the campaignID field in the campaign data
        campaignData.campaignID = newcampaignID;

        // Create a new document in the campaigns collection with the new ID
        await campaignsCollection.doc(newcampaignID).set({
          ...campaignData,
          campaignName: campaignData.campaignName + " (copy)",
          status: "INACTIVE",
          createdAt: firebase.firestore.FieldValue.serverTimestamp(), // Add createdAt field with server timestamp
        });

        setDuplicateLoading(false);
        setProjectModalOpen(false);
        return newcampaignID;
      } else {
        console.log("campaign document not found.");
        return null;
      }
    } catch (error) {
      console.error("Error duplicating campaign:", error);
      return null;
    }
  };
  const triggerDownload = (url, fileName) => {
    // Create a new anchor element
    const anchor = document.createElement("a");

    // Set the href to the download URL
    anchor.href = url;

    // Optionally set the download attribute to a filename
    anchor.download = fileName || "downloaded_video.mp4";

    // Append the anchor to the document
    document.body.appendChild(anchor);

    // Trigger the download by simulating a click
    anchor.click();

    // Remove the anchor from the document
    document.body.removeChild(anchor);
  };

  const downloadVideo = async (assetID) => {
    setDownloadLoading(true);
    if (assetID) {
      var downloadVideo = firebase.functions().httpsCallable("downloadVideo");
      downloadVideo({ assetID: assetID }).then((result) => {
        if (result.data.error) {
          console.log(result.data.error);
          return setDownloadLoading(false);
        } else {
          const url = result.data.url;

          if (url) {
            //navigate to the success page
            triggerDownload(url, `${campaign.campaignName}.mp4`);
            setDownloadLoading(false);
          }
        }
      });
    }
  };

  useEffect(() => {
    setDownloadLoading(false);
    setDuplicateLoading(false);
    setDeleteLoading(false);
  }, [projectModalOpen]);
  return (
    <>
      <div
        ref={ref}
        style={{
          width: 170,
          borderRadius: 8,
          top: 0,
          boxShadow:
            "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          display: "flex",
          animation: projectModalOpen ? "popUp 0.3s ease-in-out" : "none",
          visibility: projectModalOpen ? "visible" : "hidden",
          position: "absolute",
          marginTop: 40,
          zIndex: 11000,
          border: "none",
          marginLeft: 0,
          backgroundColor: "white",
          display: "flex",
          paddingTop: 18,
          paddingBottom: 5,
          paddingLeft: 18,
          paddingRight: 5,
          color: "#181818",
          opacity: 0.6,
          fontFamily: "SSRegular",
          fontSize: 15,
          left: 11,
        }}
        className={`backy60   alwayson ${
          projectModalOpen ? "popUpAnimation" : ""
        }`}
      >
        <MDBRow>
          <MDBCol size="12">
            <p
              onClick={(e) => {
                e.stopPropagation();
                if (!duplicateLoading && campaignID) {
                  duplicate(campaignID);
                }
              }}
              style={{ cursor: "pointer" }}
            >
              {duplicateLoading ? (
                <TailSpin
                  height="20"
                  width="20"
                  color="#181818"
                  ariaLabel="tail-spin-loading"
                  radius="3"
                  wrapperStyle={{ marginTop: -6, marginRight: 12 }}
                  wrapperClass="d-inline"
                  visible={true}
                />
              ) : (
                <FaRegCopy
                  style={{ marginRight: 12, fontSize: 16, marginTop: -2.3 }}
                />
              )}{" "}
              Duplicate
            </p>
          </MDBCol>

          {
            <MDBCol size="12">
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  setProjectModalOpen(false);
                  setIsOpen(true);
                }}
                style={{ cursor: "pointer", marginTop: 6 }}
              >
                {deleteLoading ? (
                  <TailSpin
                    height="20"
                    width="20"
                    color="#181818"
                    ariaLabel="tail-spin-loading"
                    radius="3"
                    wrapperStyle={{ marginTop: -6, marginRight: 12 }}
                    wrapperClass="d-inline"
                    visible={true}
                  />
                ) : (
                  <LuPen
                    style={{
                      marginRight: 13,
                      fontSize: 17,
                      marginTop: -3.6,
                      width: 20,
                    }}
                  />
                )}
                Edit
              </p>
            </MDBCol>
          }
          {
            <MDBCol size="12">
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  if (!deleteLoading && campaignID) {
                    deletecampaign();
                  }
                }}
                style={{ cursor: "pointer", marginTop: 6 }}
              >
                {deleteLoading ? (
                  <TailSpin
                    height="20"
                    width="20"
                    color="#181818"
                    ariaLabel="tail-spin-loading"
                    radius="3"
                    wrapperStyle={{ marginTop: -6, marginRight: 12 }}
                    wrapperClass="d-inline"
                    visible={true}
                  />
                ) : (
                  <RiDeleteBin7Line
                    style={{
                      marginRight: 13,
                      fontSize: 17,
                      marginTop: -3.6,
                      width: 20,
                    }}
                  />
                )}
                Delete
              </p>
            </MDBCol>
          }
        </MDBRow>
      </div>
    </>
  );
};

export default withRouter(DownloadPopup);
