import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiCake, BiColorFill, BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSignUpModal } from "../Context/SignUpModal";
import {
  RiCloseFill,
  RiGenderlessLine,
  RiRedPacketLine,
  RiTrophyLine,
} from "react-icons/ri";
import {
  FiCheck,
  FiCopy,
  FiCreditCard,
  FiHeadphones,
  FiMail,
  FiPlus,
  FiShoppingBag,
} from "react-icons/fi";
import {
  FaBirthdayCake,
  FaLink,
  FaRegBuilding,
  FaRegImage,
} from "react-icons/fa";
import { useDetectClickOutside } from "react-detect-click-outside";
import { BsPeace } from "react-icons/bs";

const Filter = ({ selectedFilters, setSelectedFilters }) => {
  const [inviteEmail, setInviteEmail] = useState("");
  const [password, setPassword] = useState("");
  const { filterOpen, setFilterOpen } = useSignUpModal();
  const [error, setError] = useState("");
  const [finishedReset, setFinishedReset] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [signUpMode, setSignUpMode] = useState(true);
  const [verificationError, setVerificationError] = useState(false);
  const [category, setCategory] = useState("Gender");
  const [planType, setPlanType] = useState("Monthly");
  const [profile, setProfile] = useState("");
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (filterOpen) {
        setFilterOpen(false);
      }
    },
  });

  const handleFilterChange = (category, value) => {
    setSelectedFilters((prevFilters) => {
      const currentValues = prevFilters[category] || [];
      const updatedValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value];

      return {
        ...prevFilters,
        [category]: updatedValues,
      };
    });
  };
  const categories = [
    {
      title: "Gender",
      icon: (
        <RiGenderlessLine
          style={{
            marginRight: 9,
            fontSize: 22,
            opacity: 0.7,
            marginTop: -2.5,
          }}
        />
      ),
    },
    {
      title: "Age",
      icon: (
        <BiCake
          style={{
            marginRight: 11,
            fontSize: 22,
            opacity: 0.7,
            marginTop: -2,
          }}
        />
      ),
    },
    {
      title: "Ethnicity",
      icon: (
        <BsPeace
          style={{
            marginRight: 9,
            fontSize: 22,
            opacity: 0.7,
            marginTop: -2.5,
          }}
        />
      ),
    },
    {
      title: "Type",
      icon: (
        <FiShoppingBag
          style={{
            marginRight: 9,
            fontSize: 22,
            opacity: 0.7,
            marginTop: -2.5,
          }}
        />
      ),
    },
  ];

  const renderCheckbox = (category, value, label) => {
    const isChecked = selectedFilters[category]?.includes(value) || false;

    return (
      <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
        <input
          style={{ marginRight: 9 }}
          type="checkbox"
          id={value}
          name={value}
          checked={isChecked}
          onChange={() => handleFilterChange(category, value)}
        />
        <label style={{ marginBottom: -1 }} htmlFor={value}>
          {label}
        </label>
      </div>
    );
  };

  const renderOptions = (category) => {
    switch (category) {
      case "Gender":
        return (
          <>
            {renderCheckbox("Gender", "Male", "Male")}
            {renderCheckbox("Gender", "Female", "Female")}
          </>
        );
      case "Type":
        return (
          <>
            {renderCheckbox("Type", "Talking", "Talking")}
            {renderCheckbox("Type", "Guide", "Guide")}
            {renderCheckbox("Type", "Unboxing", "Unboxing")}
          </>
        );
      case "Age":
        return (
          <>
            {renderCheckbox("Age", "18-25", "18-25")}
            {renderCheckbox("Age", "25-35", "25-35")}
            {renderCheckbox("Age", "35-55", "35-55")}
            {renderCheckbox("Age", "55+", "55+")}
          </>
        );
      case "Ethnicity":
        return (
          <>
            {renderCheckbox("Ethnicity", "Asian", "Asian")}
            {renderCheckbox("Ethnicity", "Black", "Black")}
            {renderCheckbox("Ethnicity", "White", "White")}
            {renderCheckbox("Ethnicity", "Hispanic", "Hispanic")}
            {renderCheckbox("Ethnicity", "Native American", "Native American")}
            {renderCheckbox(
              "Ethnicity",
              "Pacific Islander",
              "Pacific Islander"
            )}
            {renderCheckbox("Ethnicity", "Middle Eastern", "Middle Eastern")}
          </>
        );
    }
  };

  return (
    <>
      <div
        ref={ref}
        style={{
          paddingBottom: 50,
          width: 380,
          background: "white",
          position: "absolute",
          visibility: filterOpen ? "visible" : "hidden",
          borderRadius: 8,
          boxShadow:
            "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          height: 210,
          zIndex: 90000,
          paddingLeft:
            (resetPassword && finishedReset) ||
            firebase.auth()?.currentUser?.emailVerified === false
              ? 0
              : 20,
          display: "flex",
          right: 60,
          marginTop: -34,
          animation: filterOpen ? "popUp 0.3s ease-in-out" : "none",
        }}
        className={`backy60 ${filterOpen ? "popUpAnimation" : ""}`}
      >
        <div
          style={{
            width: 160,
            height: 210,
            paddingLeft: 0,
            paddingTop: 0,
            paddingRight: 30,
            borderRight: "1px solid #E8E8E8",
            overflowY: "scroll",
            overflowX: "hidden",
            paddingBottom: 10,
          }}
        >
          {categories.map((item) => {
            return (
              <div
                style={{
                  height: 39,
                  width: 136,
                  backgroundColor:
                    item.title === category ? "#F0F8FF" : "transparent",
                  borderRadius: 4,
                  marginTop: 10,
                  paddingTop: 0,
                  paddingLeft: 0,
                  cursor: "pointer",
                  marginLeft: -5,
                  paddingTop: 9.3,
                  paddingLeft: 15,
                }}
                onClick={() => setCategory(item.title)}
              >
                <p
                  style={{
                    color: "#2f1878",
                    fontFamily: "PPMedium",
                    fontSize: 14,
                  }}
                >
                  {item.title}
                </p>
              </div>
            );
          })}
        </div>
        <div
          style={{
            width: 220,
            height: 210,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            paddingLeft: 25,
            paddingTop: 10,
            fontFamily: "PPMedium",
            overflowY: "scroll",
            overflowX: "hidden",
            paddingBottom: 15,
          }}
        >
          {renderOptions(category)}
        </div>
      </div>
    </>
  );
};

export default withRouter(Filter);
