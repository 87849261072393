import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { BsPauseCircleFill, BsPlayCircleFill } from "react-icons/bs";
import Carousel from "./Carousel";
import { ReactComponent as Flowy } from "./modern.svg";
import ReactPlayer from "react-player";
import Box from "./Box";

const numOfItems = [1, 2, 3, 4, 5, 6];
const Section2 = () => {
  return (
    <MDBCol className="d-flex justify-content-center" size="12">
      <div
        style={{
          height: 460,
          width: 1100,
          marginTop: 0,
          borderRadius: 9,
          padding: 0,
          justifyContent: "space-evenly",
          display: "flex",
          paddingLeft: 10,
        }}
      >
        <Box
          playbackID="8qu672D7sF8smQWwOv02ZRGICQDVgZGMNCxsz46qR302c"
          Title="AI Actors"
          Description="Connect to any audience in any language using expressive AI actors."
        />
        <Box
          playbackID="oZRCWE01nK2xI287PraJ02sxyG8jwlSZtvi5abhJANvns"
          Title="Powerful Editor"
          Description="Quickly upload product shots, add dynamic subtitles and more."
        />
        <Box
          Title="Proven Templates"
          Description="Edit profitable UGC video ads to customize it to your product."
          playbackID="01H7b500VcsoOxv9NWow8UP1GtgOW9tPhgijD4Cdtapuw"
        />
      </div>
    </MDBCol>
  );
};

export default withRouter(Section2);
