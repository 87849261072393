import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";

import "react-phone-number-input/style.css";
import MainMenu from "../Layout/MainMenu";
import { FaRegCompass, FaRegStar } from "react-icons/fa";
import { BsBackpack } from "react-icons/bs";
import { IoFilterSharp } from "react-icons/io5";
import SideNav from "../Layout/SideNav";
import TopBar from "../Layout/TopBar";
import Categories from "./Categories";
import PackTemplates from "./PackTemplates";
import Templates from "./Templates";
import { useAuth } from "../Context/AuthContext";

const MyAssets = () => {
  const categories = [1, 2, 3, 4, 5, 6, 7, 9, 3, 4, 5, 6, 7, 9];
  const [queryID, setQueryID] = useState();
  const location = useLocation();
  const { workspaceID } = useAuth();
  const [assets, setAssets] = useState(null);

  useEffect(() => {
    firebase
      .firestore()
      .collection("ugc")
      .where("workspaceID", "==", workspaceID)
      .where("type", "==", "Asset")
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.empty) {
          let initAssets = [];
          querySnapshot.forEach((doc) => {
            initAssets.push(doc.data());
          });
          setAssets(initAssets);
        } else {
          setAssets([]);
        }
      });
  }, [workspaceID]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const packID = searchParams.get("packID");
    setQueryID(packID);
  }, [location]);
  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#eee9ff",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 0,
            }}
            size="12"
          >
            <MDBRow style={{ marginTop: 0 }}>
              <MainMenu />
            </MDBRow>
          </MDBCol>
          <MDBCol style={{ paddingLeft: 0 }} size="12">
            <div style={{ display: "flex", height: "105vh" }}>
              <SideNav />
              <div style={{ zIndex: 4, width: "82vw" }}>
                <TopBar />
                <div>
                  <MDBRow
                    style={{
                      width: "82vw",
                      height: "70vh",
                      paddingLeft: 30,
                      overflowY: "scroll",
                      marginTop: 30,
                      overflowX: "hidden",
                    }}
                  >
                    <Templates assets={assets} />
                  </MDBRow>
                </div>
              </div>
            </div>
          </MDBCol>
        </div>
      </div>
    </>
  );
};

export default withRouter(MyAssets);
