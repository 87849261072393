import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { TailSpin } from "react-loader-spinner";
import Step1 from "./Step1";
import Step2Digital from "./Step2Digital";
import Step3Digital from "./Step3Digital";
import Step4Digital from "./Step4Digital";
import Intro from "./Intro";
import Step1Mobile from "./Step1Mobile";
import IntroMobile from "./IntroMobile";
import Step2DigitalMobile from "./Step2DigitalMobile";
import Step3DigitalMobile from "./Step3DigitalMobile";
import Step4DigitalMobile from "./Step4DigitalMobile";

const BriefModalMobile = ({ setIsOpen, isOpen, videoID }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [productType, setProductType] = useState("Ecommerce");
  const [section, setSection] = useState("Step0");
  const [verifyingLoading, setVerifyingLoading] = useState(null);
  const [verified, setVerified] = useState(null);
  const [title, setTitle] = useState("");
  const [taskID, setTaskID] = useState("");
  const [creatorID, setCreatorID] = useState("");
  const [editorID, setEditorID] = useState("");
  const [complete, setComplete] = useState(null);
  const [IBAN, setIBAN] = useState("");

  useEffect(() => {
    if (taskID) {
      firebase
        .firestore()
        .collection("videoTasks")
        .doc(taskID)
        .onSnapshot((doc) => {
          if (doc.data().videos?.length > 0) {
            setComplete(true);
          } else {
            setComplete(false);
          }
        });
    }
  }, [taskID]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const taskIDFromParams = queryParams.get("taskID");
    const creatorIDFromParams = queryParams.get("creatorID");
    const editorIDFromParams = queryParams.get("editorID");
    if (taskIDFromParams || creatorIDFromParams) {
      setTaskID(taskIDFromParams);
      setCreatorID(creatorIDFromParams);
      setEditorID(editorIDFromParams);
    }
  }, [location]);

  const renderSection = (section) => {
    switch (section) {
      case "Step0":
        return (
          <IntroMobile
            taskID={taskID}
            creatorID={creatorID}
            setSection={setSection}
            productType={productType}
            setProductType={setProductType}
          />
        );
      case "Step4Digital":
        return (
          <Step4DigitalMobile
            setSection={setSection}
            editorID={editorID}
            creatorID={creatorID}
            productType={productType}
            setProductType={setProductType}
          />
        );
    }
  };

  useEffect(() => {
    if (complete === true) {
      return setSection("Step4Digital");
    }
  }, [complete]);

  if (!taskID || (!creatorID && !editorID)) {
    return null;
  }

  return (
    <>
      <div
        style={{
          paddingBottom: 40,
          width: "90vw",
          backgroundColor: "#fff",
          borderRadius: 13,
          zIndex: 90000,
          marginLeft: "auto",
          marginRight: "auto",
          border: "1px solid rgb(234, 236, 240)",
          paddingLeft: 30,
          paddingTop: 30,
        }}
        className="backy60"
      >
        <MDBRow>{renderSection(section)}</MDBRow>
      </div>
    </>
  );
};

export default withRouter(BriefModalMobile);
