import React from "react";
import "./index.css"; // Make sure to create a corresponding CSS file
import { MDBCol, MDBRow } from "mdbreact";
import { useTranslation } from "react-i18next";

const VideoInstructionsMobile = ({ setSection }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        backgroundColor: "white", // Added this line
        width: "90vw",
      }}
      className="video-instructions-container"
    >
      <MDBRow style={{ marginTop: 10 }} className="content">
        <MDBCol
          style={{ paddingLeft: 15 }}
          size="12"
          className="recommendations"
        >
          <p
            style={{
              fontFamily: "CSSemiBold",
              color: "black",
              fontSize: "4vw",
              textAlign: "center",
            }}
          >
            ✅ {t("Recommended")}
          </p>
          <ul
            style={{
              fontFamily: "CSMedium",
              color: "black",
              width: "90vw",
              marginLeft: "-4vw",
            }}
          >
            <li>{t("submit_minimum_footage")}</li>
            <li style={{ marginTop: 5 }}>{t("use_high_resolution_camera")}</li>
            <li style={{ marginTop: 5 }}>{t("record_well_lit_environment")}</li>
            <li style={{ marginTop: 5 }}>{t("look_directly_camera")}</li>
            <li style={{ marginTop: 5 }}>{t("pause_between_sentences")}</li>
            <li style={{ marginTop: 5 }}>{t("use_natural_gestures")}</li>
          </ul>
        </MDBCol>

        <MDBCol size="12" className="avoid">
          <p
            style={{
              fontFamily: "CSSemiBold",
              color: "black",
              fontSize: "4vw",
              textAlign: "center",
              marginTop: 19,
            }}
          >
            ❌ {t("Avoid")}
          </p>
          <ul
            style={{
              fontFamily: "CSMedium",
              color: "black",
              width: "90vw",
              marginLeft: "-4vw",
            }}
          >
            <li>{t("avoid_stitches_cuts")}</li>
            <li style={{ marginTop: 5 }}>
              {" "}
              {t("avoid_talking_without_pauses")}
            </li>
            <li style={{ marginTop: 5 }}>{t("avoid_changing_positions")}</li>
            <li style={{ marginTop: 5 }}>{t("avoid_loud_background_noise")}</li>
            <li style={{ marginTop: 5 }}>
              {t("avoid_shadows_overexposure_face")}
            </li>
            <li style={{ marginTop: 5 }}>{t("avoid_diverting_gaze")}</li>
            <li style={{ marginTop: 5 }}>
              {t("avoid_hand_gestures_above_chest")}
            </li>
            <li style={{ marginTop: 5 }}>{t("avoid_pointing_gestures")}</li>

            {/* Add the rest of the recommendations here */}
          </ul>
        </MDBCol>
      </MDBRow>

      <div className="tips">
        <p style={{ fontFamily: "CSMedium" }}>💡{t("cloningtip")}😊</p>
      </div>

      <div
        onClick={() => {
          window.scrollTo({
            top: 0,
          });
          setSection(2);
        }}
        className="next-step"
      >
        <button>{t("nextStepText")}</button>
      </div>
    </div>
  );
};

export default VideoInstructionsMobile;
