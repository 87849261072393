import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import { FaDownload, FaRegCompass, FaRegStar } from "react-icons/fa";
import { RiCloseLine } from "react-icons/ri";
import { CircularProgressbar } from "react-circular-progressbar";
import { BsPlayCircle } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { TailSpin } from "react-loader-spinner";
import DeleteModal from "./DeleteModal";
import VideoModal from "./VideoModal";

const Categories = ({ videos }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [assetID, setAssetID] = useState("");
  const [videoID, setVideoID] = useState("");
  const [videoOpen, setVideoOpen] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [playbackID, setPlaybackID] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const history = useHistory();

  function formatDateFromTimestamp(timestamp) {
    // Create a Date object from the timestamp
    const date = new Date(timestamp.seconds * 1000);

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is 0-indexed
    const year = date.getFullYear().toString().substr(-2); // Get last two digits of year

    // Format the date as dd/mm/yy
    return `${day}/${month}/${year}`;
  }

  const triggerDownload = (url, fileName) => {
    // Create a new anchor element
    const anchor = document.createElement("a");

    // Set the href to the download URL
    anchor.href = url;

    // Optionally set the download attribute to a filename
    anchor.download = fileName || "downloaded_video.mp4";

    // Append the anchor to the document
    document.body.appendChild(anchor);

    // Trigger the download by simulating a click
    anchor.click();

    // Remove the anchor from the document
    document.body.removeChild(anchor);
  };

  const downloadVideo = async (assetID) => {
    setError(null);
    setLoading(true);
    if (assetID) {
      var downloadVideo = firebase.functions().httpsCallable("downloadVideo");
      downloadVideo({ assetID }).then((result) => {
        if (result.data.error) {
          console.log(result.data.error);
          setLoading(false);
          return setError(result.data.error);
        } else {
          const url = result.data.url;

          if (url) {
            //navigate to the success page
            triggerDownload(url, "video.mp4");
            setLoading(false);
            setError(null);
          }
        }
      });
    }
  };

  const sortedVideos = videos
    ? videos.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
    : null;

  return (
    <>
      <DeleteModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        videoID={videoID}
      />
      <VideoModal
        videoOpen={videoOpen}
        setVideoOpen={setVideoOpen}
        playbackID={playbackID}
      />

      <MDBRow
        style={{
          width: "82vw",
          height: "70vh",
          paddingLeft: 30,
          overflowY: "scroll",
        }}
      >
        {sortedVideos === null ? null : sortedVideos.length > 0 ? (
          sortedVideos.map((video) => {
            const isFinished = video.status === "Finished";
            const percentage = isFinished
              ? 100
              : video.status === "Pending"
              ? 0
              : video.gpuAvailable
              ? video.progress
              : null;
            return (
              <MDBCol size="3">
                <div
                  style={{
                    paddingBottom: 30,
                    width: "18vw",
                    backgroundColor: "white",
                    marginBottom: 30,
                    borderRadius: 9,
                    boxShadow:
                      "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <MDBRow>
                    <MDBCol size="12">
                      <div
                        target="_blank"
                        key={video.videoID}
                        style={{
                          position: "relative",
                          display: "inline-block",
                          height: 265,
                          width: "100%",
                          marginRight: 6,
                          borderRadius: 5,
                          overflow: "hidden",
                          marginBottom: 16,
                          cursor:
                            video.status === "Finished" ? "pointer" : null,
                        }}
                      >
                        {video.status !== "Finished" ? (
                          video.status === "Error" ? (
                            <>
                              <RiCloseLine
                                style={{
                                  position: "absolute",
                                  zIndex: 10,
                                  color: "red",
                                  right: 5,
                                  top: 5,
                                  fontSize: 30,
                                }}
                              />
                              <p
                                style={{
                                  position: "absolute",
                                  zIndex: 100,
                                  fontFamily: "CSBold",
                                  marginLeft: 80,
                                  marginTop: 60,
                                  color: "red",
                                }}
                              >
                                Failed
                              </p>
                            </>
                          ) : video.status !== "Finished" ? (
                            <p
                              style={{
                                position: "absolute",
                                zIndex: 100,
                                fontFamily: "MAHeavy",
                                marginLeft: "29%",
                                marginTop: 90,
                                color: "white",
                                fontSize: 37,
                              }}
                            >
                              Creating...
                            </p>
                          ) : (
                            <div
                              style={{
                                position: "absolute",
                                marginLeft: 90,
                                marginTop: 36,
                                zIndex: 100,
                                fontFamily: "CSBold",
                                height: 60,
                                width: 60,
                                fontSize: 24,
                              }}
                            >
                              <CircularProgressbar
                                styles={{
                                  path: {
                                    stroke: "rgb(248, 40, 78)",
                                  },
                                  text: {
                                    textSize: "33px",
                                    fill: "white",
                                    fontFamily: "CSBold",
                                  },
                                }}
                                value={percentage}
                                text={`${percentage}%`}
                              />
                            </div>
                          )
                        ) : (
                          <>
                            {" "}
                            <BsPlayCircle
                              onClick={() => {
                                setPlaybackID(video.playbackID);
                                setVideoOpen(true);
                              }}
                              style={{
                                position: "absolute",
                                color: "white",
                                fontSize: 88,
                                zIndex: 100,
                                marginLeft: "36%",
                                marginTop: 81,
                              }}
                            />
                            <div
                              style={{
                                backgroundColor: "white",
                                position: "absolute",
                                height: 26,
                                paddingLeft: 10,
                                paddingRight: 10,
                                marginBottom: 14,
                                zIndex: 130,
                                bottom: 0,
                                right: 10,
                                textAlign: "center",
                                borderRadius: 100,
                                fontFamily: "CSSemiBold",
                                paddingTop: 4,
                                fontSize: 13,
                                color: "black",
                              }}
                              className="d-flex justify-content-center"
                            >
                              <p>{video.creditsRequired} credits</p>
                            </div>
                            <div
                              style={{
                                backgroundColor: "white",
                                color: "black",
                                position: "absolute",
                                height: 26,
                                width: 93,
                                marginBottom: 14,
                                zIndex: 130,
                                bottom: 0,
                                left: 10,
                                textAlign: "center",
                                borderRadius: 100,
                                fontFamily: "CSSemiBold",
                                paddingTop: 5,
                                fontSize: 13,
                              }}
                              className="d-flex justify-content-center"
                            >
                              <p>{formatDateFromTimestamp(video.createdAt)}</p>
                            </div>
                          </>
                        )}

                        <img
                          src={`https://image.mux.com/${video.tempPlaybackID}/animated.gif`}
                          style={{
                            display: "inline-block",
                            height: 265,
                            width: "100%",
                            borderTopRightRadius: 10,
                            borderTopLeftRadius: 10,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            objectFit: "cover",
                            verticalAlign: "top",
                            backgroundColor: "transparent", // Added this line
                            filter: "blur(1px)",
                          }}
                        />
                      </div>
                    </MDBCol>
                    {video.status === "Finished" && (
                      <>
                        {" "}
                        <MDBCol
                          className="d-flex justify-content-center"
                          style={{ marginTop: 0 }}
                          size="12"
                        >
                          <div
                            style={{
                              width: "94%",
                              height: 50,
                              border: "0.5px solid #c2d2f2",
                              fontFamily: "PPMedium",
                              color: "#2f1878",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!loading) {
                                setAssetID(video.assetID);
                                downloadVideo(video.assetID);
                              }
                            }}
                            className="d-flex justify-content-center"
                          >
                            {loading && assetID === video.assetID ? (
                              <TailSpin
                                height={30}
                                width={30}
                                color="#2f1878"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{ marginTop: 9 }}
                                wrapperClass=""
                                visible={true}
                              />
                            ) : (
                              <p style={{ marginTop: 13 }}>
                                {" "}
                                <FaDownload
                                  style={{
                                    fontSize: 12,
                                    marginRight: 11,
                                    marginTop: -2.1,
                                  }}
                                />
                                Download video
                              </p>
                            )}
                          </div>
                        </MDBCol>
                        <MDBCol
                          className="d-flex justify-content-center"
                          style={{ marginTop: 10 }}
                          size="12"
                        >
                          <div
                            style={{
                              width: "94%",
                              height: 50,
                              border: "0.5px solid red",
                              fontFamily: "PPMedium",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setVideoID(video.videoID);
                              setDeleteOpen(true);
                            }}
                            className="d-flex justify-content-center"
                          >
                            <p style={{ marginTop: 13 }}>Delete video</p>
                          </div>
                        </MDBCol>
                      </>
                    )}
                  </MDBRow>
                </div>
              </MDBCol>
            );
          })
        ) : (
          <MDBCol className="d-flex justify-content-center" size="12">
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  fontFamily: "PPMedium",
                  fontSize: 22,
                  marginTop: 90,
                  opacity: 0.7,
                }}
              >
                No videos yet
              </p>
              <p
                style={{
                  fontFamily: "PPBook",
                  fontSize: 15,
                  marginTop: -11,
                  opacity: 0.7,
                  width: 550,
                }}
              >
                Create a video inside your project to view them here. You can{" "}
                <p
                  onClick={() => history.push("/my-projects")}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  className="d-inline"
                >
                  create it here
                </p>{" "}
                or you can first{" "}
                <p
                  onClick={() => history.push("/")}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  className="d-inline"
                >
                  explore our library
                </p>{" "}
                of UGC content to get inspired.
              </p>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: 180,
                    height: 40,
                    border: "0px solid black",
                    marginRight: 10,
                    marginTop: 0,
                    cursor: "pointer",
                    marginLeft: 90,
                    paddingTop: 9,
                    boxShadow:
                      "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                    borderRadius: 4,
                    backgroundColor: "white",
                    display: "inline-block",
                  }}
                  className="d-flex justify-content-center"
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  <p
                    style={{
                      color: "#2f1878",
                      fontFamily: "PPMedium",
                      fontSize: 15,
                    }}
                  >
                    <FaRegCompass
                      style={{
                        marginRight: 10,
                        fontSize: 19,
                        opacity: 0.7,
                        marginTop: -1,
                      }}
                    />
                    Explore library
                  </p>
                </div>
                <div
                  style={{
                    width: 180,
                    height: 40,
                    border: "0px solid black",
                    marginRight: 0,
                    marginTop: 0,
                    cursor: "pointer",
                    paddingTop: 9,
                    boxShadow:
                      "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                    borderRadius: 4,
                    backgroundColor: "white",
                    display: "inline-block",
                  }}
                  className="d-flex justify-content-center"
                  onClick={() => history.push("/my-projects")}
                >
                  <p
                    style={{
                      color: "#2f1878",
                      fontFamily: "PPMedium",
                      fontSize: 15,
                    }}
                  >
                    <FiPlus
                      style={{
                        marginRight: 10,
                        fontSize: 19,
                        opacity: 0.7,
                        marginTop: -1,
                      }}
                    />
                    Create video
                  </p>
                </div>
              </div>
            </div>
          </MDBCol>
        )}
      </MDBRow>
    </>
  );
};

export default withRouter(Categories);
