import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import { FaRegCompass, FaRegStar } from "react-icons/fa";
import { useAuth } from "../Context/AuthContext";
import { FiPlus } from "react-icons/fi";
import DeleteModal from "./DeleteModal";
import { useSignUpModal } from "../Context/SignUpModal";

const Categories = () => {
  const { workspaceID } = useAuth();
  const history = useHistory();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [projectID, setProjectID] = useState("");
  const [projects, setProjects] = useState(null);
  const { setCreateProjectOpen } = useSignUpModal();

  useEffect(() => {
    firebase
      .firestore()
      .collection("projects")
      .where("workspaceID", "==", workspaceID)
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.empty) {
          let initProjects = [];
          querySnapshot.forEach((doc) => {
            initProjects.push({ ...doc.data(), projectID: doc.id });
          });
          setProjects(initProjects);
        } else {
          setProjects([]);
        }
      });
  }, []);

  function convertServerTimestampToDate(serverTimestamp) {
    // Convert timestamp to milliseconds
    if (serverTimestamp) {
      const milliseconds = serverTimestamp.toMillis();

      // Create a new Date object using the milliseconds
      const dateObject = new Date(milliseconds);

      // Format the date to "M/D/YY" format
      const formattedDate = dateObject.toLocaleDateString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "2-digit",
      });

      return formattedDate;
    }
  }

  return (
    <>
      <DeleteModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        projectID={projectID}
      />
      <MDBRow
        style={{
          width: "82vw",
          height: "70vh",
          paddingLeft: 30,
          overflowY: "scroll",
        }}
      >
        {projects === null ? null : projects.length > 0 ? (
          projects.map((project) => {
            return (
              <MDBCol size="3">
                <div
                  style={{
                    height: 200,
                    width: "18vw",
                    backgroundColor: "white",
                    marginBottom: 30,
                    borderRadius: 9,
                    boxShadow:
                      "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <MDBRow style={{ paddingLeft: 20, paddingTop: 20 }}>
                    <MDBCol size="4">
                      <div
                        style={{
                          height: 90,
                          width: 90,
                          backgroundColor: "#eee9ff",
                          borderRadius: 5,
                          fontFamily: "PPBold",
                          color: "#2f1878",
                          fontSize: 50,
                          paddingTop: 8,
                        }}
                        className="d-flex justify-content-center"
                      >
                        {project.projectName.charAt(0).toUpperCase()}
                      </div>
                    </MDBCol>
                    <MDBCol size="6">
                      <p
                        style={{
                          fontFamily: "PPMedium",
                          marginTop: 20,
                          color: "#2f1878",
                        }}
                      >
                        {project.projectName}
                      </p>
                      <p
                        style={{
                          fontFamily: "PPMedium",
                          marginTop: -14,
                          fontSize: 11,
                          color: "grey",
                        }}
                      >
                        Created at{" "}
                        {convertServerTimestampToDate(project.createdAt)}
                      </p>
                    </MDBCol>
                    <MDBCol size="6">
                      <div
                        style={{
                          width: "100%",
                          height: 50,
                          border: "0.5px solid red",
                          fontFamily: "PPMedium",
                          color: "#2f1878",
                          cursor: "pointer",
                          marginTop: 16,
                          display: "inline-block",
                          fontSize: 14,
                        }}
                        onClick={() => {
                          setProjectID(project.projectID);
                          setDeleteOpen(true);
                        }}
                        className="d-flex justify-content-center"
                      >
                        <p style={{ marginTop: 14, color: "red" }}>
                          Delete project
                        </p>
                      </div>
                    </MDBCol>
                    <MDBCol size="6">
                      <div
                        style={{
                          width: "100%",
                          height: 50,
                          border: "0.5px solid #c2d2f2",
                          fontFamily: "PPMedium",
                          color: "#2f1878",
                          cursor: "pointer",
                          marginTop: 16,
                          display: "inline-block",
                          marginLeft: -20,
                          fontSize: 14,
                        }}
                        onClick={() =>
                          history.push(
                            `/project?projectID=${project.projectID}&projectName=${project.projectName}`
                          )
                        }
                        className="d-flex justify-content-center"
                      >
                        <p style={{ marginTop: 14 }}> Open project</p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCol>
            );
          })
        ) : (
          <MDBCol className="d-flex justify-content-center" size="12">
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  fontFamily: "PPMedium",
                  fontSize: 22,
                  marginTop: 90,
                  opacity: 0.7,
                }}
              >
                Nothing to see here
              </p>
              <p
                style={{
                  fontFamily: "PPBook",
                  fontSize: 15,
                  marginTop: -11,
                  opacity: 0.7,
                  width: 550,
                }}
              >
                Create a project to start working on your first video ad. You
                can{" "}
                <p
                  onClick={() => setCreateProjectOpen(true)}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  className="d-inline"
                >
                  create it here
                </p>{" "}
                or you can first{" "}
                <p
                  onClick={() => history.push("/")}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  className="d-inline"
                >
                  explore our library
                </p>{" "}
                of UGC content to get inspired.
              </p>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: 180,
                    height: 40,
                    border: "0px solid black",
                    marginRight: 10,
                    marginTop: 0,
                    cursor: "pointer",
                    marginLeft: 90,
                    paddingTop: 9,
                    boxShadow:
                      "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                    borderRadius: 4,
                    backgroundColor: "white",
                    display: "inline-block",
                  }}
                  className="d-flex justify-content-center"
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  <p
                    style={{
                      color: "#2f1878",
                      fontFamily: "PPMedium",
                      fontSize: 15,
                    }}
                  >
                    <FaRegCompass
                      style={{
                        marginRight: 10,
                        fontSize: 19,
                        opacity: 0.7,
                        marginTop: -1,
                      }}
                    />
                    Explore library
                  </p>
                </div>
                <div
                  style={{
                    width: 180,
                    height: 40,
                    border: "0px solid black",
                    marginRight: 0,
                    marginTop: 0,
                    cursor: "pointer",
                    paddingTop: 9,
                    boxShadow:
                      "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                    borderRadius: 4,
                    backgroundColor: "white",
                    display: "inline-block",
                  }}
                  className="d-flex justify-content-center"
                  onClick={() => setCreateProjectOpen(true)}
                >
                  <p
                    style={{
                      color: "#2f1878",
                      fontFamily: "PPMedium",
                      fontSize: 15,
                    }}
                  >
                    <FiPlus
                      style={{
                        marginRight: 10,
                        fontSize: 19,
                        opacity: 0.7,
                        marginTop: -1,
                      }}
                    />
                    Create project
                  </p>
                </div>
              </div>
            </div>
          </MDBCol>
        )}
      </MDBRow>
    </>
  );
};

export default withRouter(Categories);
