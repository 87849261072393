import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import { AiOutlineRight } from "react-icons/ai";
import { useLoading } from "../Context/LoadingContext";
import { useTranslation } from "react-i18next";

const Heading = ({ requestLoading }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const goBack = () => {
    history.goBack();
  };

  if (requestLoading === true) {
    return null;
  }

  return (
    <MDBRow
      style={{
        color: "white",
        paddingTop: 140,
        width: 840,
        paddingLeft: 0,
      }}
    >
      <MDBCol style={{ cursor: "pointer" }} size="12">
        <NavLink
          to="/"
          exact
          style={{
            textDecoration: "underline",
            display: "inline-block",
            fontSize: "3.8vw",
            fontFamily: "MADemiBold",
            color: "black",
          }}
        >
          {t("HomeNav")}
        </NavLink>
        <AiOutlineRight
          style={{
            fontSize: "2vw",
            marginLeft: 6,
            marginRight: 6,
            color: "black",
          }}
          className="d-inline"
        />
        <p
          onClick={() => goBack()}
          style={{
            textDecoration: "underline",
            display: "inline-block",
            fontSize: "3.8vw",
            fontFamily: "MADemiBold",
            color: "black",
          }}
        >
          {t("CreateCloneNav")}
        </p>
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(Heading);
