import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import { AiOutlinePlayCircle, AiOutlineRight } from "react-icons/ai";
import { useLoading } from "../Context/LoadingContext";
import { BsPlayCircle } from "react-icons/bs";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { RiCloseLine } from "react-icons/ri";
import CongratModal from "./CongratModal";
import { useTranslation } from "react-i18next";

const Heading = ({ setIsOpen, requestLoading, videos }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [videoID, setVideoID] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();

  const goBack = () => {
    history.goBack();
  };

  if (requestLoading === true) {
    return null;
  }

  const duration = 10 * 60 * 1000; // 5 minutes in milliseconds

  useEffect(() => {
    console.log(videos);
  }, [videos]);

  const sortedVideos = videos.sort(
    (a, b) => b.createdAt.seconds - a.createdAt.seconds
  );

  function formatDateFromTimestamp(timestamp) {
    // Create a Date object from the timestamp
    const date = new Date(timestamp.seconds * 1000);

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is 0-indexed
    const year = date.getFullYear().toString().substr(-2); // Get last two digits of year

    // Format the date as dd/mm/yy
    return `${day}/${month}/${year}`;
  }

  function calculatePercentage(startTimestamp, endTimestamp) {
    const startDate = new Date(startTimestamp.seconds * 1000);
    const endDate = new Date(endTimestamp.seconds * 1000);
    const now = new Date();

    // Ensure that 'now' falls between the start and end dates
    if (now < startDate) return 0; // If 'now' is before the start, return 0%
    if (now >= endDate) return 40; // If 'now' is equal to or after the end, return 40%

    const totalDuration = endDate - startDate;
    const timeElapsed = now - startDate;
    let percentage = (timeElapsed / totalDuration) * 100;

    return Math.min(percentage, 40); // Cap the percentage at 40%
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Trigger a re-render
      setLoading((loading) => !loading);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <MDBRow
      style={{
        paddingTop: 80,
        width: 840,
        paddingLeft: 10,
        color: "#502274",
      }}
    >
      <CongratModal
        videoID={videoID}
        isOpen={deleteOpen}
        setIsOpen={setDeleteOpen}
      />
      <MDBCol style={{ cursor: "pointer" }} size="12">
        <NavLink
          to="/"
          exact
          style={{
            textDecoration: "underline",
            display: "inline-block",
            fontFamily: "MADemiBold",
            color: "#000",
          }}
        >
          {t("HomeNav")}
        </NavLink>
        <AiOutlineRight
          style={{ fontSize: 10, marginLeft: 6, marginRight: 6 }}
          className="d-inline"
        />
        <p
          onClick={() => goBack()}
          style={{
            textDecoration: "underline",
            display: "inline-block",
            fontFamily: "MADemiBold",
            color: "#000",
          }}
        >
          {t("VideosNav")}
        </p>
      </MDBCol>
      <MDBCol style={{ cursor: "pointer" }} size="12">
        <MDBRow style={{ width: 850, marginTop: 8 }}>
          <MDBCol size="12">
            <p
              style={{
                fontSize: 21,
                color: "#000",
                fontFamily: "MAHeavy",
              }}
            >
              {t("VideoTitle")}
            </p>
          </MDBCol>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "MADemiBold",
                fontSize: 16,
                marginTop: -13,
                color: "#000",
              }}
            >
              {videos.length > 0 ? t("VideoSubtitle") : t("NoVideos")}
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ width: 800 }}>
          {sortedVideos.length > 0 &&
            sortedVideos.map((video) => {
              const isFinished = video.status === "Finished";
              const percentage = isFinished
                ? 100
                : video.status === "Pending"
                ? 0
                : video.gpuAvailable
                ? video.progress
                : calculatePercentage(
                    video.createdAt,
                    video.expectedAt
                  ).toFixed(1);
              return (
                <MDBCol size="4">
                  <div
                    target="_blank"
                    key={video.videoID}
                    onClick={() => {
                      if (video.status === "Finished") {
                        history.push(`/video?videoID=${video.playbackID}`);
                      }
                    }}
                    style={{
                      position: "relative",
                      display: "inline-block",
                      height: 135,
                      width: 243,
                      marginRight: 6,
                      borderRadius: 5,
                      overflow: "hidden",
                      marginBottom: 16,
                      cursor: video.status === "Finished" ? "pointer" : null,
                    }}
                  >
                    {video.status !== "Finished" ? (
                      video.status === "Error" ? (
                        <>
                          <RiCloseLine
                            style={{
                              position: "absolute",
                              zIndex: 10,
                              color: "red",
                              right: 5,
                              top: 5,
                              fontSize: 30,
                            }}
                            onClick={(e) => {
                              e.stopPropagation(); // This will prevent the event from bubbling up to the parent div
                              setDeleteOpen(true);
                              setVideoID(video.videoID); // This will open the congratModal
                            }}
                          />
                          <p
                            style={{
                              position: "absolute",
                              zIndex: 100,
                              fontFamily: "CSBold",
                              marginLeft: 80,
                              marginTop: 60,
                              color: "red",
                            }}
                          >
                            {t("Failed")}
                          </p>
                        </>
                      ) : video.status === "Pending" ||
                        video.loading !== true ? (
                        <p
                          style={{
                            position: "absolute",
                            zIndex: 100,
                            fontFamily: "MAHeavy",
                            marginLeft: 80,
                            marginTop: 60,
                            color: "white",
                          }}
                        >
                          {t("Queued")}...
                        </p>
                      ) : (
                        <div
                          style={{
                            position: "absolute",
                            marginLeft: 90,
                            marginTop: 36,
                            zIndex: 100,
                            fontFamily: "CSBold",
                            height: 60,
                            width: 60,
                            fontSize: 24,
                          }}
                        >
                          <CircularProgressbar
                            styles={{
                              path: {
                                stroke: "rgb(248, 40, 78)",
                              },
                              text: {
                                textSize: "33px",
                                fill: "white",
                                fontFamily: "CSBold",
                              },
                            }}
                            value={percentage}
                            text={`${percentage}%`}
                          />
                        </div>
                      )
                    ) : (
                      <>
                        {" "}
                        <RiCloseLine
                          style={{
                            position: "absolute",
                            zIndex: 10,
                            color: "red",
                            right: 5,
                            top: 5,
                            fontSize: 30,
                          }}
                          onClick={(e) => {
                            e.stopPropagation(); // This will prevent the event from bubbling up to the parent div
                            setDeleteOpen(true);
                            setVideoID(video.videoID); // This will open the congratModal
                          }}
                        />
                        <BsPlayCircle
                          style={{
                            position: "absolute",
                            color: "white",
                            fontSize: 40,
                            zIndex: 100,
                            marginLeft: 100,
                            marginTop: 48,
                          }}
                        />
                        <div
                          style={{
                            backgroundColor: "white",
                            position: "absolute",
                            height: 26,
                            paddingLeft: 10,
                            paddingRight: 10,
                            marginBottom: 10,
                            zIndex: 130,
                            bottom: 0,
                            right: 10,
                            textAlign: "center",
                            borderRadius: 100,
                            fontFamily: "CSSemiBold",
                            paddingTop: 4,
                            fontSize: 13,
                            color: "black",
                          }}
                          className="d-flex justify-content-center"
                        >
                          <p>
                            {video.creditsRequired} {t("Credit")}
                          </p>
                        </div>
                        <div
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            position: "absolute",
                            height: 26,
                            width: 93,
                            marginBottom: 10,
                            zIndex: 130,
                            bottom: 0,
                            left: 10,
                            textAlign: "center",
                            borderRadius: 100,
                            fontFamily: "CSSemiBold",
                            paddingTop: 4,
                            fontSize: 13,
                          }}
                          className="d-flex justify-content-center"
                        >
                          <p>{formatDateFromTimestamp(video.createdAt)}</p>
                        </div>
                      </>
                    )}

                    <img
                      src={`https://image.mux.com/${video.tempPlaybackID}/animated.gif`}
                      style={{
                        display: "inline-block",
                        height: 135,
                        width: 243,
                        borderRadius: 10,
                        objectFit: "cover",
                        verticalAlign: "top",
                        backgroundColor: "transparent", // Added this line
                        filter: "blur(2px)",
                      }}
                    />
                  </div>
                </MDBCol>
              );
            })}
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(Heading);
