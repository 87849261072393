import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import { TailSpin } from "react-loader-spinner";
import { useSignUpModal } from "../Context/SignUpModal";
import { useAuth } from "../Context/AuthContext";
import { HiArrowLeft } from "react-icons/hi";
import { UploadButton } from "@bytescale/upload-widget-react";
import { RiCloseLine } from "react-icons/ri";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";

const options = {
  apiKey: "public_FW25bqdCBRECShDhV5U6Kcud6ec5", // This is your API key.
  maxFileCount: 1,
  mimeTypes: ["video/mp4"],
  maxFileSizeBytes: 524288000,
};

const UploadSectionMobile = ({ setSection }) => {
  const [videoURL, setVideoURL] = useState("");
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const submitClone = async () => {
    setError(null);
    setLoading(true);
    if (videoURL && name) {
      var startTraining = firebase.functions().httpsCallable("startTraining");
      startTraining({ videoURL: videoURL, name: name }).then((result) => {
        if (result.data.error) {
          mixpanel.track("Failed to create a clone", {
            reason: result.data.message,
          });
          console.log(result.data.message);
          setLoading(false);
          return setError(result.data.message);
        } else {
          const profileID = result.data.profileID;

          if (profileID) {
            mixpanel.track("User created a clone");
            //navigate to the success page
            console.log("Successfully started training profile id", profileID);
            return history.push("/clones");
          }
        }
      });
    }
  };

  useEffect(() => {
    if (videoURL) {
      console.log("The video url is", videoURL);
    }
  }, [videoURL]);
  return (
    <>
      <div
        style={{
          backgroundColor: "white", // Added this line
          paddingLeft: 14,
          marginLeft: 0,
        }}
        className="video-instructions-container"
      >
        <HiArrowLeft
          onClick={() => setSection(1)}
          style={{
            color: "black",
            fontSize: "5vw",
            marginTop: 26,
            cursor: "pointer",
            marginLeft: 14,
          }}
        />

        {videoURL ? (
          <>
            <div
              style={{
                marginTop: 40,
                border: "1px dashed black",
                width: "70vw",
                height: 400,
                borderRadius: 10,
                objectFit: "fill",
              }}
              className="content d-flex justify-content-center"
            >
              <RiCloseLine
                onClick={() => setVideoURL("")}
                style={{
                  color: "red",
                  position: "absolute",
                  right: 110,
                  marginTop: 10,
                  fontSize: 30,
                }}
              />

              <video
                style={{
                  width: "70vw",
                  height: 326,
                  marginTop: 45,
                  borderRadius: 10,
                  objectFit: "fill",
                }}
                controls
              >
                <source src={videoURL} type="video/mp4" />
              </video>
            </div>
          </>
        ) : (
          <UploadButton
            options={options}
            onComplete={(files) => setVideoURL(files[0].fileUrl)}
          >
            {({ onClick }) => (
              <div
                style={{
                  marginTop: 40,
                  border: "1px dashed black",
                  width: "85vw",
                  height: "90vw",
                  borderRadius: 10,
                }}
                onClick={onClick}
                className="content"
              >
                <MDBRow style={{ width: "90vw" }}>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "CSMedium",
                        color: "black",
                        fontSize: "5vw",
                        marginTop: 110,
                        textAlign: "center",
                        lineHeight: 1.3,
                      }}
                    >
                      {t("UploadTitle")}
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "CSRegular",
                        color: "#bfbac4",
                        fontSize: "3vw",
                        marginTop: -70,
                        textAlign: "center",
                        lineHeight: 1.3,
                        width: "90%",
                      }}
                    >
                      {t("UploadSubtitle")}
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "CSRegular",
                        color: "black",
                        fontSize: "3.8vw",
                        marginTop: -80,
                        textAlign: "center",
                        lineHeight: 1.3,
                        textDecoration: "underline",
                      }}
                    >
                      {t("BrowseFiles")}
                    </p>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12"></MDBCol>
                </MDBRow>
              </div>
            )}
          </UploadButton>
        )}

        <div style={{ marginLeft: 20 }} className="avoid"></div>

        <input
          style={{
            height: 45,
            borderRadius: 100,
            marginLeft: "7vw",
            display: "inline-block",
            color: "black",
            marginTop: 30,
            backgroundColor: "white",
            fontFamily: "PPMedium",
            paddingLeft: 17,
            fontSize: 15,
            paddingTop: 2,
            width: "70vw",
            border: "1px solid rgba(155, 158, 163, 0.5)",
          }}
          value={name}
          name="Name"
          onChange={(event) => setName(event.target.value)}
          placeholder={t("CloneName")}
        />

        <div
          style={{
            marginTop: 10,
            marginLeft: "7vw",
            marginRight: 0,
            paddingRight: 0,
            width: "70vw",
            opacity: name && videoURL && !loading ? 1 : 0.5,
            cursor: name && videoURL && !loading ? "pointer" : null,
          }}
          className="next-step"
          onClick={() => {
            if (!loading) {
              submitClone();
            }
          }}
        >
          <button className="d-flex justify-content-center">
            {loading ? (
              <TailSpin
                height={30}
                width={30}
                color="white"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{ marginTop: 0 }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              t("SubmitClone")
            )}
          </button>
        </div>

        <MDBCol size="12" className="d-flex justify-content-center">
          {error && (
            <p
              style={{
                marginTop: 19,
                color: "red",
                fontFamily: "CSMedium",
                textAlign: "center",
                marginLeft: 90,
              }}
            >
              {error}
            </p>
          )}
        </MDBCol>
      </div>
    </>
  );
};

export default withRouter(UploadSectionMobile);
