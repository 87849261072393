import React, { useState, useEffect, useRef } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { IoMdCloseCircle, IoIosArrowDown } from "react-icons/io";

const Filter = ({
  filterOpen,
  setFilterOpen,
  index,
  filterIndex,
  filterArrays,
  handleFilterChange,
}) => {
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (filterOpen) {
        setFilterOpen(false);
      }
    },
  });

  const categories = {
    industry: [
      "Apps",
      "Baby, Kids & Maternity",
      "Beauty & Personal Care",
      "Business Services",
      "E-Commerce (Non-app)",
      "Education",
      "Financial Services",
      "Food & Beverage",
      "Games",
      "Health",
      "Home Improvement",
      "Household Products",
      "Life Services",
      "News & Entertainment",
      "Pets",
      "Sports & Outdoors",
      "Tech & Electronics",
      "Travel",
      "Vehicle & Transportation",
    ],
    objective: [
      "Traffic",
      "App Installs",
      "Conversions",
      "Video Views",
      "Reach",
      "Lead Generation",
      "Product Sales",
    ],
    region: [
      "United States",
      "United Kingdom",
      "Japan",
      "Germany",
      "France",
      "India",
      "Italy",
      "Brazil",
      "Canada",
      "Russia",
      "South Korea",
      "Australia",
      "Spain",
      "Mexico",
      "Indonesia",
      "Netherlands",
      "Saudi Arabia",
      "Switzerland",
      "Turkey",
      "Argentina",
      "Sweden",
      "Poland",
      "Belgium",
      "Thailand",
      "Iran",
      "Austria",
      "Norway",
      "United Arab Emirates",
      "Malaysia",
    ],
    language: [
      "English",
      "Spanish",
      "Hindi",
      "Arabic",
      "Portuguese",
      "Bengali",
      "Russian",
      "Japanese",
      "Punjabi",
      "German",
      "Malay",
      "Telugu",
      "Vietnamese",
      "Korean",
      "French",
      "Tamil",
      "Urdu",
      "Turkish",
      "Italian",
      "Persian",
      "Polish",
    ],
    ctr: ["Top 1~20%", "Top 21~40%", "Top 41~60%", "Top 61~80%", "Top 81~100%"],
  };

  const renderOptions = (category) => {
    const options = categories[category];
    return options.map((value, index) => (
      <div
        key={index}
        style={{
          display: "flex",
          marginTop: index > 0 ? 14 : 5,
          marginLeft: 5,
        }}
      >
        <input
          type="checkbox"
          id={`checkbox-${category}-${index}`}
          name={`checkbox-${category}-${index}`}
          checked={filterArrays[category].includes(value)}
          onChange={() => handleFilterChange(category, value)}
          style={{
            marginRight: 0,
            accentColor: "black",
            height: 15,
            width: 15,
            border: "1 solid #e2e2e2",
          }}
        />
        <p style={{ marginTop: -5.3, marginLeft: 10 }}>{value}</p>
      </div>
    ));
  };

  return (
    <>
      <div
        ref={ref}
        style={{
          paddingBottom: 50,
          width: 320,
          background: "white",
          position: "absolute",
          visibility: filterOpen ? "visible" : "hidden",
          borderRadius: 8,
          boxShadow:
            "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          height: 210,
          zIndex: 90000,
          paddingLeft: 20,
          display: "flex",
          marginTop: 50,
          animation: filterOpen ? "popUp 0.3s ease-in-out" : "none",
          marginLeft: index * 210,
          border: filterIndex === index ? "1px solid black" : null,
        }}
        className={`backy60 ${filterOpen ? "popUpAnimation" : ""}`}
      >
        <div
          style={{
            width: "100%",
            height: 210,
            paddingLeft: 0,
            paddingTop: 0,
            paddingRight: 30,
            overflowY: "scroll",
            overflowX: "hidden",
            paddingBottom: 10,
            fontFamily: "SSMedium",
            fontSize: 17,
            paddingTop: 20,
            scrollbarWidth: 1,
          }}
        >
          {renderOptions(
            ["industry", "objective", "region", "language", "ctr"][index]
          )}
        </div>
      </div>
    </>
  );
};

export default Filter;
