import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import DashboardFooter from "../Layout/DashboardFooter";
import "react-phone-number-input/style.css";
import Heading from "./Heading";

const Checkout = ({ email, popUpVisible, setPopUpVisible, ref, seconds }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          height: "100vh",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#121114",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: "30vh",
            }}
            size="12"
          >
            <MDBRow style={{ marginTop: 0 }}>
              <DashboardNavBar
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
                email={email}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                seconds={seconds}
                ref={ref}
              />
            </MDBRow>

            <MDBCol className="d-flex justify-content-center" size="12">
              <Heading setIsOpen={setIsOpen} />
            </MDBCol>
          </MDBCol>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Checkout);
