import React, { Component } from "react";
import "../index.css";
import "./index.css";
import TopMenu from "../Layout/TopMenu";
import MenuDesktop from "../Layout/MenuDesktop";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiArrowRightSLine,
  RiCloseLine,
} from "react-icons/ri";
import mixpanel from "mixpanel-browser";
import { HiLightningBolt, HiSortAscending } from "react-icons/hi";
import ReactStars from "react-stars";
import { firebase } from "../../../firebase/config";
import { SkyLightStateless } from "react-skylight";
import { TiTick } from "react-icons/ti";
import { IoSearch, IoLocationSharp, IoArrowUp } from "react-icons/io5";
import Scroll from "react-scroll";
import Footer from "../Layout/Footer";
import FAQ from "../FAQ";
import { VscStarEmpty } from "react-icons/vsc";

class Testimonial extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: "white",
          paddingBottom: 6,
          width: 220.89,
          borderColor: "rgba(34, 43, 51, 0.06)",
          borderWidth: "2px",
          borderStyle: "solid",
          borderRadius: 6,
          marginLeft: this.props.marginLeft,
          display: "inline-block",
          overFlowX: "hidden",
          paddingRight: 20,
        }}
      >
        <MDBRow>
          <MDBCol size="3">
            <img
              src={require(`../../../Assets/Images/${this.props.image}.jpeg`)}
              style={{
                borderRadius: 200,
                height: 20,
                width: 20,
                marginLeft: 18,
                marginTop: 14,
              }}
            />
          </MDBCol>
          <MDBCol size="4">
            <MDBRow>
              <p
                style={{
                  fontSize: 11,
                  fontFamily: "Inter-SemiBold",
                  marginLeft: 6,
                  marginTop: 13,
                  width: 300,
                  color: "#30312c",
                }}
              >
                {this.props.name}
              </p>
            </MDBRow>
            <MDBRow>
              <p
                style={{
                  fontSize: 9,
                  fontFamily: "PlusJSMedium",
                  marginLeft: 6,
                  marginTop: -16,
                  opacity: 0.7,
                  color: "#30312c",
                }}
              >
                {this.props.handle}
              </p>
            </MDBRow>
          </MDBCol>
          <MDBCol size="5">
            <p
              style={{
                fontSize: 10,
                fontFamily: "PlusJSMedium",
                marginLeft: 30,
                marginTop: 13,
                opacity: 0.7,
              }}
            >
              {this.props.date}
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <p
            style={{
              fontSize: 10,
              fontFamily: "PlusJSMedium",
              marginLeft: 25,
              marginTop: -8,
              opacity: 0.7,
              lineHeight: 1.7,
              color: "#30312c",

              overflowWrap: "anywhere",
            }}
          >
            {this.props.text}
          </p>
        </MDBRow>
      </div>
    );
  }
}

export default withRouter(Testimonial);
