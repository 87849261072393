import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen, HiSwitchHorizontal } from "react-icons/hi";
import { BiColorFill, BiHelpCircle, BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSignUpModal } from "../Context/SignUpModal";
import { RiCloseFill, RiRedPacketLine, RiTrophyLine } from "react-icons/ri";
import {
  FiCheck,
  FiCopy,
  FiCreditCard,
  FiHeadphones,
  FiMail,
  FiPlus,
  FiShoppingBag,
} from "react-icons/fi";
import { FaLink, FaRegBuilding, FaRegImage } from "react-icons/fa";
import { useDetectClickOutside } from "react-detect-click-outside";
import { AiOutlineLogout } from "react-icons/ai";
import { useAuth } from "../Context/AuthContext";

const AccountPopUp = () => {
  const [inviteEmail, setInviteEmail] = useState("");
  const [password, setPassword] = useState("");
  const { accountOpen, setAccountOpen } = useSignUpModal();
  const { companyName } = useAuth();
  const [error, setError] = useState("");
  const [finishedReset, setFinishedReset] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [signUpMode, setSignUpMode] = useState(true);
  const [verificationError, setVerificationError] = useState(false);
  const [planType, setPlanType] = useState("Monthly");
  const [profile, setProfile] = useState("");
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (accountOpen) {
        setAccountOpen(false);
      }
    },
  });

  const plans = [
    {
      title: "Free",
      price: "$0",
      features: [
        "1 Free Credit",
        "1 Space Seat",
        "120+ Public Avatars",
        "300+ Public Voices",
        "Photo Avatar",
        "Generative AI Outfit",
        "Avatar FaceSwap",
        "400+ Video Templates",
      ],
    },
    {
      title: "Creator",
      price: "$179",
      features: [
        "1 Free Credit",
        "1 Space Seat",
        "120+ Public Avatars",
        "300+ Public Voices",
        "Photo Avatar",
        "Generative AI Outfit",
        "Avatar FaceSwap",
        "400+ Video Templates",
      ],
    },
    {
      title: "Business",
      price: "$539",
      features: [
        "1 Free Credit",
        "1 Space Seat",
        "120+ Public Avatars",
        "300+ Public Voices",
        "Photo Avatar",
        "Generative AI Outfit",
        "Avatar FaceSwap",
        "400+ Video Templates",
      ],
    },
    {
      title: "Enterprise",
      price: "Let's talk",
      features: [
        "1 Free Credit",
        "1 Space Seat",
        "120+ Public Avatars",
        "300+ Public Voices",
        "Photo Avatar",
        "Generative AI Outfit",
        "Avatar FaceSwap",
        "400+ Video Templates",
      ],
    },
  ];

  const categories = [
    {
      title: "Format",
      icon: (
        <FaRegImage
          style={{
            marginRight: 9,
            fontSize: 22,
            opacity: 0.7,
            marginTop: -2.5,
          }}
        />
      ),
    },
    {
      title: "Platforms",
      icon: (
        <FiCreditCard
          style={{
            marginRight: 9,
            fontSize: 22,
            opacity: 0.7,
            marginTop: -2.5,
          }}
        />
      ),
    },
    {
      title: "Industry",
      icon: (
        <FaRegBuilding
          style={{
            marginRight: 9,
            fontSize: 22,
            opacity: 0.7,
            marginTop: -2.5,
          }}
        />
      ),
    },
    {
      title: "Brand",
      icon: (
        <FiShoppingBag
          style={{
            marginRight: 9,
            fontSize: 22,
            opacity: 0.7,
            marginTop: -2.5,
          }}
        />
      ),
    },
    {
      title: "Theme",
      icon: (
        <BiColorFill
          style={{
            marginRight: 9,
            fontSize: 22,
            opacity: 0.7,
            marginTop: -2.5,
          }}
        />
      ),
    },
    {
      title: "Score",
      icon: (
        <RiTrophyLine
          style={{
            marginRight: 9,
            fontSize: 22,
            opacity: 0.7,
            marginTop: -2.5,
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div
        ref={ref}
        style={{
          paddingBottom: 5,
          width: 200,
          background: "white",
          borderRadius: 8,
          boxShadow:
            "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          paddingLeft: 15,
          display: "flex",
          animation: accountOpen ? "popUp 0.3s ease-in-out" : "none",
          visibility: accountOpen ? "visible" : "hidden",
          position: "absolute",
          right: 210,
          marginTop: 180,
          zIndex: 9000,
        }}
        className={`backy60 ${accountOpen ? "popUpAnimation" : ""}`}
      >
        <MDBRow
          style={{ fontFamily: "SSMedium", fontSize: 14, color: "black" }}
        >
          <MDBCol size="12">
            <p style={{ marginTop: 16 }}>Signed in as</p>
            <p style={{ marginTop: -13, fontSize: 12, opacity: 0.8 }}>
              {firebase.auth().currentUser.email.slice(0, 23)}
              {firebase.auth().currentUser.email.length > 17 && "..."}
            </p>
            <hr
              style={{
                width: 200,
                backgroundColor: "grey",
                marginLeft: -15,
                marginTop: -5,
                opacity: 0.1,
              }}
            />

            <p
              onClick={() => firebase.auth().signOut()}
              style={{ marginTop: 0, color: "red", cursor: "pointer" }}
            >
              <AiOutlineLogout
                style={{
                  fontSize: 18,
                  marginRight: 11,
                  marginTop: -2,
                  marginLeft: -5,
                }}
              />
              Sign out
            </p>
          </MDBCol>
        </MDBRow>
      </div>
    </>
  );
};

export default withRouter(AccountPopUp);
