import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import axios from "axios";
import "react-phone-number-input/style.css";
import MainMenu from "../Layout/MainMenu";
import { FaRegCompass, FaRegStar } from "react-icons/fa";
import { BsBackpack } from "react-icons/bs";
import { IoFilterSharp } from "react-icons/io5";
import SideNav from "../Layout/SideNav";
import TopBar from "../Layout/TopBar";
import Checkout from "./Checkout";
import { TailSpin } from "react-loader-spinner";
import { useAuth } from "../Context/AuthContext";
import { IoIosCheckmarkCircle } from "react-icons/io";
import BriefModal from "./BriefModal";

const CreatorOnboarding = () => {
  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#fff",
            height: "100vh",
            overflowY: "scroll",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 0,
              marginTop: 150,
            }}
            size="12"
            className="d-flex justify-content-center"
          >
            <BriefModal />
          </MDBCol>
        </div>
      </div>
    </>
  );
};

export default withRouter(CreatorOnboarding);
