import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { RiSettings4Line } from "react-icons/ri";
import { BiGridAlt, BiCrown } from "react-icons/bi";
import { firebase } from "../../../firebase/config";
import { SkyLightStateless } from "react-skylight";
import enhanceWithClickOutside from "react-click-outside";
import OutsideClickHandler from "react-outside-click-handler";
import { FaClone, FaDollarSign, FaMoneyBillWave } from "react-icons/fa";
import { BsFillCollectionFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useAuth } from "../Context/AuthContext";

const PopUpMobile = ({ email, setPopUpVisible, popUpVisible }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { paid } = useAuth();

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setPopUpVisible(false);
      }}
    >
      <SkyLightStateless
        hideOnOverlayClicked={true}
        onOverlayClicked={() => {
          setPopUpVisible(false);
        }}
        onCloseClicked={() => {
          setPopUpVisible(false);
        }}
        isVisible={popUpVisible}
        overlayStyles={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9000,
          backgroundColor: "transparent",
          opacity: 0.8,
          border: "none",
        }}
        dialogStyles={{
          zIndex: 9000,
          width: "70vw",
          top: 304,
          backgroundColor: "transparent",
          border: "none",
          borderRadius: 9,
          boxShadow: "none",
          right: "10vw",
          position: "fixed",
          paddingBottom: 10,
        }}
        closeButtonStyle={{
          display: "none",
        }}
      >
        <div
          className={`fade-in-out ${popUpVisible ? "visible" : ""}`}
          style={{
            paddingBottom: 0,
            width: "60vw",
            backgroundColor: "rgb(239, 240, 236)",
            position: "fixed",
            right: 0,
            top: 0,
            paddingTop: 5,
            borderRadius: 8,
            boxShadow: "0px 0px 10px -6px rgba(0,0,0,1)",
            border: "1px solid #2b2f3b",
            overflow: "hidden",
          }}
        >
          <MDBRow style={{ color: "black", paddingLeft: 14 }}>
            <MDBCol
              style={{ borderBottom: "1px solid #2b2f3b", paddingBottom: 8 }}
              size="12"
            >
              <div
                style={{
                  display: "inline-block",
                  position: "absolute",
                  backgroundColor: "#f8284e",
                  width: 40,
                  height: 40,
                  borderRadius: 9,
                  marginTop: 10,
                }}
                className="d-flex justify-content-center"
              >
                <p
                  style={{
                    marginTop: 9,
                    fontFamily: "CSBold",
                    fontFamily: "MAHeavy",
                    color: "white",
                  }}
                >
                  {firebase.auth().currentUser &&
                    firebase.auth().currentUser.email.charAt(0).toUpperCase()}
                </p>
              </div>
              <p
                style={{
                  fontFamily: "MABold",
                  color: "black",
                  fontSize: "3.4vw",
                  marginTop: 20,
                  display: "inline-block",
                  marginLeft: 55,
                  cursor: "pointer",
                  zIndex: 3000,
                }}
              >
                {firebase.auth().currentUser &&
                  firebase.auth().currentUser.email.substring(0, 20)}{" "}
                {firebase.auth().currentUser &&
                  firebase.auth().currentUser.email.length > 20 &&
                  "..."}
              </p>
            </MDBCol>

            <MDBCol
              style={{
                borderTop: "0px solid #2b2f3b",
                marginTop: 8,
                paddingTop: 10,
                paddingLeft: 25,
              }}
              size="12"
            >
              <NavLink
                onClick={() => setPopUpVisible(false)}
                to="/videos"
                exact
                style={{
                  fontSize: "3.6vw",
                  marginTop: 6,
                  opacity: 1,
                  color: "black",
                  fontFamily: "MADemiBold",
                }}
              >
                <BiGridAlt
                  style={{ fontSize: "3.8vw", marginRight: 7, marginTop: -2.6 }}
                  className="d-inline"
                />{" "}
                {t("popupVideo")}
              </NavLink>
            </MDBCol>
            <MDBCol
              style={{
                borderTop: "0px solid #2b2f3b",
                marginTop: -6,
                paddingTop: 16,
                paddingLeft: 25,
              }}
              size="12"
            >
              <NavLink
                onClick={() => setPopUpVisible(false)}
                to="/explore"
                exact
              >
                <p
                  style={{
                    fontSize: "3.6vw",
                    marginTop: 6,
                    opacity: 1,
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "MADemiBold",
                  }}
                >
                  <RiSettings4Line
                    style={{
                      fontSize: "3.8vw",
                      marginRight: 7,
                      marginTop: -2.6,
                    }}
                    className="d-inline"
                  />{" "}
                  {t("popupExplore")}
                </p>
              </NavLink>
            </MDBCol>
            <MDBCol
              style={{
                borderTop: "0px solid #2b2f3b",
                marginTop: -19,
                paddingTop: 16,
                paddingLeft: 25,
              }}
              size="12"
            >
              <NavLink
                onClick={() => setPopUpVisible(false)}
                to="/create-clone"
                exact
              >
                <p
                  style={{
                    fontSize: "3.6vw",
                    marginTop: 6,
                    opacity: 1,
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "MADemiBold",
                  }}
                >
                  <FaClone
                    style={{
                      fontSize: "3.8vw",
                      marginRight: 7,
                      marginTop: -2.6,
                    }}
                    className="d-inline"
                  />{" "}
                  {t("popupCreateClone")}
                </p>
              </NavLink>
            </MDBCol>
            <MDBCol
              style={{
                borderTop: "0px solid #2b2f3b",
                marginTop: -19,
                paddingTop: 16,
                paddingLeft: 25,
              }}
              size="12"
            >
              <NavLink
                onClick={() => setPopUpVisible(false)}
                to="/clones"
                exact
              >
                <p
                  style={{
                    fontSize: "3.6vw",
                    marginTop: 6,
                    opacity: 1,
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "MADemiBold",
                  }}
                >
                  <BsFillCollectionFill
                    style={{
                      fontSize: "3.8vw",
                      marginRight: 7,
                      marginTop: -2.6,
                    }}
                    className="d-inline"
                  />{" "}
                  {t("popupMyClones")}
                </p>
              </NavLink>
            </MDBCol>

            <MDBCol
              style={{
                borderTop: "0px solid #2b2f3b",
                marginTop: -5,
                paddingTop: 0,
                paddingLeft: 25,
              }}
              size="12"
            >
              <NavLink
                onClick={() => setPopUpVisible(false)}
                to="/checkout"
                exact
              >
                <p
                  style={{
                    fontSize: "3.6vw",
                    marginTop: 6,
                    opacity: 1,
                    color: "black",
                    fontFamily: "MADemiBold",
                    cursor: "pointer",
                  }}
                >
                  <FaMoneyBillWave
                    style={{
                      fontSize: "3.8vw",
                      marginRight: 7,
                      marginTop: -3.2,
                    }}
                    className="d-inline"
                  />{" "}
                  {t("buyCredits")}
                </p>
              </NavLink>
            </MDBCol>

            <MDBCol
              style={{
                borderTop: "1px solid #2b2f3b",
                marginTop: 0,
                paddingTop: 16,
                paddingBottom: 6,
                paddingLeft: 29,
              }}
              size="12"
            >
              <p
                style={{
                  fontSize: "3.6vw",
                  marginTop: 0,
                  opacity: 1,
                  color: "black",
                  fontFamily: "MADemiBold",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setPopUpVisible(false);
                  firebase
                    .auth()
                    .signOut()
                    .then(() => {
                      history.push("/");
                      window.location.reload();
                      window.scrollTo(0, 0);
                    });
                }}
              >
                <FiLogOut
                  style={{ fontSize: "3.8vw", marginRight: 7, marginTop: -2.6 }}
                  className="d-inline"
                />{" "}
                {t("popupLogout")}
              </p>
            </MDBCol>
          </MDBRow>
        </div>
      </SkyLightStateless>
    </OutsideClickHandler>
  );
};

export default withRouter(enhanceWithClickOutside(PopUpMobile));
