import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { TailSpin } from "react-loader-spinner";
import { FaShippingFast } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { MdOutlinePhonelink } from "react-icons/md";

const Intro = ({ editorID, creatorID }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [monthlyEarnings, setMonthlyEarnings] = useState(0);
  const [completedProjects, setCompletedProjects] = useState(0);
  const [rating, setRating] = useState(0);
  const [iban, setIban] = useState("");
  const [firstName, setFirstName] = useState("");
  const [nextPayoutDate, setNextPayoutDate] = useState("");

  useEffect(() => {
    if (creatorID) {
      const fetchData = async () => {
        const db = firebase.firestore();

        // Fetch total earnings
        const earningsSnapshot = await db
          .collection("creators")
          .doc(creatorID)
          .collection("earnings")
          .get();
        let total = 0;
        earningsSnapshot.forEach((doc) => {
          total += doc.data().amount;
        });
        setTotalEarnings(total);

        // Fetch monthly earnings (last 29 days)
        const lastMonthSnapshot = await db
          .collection("creators")
          .doc(creatorID)
          .collection("earnings")
          .where("date", ">", new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))
          .get();
        let monthlyTotal = 0;
        lastMonthSnapshot.forEach((doc) => {
          monthlyTotal += doc.data().amount;
        });
        setMonthlyEarnings(monthlyTotal);

        // Fetch completed projects
        const completedProjectsSnapshot = await db
          .collection("videoTasks")
          .where("creatorId", "==", creatorID)
          .where("videoStatus", "==", "Complete")
          .get();
        setCompletedProjects(completedProjectsSnapshot.size);

        // Fetch creator info
        const creatorDoc = await db.collection("creators").doc(creatorID).get();
        if (creatorDoc.exists) {
          const data = creatorDoc.data();
          setRating(data.rank);
          setIban(data.IBAN);
          setFirstName(data.firstName);
        }

        // Calculate next payout date
        const now = new Date();
        const nextPayout = now.getMonth() === 1 ? 28 : 30; // February is 28 days
        const nextPayoutDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          nextPayout
        );
        setNextPayoutDate(nextPayoutDate.toLocaleDateString());
      };

      fetchData();
    }
  }, [creatorID]);

  useEffect(() => {
    if (editorID) {
      const fetchData = async () => {
        const db = firebase.firestore();

        // Fetch total earnings
        const earningsSnapshot = await db
          .collection("editors")
          .doc(editorID)
          .collection("earnings")
          .get();
        let total = 0;
        earningsSnapshot.forEach((doc) => {
          total += doc.data().amount;
        });
        setTotalEarnings(total);

        // Fetch monthly earnings (last 29 days)
        const lastMonthSnapshot = await db
          .collection("editors")
          .doc(editorID)
          .collection("earnings")
          .where("date", ">", new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))
          .get();
        let monthlyTotal = 0;
        lastMonthSnapshot.forEach((doc) => {
          monthlyTotal += doc.data().amount;
        });
        setMonthlyEarnings(monthlyTotal);

        // Fetch completed projects
        const completedProjectsSnapshot = await db
          .collection("videoTasks")
          .where("editorId", "==", editorID)
          .where("videoStatus", "==", "Complete")
          .get();
        setCompletedProjects(completedProjectsSnapshot.size);

        // Fetch creator info
        const editorDoc = await db.collection("editors").doc(editorID).get();
        if (editorDoc.exists) {
          const data = editorDoc.data();
          setRating(data.rank);
          setIban(data.IBAN);
          setFirstName(data.firstName);
        }

        // Calculate next payout date
        const now = new Date();
        const nextPayout = now.getMonth() === 1 ? 28 : 30; // February is 28 days
        const nextPayoutDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          nextPayout
        );
        setNextPayoutDate(nextPayoutDate.toLocaleDateString());
      };

      fetchData();
    }
  }, [editorID]);

  if (!firstName) {
    return null;
  }
  return (
    <MDBCol size="12">
      <div>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
          }}
        >
          {/* Step 0 of 4 */}
        </p>
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 34,
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          Summary for {firstName}
        </p>
        <p
          style={{
            fontFamily: "SSMedium",
            marginTop: 10,
            lineHeight: 1.7,
            width: 410,
            fontSize: 18,
          }}
        >
          Total Earnings: {totalEarnings} UAH
        </p>
        <p
          style={{
            fontFamily: "SSMedium",
            marginTop: 10,
            lineHeight: 1.7,
            width: 410,
            fontSize: 18,
          }}
        >
          This Month's Earnings: {monthlyEarnings} UAH
        </p>
        <p
          style={{
            fontFamily: "SSMedium",
            marginTop: 10,
            lineHeight: 1.7,
            width: 410,
            fontSize: 18,
          }}
        >
          Next Payout Date: {nextPayoutDate}
        </p>
        <p
          style={{
            fontFamily: "SSMedium",
            marginTop: 10,
            lineHeight: 1.7,
            width: 410,
            fontSize: 18,
          }}
        >
          Completed Projects: {completedProjects}
        </p>
        <p
          style={{
            fontFamily: "SSMedium",
            marginTop: 10,
            lineHeight: 1.7,
            fontSize: 18,
            width: "90%",
          }}
        >
          Rating: {rating ? rating?.toFixed(1) : 0.0}/5.0{" "}
          <p style={{ fontSize: 14 }} className="d-inline">
            (Your rating score is determined by customer feedback and how fast
            you complete projects. The higher your rating, the more projects you
            will recieve.)
          </p>
        </p>
        <p
          style={{
            fontFamily: "SSMedium",
            marginTop: 10,
            lineHeight: 1.7,
            width: 410,
            fontSize: 18,
          }}
        >
          IBAN: {iban}
          {"  "}
          <a
            href="https://t.me/+1234567890"
            target="_blank"
            style={{ textDecoration: "underline", color: "blue", fontSize: 14 }}
            className="d-inline"
          >
            (Need to change? Get help.)
          </a>
        </p>
      </div>
    </MDBCol>
  );
};

export default withRouter(Intro);
