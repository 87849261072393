import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const QuestionsMobile = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [iconVisible, setIconVisible] = useState(true);
  const [openIndex, setOpenIndex] = useState(null);
  const { t } = useTranslation();

  const videoRef = useRef();

  const faqs = [
    {
      question: t("FAQTitle1"),
      answer: t("FAQDescription1"),
    },
    {
      question: t("FAQTitle2"),
      answer: t("FAQDescription2"),
    },
    {
      question: t("FAQTitle3"),
      answer: t("FAQDescription3"),
    },
    {
      question: t("FAQTitle4"),
      answer: t("FAQDescription4"),
    },
    {
      question: t("FAQTitle6"),
      answer: t("FAQDescription6"),
    },
    {
      question: t("FAQTitle7"),
      answer: t("FAQDescription7"),
    },
    {
      question: t("FAQTitle8"),
      answer: t("FAQDescription8"),
    },
    {
      question: t("FAQTitle9"),
      answer: t("FAQDescription9"),
    },
  ];

  const toggleOpen = (index) => {
    if (index === openIndex) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  const handleMouseEnter = (item) => {
    setCurrentItem(item);
    const video = videoRef.current;
    if (video) {
      video.play();
    }
  };

  const handleMouseLeave = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      video.currentTime = 0; // Rewind the video to the beginning when pausing
      setCurrentItem(null);
    }
  };

  return (
    <>
      <MDBRow
        style={{
          marginTop: 0,
          paddingTop: 0,
          paddingBottom: 10,
        }}
      >
        <MDBCol className="d-flex justify-content-center" size="12">
          <MDBRow
            style={{
              marginTop: 10,
              width: "100%",
              borderRadius: 10,
              paddingBottom: 50,
              overflowY: "hidden",
              marginLeft: 7,
              backgroundColor: "#27292d",
              border: "2px solid black",
            }}
          >
            <MDBCol
              style={{ paddingLeft: 0 }}
              className="d-flex justify-content-start"
              size="12"
            >
              <MDBRow style={{ marginTop: 40, paddingLeft: 0 }}>
                {faqs.map((faq, index) => (
                  <>
                    <MDBCol
                      key={faq.question}
                      className="d-flex justify-content-start"
                      size="12"
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                    >
                      <div
                        onClick={() => toggleOpen(index)}
                        style={{
                          color: "white",
                          fontFamily: "CSMedium",
                          fontSize: "4vw",
                          marginLeft: 30,
                          lineHeight: 1,
                          opacity: 1,
                          cursor: "pointer",
                          userSelect: "none",
                          paddingTop: 5,
                          paddingBottom: 26,
                          width: "90%",
                        }}
                        className="d-flex justify-content-between"
                      >
                        <p style={{ width: "60vw", lineHeight: 1.3 }}>
                          {faq.question}
                        </p>{" "}
                        <div
                          style={{
                            height: "6vw",
                            width: "6vw",
                            backgroundColor: "transparent",
                            borderRadius: 100,
                            marginTop: 0,
                            border: "2px solid white",
                          }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <AiOutlinePlus
                            style={{ color: "#fff", fontSize: 13 }}
                          />
                        </div>
                      </div>
                    </MDBCol>
                    <MDBCol
                      className="d-flex justify-content-start"
                      size="12"
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                      }}
                    >
                      <div
                        style={{
                          color: "white",
                          fontFamily: "MADemiBold",
                          fontSize: "4vw",
                          marginTop: openIndex === index ? -14 : 0,
                          marginLeft: 30,
                          lineHeight: 1.6,
                          opacity: 0.7,
                          borderBottom: "1px solid grey",
                          width: "90%",
                          marginBottom: 40,
                          paddingBottom: openIndex === index ? 50 : 0,
                        }}
                      >
                        {openIndex === index && <div>{faq.answer}</div>}
                      </div>
                    </MDBCol>
                  </>
                ))}
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(QuestionsMobile);
