import React from "react";

const Layout = (props) => {
  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Layout;
