import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageModal from "./LanguageModal";
import LanguageModalMobile from "./LanguageModalMobile";

const countryCodeToFlagPath = (code) => {
  const language = languages.find((lang) => lang.code === code);
  if (language) {
    return language.flag;
  } else {
    return "Path not found";
  }
};

const languages = [
  { code: "en", name: "English", flag: require("./flags/usa.png") },
  { code: "gb", name: "English(UK)", flag: require("./flags/gb.png") },
  { code: "es", name: "Spanish", flag: require("./flags/spain.png") },
  { code: "jp", name: "Japanese", flag: require("./flags/japan.png") },
  { code: "zh", name: "Chinese", flag: require("./flags/china.png") },
  { code: "de", name: "German", flag: require("./flags/germany.png") },
  { code: "hi", name: "Hindi", flag: require("./flags/india.png") },
  { code: "fr", name: "French", flag: require("./flags/france.png") },
  { code: "ko", name: "Korean", flag: require("./flags/south-korea.png") },
  {
    code: "br",
    name: "Brazilian Portuguese",
    flag: require("./flags/brazil.png"),
  },
  { code: "pt", name: "Portuguese", flag: require("./flags/portugal.png") },
  { code: "it", name: "Italian", flag: require("./flags/italy.png") },
  {
    code: "mx",
    name: "Mexican Spanish",
    flag: require("./flags/mexico.png"),
  },
  {
    code: "ind",
    name: "Indonesian",
    flag: require("./flags/indonesia.png"),
  },
  { code: "du", name: "Dutch", flag: require("./flags/dutch.png") },
  { code: "tr", name: "Turkish", flag: require("./flags/turkey.png") },
  { code: "ph", name: "Filipino", flag: require("./flags/philippines.png") },
  { code: "po", name: "Polish", flag: require("./flags/poland.png") },
  { code: "sw", name: "Swedish", flag: require("./flags/sweden.png") },
  { code: "bu", name: "Bulgarian", flag: require("./flags/bulgaria.png") },
  {
    code: "sa_ar",
    name: "Saudi Arabian Arabic",
    flag: require("./flags/saudi.png"),
  },
  { code: "uae_ar", name: "UAE Arabic", flag: require("./flags/uae.png") },
  { code: "cz", name: "Czech", flag: require("./flags/czech-republic.png") },
  { code: "gr", name: "Greek", flag: require("./flags/greece.png") },
  { code: "fi", name: "Finnish", flag: require("./flags/finland.png") },
  { code: "cr", name: "Croatian", flag: require("./flags/croatia.png") },
  { code: "ma", name: "Malaysian", flag: require("./flags/malaysia.png") },
  { code: "sl", name: "Slovenian", flag: require("./flags/slovenia.png") },
  { code: "da", name: "Danish", flag: require("./flags/denmark.png") },
  { code: "ru", name: "Russian", flag: require("./flags/russia.png") },
  { code: "uk", name: "Ukrainian", flag: require("./flags/ukraine.png") },
];

const DashboardFooterMobile = () => {
  const { t, i18n } = useTranslation();
  const [flag, setFlag] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const flagFile = countryCodeToFlagPath(i18n.language);
    setFlag(flagFile);
  }, [i18n.language]);

  return (
    <MDBRow
      style={{
        paddingBottom: 40,
        color: "rgb(242, 241, 243)",
        overflowX: "hidden",
        paddingTop: 0,
        borderTop: "0px solid #323033",
        backgroundColor: "transparent",
        width: "100vw",
      }}
      size="12"
      className="d-flex justify-content-center"
    >
      {/* <LanguageModalMobile isOpen={isOpen} setIsOpen={setIsOpen} />
      <div
        style={{
          position: "fixed",
          bottom: 10,
          left: 10,
          height: 60,
          paddingLeft: 20,
          paddingRight: 20,
          backgroundColor: "rgb(32, 30, 35)",
          borderRadius: 7,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 5, // Align items vertically in the center
          cursor: "pointer",
          zIndex: 1000,
        }}
        onClick={() => setIsOpen(true)}
      >
        {flag && (
          <img
            src={flag}
            style={{ height: 30, marginRight: 10, marginTop: 0 }}
          />
        )}
        <p
          style={{
            fontFamily: "CSBold",
            fontSize: 19,
            margin: 0,
            textAlign: "center",
          }}
        >
          Change Country
        </p>
      </div> */}
      <MDBRow
        style={{ width: "80vw" }}
        className="d-flex justify-content-center"
      >
        <MDBCol className="d-flex justify-content-center" size="12">
          <p
            style={{
              color: "black",
              fontFamily: "MADemiBold",
              textAlign: "center",
            }}
          >
            {t("footer")}
          </p>
        </MDBCol>
      </MDBRow>
    </MDBRow>
  );
};

export default withRouter(DashboardFooterMobile);
