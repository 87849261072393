import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen, HiSwitchHorizontal } from "react-icons/hi";
import { BiColorFill, BiHelpCircle, BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSignUpModal } from "../Context/SignUpModal";
import { RiCloseFill, RiRedPacketLine, RiTrophyLine } from "react-icons/ri";
import {
  FiCheck,
  FiCopy,
  FiCreditCard,
  FiHeadphones,
  FiMail,
  FiPlus,
  FiShoppingBag,
} from "react-icons/fi";
import { FaLink, FaRegBuilding, FaRegImage } from "react-icons/fa";
import { useDetectClickOutside } from "react-detect-click-outside";
import { AiOutlineLogout } from "react-icons/ai";
import { useAuth } from "../Context/AuthContext";
import { HexColorPicker } from "react-colorful";
import {
  TbAlignBoxCenterMiddle,
  TbAlignBoxLeftMiddle,
  TbAlignBoxRightMiddle,
  TbAlignCenter,
  TbAlignLeft,
  TbAlignRight,
  TbBoxAlignLeft,
  TbStackBack,
  TbStackBackward,
  TbStackForward,
  TbStackFront,
} from "react-icons/tb";

const LayerAlignPicker = ({
  layerAlignPickerOpen,
  setLayerAlignPickerOpen,
  selectedLayerAlign,
  setSelectedLayerAlign,
  selectedIndex,
  videoItems,
}) => {
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (layerAlignPickerOpen) {
        setLayerAlignPickerOpen(false);
      }
    },
  });

  return (
    <>
      <div
        ref={ref}
        style={{
          width: 180,
          borderRadius: 8,
          boxShadow:
            "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          display: "flex",
          animation: layerAlignPickerOpen ? "popUp 0.3s ease-in-out" : "none",
          visibility: layerAlignPickerOpen ? "visible" : "hidden",
          position: "absolute",
          marginTop: 240,
          zIndex: 11000,
          border: "none",
          marginLeft: -75,
          backgroundColor: "white",
          paddingTop: 7,
          paddingBottom: 5,
          paddingLeft: 5,
          paddingRight: 5,
        }}
        className={`backy60 ${layerAlignPickerOpen ? "popUpAnimation" : ""}`}
      >
        <MDBRow>
          <MDBCol size="12">
            <div
              style={{
                height: "100%",
                textAlign: "center",
                fontWeight: "700",
                paddingTop: 9,
                borderRadius: 8,
                marginRight: 3,
                display: "flex",
                color:
                  videoItems[selectedIndex]?.layerAlign === "left"
                    ? "white"
                    : null,
                backgroundColor:
                  videoItems[selectedIndex]?.layerAlign === "left"
                    ? "rgb(117, 89, 255)"
                    : null,
              }}
              onClick={() => {
                setSelectedLayerAlign("front");
              }}
            >
              <TbStackFront
                style={{ fontSize: 24, marginRight: 10, marginLeft: 6 }}
              />
              <p style={{ fontSize: 15, marginTop: 0.5 }}>Bring to Front</p>
            </div>
          </MDBCol>
          <MDBCol size="12">
            <div
              style={{
                height: "100%",
                textAlign: "center",
                fontWeight: "700",
                paddingTop: 9,
                borderRadius: 8,
                marginRight: 3,
                marginTop: -8,
                display: "flex",
                color:
                  videoItems[selectedIndex]?.layerAlign === "left"
                    ? "white"
                    : null,
                backgroundColor:
                  videoItems[selectedIndex]?.layerAlign === "left"
                    ? "rgb(117, 89, 255)"
                    : null,
              }}
              onClick={() => {
                setSelectedLayerAlign("forward");
              }}
            >
              <TbStackForward
                style={{ fontSize: 24, marginRight: 10, marginLeft: 6 }}
              />
              <p style={{ fontSize: 15, marginTop: 0.5 }}>Bring Forward</p>
            </div>
          </MDBCol>
          <MDBCol size="12">
            <div
              style={{
                height: "100%",
                textAlign: "center",
                fontWeight: "700",
                paddingTop: 9,
                borderRadius: 8,
                marginRight: 3,
                marginTop: -8,
                display: "flex",
                color:
                  videoItems[selectedIndex]?.layerAlign === "left"
                    ? "white"
                    : null,
                backgroundColor:
                  videoItems[selectedIndex]?.layerAlign === "left"
                    ? "rgb(117, 89, 255)"
                    : null,
              }}
              onClick={() => {
                setSelectedLayerAlign("backward");
              }}
            >
              <TbStackBackward
                style={{ fontSize: 24, marginRight: 10, marginLeft: 6 }}
              />
              <p style={{ fontSize: 15, marginTop: 0.5 }}>Bring Backward</p>
            </div>
          </MDBCol>
          <MDBCol size="12">
            <div
              style={{
                height: "100%",
                textAlign: "center",
                fontWeight: "700",
                paddingTop: 9,
                borderRadius: 8,
                marginRight: 3,
                marginTop: -8,
                display: "flex",
                color:
                  videoItems[selectedIndex]?.layerAlign === "left"
                    ? "white"
                    : null,
                backgroundColor:
                  videoItems[selectedIndex]?.layerAlign === "left"
                    ? "rgb(117, 89, 255)"
                    : null,
              }}
              onClick={() => {
                setSelectedLayerAlign("back");
              }}
            >
              <TbStackBack
                style={{ fontSize: 24, marginRight: 10, marginLeft: 6 }}
              />
              <p style={{ fontSize: 15, marginTop: 0.5 }}>Bring to Back</p>
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    </>
  );
};

export default withRouter(LayerAlignPicker);
