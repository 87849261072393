import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { TailSpin } from "react-loader-spinner";
import { FaShippingFast } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { MdOutlinePhonelink } from "react-icons/md";

const Intro = ({
  setIsOpen,
  isOpen,
  videoID,
  productType,
  setProductType,
  setSection,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <MDBCol size="12">
      <div>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
          }}
        >
          {/* Step 0 of 4 */}
        </p>
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 24,
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          Welcome to RowAds
        </p>
        <p
          style={{
            fontFamily: "SSRegular",
            marginTop: 10,
            lineHeight: 1.7,
            width: "90%",
          }}
        >
          We're going to set up your profile on RowAds to get you started. We'll
          need to verify your identity and get your payout details to send
          payments for completed video projects. It will take about 5 minutes to
          complete.
        </p>
      </div>

      <div
        style={{
          height: 55,
          width: "90%",
          backgroundColor: "#ed1165",
          borderRadius: 7,
          marginTop: 20,
          textAlign: "center",
          fontFamily: "SSRegular",
          color: "white",
          fontSize: 18,
          paddingTop: 14,
          cursor: "pointer",
          opacity: 1,
        }}
        onClick={() => {
          setSection("Step1");
        }}
        className="d-flex justify-content-center"
      >
        {loading ? (
          <TailSpin
            height="25"
            width="25"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{ marginTop: 0 }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            <p>Continue</p>
          </>
        )}
      </div>
    </MDBCol>
  );
};

export default withRouter(Intro);
