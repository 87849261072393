import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import { FaDownload, FaRegCompass, FaRegStar } from "react-icons/fa";
import { RiCloseFill, RiCloseLine } from "react-icons/ri";
import { CircularProgressbar } from "react-circular-progressbar";
import { BsPlayCircle } from "react-icons/bs";
import { FiHeart, FiPlus } from "react-icons/fi";
import { TailSpin } from "react-loader-spinner";
import DeleteModal from "./DeleteModal";
import VideoModal from "./VideoModal";
import { useSignUpModal } from "../Context/SignUpModal";
import AddToProject from "../Layout/AddToProject";

const Categories = ({ clones }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [assetID, setAssetID] = useState("");
  const [videoID, setVideoID] = useState("");
  const [videoOpen, setVideoOpen] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [playbackID, setPlaybackID] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedUGC, setSelectedUGC] = useState("");
  const {
    cloneModalOpen,
    setCloneModalOpen,
    addProjectOpen,
    setAddProjectOpen,
  } = useSignUpModal();
  const history = useHistory();

  function formatDateFromTimestamp(timestamp) {
    // Create a Date object from the timestamp
    const date = new Date(timestamp.seconds * 1000);

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is 0-indexed
    const year = date.getFullYear().toString().substr(-2); // Get last two digits of year

    // Format the date as dd/mm/yy
    return `${day}/${month}/${year}`;
  }

  const triggerDownload = (url, fileName) => {
    // Create a new anchor element
    const anchor = document.createElement("a");

    // Set the href to the download URL
    anchor.href = url;

    // Optionally set the download attribute to a filename
    anchor.download = fileName || "downloaded_video.mp4";

    // Append the anchor to the document
    document.body.appendChild(anchor);

    // Trigger the download by simulating a click
    anchor.click();

    // Remove the anchor from the document
    document.body.removeChild(anchor);
  };

  const downloadVideo = async (assetID) => {
    setError(null);
    setLoading(true);
    if (assetID) {
      var downloadVideo = firebase.functions().httpsCallable("downloadVideo");
      downloadVideo({ assetID }).then((result) => {
        if (result.data.error) {
          console.log(result.data.error);
          setLoading(false);
          return setError(result.data.error);
        } else {
          const url = result.data.url;

          if (url) {
            //navigate to the success page
            triggerDownload(url, "video.mp4");
            setLoading(false);
            setError(null);
          }
        }
      });
    }
  };

  const sortedClones = clones
    ? clones.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
    : null;

  return (
    <>
      <DeleteModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        selectedUGC={selectedUGC}
      />

      <AddToProject ugc={selectedUGC} />

      <MDBRow
        style={{
          width: "82vw",
          paddingBottom: 100,
          paddingLeft: 30,
          overflowY: "scroll",
        }}
      >
        {sortedClones ? (
          sortedClones.length > 0 ? (
            sortedClones.map((clone) => {
              const isFinished = clone.status === "Finished";
              const percentage = isFinished
                ? 100
                : clone.status === "Pending"
                ? 0
                : clone.progress;
              return (
                <MDBCol size="3">
                  <div
                    style={{
                      paddingBottom: 20,
                      width: "18vw",
                      backgroundColor: "white",
                      marginBottom: 30,
                      borderRadius: 9,
                      boxShadow:
                        "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                    }}
                    className="d-flex justify-content-center"
                  >
                    <MDBRow style={{ paddingTop: 14, width: "17.5vw" }}>
                      {clone.status === "Finished" && (
                        <MDBCol
                          className="d-flex justify-content-between"
                          size="12"
                        >
                          <div
                            style={{
                              height: 40,
                              width: 40,
                              border: "0.5px solid red",
                              borderRadius: 6,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedUGC(clone);
                              setDeleteOpen(true);
                            }}
                            className="d-flex justify-content-center"
                          >
                            <RiCloseFill
                              style={{
                                marginTop: 9,
                                fontSize: 20,
                                color: "red",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              height: 40,
                              width: 40,
                              border: "0.5px solid #c2d2f2",
                              borderRadius: 6,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSelectedUGC(clone);
                              setAddProjectOpen(true);
                            }}
                            className="d-flex justify-content-center"
                          >
                            <FiPlus
                              style={{
                                marginTop: 9,
                                fontSize: 20,
                                color: "#2f1878",
                              }}
                            />
                          </div>
                        </MDBCol>
                      )}
                      <MDBCol
                        className="d-flex justify-content-center"
                        style={{ marginTop: 10 }}
                        size="12"
                      >
                        <img
                          src={`https://image.mux.com/${clone.playbackID}/animated.gif`}
                          style={{
                            display: "inline-block",
                            height: "28vw",
                            width: "100%",
                            borderRadius: 4,
                            objectFit: "cover",
                            verticalAlign: "top",
                            backgroundColor: "transparent", // Added this line
                            filter: "blur(1px)",
                          }}
                        />
                        <div
                          style={{
                            backgroundColor: "white",
                            position: "absolute",
                            height: 26,
                            paddingLeft: 10,
                            paddingRight: 10,
                            marginBottom: 14,
                            zIndex: 130,
                            bottom: 0,
                            right: 25,
                            textAlign: "center",
                            borderRadius: 100,
                            fontFamily: "CSSemiBold",
                            paddingTop: 4,
                            fontSize: 13,
                            color: "black",
                          }}
                          className="d-flex justify-content-center"
                        >
                          <p>20 credits</p>
                        </div>
                        <div
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            position: "absolute",
                            height: 26,
                            width: 93,
                            marginBottom: 14,
                            zIndex: 130,
                            bottom: 0,
                            left: 25,
                            textAlign: "center",
                            borderRadius: 100,
                            fontFamily: "CSSemiBold",
                            paddingTop: 5,
                            fontSize: 13,
                          }}
                          className="d-flex justify-content-center"
                        >
                          <p>{formatDateFromTimestamp(clone.createdAt)}</p>
                        </div>
                        {clone.status !== "Finished" ? (
                          clone.progress > 0 ? (
                            <div
                              style={{
                                position: "absolute",
                                marginLeft: "5%",
                                marginTop: 156,
                                zIndex: 100,
                                fontFamily: "CSBold",
                                height: 150,
                                width: 150,
                                fontSize: 24,
                              }}
                            >
                              <CircularProgressbar
                                styles={{
                                  path: {
                                    stroke: "#2f1878",
                                  },
                                  text: {
                                    textSize: "33px",
                                    fill: "white",
                                    fontFamily: "CSBold",
                                  },
                                }}
                                value={percentage}
                                text={`${percentage}%`}
                              />
                            </div>
                          ) : (
                            <p
                              style={{
                                position: "absolute",
                                color: "white",
                                fontFamily: "PPMedium",
                                fontSize: 28,
                                marginTop: 200,
                              }}
                            >
                              {clone.status}...
                            </p>
                          )
                        ) : null}
                      </MDBCol>
                      {clone.status === "Finished" && (
                        <>
                          <MDBCol
                            className="d-flex justify-content-center"
                            style={{ marginTop: 16 }}
                            size="12"
                          >
                            <div
                              style={{
                                width: "100%",
                                height: 50,
                                border: "0.5px solid #c2d2f2",
                                fontFamily: "PPMedium",
                                color: "#2f1878",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelectedUGC(item);
                                setAddProjectOpen(true);
                              }}
                              className="d-flex justify-content-center"
                            >
                              <p style={{ marginTop: 13 }}>
                                {" "}
                                <FiPlus
                                  style={{
                                    fontSize: 20,
                                    marginRight: 8,
                                    marginTop: -1.9,
                                  }}
                                />
                                Add to project
                              </p>
                            </div>
                          </MDBCol>
                          <MDBCol
                            className="d-flex justify-content-center"
                            style={{ marginTop: 10 }}
                            size="12"
                          >
                            <div
                              style={{
                                width: "100%",
                                height: 50,
                                border: "0.5px solid red",
                                fontFamily: "PPMedium",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelectedUGC(clone);
                                setDeleteOpen(true);
                              }}
                              className="d-flex justify-content-center"
                            >
                              <p style={{ marginTop: 13 }}> Delete clone</p>
                            </div>
                          </MDBCol>
                        </>
                      )}
                    </MDBRow>
                  </div>
                </MDBCol>
              );
            })
          ) : (
            <MDBCol className="d-flex justify-content-center" size="12">
              <div style={{ textAlign: "center" }}>
                <p
                  style={{
                    fontFamily: "PPMedium",
                    fontSize: 22,
                    marginTop: 0,
                    opacity: 0.7,
                  }}
                >
                  No clones yet
                </p>
                <p
                  style={{
                    fontFamily: "PPBook",
                    fontSize: 15,
                    marginTop: -11,
                    opacity: 0.7,
                    width: 550,
                  }}
                >
                  Create a clone by uploading a video sample here. You can{" "}
                  <p
                    onClick={() => history.push("/my-projects")}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    className="d-inline"
                  >
                    create it here
                  </p>{" "}
                  or you can first{" "}
                  <p
                    onClick={() => history.push("/")}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    className="d-inline"
                  >
                    explore our library
                  </p>{" "}
                  of UGC content to get inspired.
                </p>
                <div
                  className="d-flex justify-content-center"
                  style={{ display: "flex" }}
                >
                  <div
                    style={{
                      width: 180,
                      height: 40,
                      border: "0px solid black",
                      marginRight: 0,
                      marginTop: 0,
                      cursor: "pointer",
                      paddingTop: 9,
                      boxShadow:
                        "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                      borderRadius: 4,
                      backgroundColor: "white",
                      display: "inline-block",
                    }}
                    className="d-flex justify-content-center"
                    onClick={() => setCloneModalOpen(true)}
                  >
                    <p
                      style={{
                        color: "#2f1878",
                        fontFamily: "PPMedium",
                        fontSize: 15,
                      }}
                    >
                      <FiPlus
                        style={{
                          marginRight: 10,
                          fontSize: 19,
                          opacity: 0.7,
                          marginTop: -1,
                        }}
                      />
                      Create clone
                    </p>
                  </div>
                </div>
              </div>
            </MDBCol>
          )
        ) : null}
      </MDBRow>
    </>
  );
};

export default withRouter(Categories);
