import React from "react";
import "./index.css"; // Make sure to create a corresponding CSS file
import { useTranslation } from "react-i18next";

const AssetInstructions = ({ setSection }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        paddingBottom: 0, // Added this line
        background: "transparent",
      }}
      className="video-instructions-container"
    >
      <div style={{ marginTop: 40 }} className="content">
        <div className="recommendations">
          <p style={{ fontFamily: "PPBold", color: "black", fontSize: 22 }}>
            ✅ {t("Recommended")}
          </p>
          <ul
            style={{
              fontFamily: "PPMedium",
              color: "black",
              width: 300,
              marginLeft: -20,
            }}
          >
            <li style={{ marginTop: 5 }}>Stick to 9:16 vertical format</li>
          </ul>
        </div>

        <div style={{ marginLeft: 20 }} className="avoid">
          <p style={{ fontFamily: "PPBold", color: "black", fontSize: 22 }}>
            ❌ {t("Avoid")}
          </p>
          <ul
            style={{
              fontFamily: "PPMedium",
              color: "black",
              width: 300,
              marginLeft: -20,
            }}
          >
            <li>Square (1:1) or rectangular (16:9) video formats</li>

            {/* Add the rest of the recommendations here */}
          </ul>
        </div>
      </div>

      <div className="tips">
        <p style={{ fontFamily: "PPMedium" }}>
          💡When using your asset inside a project, the audio within the video
          is replaced by your voiceover audio, so make sure the asset doesn't
          have any speech that you'll need to be audible.😊
        </p>
      </div>

      <div onClick={() => setSection(2)} className="next-step">
        <button>{t("nextStepText")}</button>
      </div>
    </div>
  );
};

export default AssetInstructions;
