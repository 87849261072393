import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen, HiSwitchHorizontal } from "react-icons/hi";
import { BiColorFill, BiHelpCircle, BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSignUpModal } from "../Context/SignUpModal";
import { RiCloseFill, RiRedPacketLine, RiTrophyLine } from "react-icons/ri";
import {
  FiCheck,
  FiCopy,
  FiCreditCard,
  FiHeadphones,
  FiMail,
  FiPlus,
  FiShoppingBag,
} from "react-icons/fi";
import { FaLink, FaRegBuilding, FaRegImage } from "react-icons/fa";
import { useDetectClickOutside } from "react-detect-click-outside";
import { AiOutlineLogout } from "react-icons/ai";
import { useAuth } from "../Context/AuthContext";
import { HexColorPicker } from "react-colorful";

const FontWeightPicker = ({
  fontWeightPickerOpen,
  setFontWeightPickerOpen,
  selectedFontWeight,
  setSelectedFontWeight,
  selectedIndex,
  videoItems,
}) => {
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (fontWeightPickerOpen) {
        setFontWeightPickerOpen(false);
      }
    },
  });

  return (
    <>
      <div
        ref={ref}
        style={{
          width: 180,
          borderRadius: 8,
          boxShadow:
            "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          display: "flex",
          animation: fontWeightPickerOpen ? "popUp 0.3s ease-in-out" : "none",
          visibility: fontWeightPickerOpen ? "visible" : "hidden",
          position: "absolute",
          marginTop: 100,
          zIndex: 9000,
          border: "none",
          marginLeft: -80,
          backgroundColor: "white",
          height: 45,
          display: "flex",
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 5,
          paddingRight: 5,
        }}
        className={`backy60 ${fontWeightPickerOpen ? "popUpAnimation" : ""}`}
      >
        <div
          style={{
            width: 40,
            height: "100%",
            textAlign: "center",
            fontWeight: "700",
            paddingTop: 0,
            borderRadius: 8,
            marginRight: 3,
            color:
              videoItems[selectedIndex].style.fontWeight === "700"
                ? "white"
                : null,
            backgroundColor:
              videoItems[selectedIndex].style.fontWeight === "700"
                ? "rgb(117, 89, 255)"
                : null,
          }}
          onClick={() => {
            setSelectedFontWeight((prevState) => ({
              ...prevState,
              fontWeight: prevState.fontWeight === "700" ? null : "700",
            }));
          }}
          className="d-flex justify-content-center"
        >
          <p>B</p>
        </div>
        <div
          style={{
            width: 40,
            height: "100%",
            textAlign: "center",
            fontStyle: "italic",
            paddingTop: 0,
            fontWeight: "700",
            borderRadius: 8,
            marginRight: 3,
            color:
              videoItems[selectedIndex].style.fontStyle === "italic"
                ? "white"
                : null,
            backgroundColor:
              videoItems[selectedIndex].style.fontStyle === "italic"
                ? "rgb(117, 89, 255)"
                : null,
          }}
          onClick={() => {
            setSelectedFontWeight((prevState) => ({
              ...prevState,
              fontStyle: prevState.fontStyle === "italic" ? null : "italic",
            }));
          }}
        >
          <p>I</p>
        </div>
        <div
          style={{
            width: 40,
            height: "100%",
            textAlign: "center",
            paddingTop: 0,
            fontWeight: "700",
            textDecoration: "underline",
            borderRadius: 8,
            marginRight: 3,
            color:
              videoItems[selectedIndex].style.textDecoration === "underline"
                ? "white"
                : null,
            backgroundColor:
              videoItems[selectedIndex].style.textDecoration === "underline"
                ? "rgb(117, 89, 255)"
                : null,
          }}
          onClick={() => {
            setSelectedFontWeight((prevState) => ({
              ...prevState,
              textDecoration:
                prevState.textDecoration === "underline" ? null : "underline",
            }));
          }}
        >
          <p>U</p>
        </div>
        <div
          style={{
            width: 40,
            height: "100%",
            textAlign: "center",
            paddingTop: 0,
            fontWeight: "700",
            borderRadius: 8,
            marginRight: 3,
            color:
              videoItems[selectedIndex].style.textTransform === "uppercase"
                ? "white"
                : null,
            backgroundColor:
              videoItems[selectedIndex].style.textTransform === "uppercase"
                ? "rgb(117, 89, 255)"
                : null,
          }}
          onClick={() => {
            setSelectedFontWeight((prevState) => ({
              ...prevState,
              textTransform:
                prevState.textTransform === "uppercase" ? null : "uppercase",
            }));
          }}
        >
          <p>TT</p>
        </div>
      </div>
    </>
  );
};

export default withRouter(FontWeightPicker);
