import React, { useState, useRef, useEffect } from "react";
import { FaPause, FaPlay } from "react-icons/fa6";
import { TailSpin } from "react-loader-spinner";
import VoiceSettings from "./VoiceSettings";
import { useAuth } from "../Context/AuthContext";
import { firebase } from "../../../firebase/config";

const TextBox = ({ voiceID, setVideoItems, selectedIndex, videoItems }) => {
  const [inputValue, setInputValue] = useState("");
  const [playing, setPlaying] = useState(false);
  const [speechLoading, setSpeechLoading] = useState(false);
  const [error, setError] = useState("");
  const [speechURL, setSpeechURL] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [audioSource, setAudioSource] = useState(null);
  const audioRef = useRef(null);

  const handleChange = (event) => {
    setInputValue(event.target.value.substr(0, limit));
  };

  const pauseAudio = () => {
    if (audioContext && audioSource) {
      audioContext.suspend();
      setPlaying(false);
    }
  };

  const updateVideoItem = (duration, videoURL, script) => {
    setVideoItems((prevItems) => {
      const updatedItems = [...prevItems];
      const selectedItem = updatedItems[selectedIndex];

      if (selectedItem) {
        const endTime = selectedItem.startTime + duration;
        selectedItem.endTime = endTime;
        selectedItem.trimEnd = endTime;
        selectedItem.initialEndTime = endTime;
        selectedItem.videoURL = videoURL;
        selectedItem.script = script;
      }

      return updatedItems;
    });
  };
  useEffect(() => {
    if (inputValue) {
      setSpeechURL(null);
    }
  }, [inputValue]);

  useEffect(() => {
    setSpeechURL(null);
  }, [voiceID]);

  const handleAudioEnded = () => {
    setSamplePlaying(false);
    audioRef.current.removeEventListener("ended", handleAudioEnded);
    audioRef.current = null;
    setCurrentMP3(null);
  };

  const textToSpeech = async () => {
    if (speechURL) {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const response = await fetch(speechURL);
      const arrayBuffer = await response.arrayBuffer();
      audioContext.decodeAudioData(
        arrayBuffer,
        (buffer) => {
          const source = audioContext.createBufferSource();
          source.buffer = buffer;
          source.connect(audioContext.destination);
          source.onended = () => {
            setPlaying(false);
            console.log("Playback finished.");
          };
          source.start(0);
          setAudioContext(audioContext);
          setAudioSource(source);
          setSpeechLoading(false);
          setPlaying(true);
        },
        (e) => {
          console.error("Error decoding audio data:", e);
          setError("Error decoding audio data.");
          setSpeechLoading(false);
        }
      );
    } else if (inputValue && voiceID) {
      setSpeechLoading(true);
      var createSpeech = firebase.functions().httpsCallable("textToSpeech");
      createSpeech({
        text: inputValue,
        voiceID: voiceID,
      }).then(async (result) => {
        if (result.data.error) {
          setError(result.data.error);
          setSpeechLoading(false);
        } else {
          const mp3Url = result.data.audioURL;
          const duration = result.data.duration;
          updateVideoItem(duration, mp3Url, inputValue);
          setSpeechURL(mp3Url);
          try {
            const audioContext = new (window.AudioContext ||
              window.webkitAudioContext)();
            const response = await fetch(mp3Url);
            const arrayBuffer = await response.arrayBuffer();
            audioContext.decodeAudioData(
              arrayBuffer,
              (buffer) => {
                const source = audioContext.createBufferSource();
                source.buffer = buffer;
                source.connect(audioContext.destination);
                source.onended = () => {
                  setPlaying(false);
                  console.log("Playback finished.");
                };
                source.start(0);
                setAudioContext(audioContext);
                setAudioSource(source);
                setSpeechLoading(false);
                setPlaying(true);
              },
              (e) => {
                console.error("Error decoding audio data:", e);
                setError("Error decoding audio data.");
                setSpeechLoading(false);
              }
            );
          } catch (e) {
            console.error("Playback failed:", e);
            setError("Playback failed.");
            setSpeechLoading(false);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (videoItems[selectedIndex]?.type === "actor") {
      if (videoItems[selectedIndex].script) {
        setInputValue(videoItems[selectedIndex].script);
      } else {
        setInputValue("");
      }
    } else {
      setInputValue("");
    }
  }, [selectedIndex]);

  const limit = 5000;

  return (
    <>
      <textarea
        style={{
          backgroundColor: "white",
          height: "90%",
          width: "98%",
          borderRadius: 8,
          paddingLeft: 45,
          paddingTop: 14,
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          fontFamily: "SSMedium",
          fontSize: 14,
          border: "none",
        }}
        disabled={videoItems[selectedIndex]?.type !== "actor"}
        value={inputValue}
        onChange={handleChange}
        placeholder={
          videoItems[selectedIndex]?.type !== "actor"
            ? "Choose an actor first to write your script"
            : "Write your script..."
        }
        maxLength={limit}
      />
      {speechLoading ? (
        <TailSpin
          height={20}
          width={20}
          color="blue"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            marginTop: 13,
            position: "absolute",
            marginLeft: 12,
            color: "blue",
          }}
          wrapperClass=""
          visible={true}
        />
      ) : playing ? (
        <FaPause
          onClick={() => pauseAudio()}
          style={{
            position: "absolute",
            marginLeft: 16,
            color: "blue",
            cursor: "pointer",
            marginTop: 16.5,
          }}
        />
      ) : (
        <FaPlay
          onClick={() => {
            textToSpeech();
          }}
          style={{
            position: "absolute",
            marginLeft: 16,
            color: "blue",
            cursor: "pointer",
            marginTop: 16.5,
            opacity: videoItems[selectedIndex]?.type !== "actor" ? 0.5 : 1,
          }}
        />
      )}
    </>
  );
};

export default TextBox;
