import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { BsPauseCircleFill, BsPlayCircleFill } from "react-icons/bs";
import Carousel from "./Carousel";
import { ReactComponent as Flowy } from "./modern.svg";
import ReactPlayer from "react-player";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Pricing from "../Pricing/Pricing";
import Questions from "../Dashboard/Questions";

const numOfItems = [1, 2, 3, 4, 5, 6];
const Explainer = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [iconVisible, setIconVisible] = useState(true);
  const videoRef = useRef();
  const history = useHistory();

  const handleMouseEnter = (item) => {
    setCurrentItem(item);
    const video = videoRef.current;
    if (video) {
      video.play();
    }
  };

  const handleMouseLeave = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      video.currentTime = 0; // Rewind the video to the beginning when pausing
      setCurrentItem(null);
    }
  };

  return (
    <>
      <MDBRow
        style={{
          marginTop: 0,
          paddingTop: 0,
          paddingBottom: 100,
        }}
      >
        <MDBCol className="d-flex justify-content-center" size="12">
          <MDBRow
            style={{
              marginTop: 30,
              color: "white",
              width: "100vw",
            }}
          >
            <MDBCol size="12">
              <Carousel direction="left" />
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <p
            style={{
              fontFamily: "SSRegular",
              fontSize: 57,
              marginTop: 80,
              textAlign: "center",
              lineHeight: 1,
              color: "black",
            }}
          >
            We've made it simple to test ad concepts with
            <br />
            real actors. From brief to video in just days. Discover
            <br />
            and scale your winning ads, faster than ever.
          </p>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <div
            style={{
              height: 700,
              width: 1300,
              backgroundColor: "black",
              marginTop: 40,
              borderRadius: 3,
            }}
          ></div>
        </MDBCol>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <MDBCol size="12" className="d-flex justify-content-center">
          <div
            style={{
              height: 50,
              width: 240,
              backgroundColor: "blue",
              color: "white",
              textAlign: "center",
              borderRadius: 7,
              fontFamily: "SSMedium",
              paddingTop: 13,
              marginTop: -40,
              cursor: "pointer",
            }}
            onClick={() => history.push("/auth")}
          >
            <p>Try it free</p>
          </div>
        </MDBCol>
        <MDBCol style={{ marginTop: 60, paddingBottom: 50 }} size="12">
          <Pricing />
        </MDBCol>
        <MDBCol size="12">
          <Questions />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(Explainer);
