import React, { Component, useState, useEffect } from "react";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "./Layout/DashboardNavBar";
import DashboardFooter from "./Layout/DashboardFooter";
import { firebase } from "../../firebase/config";
import "react-phone-number-input/style.css";
import { BiChevronDown, BiLinkAlt } from "react-icons/bi";
// import Heading from "./Heading";
import { FiArrowUpRight, FiRefreshCw, FiChevronRight } from "react-icons/fi";
import { FaChevronRight } from "react-icons/fa";
import Questions from "./Dashboard/Questions";

const FAQ = ({ email, popUpVisible, setPopUpVisible, ref, seconds }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [uploadOptionsVisible, setUploadOptionsVisible] = useState(false);
  const [uploading, setUploading] = useState(true);
  const [youtubeLink, setYoutubeLink] = useState(null);
  const [uploadingArray, setUploadingArray] = useState([]);
  const [historyArray, setHistoryArray] = useState([]);
  const [processingArray, setProcessingArray] = useState([]);
  const [videoVisible, setVideoVisible] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [freeTrialUsed, setFreeTrialUsed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  useEffect(() => {
    if (firebase.auth().currentUser) {
      const uid = firebase.auth().currentUser.uid;

      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .onSnapshot((doc) => {
          setFreeTrialUsed(doc.data().freeTrialUsed);
        });

      firebase
        .firestore()
        .collection("customers")
        .doc(uid)
        .collection("subscriptions")
        .where("status", "==", "active")
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            let initActive = [];
            querySnapshot.forEach((doc) => {
              console.log(doc.data());
              initActive.push(doc.data());
            });
            if (initActive.length > 0) {
              setSubscribed(true);
            }
          } else {
            setSubscribed(false);
          }
        });
      console.log(uid);
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .collection("tasks")
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            let initArray = [];
            let processArray = [];
            let uploadArray = [];
            querySnapshot.forEach((doc) => {
              console.log(doc.data());
              if (doc.data().status === "Complete") {
                initArray.push(doc.data());
              } else if (
                doc.data().status !== "Error" &&
                doc.data().status !== "Complete" &&
                doc.data().status !== "Uploading"
              ) {
                setUploading(false);
                processArray.push(doc.data());
              } else if (doc.data().status === "Uploading") {
                setUploading(true);
                uploadArray.push(doc.data());
              }
            });

            console.log("The init array is,", initArray);
            setHistoryArray(initArray);
            setProcessingArray(processArray);

            setUploadingArray(uploadArray);
          }
        });
    }
  }, [firebase.auth().currentUser]);

  useEffect(() => {
    setPopUpVisible(false);
  }, []);

  useEffect(() => {
    console.log("Uploading array is", uploadingArray);
  }, [uploadingArray]);

  useEffect(() => {
    setYoutubeLink("");
  }, [modalVisible]);

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            background: "white",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 0,
            }}
            size="12"
          >
            <MDBRow
              style={{
                marginTop: 0,
                position: "fixed",
                backgroundColor: "white",
                zIndex: 100,
              }}
            >
              <DashboardNavBar
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
                email={email}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                seconds={seconds}
                ref={ref}
              />
            </MDBRow>
            <MDBCol
              style={{ marginTop: 90 }}
              className="d-flex justify-content-center"
              size="12"
            >
              {firebase.auth().currentUser ? (
                <Questions subscribed={subscribed} seconds={seconds} />
              ) : (
                <Questions setIsOpen={setIsOpen} />
              )}
            </MDBCol>

            {firebase.auth().currentUser ? null : (
              <>
                {/* <Explainer />
                {/* <Process /> */}
                {/* <Steps />
                <Pricing />
                <div style={{ marginTop: 50 }}></div>
                <Questions />  */}
              </>
            )}
          </MDBCol>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(FAQ);
