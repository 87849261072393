import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import {
  FaRegCompass,
  FaRegFolder,
  FaRegStar,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import {
  RiRedPacketLine,
  RiShapesFill,
  RiUploadCloud2Fill,
} from "react-icons/ri";
import { BiSolidVideos } from "react-icons/bi";
import { HiOutlineTemplate } from "react-icons/hi";
import { IoPersonCircleOutline, IoText } from "react-icons/io5";
import { useSignUpModal } from "../Context/SignUpModal";

const LeftNav = () => {
  const { tab, setTab } = useSignUpModal();
  return (
    <div
      style={{
        width: 90,
        backgroundColor: "rgb(22, 23, 26)",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        overflowY: "hidden",
        overflowX: "hidden",
        display: "flex",
        justifyContent: "center",
        paddingRight: 14,
      }}
    >
      <MDBRow
        style={{
          marginTop: 80,
          marginLeft: 0,
          overflowY: "hidden",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor:
              tab === "template" ? "rgba(255, 255, 255, 0.1)" : null,
            height: 63,
            borderRadius: 6,
            width: 63,
            marginBottom: 10,
            cursor: "pointer",
          }}
          onClick={() => setTab("template")}
        >
          <div className="d-flex justify-content-center">
            <HiOutlineTemplate
              style={{
                color: "white",
                fontSize: 24,
                textAlign: "center",
                marginTop: 11,
              }}
            />
          </div>
          <p
            style={{
              fontSize: 11,
              color: "#fff",
              fontFamily: "SSMedium",
              opacity: 1,
              marginTop: 3,
              textAlign: "center",
            }}
          >
            Template
          </p>
        </div>
        <div
          style={{
            backgroundColor:
              tab === "actor" ? "rgba(255, 255, 255, 0.1)" : null,
            height: 63,
            borderRadius: 6,
            width: 63,
            marginBottom: 10,
            cursor: "pointer",
          }}
          onClick={() => setTab("actor")}
        >
          <div className="d-flex justify-content-center">
            <IoPersonCircleOutline
              style={{
                color: "white",
                fontSize: 24,
                textAlign: "center",
                marginTop: 11,
              }}
            />
          </div>
          <p
            style={{
              fontSize: 11,
              color: "#fff",
              fontFamily: "SSMedium",
              opacity: 1,
              marginTop: 3,
              textAlign: "center",
            }}
          >
            Actors
          </p>
        </div>
        <div
          style={{
            backgroundColor: tab === "text" ? "rgba(255, 255, 255, 0.1)" : null,
            height: 63,
            borderRadius: 6,
            width: 63,
            marginBottom: 10,
            cursor: "pointer",
          }}
          onClick={() => setTab("text")}
        >
          <div className="d-flex justify-content-center">
            <IoText
              style={{
                color: "white",
                fontSize: 24,
                textAlign: "center",
                marginTop: 11,
              }}
            />
          </div>
          <p
            style={{
              fontSize: 11,
              color: "#fff",
              fontFamily: "SSMedium",
              opacity: 1,
              marginTop: 3,
              textAlign: "center",
            }}
          >
            Text
          </p>
        </div>
        <div
          style={{
            backgroundColor:
              tab === "element" ? "rgba(255, 255, 255, 0.1)" : null,
            height: 63,
            borderRadius: 6,
            width: 63,
            marginBottom: 10,
            cursor: "pointer",
          }}
          onClick={() => setTab("element")}
        >
          <div className="d-flex justify-content-center">
            <RiShapesFill
              style={{
                color: "white",
                fontSize: 24,
                textAlign: "center",
                marginTop: 11,
              }}
            />
          </div>
          <p
            style={{
              fontSize: 11,
              color: "#fff",
              fontFamily: "SSMedium",
              opacity: 1,
              marginTop: 3,
              textAlign: "center",
            }}
          >
            Element
          </p>
        </div>
        <div
          style={{
            backgroundColor:
              tab === "asset" ? "rgba(255, 255, 255, 0.1)" : null,
            height: 63,
            borderRadius: 6,
            width: 63,
            marginBottom: 10,
            cursor: "pointer",
          }}
          onClick={() => setTab("asset")}
        >
          <div className="d-flex justify-content-center">
            <RiUploadCloud2Fill
              style={{
                color: "white",
                fontSize: 24,
                textAlign: "center",
                marginTop: 11,
              }}
            />
          </div>
          <p
            style={{
              fontSize: 11,
              color: "#fff",
              fontFamily: "SSMedium",
              opacity: 1,
              marginTop: 3,
              textAlign: "center",
            }}
          >
            Asset
          </p>
        </div>
      </MDBRow>
    </div>
  );
};

export default withRouter(LeftNav);
