import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import axios from "axios";
import "react-phone-number-input/style.css";
import MainMenu from "../Layout/MainMenu";
import { FaRegCompass, FaRegStar } from "react-icons/fa";
import { BsBackpack } from "react-icons/bs";
import { IoFilterSharp } from "react-icons/io5";
import SideNav from "../Layout/SideNav";
import TopBar from "../Layout/TopBar";
import Checkout from "./Checkout";
import { TailSpin } from "react-loader-spinner";
import { useAuth } from "../Context/AuthContext";
import { IoIosCheckmarkCircle } from "react-icons/io";

const Settings = () => {
  const categories = [1, 2, 3, 4, 5, 6, 7, 9, 3, 4, 5, 6, 7, 9];
  const [loading, setLoading] = useState(false);
  const { fbConnected, paymentMethodId, customerId, subscriptionStatus } =
    useAuth();
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [billingPortalLoading, setBillingPortalLoading] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);

  const handleFacebookLogin = () => {
    const uid = firebase.auth().currentUser.uid;
    window.location.href = `https://us-central1-rowads-5bf77.cloudfunctions.net/facebookLogin?uid=${uid}`;
  };

  const handleSubscribe = async () => {
    setSubscriptionError(false);
    if (!customerId) {
      alert("Please add billing details before subscribing.");
      return;
    }

    setSubscribeLoading(true);

    try {
      const subscribeResult = await firebase
        .functions()
        .httpsCallable("createSubscription")({
        customerId,
      });

      console.log("Subscription successful:", subscribeResult);
      setSubscribeLoading(false);
      // Update the UI or navigate to a different page as needed
    } catch (error) {
      console.error("Error subscribing:", error);
      setSubscribeLoading(false);
      setSubscriptionError(true);
      alert("Failed to subscribe. Please try again.");
    }

    setSubscribeLoading(false);
  };

  const handleBillingPortal = async () => {
    if (!customerId) {
      alert("No customer ID found.");
      return;
    }

    setBillingPortalLoading(true);

    try {
      const result = await firebase
        .functions()
        .httpsCallable("createBillingPortalSession")({
        customerId,
      });

      window.location.href = result.data.url;
    } catch (error) {
      console.error("Error creating billing portal session:", error);
      alert("Failed to open billing portal. Please try again.");
    }

    setBillingPortalLoading(false);
  };

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#fff",
            height: "100vh",
            overflowY: "scroll",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 0,
            }}
            size="12"
          >
            <MDBRow style={{ marginTop: 0 }}>
              <MainMenu />
            </MDBRow>
          </MDBCol>
          <MDBCol
            className="d-flex justify-content-center"
            style={{ paddingLeft: 0, marginTop: 20 }}
            size="12"
          >
            <MDBRow style={{ paddingTop: 90, width: 1200 }}>
              <MDBCol size="6">
                <div
                  style={{
                    width: 560,
                    height: 200,
                    border: "1px solid rgb(234, 236, 240)",
                    borderRadius: 20,
                    paddingLeft: 25,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "SSMedium",
                      marginTop: 30,
                      fontSize: 19,
                    }}
                  >
                    Connect Ad Account
                  </p>
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      marginTop: -15,
                      fontSize: 15,
                      opacity: 0.7,
                    }}
                  >
                    Connect your ad account to start recieving videos. We use
                    your ad performance data to optimise videos that we create,
                  </p>
                  <div
                    style={{
                      height: 40,
                      width: 470,
                      backgroundColor: "#f8f8f8",
                      borderRadius: 6,
                    }}
                    className="d-flex justify-content-between"
                  >
                    <div>
                      <img
                        src={require("./images.png")}
                        style={{
                          height: 14,
                          marginTop: 13,
                          marginLeft: 15,
                          position: "absolute",
                        }}
                      />
                      <p
                        style={{
                          fontFamily: "SSMedium",
                          display: "inline-block",
                          marginLeft: 50,
                          marginTop: 7.6,
                        }}
                      >
                        Meta
                      </p>
                    </div>
                    {fbConnected ? (
                      <p
                        style={{
                          marginRight: 16,
                          fontFamily: "SSMedium",
                          fontSize: 15,
                          marginTop: 9,
                        }}
                      >
                        <IoIosCheckmarkCircle
                          style={{
                            fontSize: 20,
                            marginRight: 6,
                            marginTop: -1.9,
                          }}
                          color="#32de84"
                        />
                        Connected
                      </p>
                    ) : (
                      <div
                        style={{
                          height: 30,
                          width: 90,
                          boxShadow: "rgba(0, 0, 0, 0.06) 0px 1px 2px",
                          backgroundColor: "white",
                          border: "1px solid #dbdbdb",
                          marginTop: 5,
                          marginRight: 5,
                          borderRadius: 6,
                          textAlign: "center",
                          fontFamily: "SSMedium",
                          paddingTop: 3.5,
                          fontSize: 14,
                          cursor: "pointer",
                        }}
                        onClick={() => handleFacebookLogin()}
                      >
                        Connect
                      </div>
                    )}
                  </div>
                  {fbConnected && (
                    <p
                      style={{
                        fontFamily: "SSRegular",
                        fontSize: 13,
                        marginTop: 7,
                      }}
                    >
                      Want to disconnect?{" "}
                      <p
                        style={{
                          color: "rgb(237, 17, 101)",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        className="d-inline"
                      >
                        {" "}
                        Talk to our support.
                      </p>
                    </p>
                  )}
                </div>
              </MDBCol>
              <MDBCol size="6">
                <div
                  style={{
                    width: 560,
                    height: 530,
                    border: "1px solid rgb(234, 236, 240)",
                    borderRadius: 20,
                    paddingLeft: 25,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "SSMedium",
                      marginTop: 30,
                      fontSize: 19,
                    }}
                  >
                    Billing Details
                  </p>
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      marginTop: -15,
                      fontSize: 15,
                      opacity: 0.7,
                      width: 460,
                    }}
                  >
                    At the end of each month we charge a percentage of ad spend
                    on our videos you use. View your plan to see your
                    percentage.
                  </p>
                  <Checkout />
                </div>
              </MDBCol>
              <MDBCol size="6">
                <div
                  style={{
                    width: 560,
                    height: 300,
                    border: "1px solid rgb(234, 236, 240)",
                    borderRadius: 20,
                    marginTop: -300,
                    paddingLeft: 25,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "SSMedium",
                      marginTop: 30,
                      fontSize: 19,
                    }}
                  >
                    Organic marketing plan - $200/mo
                  </p>
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      marginTop: -5,
                      fontSize: 15,
                      opacity: 0.7,
                      width: 480,
                    }}
                  >
                    Want to use our video ads for organic marketing purposes e.g
                    TikTok and Instagram pages? Subscribe to our Organic
                    Subscription to access the rights to use these videos for
                    organic marketing channels.
                  </p>

                  {subscriptionStatus === "active" ? (
                    <button
                      style={{
                        height: 55,
                        width: 460,
                        backgroundColor: "#32de84",
                        borderRadius: 7,
                        marginTop: 10,
                        textAlign: "center",
                        fontFamily: "SSRegular",
                        color: "white",
                        fontSize: 18,
                        paddingTop: 14,
                        cursor: "pointer",
                        opacity: 1,
                        border: "none",
                      }}
                      type="submit"
                    >
                      <p>Subscribed</p>
                    </button>
                  ) : (
                    <button
                      style={{
                        height: 55,
                        width: 460,
                        backgroundColor: "rgb(237, 17, 101)",
                        borderRadius: 7,
                        marginTop: 10,
                        textAlign: "center",
                        fontFamily: "SSRegular",
                        color: "white",
                        fontSize: 18,
                        paddingTop: 14,
                        cursor: "pointer",
                        opacity: 1,
                        border: "none",
                      }}
                      className="d-flex justify-content-center"
                      onClick={() => handleSubscribe()}
                      type="submit"
                    >
                      {subscribeLoading ? (
                        <TailSpin
                          height="25"
                          width="25"
                          color="#fff"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{ marginTop: 0 }}
                          wrapperClass=""
                          visible={true}
                        />
                      ) : (
                        <>
                          <p>Subscribe to Organic Marketing - $200/mo</p>
                        </>
                      )}
                    </button>
                  )}
                  {subscriptionError ? (
                    <p
                      style={{
                        fontFamily: "SSRegular",
                        marginTop: 15,
                        fontSize: 13,
                        opacity: 0.7,
                        width: 480,
                        fontStyle: "italic",
                        color: "red",
                        cursor: "pointer",
                      }}
                    >
                      There was an error with your card. Try again.
                    </p>
                  ) : subscriptionStatus === "active" ? (
                    <p
                      style={{
                        fontFamily: "SSRegular",
                        marginTop: 15,
                        fontSize: 13,
                        opacity: 0.7,
                        width: 480,
                        fontStyle: "italic",
                        color: "rgb(237, 17, 101)",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => handleBillingPortal()}
                    >
                      Manage your subscription details.
                    </p>
                  ) : (
                    <p
                      style={{
                        fontFamily: "SSRegular",
                        marginTop: 15,
                        fontSize: 13,
                        opacity: 0.7,
                        width: 480,
                        fontStyle: "italic",
                      }}
                    >
                      Make sure to add billing details to subscribe.
                    </p>
                  )}
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </div>
      </div>
    </>
  );
};

export default withRouter(Settings);
