import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { TailSpin } from "react-loader-spinner";
import { FaShippingFast } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { MdOutlinePhonelink } from "react-icons/md";
import { UploadButton } from "@bytescale/upload-widget-react";

const Intro = ({ setSection, taskID, creatorID, editorID }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState("");
  const [title, setTitle] = useState("");
  useEffect(() => {
    if (taskID) {
      firebase
        .firestore()
        .collection("videoTasks")
        .doc(taskID)
        .onSnapshot((doc) => {
          setTitle(doc.data().projectName);
        });
    }
  }, [taskID]);

  const handleUpload = async () => {
    if (videos.length > 0 && (creatorID || editorID) && taskID) {
      setLoading(true);
      try {
        if (creatorID) {
          const creatorUpload = firebase
            .functions()
            .httpsCallable("creatorUpload");
          const result = await creatorUpload({
            creatorID,
            taskID,
            videos,
          });
          // console.log(result);
          if (result?.data?.status === "Success") {
            setSection("Step4Digital");
          }
        } else if (editorID) {
          const editorUpload = firebase
            .functions()
            .httpsCallable("editorUpload");
          const result = await editorUpload({
            taskID,
            videos,
            editorID,
          });
          // console.log(result);
          if (result?.data?.status === "Success") {
            setSection("Step4Digital");
          }
        }
      } catch (err) {
        const errorMessage = err.message.replace(/^FirebaseError:\s*/, "");
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  const options = {
    apiKey: "public_12a1z1r8P8PKF8HbuepJRBRnfdUt", // This is your API key.
    maxFileCount: editorID ? 1 : 5,
    mimeTypes: ["video/mp4"],
    maxFileSizeBytes: 524288000,
  };

  return (
    <MDBCol size="12">
      <div>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
          }}
        >
          {/* Step 0 of 4 */}
        </p>
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 24,
            marginTop: 10,
            marginBottom: 0,
            width: "90%",
          }}
        >
          {editorID
            ? `Upload your edited video for ${title} when it's ready.`
            : `Upload your videos for ${title} when they are ready.`}
        </p>
        <p
          style={{
            fontFamily: "SSRegular",
            marginTop: 10,
            lineHeight: 1.7,
            width: 410,
          }}
        >
          {editorID
            ? `Upload your complete edited video footage below and click submit to
            complete this project.`
            : `Upload your raw, unedited video footage below and click submit to
            complete this project.`}
        </p>
      </div>
      {videos.length > 0 ? (
        <>
          <div
            style={{
              marginTop: 10,
              border: "1px dashed black",
              width: "90%",
              height: 200,
              borderRadius: 10,
            }}
            className="content d-flex justify-content-center"
          >
            <RiCloseLine
              onClick={() => setVideos([])}
              style={{
                color: "red",
                position: "absolute",
                right: 80,
                marginTop: 10,
                fontSize: 30,
                cursor: "pointer",
                zIndex: 100,
              }}
            />

            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                style={{
                  fontFamily: "SSMedium",
                  color: "black",
                  fontSize: 18,
                  marginTop: 86,
                  textAlign: "center",
                  lineHeight: 1.3,
                }}
              >
                You've uploaded {videos.length} files.
              </p>
            </MDBCol>
          </div>
        </>
      ) : (
        <UploadButton
          options={options}
          onComplete={(files) => {
            const fileURLs = files.map((file) => file.fileUrl);
            setVideos(fileURLs);
          }}
        >
          {({ onClick }) => (
            <div
              style={{
                marginTop: 10,
                border: "1px dashed black",
                width: "90%",
                height: 200,
                borderRadius: 10,
              }}
              onClick={onClick}
              className="content d-flex justify-content-center"
            >
              <MDBRow style={{ width: "100%" }}>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "SSMedium",
                      color: "black",
                      fontSize: 18,
                      marginTop: 36,
                      textAlign: "center",
                      lineHeight: 1.3,
                    }}
                  >
                    {t("UploadTitle")}
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      color: "black",
                      fontSize: 16,
                      marginTop: -5,
                      textAlign: "center",
                      lineHeight: 1.3,
                    }}
                  >
                    must be portrait video, must be in mp4 format, 360p-4K
                    resolution, Max 500mb
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "SSRegular",
                      color: "black",
                      fontSize: 16,
                      marginTop: -5,
                      textAlign: "center",
                      lineHeight: 1.3,
                      textDecoration: "underline",
                    }}
                  >
                    {t("BrowseFiles")}
                  </p>
                </MDBCol>
              </MDBRow>
            </div>
          )}
        </UploadButton>
      )}

      <div
        style={{
          height: 55,
          width: 410,
          backgroundColor: "blue",
          borderRadius: 7,
          marginTop: 20,
          textAlign: "center",
          fontFamily: "SSRegular",
          color: "white",
          fontSize: 18,
          paddingTop: 14,
          cursor: "pointer",
          opacity: videos.length > 0 ? 1 : 0.5,
        }}
        onClick={() => {
          if (videos.length > 0) {
            handleUpload();
          }
        }}
        className="d-flex justify-content-center"
      >
        {loading ? (
          <TailSpin
            height="25"
            width="25"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{ marginTop: 0 }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            <p>Continue</p>
          </>
        )}
      </div>
      <p
        style={{
          fontSize: 15,
          color: "red",
          fontFamily: "SSRegular",
          textAlign: "center",
          marginTop: 8,
          width: "90%",
        }}
      >
        {error}
      </p>
    </MDBCol>
  );
};

export default withRouter(Intro);
