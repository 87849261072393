import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import { AiOutlinePlayCircle, AiOutlineRight } from "react-icons/ai";
import "react-circular-progressbar/dist/styles.css";
import { BiRightArrowAlt } from "react-icons/bi";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import { firebase } from "../../../firebase/config";
import { TailSpin } from "react-loader-spinner";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";

const Heading = ({ setIsOpen, requestLoading }) => {
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState(2);
  const [selectedPackage, setSelectedPackage] = useState(2);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const goBack = () => {
    history.goBack();
  };

  if (requestLoading === true) {
    return null;
  }

  const calculatePrice = (selectedPackage) => {
    switch (selectedPackage) {
      case 1:
        return t("priceString1");
      case 2:
        return t("priceString2");
      case 3:
        return t("priceString3");
    }
  };

  const trackCheckoutEvent = (selectedPackage) => {
    let priceValue;

    switch (selectedPackage) {
      case 1:
        priceValue = 10;
        break;
      case 2:
        priceValue = 15;
        break;
      case 3:
        priceValue = 25;
        break;
      default:
        // Handle unexpected package numbers or add a default value if needed
        console.error("Invalid package selected");
        return;
    }

    // Assuming mixpanel is already initialized and available
    mixpanel.track("Started Checkout", { price: priceValue });
  };

  const startPayment = async () => {
    setLoading(true);
    trackCheckoutEvent(selectedPackage);

    // Determine the current language from i18n
    const currentLanguage = i18n.language;

    // Define price mapping based on the language
    const prices = {
      en: [20, 25, 30], // Prices in USD
      ko: [26500, 33000, 39000], // Prices in KRW
      ja: [3000, 3800, 4400], // Prices in JPY
    };

    // Determine the currency based on the language
    const currencies = {
      en: "USD",
      ko: "KRW",
      ja: "JPY",
    };

    // Select price and currency based on the selected package and current language
    let price = prices[currentLanguage][selectedPackage - 1];
    let currency = currencies[currentLanguage];

    const uid = firebase.auth().currentUser.uid;
    const successUrlBase = window.location.origin;
    const successUrlParams = `?price=${price}&currency=${currency}`;
    const successUrl = successUrlBase + successUrlParams;
    const cancelUrl = window.location.origin + "/checkout";

    const docRef = await firebase
      .firestore()
      .collection("customers")
      .doc(uid)
      .collection("checkout_sessions")
      .add({
        mode: "payment",
        price: calculatePrice(selectedPackage), // One-time price created in Stripe
        success_url: successUrl,
        cancel_url: cancelUrl,
        automatic_tax: true,
        allow_promotion_codes: true,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  const renderSection = (section) => {
    switch (section) {
      case 1:
        return (
          <Section1
            setSection={setSection}
            selectedPackage={selectedPackage}
            setSelectedPackage={setSelectedPackage}
          />
        );
      case 2:
        return (
          <Section2
            selectedPackage={selectedPackage}
            setSelectedPackage={setSelectedPackage}
          />
        );
      case 3:
        return <Section3 />;
    }
  };

  return (
    <MDBRow
      style={{
        color: "black",
        paddingTop: 80,
        paddingLeft: 10,
        width: 840,
      }}
    >
      <MDBCol style={{ cursor: "pointer" }} size="12">
        <NavLink
          to="/"
          exact
          style={{
            textDecoration: "underline",
            display: "inline-block",
            fontFamily: "MADemiBold",
            color: "black",
          }}
        >
          {t("HomeNav")}
        </NavLink>
        <AiOutlineRight
          style={{ fontSize: 10, marginLeft: 6, marginRight: 6 }}
          className="d-inline"
        />
        <p
          onClick={() => goBack()}
          style={{
            textDecoration: "underline",
            display: "inline-block",
            fontFamily: "MADemiBold",
            color: "black",
          }}
        >
          {t("CheckoutNav")}
        </p>
      </MDBCol>
      {renderSection(section)}
    </MDBRow>
  );
};

export default withRouter(Heading);
