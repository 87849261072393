import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import { BsPlay, BsPause } from "react-icons/bs";
import {
  FaRegCompass,
  FaRegFolder,
  FaRegStar,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { RiRedPacketLine } from "react-icons/ri";
import { BiSolidVideos } from "react-icons/bi";
import SearchSection from "./SearchSection";

const ElementSection = ({
  initImages,
  initVideos,
  music,
  initIcons,
  addItem,
  lastTime,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchSection, setSearchSection] = useState(false);
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [shapes, setShapes] = useState([
    "https://firebasestorage.googleapis.com/v0/b/ugcai-app.appspot.com/o/shapes%2Farrow-line.svg?alt=media&token=568512ad-5d82-4824-96b7-e1799fb6ec7d",
    "https://firebasestorage.googleapis.com/v0/b/ugcai-app.appspot.com/o/shapes%2Fcircle-stroke.svg?alt=media&token=d845aab5-3448-4028-b7ab-296a486feeae",
    "https://firebasestorage.googleapis.com/v0/b/ugcai-app.appspot.com/o/shapes%2Fcircle.svg?alt=media&token=0828a216-16a0-43cc-b943-8626580b1cbd",
    "https://firebasestorage.googleapis.com/v0/b/ugcai-app.appspot.com/o/shapes%2Fline.svg?alt=media&token=d03593b1-3b69-4871-ad33-69e6350c6649",
    "https://firebasestorage.googleapis.com/v0/b/ugcai-app.appspot.com/o/shapes%2Frect-stroke.svg?alt=media&token=cf2e3174-3567-4edc-a53b-5622e4348bad",
    "https://firebasestorage.googleapis.com/v0/b/ugcai-app.appspot.com/o/shapes%2Frectange.svg?alt=media&token=55ac3040-0b28-4152-b48e-901ff51ef657",
    "https://firebasestorage.googleapis.com/v0/b/ugcai-app.appspot.com/o/shapes%2Fsquare-stroke.svg?alt=media&token=ee294c2e-163f-4706-a512-be65fea97455",
    "https://firebasestorage.googleapis.com/v0/b/ugcai-app.appspot.com/o/shapes%2Fsquare.svg?alt=media&token=e2ec2648-92c7-4be3-93c7-d343f079e5f5",
    "https://firebasestorage.googleapis.com/v0/b/ugcai-app.appspot.com/o/shapes%2Ftriangle.svg?alt=media&token=42d574bb-6387-48a2-8515-9ec4ffcd3aab",
  ]);

  const audioRef = useRef(null);

  const titles = ["Icon", "Image", "Video"];

  const renderInit = (title) => {
    switch (title) {
      case "Image":
        return (
          initImages.photos &&
          initImages.photos.slice(0, 5).map((image, index) => (
            <div
              key={index}
              style={{
                width: "7vw",
                height: "7vw",
                borderRadius: 3,
                cursor: "pointer",
                backgroundColor: "#1f2124",
                marginRight: 15,
                flexShrink: 0,
              }}
              onClick={() => {
                addItem({
                  // Replace with the URL of the second video
                  videoRef: React.createRef(),
                  startTime: 0,
                  initialStartTime: 0,
                  endTime: lastTime,
                  initialEndTime: lastTime,
                  trimStart: 0,
                  trimEnd: lastTime,
                  x: "50%",
                  y: "50%",
                  width: "100%",
                  height: "100%",
                  layerIndex: 2,
                  type: "image",
                  thumbnail: image.src.tiny,
                  color: "black",
                });
              }}
              className="d-flex justify-content-center"
            >
              <img
                style={{
                  width: "80%",
                  height: "80%",
                  marginTop: "10%",
                }}
                src={image.src.tiny}
              />
            </div>
          ))
        );
      case "Video":
        return (
          initVideos.videos &&
          initVideos.videos.slice(0, 5).map((video, index) => {
            return (
              <div
                key={index}
                style={{
                  width: "7vw",
                  height: "7vw",
                  borderRadius: 3,
                  cursor: "pointer",
                  backgroundColor: "#1f2124",
                  marginRight: 15,
                  flexShrink: 0,
                }}
                className="d-flex justify-content-center"
                onClick={() => {
                  addItem({
                    // Replace with the URL of the second video
                    videoRef: React.createRef(),
                    videoURL: video.video_files[0].link,
                    startTime: 0,
                    initialStartTime: 0,
                    endTime: lastTime,
                    initialEndTime: lastTime,
                    trimStart: 0,
                    trimEnd: lastTime,
                    x: "50%",
                    y: "50%",
                    width: "100%",
                    height: "100%",
                    layerIndex: 2,
                    type: "video",
                    thumbnail: video.image,
                    color: "black",
                  });
                }}
              >
                <img
                  style={{
                    width: "80%",
                    height: "80%",
                    marginTop: "10%",
                  }}
                  src={video.image}
                />
              </div>
            );
          })
        );
      case "Icon":
        return (
          initIcons &&
          initIcons.map((icon, index) => (
            <div
              key={index}
              style={{
                width: "7vw",
                height: "7vw",
                borderRadius: 3,
                cursor: "pointer",
                backgroundColor: "#1f2124",
                marginRight: 15,
                flexShrink: 0,
                color: "#949bff",
              }}
              onClick={() => {
                addItem({
                  // Replace with the URL of the second video
                  videoRef: React.createRef(),
                  startTime: 0,
                  initialStartTime: 0,
                  endTime: lastTime,
                  initialEndTime: lastTime,
                  trimStart: 0,
                  trimEnd: lastTime,
                  x: "50%",
                  y: "50%",
                  width: "100%",
                  height: "100%",
                  layerIndex: 2,
                  type: "icon",
                  thumbnail: icon.preview,
                  color: "black",
                });
              }}
              className="d-flex justify-content-center"
            >
              <div
                style={{
                  width: "80%",
                  height: "80%",
                  marginTop: "10%",
                  color: "red",
                  backgroundImage: `url(${icon.preview})`,
                  backgroundSize: "cover",
                  filter:
                    "invert(67%) sepia(68%) saturate(5352%) hue-rotate(211deg) brightness(101%) contrast(102%)",
                }}
              ></div>
            </div>
          ))
        );
      case "Shape":
        return (
          shapes &&
          shapes.slice(0, 5).map((shape, index) => (
            <div
              key={index}
              style={{
                width: "7vw",
                height: "7vw",
                borderRadius: 3,
                cursor: "pointer",
                backgroundColor: "#1f2124",
                marginRight: 15,
                flexShrink: 0,
                color: "#949bff",
              }}
              className="d-flex justify-content-center"
            >
              <div
                style={{
                  width: "80%",
                  height: "80%",
                  marginTop: "10%",
                  color: "red",
                  backgroundImage: `url(${shape})`,
                  backgroundSize: "cover",
                  filter:
                    "invert(67%) sepia(68%) saturate(5352%) hue-rotate(211deg) brightness(101%) contrast(102%)",
                }}
              ></div>
            </div>
          ))
        );
    }
  };

  const handlePlayMusic = (song) => {
    if (currentSong && currentSong.Name === song.Name) {
      // If the clicked song is already playing, toggle between play and pause
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } else {
      // If a new song is clicked, stop the current song and play the new one
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setCurrentSong(song);
      setIsPlaying(true);
      audioRef.current = new Audio(song.URL);
      audioRef.current.play();
    }
  };

  return searchSection ? (
    <SearchSection
      initImages={initImages}
      initVideos={initVideos}
      selectedCategory={selectedCategory}
      setSearchSection={setSearchSection}
      initIcons={initIcons}
      addItem={addItem}
      music={music}
      shapes={shapes}
    />
  ) : (
    <MDBRow style={{ marginTop: 75, marginLeft: 0, overflowY: "hidden" }}>
      <MDBCol size="12">
        <p
          style={{
            fontSize: 20,
            color: "#fff",
            fontFamily: "PPBold",
            opacity: 0.9,
          }}
        >
          Add Elements
        </p>
      </MDBCol>
      <MDBCol size="12">
        <MDBRow
          style={{ height: "84vh", overflowY: "scroll", paddingBottom: 40 }}
        >
          {titles.map((category) => (
            <React.Fragment key={category}>
              <MDBCol
                className="d-flex justify-content-between"
                style={{ marginTop: 20 }}
                size="12"
              >
                <p
                  style={{
                    fontSize: 18,
                    color: "#fff",
                    fontFamily: "PPBold",
                    opacity: 0.9,
                  }}
                >
                  {category}
                </p>
                <p
                  style={{
                    fontSize: 15,
                    color: "#fff",
                    fontFamily: "PPMedium",
                    opacity: 0.9,
                    marginRight: 20,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedCategory(category);
                    setSearchSection(true);
                  }}
                >
                  See All
                </p>
              </MDBCol>
              <MDBCol
                style={{
                  display: "flex",
                  overflowX: "auto",
                  color: "white",
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                  width: "100%",
                  marginTop: -9,
                  WebkitOverflowScrolling: "touch",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
                size="12"
              >
                <div style={{ display: "flex" }}>{renderInit(category)}</div>
              </MDBCol>
            </React.Fragment>
          ))}

          <MDBCol
            className="d-flex justify-content-between"
            style={{ marginTop: 20 }}
            size="12"
          >
            <p
              style={{
                fontSize: 18,
                color: "#fff",
                fontFamily: "PPBold",
                opacity: 0.9,
              }}
            >
              Music
            </p>
            <p
              style={{
                fontSize: 15,
                color: "#fff",
                fontFamily: "PPMedium",
                opacity: 0.9,
                marginRight: 20,
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedCategory("Music");
                setSearchSection(true);
              }}
            >
              See All
            </p>
          </MDBCol>
          <MDBCol
            style={{
              color: "white",
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              width: "100%",
              marginTop: -9,
              height: "10vh",
              WebkitOverflowScrolling: "touch",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            size="12"
          >
            <div>
              {music.slice(0, 9).map((song, index) => (
                <div
                  key={index}
                  style={{
                    width: "98%",
                    height: "4vw",
                    borderRadius: 3,
                    cursor: "pointer",
                    backgroundColor: "#1f2124",
                    marginRight: 15,
                    flexShrink: 0,
                    marginTop: 10,
                    display: "flex",
                  }}
                  onClick={() => {
                    addItem({
                      // Replace with the URL of the second video
                      videoRef: React.createRef(),
                      startTime: 0,
                      initialStartTime: 0,
                      endTime: lastTime,
                      initialEndTime: lastTime,
                      trimStart: 0,
                      trimEnd: lastTime,
                      x: 0,
                      y: 0,
                      width: "100%",
                      height: "100%",
                      layerIndex: 2,
                      videoURL: song.URL,
                      name: song.Name,
                      type: "music",
                      color: "black",
                      volume: 0.5,
                    });
                  }}
                >
                  {currentSong &&
                  currentSong.Name === song.Name &&
                  isPlaying ? (
                    <BsPause
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePlayMusic(song);
                      }}
                      style={{
                        marginTop: "2.4vh",
                        marginLeft: 23,
                        fontSize: 33,
                      }}
                    />
                  ) : (
                    <BsPlay
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePlayMusic(song);
                      }}
                      style={{
                        marginTop: "2.4vh",
                        marginLeft: 23,
                        fontSize: 33,
                      }}
                    />
                  )}

                  <p
                    style={{
                      fontFamily: "PPMedium",
                      fontSize: 17,
                      marginTop: 19,
                      marginLeft: 17,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "200px", // Adjust the maximum width as needed
                    }}
                  >
                    {song.Name.length > 20
                      ? `${song.Name.slice(0, 20)}...`
                      : song.Name}
                    <p
                      style={{
                        fontFamily: "PPMedium",
                        fontSize: 12,
                        marginLeft: 0,
                      }}
                    >
                      {song.Duration}
                    </p>
                  </p>
                </div>
              ))}
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(ElementSection);
