import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { BiRightArrowAlt } from "react-icons/bi";
import { RiLogoutCircleLine, RiMenu5Line } from "react-icons/ri";
import { firebase } from "../../../firebase/config";
import { useSignUpModal } from "../Context/SignUpModal";
import { useAuth } from "../Context/AuthContext";
import PopupMobile from "./PopupMobile";
import SignupMobile from "./SignupMobile";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";

const DashboardNavBarMobile = () => {
  const { isOpen, setIsOpen } = useSignUpModal();
  const [loggedIn, setLoggedIn] = useState(false);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const history = useHistory(); // Add this line
  const { paid, offerTimeLeft, signUpTime, credits } = useAuth();

  const { t } = useTranslation();

  useEffect(() => {
    if (!firebase.auth().currentUser) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [firebase.auth().currentUser]);
  const textRepetitions = Array.from({ length: 100 }, (_, i) => i + 1);

  return (
    <>
      <SignupMobile isOpen={isOpen} setIsOpen={setIsOpen} />
      <PopupMobile
        popUpVisible={popUpVisible}
        setPopUpVisible={setPopUpVisible}
      />

      <MDBRow
        className="d-flex justify-content-center"
        style={{
          width: "100vw",
          position: "fixed",
          backgroundColor: "transparent",
          zIndex: 970,
          height: 77,
          border: "1px solid transparent",
          marginTop: 20,
        }}
      >
        <MDBCol
          style={{
            border: "1px solid black",
            height: 77,
            position: "fixed",
            zIndex: 900,
            backgroundColor: "white",
            borderRadius: 200,
            width: "95vw",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center", // Centers items vertically
            left: "50%", // Center horizontally
            transform: "translateX(-50%)", // Adjust position to the left by
          }}
          size="12"
        >
          {/* Logo NavLink */}
          <NavLink
            to="/"
            exact
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            <img
              src={require("./logos.png")}
              alt="Logo"
              style={{
                height: "7vw",
                marginRight: 6,
              }}
            />
          </NavLink>

          {/* Conditional content based on authentication */}
          {firebase.auth().currentUser ? (
            // Elements to be horizontally aligned
            <>
              <NavLink to="/checkout" exact>
                <div
                  style={{
                    height: "8.8vw",
                    backgroundColor: "transparent",
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 0,
                  }}
                >
                  <p
                    style={{
                      color: "black",
                      fontFamily: "MABold",
                      fontSize: "3.2vw",
                      cursor: "pointer",
                      textAlign: "center",
                      margin: 0,
                      marginTop: 4, // Ensure no additional margin
                    }}
                  >
                    {credits} {t("creditsLeft")}
                  </p>
                </div>
              </NavLink>
              <NavLink to="/checkout" exact>
                <div
                  style={{
                    height: "9vw",
                    backgroundColor: "rgb(242, 241, 243)",
                    borderRadius: 4,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "20vw", // Consistent width
                    marginRight: "-3vw",
                    border: "1px solid black",
                  }}
                  onClick={() => mixpanel.track("Clicked on Add Credits")}
                >
                  <p
                    style={{
                      color: "black",
                      fontFamily: "MABold",
                      fontSize: "3.2vw",
                      cursor: "pointer",
                      textAlign: "center",
                      margin: 0, // Ensure no additional margin
                    }}
                  >
                    Buy credits
                  </p>
                </div>
              </NavLink>

              <div
                style={{
                  height: "8.8vw",
                  width: "8.8vw",
                  border: "2px solid white",
                  borderRadius: "50%",
                  backgroundColor: "rgb(242, 241, 243)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid black",
                }}
                onClick={() => setPopUpVisible(true)}
              >
                <RiMenu5Line style={{ fontSize: "3.8vw" }} />
              </div>
            </>
          ) : (
            <div
              style={{
                height: 54,
                paddingLeft: "4vw",
                paddingRight: "4vw",
                border: "1px solid black",
                borderRadius: 100,
                backgroundColor: "rgb(242, 241, 243)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setIsOpen(true)}
            >
              <p
                style={{
                  fontFamily: "MABold",
                  fontSize: "3.7vw",
                  color: "black",
                  cursor: "pointer",
                  margin: 0,
                }}
              >
                {t("signUpNav")}
              </p>
            </div>
          )}
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(DashboardNavBarMobile);
