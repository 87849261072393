import React, { useEffect, useRef, useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import "./index.css";
import { BsFillPlayFill } from "react-icons/bs";
import { BsFillVolumeUpFill } from "react-icons/bs";
import { AiOutlineFullscreen } from "react-icons/ai";
import { BsPauseFill } from "react-icons/bs";
import { BsFillVolumeMuteFill } from "react-icons/bs";
import tiktok1 from "./tiktok1.mp4";
import tiktok2 from "./tiktok2.mp4";
import tiktok3 from "./tiktok3.mp4";
import tiktok4 from "./tiktok4.mp4";
import tiktok5 from "./tiktok5.mp4";
import tiktok6 from "./tiktok6.mp4";
import Gary from "./Gary.jpg";
import Neil from "./Neil.jpg";
import Deep from "./Deep.jpeg";
import Ezra from "./Ezra.jpg";
import Kara from "./Kara.jpg";
import Chris from "./Chris.jpg";

const tiktoks = [
  tiktok1,
  tiktok2,
  tiktok3,
  tiktok1,
  tiktok2,
  tiktok3,
  tiktok1,
  tiktok2,
  tiktok3,
  tiktok1,
  tiktok2,
  tiktok3,
  tiktok1,
  tiktok2,
  tiktok3,
  tiktok1,
  tiktok2,
  tiktok3,
];

const quotes2 = [
  {
    content:
      "TikTok lets anyone, not just major brands, go viral overnight. All you need is creativity and some understanding of the platform's culture.",
    author: "Ezra Cooperstein",
    company: "Agency",
    photo: Ezra,
  },
  {
    content:
      "Authenticity is key on TikTok. Brands need to loosen up, have fun and not be afraid to show some personality to win over TikTok.",
    author: "Kara Hartnett",
    company: "TikTok",
    photo: Kara,
  },
  {
    content:
      "TikTok is reshaping pop culture and youth trends. Brands that dismiss it do so at their own peril. It's the future of marketing.",
    author: "Chris Cunningham",
    company: "Goat",
    photo: Chris,
  },
  {
    content:
      "TikTok lets anyone, not just major brands, go viral overnight. All you need is creativity and some understanding of the platform's culture.",
    author: "Ezra Cooperstein",
    company: "Agency",
    photo: Ezra,
  },
  {
    content:
      "Authenticity is key on TikTok. Brands need to loosen up, have fun and not be afraid to show some personality to win over TikTok.",
    author: "Kara Hartnett",
    company: "TikTok",
    photo: Kara,
  },
  {
    content:
      "TikTok is reshaping pop culture and youth trends. Brands that dismiss it do so at their own peril. It's the future of marketing.",
    author: "Chris Cunningham",
    company: "Goat",
    photo: Chris,
  },
  {
    content:
      "TikTok lets anyone, not just major brands, go viral overnight. All you need is creativity and some understanding of the platform's culture.",
    author: "Ezra Cooperstein",
    company: "Agency",
    photo: Ezra,
  },
  {
    content:
      "Authenticity is key on TikTok. Brands need to loosen up, have fun and not be afraid to show some personality to win over TikTok.",
    author: "Kara Hartnett",
    company: "TikTok",
    photo: Kara,
  },
  {
    content:
      "TikTok is reshaping pop culture and youth trends. Brands that dismiss it do so at their own peril. It's the future of marketing.",
    author: "Chris Cunningham",
    company: "Goat",
    photo: Chris,
  },
];

const quotes = [
  {
    content:
      "TikTok is the most exciting thing in social media right now. It's where brands need to be if they want to reach younger audiences.",
    author: "Gary Vaynerchuk",
    company: "Vayner",
    photo: Gary,
  },
  {
    content:
      "TikTok has completely changed the social media marketing landscape. Brands that aren't on TikTok are missing out.",
    author: "Neil Patel",
    company: "NPDigital",
    photo: Neil,
  },
  {
    content:
      "The organic reach on TikTok is unlike anything else out there. If your content resonates, it can spread like wildfire. It's just crazy.",
    author: "Deep Patel",
    company: "Forbes",
    photo: Deep,
  },
  {
    content:
      "TikTok is the most exciting thing in social media right now. It's where brands need to be if they want to reach younger audiences.",
    author: "Gary Vaynerchuk",
    company: "Vayner",
    photo: Gary,
  },
  {
    content:
      "TikTok has completely changed the social media marketing landscape. Brands that aren't on TikTok are missing out.",
    author: "Neil Patel",
    company: "NPDigital",
    photo: Neil,
  },
  {
    content:
      "The organic reach on TikTok is unlike anything else out there. If your content resonates, it can spread like wildfire. It's just crazy.",
    author: "Deep Patel",
    company: "Forbes",
    photo: Deep,
  },
  {
    content:
      "TikTok is the most exciting thing in social media right now. It's where brands need to be if they want to reach younger audiences.",
    author: "Gary Vaynerchuk",
    company: "Vayner",
    photo: Gary,
  },
  {
    content:
      "TikTok has completely changed the social media marketing landscape. Brands that aren't on TikTok are missing out.",
    author: "Neil Patel",
    company: "NPDigital",
    photo: Neil,
  },
  {
    content:
      "The organic reach on TikTok is unlike anything else out there. If your content resonates, it can spread like wildfire. It's just crazy.",
    author: "Deep Patel",
    company: "Forbes",
    photo: Deep,
  },
];

const tiktoks2 = [
  tiktok4,
  tiktok5,
  tiktok6,
  tiktok4,
  tiktok5,
  tiktok6,
  tiktok4,
  tiktok5,
  tiktok6,
  tiktok4,
  tiktok5,
  tiktok6,
  tiktok4,
  tiktok5,
  tiktok6,
  tiktok4,
  tiktok5,
  tiktok6,
];

const Carousel = ({ direction }) => {
  const carouselRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false); // New state variable
  const [videoStates, setVideoStates] = useState({}); // New state to hold play/pause states for each video
  const [muteStates, setMuteStates] = useState({}); // New state to hold mute states for each video
  const videoRefs = {}; // Object to hold refs for each video

  const togglePlayPause = (index) => {
    const video = videoRefs[index].current;
    if (videoStates[index]) {
      video.pause();
    } else {
      video.play();
    }
    setVideoStates({
      ...videoStates,
      [index]: !videoStates[index],
    });
  };

  const toggleMute = (index) => {
    const video = videoRefs[index].current;
    video.muted = !muteStates[index];
    setMuteStates({
      ...muteStates,
      [index]: !muteStates[index],
    });
  };

  const toggleFullscreen = (index) => {
    const video = videoRefs[index].current;
    if (video.requestFullscreen) {
      video.requestFullscreen();
    }
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    let requestID;

    const step = () => {
      if (!isHovered) {
        // Check if the carousel is hovered
        if (direction === "left") {
          carousel.scrollLeft -= 1;
          if (carousel.scrollLeft <= 0) {
            carousel.scrollLeft = carousel.scrollWidth - carousel.clientWidth;
          }
        } else {
          carousel.scrollLeft += 2;
          if (
            carousel.scrollLeft >=
            carousel.scrollWidth - carousel.clientWidth
          ) {
            carousel.scrollLeft = 0;
          }
        }
      }

      requestID = requestAnimationFrame(step);
    };

    requestID = requestAnimationFrame(step);

    return () => cancelAnimationFrame(requestID);
  }, [direction, isHovered]);

  const colors = ["rgb(233, 192, 233)", "rgb(120, 0, 22)", "rgb(210, 232, 35)"];
  const items = ["rgb(233, 192, 233)", "rgb(120, 0, 22)", "rgb(210, 232, 35)"]; // Duplicate items to fill the screen

  const backgroundSelect = (num) => {
    switch (num) {
      case 1:
        return "#2f1878";
        break;
      case 7:
        return "#2f1878";
        break;
      case 5:
        return "#2f1878";
        break;
      case 3:
        return "#2f1878";
        break;
      case 9:
        return "#2f1878";
        break;
    }
  };

  const textSelect = (num) => {
    switch (num) {
      case 1:
        return "#780016";
        break;
      case 7:
        return "#780016";
        break;
      case 5:
        return "#d2e823";
        break;
      case 3:
        return "#e9c0e9";
        break;
      case 9:
        return "#e9c0e9";
        break;
    }
  };

  return (
    <div
      className="d-flex justify-content-center"
      ref={carouselRef}
      style={{ marginBottom: 30 }}
    >
      <div>
        {items.map((color, index) => {
          videoRefs[index] = useRef(null); // Initialize ref for each video
          const videoURL =
            direction !== "right" ? tiktoks[index] : tiktoks2[index];
          const content =
            direction !== "right" ? quotes[index] : quotes2[index];

          // Loop over the tiktoks array
          // const videoURL = tiktoks[0]; // Loop over the tiktoks array

          return (
            <div
              index={index}
              style={{
                position: "relative",
                display: "inline-block",
                height: 450,
                width: 270,
                marginLeft: 9,
                marginRight: 9,
                transform:
                  index === 0
                    ? "rotate(-2deg)"
                    : index === 2
                    ? "rotate(2deg)"
                    : "rotate(0deg) translateY(-10px)", // Adjust the degree as needed
              }}
            >
              <video
                controls={false}
                ref={videoRefs[index]}
                src={videoURL}
                style={{
                  display: "inline-block",
                  height: 450,
                  width: 270,
                  marginLeft: 0,
                  borderRadius: 17,
                  marginRight: 9,
                  objectFit: "fill",
                  border: "4px solid blue",
                  verticalAlign: "top", // Added this line
                }}
              />
              <div
                style={{
                  position: "absolute",
                  height: 38,
                  width: 73,
                  backgroundColor: "black",
                  zIndex: 1,
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  borderRadius: 4,
                  cursor: "pointer",
                }}
                onClick={() => togglePlayPause(index)}
                className="d-flex justify-content-center"
              >
                {!videoStates[index] ? (
                  <BsFillPlayFill style={{ marginTop: 3, fontSize: 33 }} />
                ) : (
                  <BsPauseFill style={{ marginTop: 3, fontSize: 33 }} />
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  height: 35,
                  width: 77,
                  backgroundColor: "black",
                  zIndex: 1,
                  bottom: -9,
                  right: -30,
                  transform: "translate(-50%, -50%)",
                  borderRadius: 4,
                  cursor: "pointer",
                }}
                className="d-flex justify-content-between"
              >
                {muteStates[index] ? (
                  <BsFillVolumeMuteFill
                    onClick={() => toggleMute(index)} // Pass index to the click handler
                    style={{ marginTop: 6, fontSize: 23, marginLeft: 12 }}
                  />
                ) : (
                  <BsFillVolumeUpFill
                    onClick={() => toggleMute(index)} // Pass index to the click handler
                    style={{ marginTop: 6, fontSize: 23, marginLeft: 12 }}
                  />
                )}
                <AiOutlineFullscreen
                  onClick={() => toggleFullscreen(index)}
                  style={{ marginTop: 6, fontSize: 24, marginRight: 9 }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;
