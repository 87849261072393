import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import { AiOutlinePlayCircle, AiOutlineRight } from "react-icons/ai";
import { useLoading } from "../Context/LoadingContext";
import { BsPlayCircle } from "react-icons/bs";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { BiRightArrowAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";

const Section1Mobile = ({ setIsOpen, requestLoading, setSection }) => {
  const [name, setName] = useState("");
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const goBack = () => {
    history.goBack();
  };

  if (requestLoading === true) {
    return null;
  }

  useEffect(() => {
    setVideos(numberOfVideos);
  }, []);

  const numberOfVideos = [1, 2, 3, 4];

  const items = [
    "5xChVgwo4YItgPwPlGjfMYNu7CQXnOkkL32OsL00jsI8",
    "rtaVlsCije02kQUT1e1I9W1zXyoKann702zqLLrA01VA8E",
    "NIzvKklM1ljzdl6xD01FTHGg2NTThmHvY4r4hen15PtM",
    "Bh8EJ9WeLqZJKL4oQvLH7eUr4SWquu24ngGtLD7evB4",
  ];

  const percentage = 66;

  return (
    <>
      <MDBCol className="d-flex justify-content-center" size="12">
        <p
          style={{
            display: "inline-block",
            fontFamily: "MADemiBold",
            fontSize: "3.7vw",
            color: "#f8284e",
            marginTop: 20,
          }}
        >
          {t("checkoutTopText")}
        </p>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <p
          style={{
            display: "inline-block",
            fontFamily: "MAHeavy",
            fontSize: "6.4vw",
            marginTop: -17,
            textAlign: "center",
            lineHeight: 1.3,
            color: "black",
          }}
        >
          {t("checkoutTitle")}
        </p>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <p
          style={{
            display: "inline-block",
            fontSize: "3.5vw",
            textAlign: "center",
            marginTop: -14,
            fontFamily: "MADemiBold",
            color: "black",
          }}
        >
          {t("checkoutSubtitle")}
        </p>
      </MDBCol>
      <MDBCol
        className="d-flex justify-content-center"
        style={{ cursor: "pointer", marginTop: 10, marginLeft: -10 }}
        size="12"
      >
        <MDBRow style={{ width: "100vw" }}>
          {items.map((item, index) => {
            return (
              <MDBCol size="3">
                <div
                  key={index}
                  style={{
                    position: "relative",
                    display: "inline-block",
                    height: "88vw",
                    width: "50vw",
                    marginRight: 6,
                    borderRadius: 5,
                    overflow: "hidden",
                    marginBottom: 16,
                  }}
                >
                  <img
                    src={`https://image.mux.com/${item}/animated.gif`}
                    style={{
                      display: "inline-block",
                      height: "88vw",
                      width: "50vw",
                      borderRadius: 10,
                      objectFit: "cover",
                      verticalAlign: "top",
                      backgroundColor: "transparent", // Added this line
                      filter: "blur(2px)",
                    }}
                  />
                </div>
              </MDBCol>
            );
          })}
        </MDBRow>
      </MDBCol>
      <MDBCol
        style={{ marginTop: 0 }}
        className="d-flex justify-content-center"
        size="12"
      >
        <div>
          <div
            style={{
              height: "2.6vw",
              width: "2.6vw",
              backgroundColor: "#f8284e",
              borderRadius: 200,
              display: "inline-block",
              marginLeft: 10,
              opacity: 1,
            }}
          ></div>
          <div
            style={{
              height: "2.6vw",
              width: "2.6vw",
              backgroundColor: "#f8284e",
              borderRadius: 200,
              display: "inline-block",
              marginLeft: 10,
              opacity: 0.4,
            }}
          ></div>
          <div
            style={{
              height: "2.6vw",
              width: "2.6vw",
              backgroundColor: "#f8284e",
              borderRadius: 200,
              display: "inline-block",
              marginLeft: 10,
              opacity: 0.4,
            }}
          ></div>
        </div>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <div
          onClick={() => {
            setSection(2);
          }}
          style={{
            height: 54,
            width: "75vw",
            border: "2px solid #f8284e",
            borderRadius: 8,
            marginTop: 19,
            backgroundColor: "#f8284e",
            cursor: 1,
          }}
          className="d-flex justify-content-center"
        >
          {loading ? (
            <TailSpin
              height={30}
              width={30}
              color="black"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{ marginTop: 10 }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            <p
              style={{
                fontFamily: "MABold",
                fontSize: 16,
                display: "inline-block",
                color: "white",
                marginLeft: 0,
                marginTop: 13,
                zIndex: 300,
              }}
            >
              {t("checkoutContinue")}
              <BiRightArrowAlt
                style={{
                  marginTop: -3.1,
                  fontSize: 18,
                  color: "#dfc8e7",
                  marginLeft: 9,
                }}
                className="d-inline"
              />
            </p>
          )}
        </div>
      </MDBCol>
    </>
  );
};

export default withRouter(Section1Mobile);
