import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { BsFillLightningChargeFill } from "react-icons/bs";
import CarouselReaction from "./CarouselReactions";
import {
  FaBolt,
  FaCheck,
  FaChevronLeft,
  FaChevronRight,
  FaRegCheckCircle,
} from "react-icons/fa";
import { AiOutlineClockCircle } from "react-icons/ai";
import ReactStars from "react-stars";
import Questions from "./Questions";
import CarouselReactionMobile from "./CarouselReactionsMobile";
import QuestionsMobile from "./QuestionsMobile";
import { useTranslation } from "react-i18next";
import MuxPlayer from "@mux/mux-player-react";

const ExplainerMobile = ({}) => {
  const carouselRef = useRef(null);
  const { t } = useTranslation();

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 250, behavior: "smooth" }); // Adjust the value as needed
    }
  };

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -250, behavior: "smooth" }); // Adjust the value as needed
    }
  };

  const reviewData = [
    {
      name: "Mitch",
      date: t("review1Date"),
      occasion: t("review1occasion"),
      review: t("review1content"),
      stars: 5,
    },
    {
      name: "Anonymous",
      date: t("review2Date"),
      occasion: t("review2occasion"),
      review: t("review2content"),
      stars: 5,
    },
    {
      name: "Kristen",
      date: t("review3Date"),
      occasion: t("review3occasion"),
      review: t("review3content"),
      stars: 5,
    },
    // Add more objects as needed
  ];

  function trimToTwoLines(text) {
    const maxChars = maxCharsPerLine * 2; // Two lines worth of characters
    if (text.length <= maxChars) {
      return text;
    }
    return text.substring(0, maxChars) + "...";
  }

  return (
    <>
      <MDBRow
        style={{
          marginTop: -29,
          paddingTop: 0,
          paddingBottom: 100,
          paddingLeft: 0,
        }}
      >
        <MDBCol
          style={{ paddingLeft: 0 }}
          className="d-flex justify-content-center"
          size="12"
        >
          <MDBRow
            style={{
              marginTop: 0,
              color: "white",
              width: "100vw",
              overflowX: "hidden",
              paddingLeft: 0,
            }}
          >
            <MDBCol
              style={{ marginTop: 44, paddingRight: "5vw", width: "100vw" }}
              className="d-flex justify-content-between align-items-center" // Added align-items-center for vertical alignment
              size="12"
            >
              <p
                style={{
                  fontFamily: "MABold",
                  fontSize: "4.4vw",
                  marginLeft: "3vw",
                  color: "black",
                  // Removed position: absolute and other positioning styles
                }}
              >
                {t("exampleTitle")}
              </p>
              <div
                style={{
                  width: "20vw",
                  display: "flex",
                  alignItems: "center",
                  padding: 0,
                  marginTop: -16,
                }}
              >
                <div
                  style={{
                    height: "8.2vw",
                    width: "8.2vw",
                    backgroundColor: "white",
                    borderRadius: "50%", // For a perfect circle
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 10,
                    border: "1px solid black",
                    display: "inline-block",
                  }}
                  onClick={() => scrollLeft()}
                  className="hover-border d-flex justify-content-center"
                >
                  <FaChevronLeft
                    style={{
                      fontSize: "3vw",
                      opacity: 0.8,
                      color: "black",
                    }}
                  />
                </div>
                <div
                  style={{
                    height: "8.2vw",
                    width: "8.2vw",
                    borderRadius: "50%", // For a perfect circle
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                    border: "1px solid black",
                    display: "inline-block",
                  }}
                  onClick={() => scrollRight()}
                  className="hover-border d-flex justify-content-center"
                >
                  <FaChevronRight
                    style={{
                      fontSize: "3vw",
                      opacity: 0.8,
                      color: "black",
                    }}
                  />
                </div>
              </div>
            </MDBCol>

            <MDBCol style={{ marginTop: 8 }} size="12">
              <CarouselReactionMobile
                scrollLeft={scrollLeft}
                scrollRight={scrollRight}
                carouselRef={carouselRef}
              />
            </MDBCol>
            <MDBRow
              style={{
                overflowX: "scroll",
                backgroundColor: "#fac921",
                marginTop: -10,
                borderTop: "2px solid black",
                borderBottom: "2px solid black",
                width: "120vw",
                marginLeft: "4vw",
                borderLeft: "2px solid black",
                borderRadius: 9,
                color: "#000",
              }}
            >
              <MDBCol style={{ marginLeft: 10 }} size="12">
                <p
                  style={{
                    marginTop: 18,
                    fontFamily: "MAHeavy",
                    fontSize: "4.4vw",
                  }}
                >
                  {t("howText")}
                </p>
                <p
                  style={{
                    marginTop: -16,
                    fontFamily: "MABold",
                    fontSize: "3.3vw",
                    opacity: 0.8,
                  }}
                >
                  {t("howSubtext")}
                </p>
              </MDBCol>
              <MDBCol
                style={{
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  marginTop: 5,
                  marginLeft: 10,
                }}
              >
                {/* Container for the numbered elements and their descriptions */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      border: "1px dashed black",
                      width: "149vw",
                      height: 1,
                      zIndex: 1,
                      top: 25,
                      marginLeft: 10,
                      opacity: 0.6,
                    }}
                  />
                  {/* Each pair of number and description is wrapped in its own div */}
                  <div style={{ marginRight: "10vw", zIndex: 10 }}>
                    {" "}
                    {/* Add marginRight for spacing */}
                    {/* Number */}
                    <div
                      style={{
                        height: "12vw",
                        width: "12vw",
                        border: "1px solid rgb(63, 59, 69)",
                        borderRadius: "50%", // Shorter syntax for fully rounded corners
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10,
                        backgroundColor: "#000", //#endregion // You might need to adjust this for the first circle
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "MABold",
                          fontSize: "5vw",
                          marginTop: 20,
                          color: "white",
                        }}
                      >
                        1
                      </p>
                    </div>
                    {/* Description */}
                    <div style={{ width: "40vw", marginTop: 7, height: 100 }}>
                      <p style={{ fontFamily: "MAHeavy", fontSize: "4vw" }}>
                        {t("step1")}
                      </p>
                      <p
                        style={{
                          fontFamily: "MABold",
                          fontSize: "3vw",
                          opacity: 0.7,
                          marginTop: -13,
                          width: "44vw",
                          whiteSpace: "normal", // Allow wrapping
                          wordWrap: "break-word", // Break
                        }}
                      >
                        {t("step1Subtext")}
                      </p>
                    </div>
                  </div>

                  <div style={{ marginRight: "10vw", zIndex: 10 }}>
                    {" "}
                    {/* Add marginRight for spacing */}
                    {/* Number */}
                    <div
                      style={{
                        height: "12vw",
                        width: "12vw",
                        border: "1px solid rgb(63, 59, 69)",
                        borderRadius: "50%", // Shorter syntax for fully rounded corners
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10,
                        backgroundColor: "#000",
                        color: "white", //#endregion // You might need to adjust this for the first circle
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "MABold",
                          marginTop: 20,
                          fontSize: "5vw",
                        }}
                      >
                        2
                      </p>
                    </div>
                    {/* Description */}
                    <div style={{ width: "40vw", marginTop: 7, height: 100 }}>
                      <p style={{ fontFamily: "MAHeavy", fontSize: "4vw" }}>
                        {t("step2")}
                      </p>
                      <p
                        style={{
                          fontFamily: "MABold",
                          fontSize: "3vw",
                          opacity: 0.7,
                          marginTop: -13,
                          width: "40vw",
                          whiteSpace: "normal", // Allow wrapping
                          wordWrap: "break-word", // Break
                        }}
                      >
                        {t("step2Subtext")}
                      </p>
                    </div>
                  </div>
                  <div style={{ marginRight: "10vw", zIndex: 10 }}>
                    {" "}
                    {/* Add marginRight for spacing */}
                    {/* Number */}
                    <div
                      style={{
                        height: "12vw",
                        width: "12vw",
                        border: "1px solid rgb(63, 59, 69)",
                        borderRadius: "50%", // Shorter syntax for fully rounded corners
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#000",
                        color: "white", //#endregion // You might need to adjust this for the first circle
                        //#endregion // You might need to adjust this for the first circle
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "MABold",
                          marginTop: 20,
                          fontSize: "5vw",
                        }}
                      >
                        3
                      </p>
                    </div>
                    {/* Description */}
                    <div style={{ width: "40vw", marginTop: 7, height: 100 }}>
                      <p style={{ fontFamily: "MAHeavy", fontSize: "4vw" }}>
                        {t("step3")}
                      </p>
                      <p
                        style={{
                          fontFamily: "MABold",
                          fontSize: "3vw",
                          opacity: 0.7,
                          marginTop: -13,
                          width: "40vw",
                          whiteSpace: "normal", // Allow wrapping
                          wordWrap: "break-word", // Break
                        }}
                      >
                        {t("step3Subtext")}
                      </p>
                    </div>
                  </div>
                  <div style={{ marginRight: "10vw", zIndex: 10 }}>
                    {" "}
                    {/* Add marginRight for spacing */}
                    {/* Number */}
                    <div
                      style={{
                        height: "12vw",
                        width: "12vw",
                        border: "1px solid rgb(63, 59, 69)",
                        borderRadius: "50%", // Shorter syntax for fully rounded corners
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#000",
                        color: "white", //#endregion // You might need to adjust this for the first circle
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "MABold",
                          marginTop: 20,
                          fontSize: "5vw",
                        }}
                      >
                        4
                      </p>
                    </div>
                    {/* Description */}
                    <div
                      style={{ maxWidth: "40vw", marginTop: 7, height: 100 }}
                    >
                      <p style={{ fontFamily: "MAHeavy", fontSize: "4vw" }}>
                        {t("step4")}
                      </p>
                      <p
                        style={{
                          fontFamily: "MABold",
                          fontSize: "3vw",
                          opacity: 0.7,
                          marginTop: -13,
                          width: "40vw",
                          whiteSpace: "normal", // Allow wrapping
                          wordWrap: "break-word", // Break
                        }}
                      >
                        {t("step4Subtext")}
                      </p>
                    </div>
                  </div>

                  {/* Repeat the structure for elements 2, 3, and 4... */}
                  {/* Make sure to replace the content of the <p> tags accordingly */}
                  {/* ... */}
                </div>
              </MDBCol>
            </MDBRow>

            <MDBCol className="d-flex justify-content-between" size="12">
              <p
                style={{
                  marginTop: 38,
                  fontFamily: "MABold",
                  fontSize: "4.4vw",
                  marginLeft: 7,
                  color: "#000",
                }}
              >
                What fans are saying
              </p>
            </MDBCol>
            <MDBCol
              style={{
                overflowX: "scroll",
                width: "100%",
                display: "flex",
                flexWrap: "nowrap",
                overflowY: "hidden",
                marginLeft: 7,
                color: "#502274",
              }}
            >
              <div style={{ display: "flex" }}>
                {reviewData.map((review, index) => (
                  <div
                    key={index}
                    style={{
                      paddingBottom: "0vw",
                      width: "60vw",
                      borderRadius: 9,
                      display: "inline-block",
                      marginRight: 9,
                      border: "2px solid #2c44a5",
                      backgroundColor: "#2c44a5",
                      color: "white",
                    }}
                  >
                    <MDBRow style={{ paddingLeft: "7vw", marginTop: 20 }}>
                      <MDBCol className="d-flex align-items-center" size="12">
                        <p
                          style={{
                            fontFamily: "CSMedium",
                            margin: 0,
                            display: "inline-block",
                          }}
                        >
                          {review.name}
                        </p>
                        <div
                          style={{
                            lineHeight: "1",
                            display: "inline-block",
                            marginLeft: 9,
                          }}
                        >
                          <ReactStars
                            color1={"#fff"}
                            color2={"#fff"}
                            count={5}
                            value={review.stars}
                            edit={false}
                            size={9}
                            className="d-inline"
                          />
                        </div>
                      </MDBCol>

                      <MDBCol size="12">
                        <p
                          style={{
                            fontSize: 13,
                            fontFamily: "CSRegular",
                            opacity: 0.8,
                            marginTop: 0,
                          }}
                        >
                          {review.date} • {review.occasion}
                        </p>
                      </MDBCol>
                      <MDBCol size="12">
                        <p
                          style={{
                            fontSize: 13,
                            fontFamily: "CSRegular",
                            opacity: 0.8,
                            marginTop: "-2vw",
                          }}
                        >
                          {review.review}
                        </p>
                      </MDBCol>
                    </MDBRow>
                    <MDBCol
                      style={{ paddingBottom: "5vw" }}
                      className="d-flex justify-content-start"
                      size="12"
                    >
                      <p
                        style={{
                          fontFamily: "CSMedium",
                          fontSize: "3vw",
                          opacity: 0.7,
                          marginTop: 0,
                          marginLeft: 15,
                        }}
                      >
                        <FaRegCheckCircle
                          style={{
                            marginRight: 6,
                            marginTop: -2.57,
                            fontSize: 13,
                          }}
                        />
                        {t("verifiedText")}
                      </p>
                    </MDBCol>
                  </div>
                ))}
              </div>
            </MDBCol>

            <MDBCol className="d-flex justify-content-between" size="12">
              <p
                style={{
                  marginTop: 28,
                  fontFamily: "MABold",
                  fontSize: "4.4vw",
                  marginLeft: 7,
                  color: "#502274",
                }}
              >
                {t("FAQTitle")}
              </p>
            </MDBCol>
            <MDBCol size="12">
              <QuestionsMobile />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(ExplainerMobile);
