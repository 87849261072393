import React, { Component, useEffect, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { firebase } from "../../firebase/config";
import ScrollToTop from "./ScrollToTop";
import { withRouter } from "react-router-dom";
import Landing from "../Parts/Landing/Landing";
import LandingMobile from "../Parts/Landing/LandingMobile";
import Request from "../Parts/Request/Request";
import RequestMobile from "../Parts/Request/RequestMobile";
import MyAssets from "../Parts/MyAssets/MyAssets";
import MyProjects from "../Parts/MyProjects/MyProjects";
import MyVideos from "../Parts/MyVideos/MyVideos";
import Checkout from "../Parts/Checkout/Checkout";
import CheckoutMobile from "../Parts/Checkout/CheckoutMobile";
import Project from "../Parts/Project/Project";
import "./index.css";
import { useLocation } from "react-router-dom";
import Privacy from "../Parts/Privacy/Privacy";
import Terms from "../Parts/Terms/Terms";
import Success from "../Parts/Success/Success";
import SuccessMobile from "../Parts/Success/SuccessMobile";
import { useAuth } from "../Parts/Context/AuthContext";
import Videos from "../Parts/Videos/Videos";
import VideosMobile from "../Parts/Videos/VideosMobile";
import Video from "../Parts/Video/Video";
import VideoMobile from "../Parts/Video/VideoMobile";
import Explore from "../Parts/Explore/Explore";
import ExploreMobile from "../Parts/Explore/ExploreMobile";
import CreateClone from "../Parts/CreateClone/CreateClone";
import CreateCloneMobile from "../Parts/CreateClone/CreateCloneMobile";
import Clones from "../Parts/Clones/Clones";
import ClonesMobile from "../Parts/Clones/ClonesMobile";
import Packs from "../Parts/Packs/Packs";
import { useTranslation } from "react-i18next";
import { Analytics } from "@vercel/analytics/react";
import PricingPage from "../Parts/PricingPage/PricingPage";
import About from "../Parts/About/About";
import FAQ from "../Parts/FAQ";
import axios from "axios";
import Dashboard from "../Parts/Dashboard/Dashboard";
import WorkSpace from "../Parts/WorkSpace/WorkSpace";
import MyClones from "../Parts/MyClones/MyClones";
import SignUp from "../Parts/SignUp/SignUp";
import Upgrade from "../Parts/Upgrade/Upgrade";
import Settings from "../Parts/Settings/Settings";
import CreatorOnboarding from "../Parts/CreatorOnboarding/CreatorOnboarding";
import CreatorOnboardingMobile from "../Parts/CreatorOnboarding/CreatorOnboardingMobile";
import CreatorSummary from "../Parts/CreatorSummary/CreatorSummary";
import CreatorSummaryMobile from "../Parts/CreatorSummary/CreatorSummaryMobile";
import CreatorUploadMobile from "../Parts/CreatorUpload/CreatorUploadMobile";
import CreatorUpload from "../Parts/CreatorUpload/CreatorUpload";

const BasePage = () => {
  const [isDesktop, setIsDesktop] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const { loggedIn, loading } = useAuth();
  const { i18n } = useTranslation();

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/") // Replace with your preferred geolocation API
      .then((response) => {
        const countryCode = response.data.country_code;
        const languageMap = {
          US: "en", // United States
          JP: "ja", // Japan
          KR: "ko", // South Korea
        };
        const languageCode = languageMap[countryCode] || "en"; // Default to English if country not in map
        i18n.changeLanguage(languageCode);
      })
      .catch((error) => {
        console.error("Error fetching location:", error);
        i18n.changeLanguage("en"); // Fallback to English in case of error
      });
  }, [i18n]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("profiles")
      .get()
      .then((querySnapshot) => {
        let empty = [];
        querySnapshot.forEach((doc) => {
          empty.push(doc.data());
        });

        // Improved filter function
        const newEmpty = empty.filter((profile) => {
          // Check if Category is defined and is a string
          return profile.kpop === true && profile.private === false;
        });

        console.log("New Empty is", newEmpty);
        // console.log("New empty is", newEmpty);

        setProfiles(newEmpty);
        // console.log(empty);
      });
  }, []);

  useEffect(() => {
    screenSizeDetector();
    window.addEventListener("resize", screenSizeDetector);
  }, []);

  // useEffect(() => {
  //   firebase.auth().signOut();
  // }, []);

  const screenSizeDetector = () => {
    if (window.innerWidth > 900 && window.innerWidth < 1000) {
      setIsDesktop(true);
    } else if (window.innerWidth <= 900) {
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
    }
  };

  if (loading === true || loggedIn === null) {
    return null;
  }

  return (
    <>
      <ScrollToTop />
      <Analytics />

      <Switch>
        {!firebase.auth().currentUser ? (
          <Route
            exact
            path="/"
            render={() => (isDesktop ? <SignUp /> : <SignUp />)}
          />
        ) : (
          <Route
            exact
            path="/"
            render={() =>
              isDesktop ? (
                <Dashboard profiles={profiles} />
              ) : (
                <Dashboard profiles={profiles} />
              )
            }
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/checkout"
            render={() => (isDesktop ? <Checkout /> : <CheckoutMobile />)}
          />
        )}

        <Route
          exact
          path="/creator-onboarding"
          render={() =>
            isDesktop ? <CreatorOnboarding /> : <CreatorOnboardingMobile />
          }
        />

        <Route
          exact
          path="/editor-onboarding"
          render={() =>
            isDesktop ? <CreatorOnboarding /> : <CreatorOnboardingMobile />
          }
        />

        <Route
          exact
          path="/creator-summary"
          render={() =>
            isDesktop ? <CreatorSummary /> : <CreatorSummaryMobile />
          }
        />

        <Route
          exact
          path="/editor-summary"
          render={() =>
            isDesktop ? <CreatorSummary /> : <CreatorSummaryMobile />
          }
        />

        <Route
          exact
          path="/creator-upload"
          render={() =>
            isDesktop ? <CreatorUpload /> : <CreatorUploadMobile />
          }
        />

        <Route
          exact
          path="/editor-upload"
          render={() =>
            isDesktop ? <CreatorUpload /> : <CreatorUploadMobile />
          }
        />

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/workspace"
            render={() => (isDesktop ? <WorkSpace /> : <WorkSpace />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/packs"
            render={() => (isDesktop ? <Packs /> : <Packs />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/my-projects"
            render={() => (isDesktop ? <MyProjects /> : <MyProjects />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/settings"
            render={() => (isDesktop ? <Settings /> : <Settings />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/my-videos"
            render={() => (isDesktop ? <MyVideos /> : <MyVideos />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/my-assets"
            render={() => (isDesktop ? <MyAssets /> : <MyAssets />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/my-clones"
            render={() => (isDesktop ? <MyClones /> : <MyClones />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/my-projects"
            render={() => (isDesktop ? <Packs /> : <Packs />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/success"
            render={() => (isDesktop ? <Success /> : <SuccessMobile />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/checkout"
            render={() => (isDesktop ? <Checkout /> : <CheckoutMobile />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/project"
            render={() => (isDesktop ? <Project /> : <Project />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/upgrade"
            render={() => (isDesktop ? <Upgrade /> : <Upgrade />)}
          />
        )}

        <Route
          exact
          path="/pricing"
          render={() =>
            isDesktop ? (
              <PricingPage
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
              />
            ) : (
              <PricingPage
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
              />
            )
          }
        />

        <Route
          exact
          path="/about"
          render={() =>
            isDesktop ? (
              <About
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
              />
            ) : (
              <About
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
              />
            )
          }
        />

        <Route
          exact
          path="/request"
          render={() => (isDesktop ? <Request /> : <RequestMobile />)}
        />
        {firebase.auth().currentUser && (
          <Route
            exact
            path="/create-clone"
            render={() => (isDesktop ? <CreateClone /> : <CreateCloneMobile />)}
          />
        )}

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/clones"
            render={() => (isDesktop ? <Clones /> : <ClonesMobile />)}
          />
        )}

        <Route
          exact
          path="/video"
          render={() => (isDesktop ? <Video /> : <VideoMobile />)}
        />

        <Route
          exact
          path="/explore"
          render={() =>
            isDesktop ? (
              <Explore profiles={profiles} />
            ) : (
              <ExploreMobile profiles={profiles} />
            )
          }
        />

        {firebase.auth().currentUser && (
          <Route
            exact
            path="/videos"
            render={() => (isDesktop ? <Videos /> : <VideosMobile />)}
          />
        )}

        <Route
          exact
          path="/faq"
          render={() =>
            isDesktop ? (
              <FAQ
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
              />
            ) : (
              <FAQ
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
              />
            )
          }
        />

        <Route
          exact
          path="/privacy-policy"
          render={() => (isDesktop ? <Privacy /> : <Privacy />)}
        />

        <Route
          exact
          path="/terms-of-service"
          render={() => (isDesktop ? <Terms /> : <Terms />)}
        />

        <Redirect to="/" />
      </Switch>
    </>
  );
};

export default withRouter(BasePage);
