import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import { FaRegCompass, FaRegStar } from "react-icons/fa";

const Categories = () => {
  const categories = [
    "Pre-Order",
    "Testimonial",
    "What I Ordered",
    "Myth",
    "Weight Loss",
    "Nutrition",
    "Su Metabolism",
    "Detox",
    "Hormones",
    "Nutrition",
    "Su Metabolism",
    "Detox",
    "Hormones",
    "Hormones",
    "Nutrition",
    "Su Metabolism",
    "Detox",
    "Hormones",
  ];
  return (
    <MDBRow
      style={{
        width: "82vw",
        height: "70vh",
        paddingLeft: 30,
        overflowY: "scroll",
      }}
    >
      {categories.map((item) => {
        return (
          <MDBCol size="3">
            <NavLink to="/project" exact>
              <div
                style={{
                  height: 400,
                  width: "18vw",
                  backgroundColor: "white",
                  marginBottom: 30,
                  borderRadius: 9,
                  boxShadow:
                    "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                }}
              ></div>
            </NavLink>
          </MDBCol>
        );
      })}
    </MDBRow>
  );
};

export default withRouter(Categories);
