import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import { AiOutlinePlayCircle, AiOutlineRight } from "react-icons/ai";
import algoliasearch from "algoliasearch/lite";
import { firebase } from "../../../firebase/config";
import { useSignUpModal } from "../Context/SignUpModal";

import {
  InstantSearch,
  SearchBox,
  Hits,
  Configure,
  useInstantSearch,
} from "react-instantsearch-hooks-web";
import { InfiniteHits, useInfiniteHits } from "react-instantsearch-hooks-web";
import { FaLock, FaSearch } from "react-icons/fa";
import { BsMagic } from "react-icons/bs";
import { useAuth } from "../Context/AuthContext";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";

const searchClient = algoliasearch(
  "S4X3XXA1UI",
  "8c00ede015c71c08b4d36db0b6ab2b70"
);

function getFirstName(fullName) {
  // Split the full name by space and return the first element of the array
  return fullName.split(" ")[0];
}
const HitInit = ({ hit }) => {
  const [imageLoaded, setImageLoaded] = useState({});
  const { paid } = useAuth();
  const { setIsOpen } = useSignUpModal();

  const handleImageLoaded = (name) => {
    setImageLoaded((prev) => ({ ...prev, [name]: true }));
  };
  const placeholderStyle = {
    height: 100,
    width: 100,
    borderRadius: 100,
    backgroundColor: "grey",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <MDBCol
      key={hit.name}
      className="d-flex justify-content-center"
      style={{ marginTop: 20 }}
      size="4"
    >
      {hit.free || firebase.auth().currentUser ? (
        <NavLink
          onClick={() => {
            if (hit.free || paid) {
              mixpanel.track("Clicked on a profile on explore", {
                profile: hit.name,
              });
            } else {
              mixpanel.track(
                "Redirected to paywall after clicking profile on explore",
                {
                  profile: hit.name,
                }
              );
            }
          }}
          exact
          to={hit.free || paid ? `/request?id=${hit.profileID}` : "/explore"}
        >
          {!imageLoaded[hit.name] && <div style={placeholderStyle}></div>}

          <img
            src={hit.image}
            onLoad={() => handleImageLoaded(hit.name)}
            style={{
              height: "26vw",
              width: "26vw",
              borderRadius: 100,
              objectFit: "cover",
              display: imageLoaded[hit.name] ? "block" : "none",
              border: "2px solid black",
              opacity: hit.free === true || paid ? 1 : 0.3,
              filter: hit.free === true || paid ? null : "blur(2px)",
            }}
          />

          {hit.free !== true && !paid && (
            <FaLock
              style={{
                position: "absolute",
                top: "33%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: 30,
                color: "black",
              }}
            />
          )}
          <p
            style={{
              textAlign: "center",
              marginTop: 10,
              fontFamily: "MABold",
              fontSize: "3.9vw",
              color: "black",
            }}
          >
            {getFirstName(hit.name)}
          </p>
        </NavLink>
      ) : (
        <div style={{ cursor: "pointer" }} onClick={() => setIsOpen(true)}>
          {!imageLoaded[hit.name] && <div style={placeholderStyle}></div>}

          <img
            src={hit.image}
            onLoad={() => handleImageLoaded(hit.name)}
            style={{
              height: "26vw",
              width: "26vw",
              borderRadius: 100,
              objectFit: "cover",
              display: imageLoaded[hit.name] ? "block" : "none",
              border: "2px solid black",
              opacity: hit.free === true || paid ? 1 : 0.3,
              filter: hit.free === true || paid ? null : "blur(2px)",
            }}
          />

          {hit.free !== true && !paid && (
            <FaLock
              style={{
                position: "absolute",
                top: "33%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: 30,
                color: "black",
              }}
            />
          )}
          <p
            style={{
              textAlign: "center",
              marginTop: 10,
              fontFamily: "MABold",
              fontSize: "3.9vw",
              color: "black",
            }}
          >
            {getFirstName(hit.name)}
          </p>
        </div>
      )}
    </MDBCol>
  );
};

const CustomInfiniteHits = ({ selectedCategories }) => {
  const { hits, hasMore, refineNext } = useInfiniteHits();

  console.log("Hits are", hits);

  const kpopHits = hits.filter((hit) => hit.kpop === true);

  const filteredHits = kpopHits.filter(
    (hit) =>
      selectedCategories.length === 0 ||
      hit.Category.some((cat) => selectedCategories.includes(cat))
  );

  const sortedProfiles = filteredHits.sort((a, b) =>
    b.free === a.free ? 0 : a.free ? -1 : 1
  );

  return (
    <MDBRow style={{ width: "90vw", marginTop: 10 }}>
      {sortedProfiles.map((hit) => (
        <HitInit key={hit.objectID} hit={hit} />
      ))}
      {sortedProfiles.length === 0 && (
        <MDBCol className="d-flex justify-content-center" size="12">
          <p style={{ fontFamily: "CSMedium", marginTop: 70 }}></p>
        </MDBCol>
      )}
      {hasMore && <button onClick={refineNext}>Load More</button>}
    </MDBRow>
  );
};

const CategoryItem = ({ name, value, onClick, isSelected }) => (
  <div
    onClick={() => onClick(value)}
    style={{
      height: 30,
      width: "30vw",
      backgroundColor: !isSelected ? "white" : "#27292d",
      borderRadius: 100,
      display: "inline-block",
      marginLeft: 3,
      textAlign: "center",
      cursor: "pointer", // Add cursor pointer for better UX
      border: "1px solid black",
    }}
  >
    <p
      style={{
        color: isSelected ? "white" : "black",
        fontFamily: "MABold",
        marginTop: 6,
        fontSize: 14,
      }}
    >
      {name}
    </p>
  </div>
);

const CategoryRow = ({ startIndex, endIndex, onClick, selectedCategories }) => {
  const { t } = useTranslation();
  const categories = [
    {
      label: "NBA",
      value: "NBA",
    },
    {
      label: "Football",
      value: "Football",
    },
    {
      label: "NFL",
      value: "NFL",
    },
    {
      label: "Comedians",
      value: "Comedians",
    },
    {
      label: "Movies",
      value: "Movies",
    },
    {
      label: "Pop Stars",
      value: "Pop Stars",
    },
    {
      label: "Actors",
      value: "Actors",
    },
  ];

  return (
    <MDBCol
      style={{ marginTop: startIndex === 0 ? 18 : 9, marginLeft: 0 }}
      size="12"
    >
      <div className="d-flex justify-content-center">
        {categories.slice(startIndex, endIndex).map((category) => (
          <CategoryItem
            key={category.value}
            value={category.value}
            name={category.label}
            onClick={onClick}
            isSelected={selectedCategories.includes(category.value)}
          />
        ))}
      </div>
    </MDBCol>
  );
};

const Hit = withRouter(HitInit);

const HeadingMobile = ({ requestLoading, profiles }) => {
  const history = useHistory();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { setIsOpen } = useSignUpModal();
  const { t } = useTranslation();
  const { paid } = useAuth();

  const goBack = () => {
    history.goBack();
  };

  if (requestLoading === true) {
    return null;
  }

  const toggleCategory = (category) => {
    setSelectedCategories((prev) => {
      if (prev.includes(category)) {
        return prev.filter((cat) => cat !== category); // Remove category
      } else {
        return [...prev, category]; // Add category
      }
    });
  };

  return (
    <MDBRow
      style={{
        color: "white",
        paddingTop: 140,
        width: "100vw",
        paddingLeft: 10,
      }}
    >
      <MDBCol style={{ cursor: "pointer" }} size="12">
        <NavLink
          to="/"
          exact
          style={{
            textDecoration: "underline",
            display: "inline-block",
            fontFamily: "MADemiBold",
            color: "black",
            fontSize: "3.8vw",
          }}
        >
          {t("HomeNav")}
        </NavLink>
        <AiOutlineRight
          style={{
            fontSize: "2vw",
            marginLeft: 6,
            marginRight: 6,
            color: "black",
          }}
          className="d-inline"
        />
        <p
          onClick={() => goBack()}
          style={{
            textDecoration: "underline",
            display: "inline-block",
            fontSize: "3.8vw",
            fontFamily: "MADemiBold",
            color: "black",
          }}
        >
          {t("ExploreNav")}
        </p>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        {firebase.auth().currentUser ? (
          <NavLink
            to="/create-clone"
            onClick={() =>
              mixpanel.track("Clicked on create clone on the explore page")
            }
            exact
            className="d-flex justify-content-center"
            style={{
              height: 30,
              width: "90vw",
              border: "1px solid black",
              borderRadius: 200,
              backgroundColor: "white",
              fontFamily: "CSSemiBold",
              paddingTop: 6,
              color: "black",
              fontSize: "3.5vw",
              marginTop: 20,
              cursor: "pointer",
            }}
          >
            <p style={{ textDecoration: "underline" }} className="d-inline">
              {" "}
              <BsMagic
                style={{ marginRight: 9, marginTop: -3 }}
                className="d-inline"
              />
              {t("CustomCloneButton")}
            </p>
          </NavLink>
        ) : (
          <div
            onClick={() => setIsOpen(true)}
            className="d-flex justify-content-center"
            style={{
              height: 30,
              width: "90vw",
              border: "1px solid black",
              borderRadius: 200,
              backgroundColor: "white",
              fontFamily: "CSSemiBold",
              paddingTop: 6,
              color: "black",
              fontSize: "3.5vw",
              marginTop: 20,
              cursor: "pointer",
            }}
          >
            <p style={{ textDecoration: "underline" }} className="d-inline">
              {" "}
              <BsMagic
                style={{ marginRight: 9, marginTop: -3 }}
                className="d-inline"
              />
              {t("CustomCloneButton")}
            </p>
          </div>
        )}
      </MDBCol>
      <MDBCol
        className="d-flex justify-content-center"
        style={{ cursor: "pointer" }}
        size="12"
      >
        <p
          style={{
            fontSize: "6vw",
            marginTop: 14,
            fontFamily: "MAHeavy",
            color: "black",
            textAlign: "center",
          }}
        >
          {t("ExploreTitle")}
        </p>
      </MDBCol>
      <MDBCol
        className="d-flex justify-content-center"
        style={{ cursor: "pointer" }}
        size="12"
      >
        <p
          style={{
            fontFamily: "MABold",
            fontSize: "3.6vw",
            marginTop: -13,
            textAlign: "center",
            color: "black",
            lineHeight: 1.2,
          }}
        >
          {t("ExploreSubheading")}
        </p>
      </MDBCol>

      <MDBCol
        style={{ marginTop: 5 }}
        className="d-flex justify-content-center"
        size="12"
      >
        <MDBRow size="12">
          <InstantSearch searchClient={searchClient} indexName="profiles">
            <FaSearch
              style={{
                position: "absolute",
                color: "black",
                zIndex: 30,
                marginTop: 10,
                opacity: 0.6,
                left: "10vw",
              }}
            />
            <Configure hitsPerPage={300} filters="NOT private:true" />
            <MDBCol className="d-flex justify-content-center" size="12">
              <SearchBox placeholder={t("SearchbarText")} />
            </MDBCol>
            <MDBCol className="d-flex justify-content-center" size="12">
              <MDBRow>
                <CategoryRow
                  startIndex={0}
                  endIndex={3}
                  onClick={toggleCategory}
                  selectedCategories={selectedCategories}
                />
                <CategoryRow
                  startIndex={3}
                  endIndex={6}
                  onClick={toggleCategory}
                  selectedCategories={selectedCategories}
                />
                <CategoryRow
                  startIndex={6}
                  endIndex={9}
                  onClick={toggleCategory}
                  selectedCategories={selectedCategories}
                />
              </MDBRow>
            </MDBCol>
            <MDBCol className="d-flex justify-content-center" size="12">
              <CustomInfiniteHits selectedCategories={selectedCategories} />
            </MDBCol>
          </InstantSearch>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(HeadingMobile);
