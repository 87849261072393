import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import { FaRegCompass, FaRegStar } from "react-icons/fa";

const Categories = () => {
  const categories = [
    "Pre-Order",
    "Testimonial",
    "What I Ordered",
    "Myth",
    "Weight Loss",
    "Nutrition",
    "Su Metabolism",
    "Detox",
    "Hormones",
    "Nutrition",
    "Su Metabolism",
    "Detox",
    "Hormones",
    "Hormones",
    "Nutrition",
    "Su Metabolism",
    "Detox",
    "Hormones",
  ];
  return (
    <div
      style={{
        width: "82vw",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px 20px",
        backgroundColor: "transparent",
        borderRadius: "5px",
        overflowX: "scroll",
        scrollbarWidth: "none", // Hide scrollbar for Firefox
        msOverflowStyle: "none", // Hide scrollbar for IE and Edge
        "&::-webkit-scrollbar": {
          display: "none",
          marginTop: 100,
          marginLeft: 70,
        },
      }}
    >
      {categories.map((category, index) => (
        <div
          key={index}
          style={{
            flex: "1",
            textAlign: "center",
            height: 40,
            cursor: "pointer",
            backgroundColor: "white",
            marginRight: 10,
            marginLeft: 10,
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 100,
            boxShadow:
              "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          }}
          className="align-items-center"
        >
          <p
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              whiteSpace: "nowrap",
              marginTop: 8,
              fontFamily: "PPMedium",
            }}
          >
            {category}
          </p>
        </div>
      ))}
    </div>
  );
};

export default withRouter(Categories);
