import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { BiRightArrowAlt } from "react-icons/bi";
import { GoQuestion } from "react-icons/go";
import {
  RiLogoutCircleLine,
  RiMenu5Line,
  RiSearchLine,
  RiUserAddLine,
} from "react-icons/ri";
import { firebase } from "../../../firebase/config";
import Signup from "./Signup";
import { useSignUpModal } from "../Context/SignUpModal";
import { useAuth } from "../Context/AuthContext";
import Popup from "./Popup";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import {
  FaChevronLeft,
  FaCoins,
  FaDiscord,
  FaRegBell,
  FaRegQuestionCircle,
} from "react-icons/fa";
import { IoClose, IoSearch } from "react-icons/io5";
import { BsFillLightningChargeFill } from "react-icons/bs";
import Credits from "./Credits";
import Invite from "./Invite";
import Notifications from "./Notifications";
import AccountPopUp from "./AccountPopUp";
import Onboarding from "./Onboarding";
import CloneModal from "./CloneModal";
import AssetModal from "./AssetModal";

const PricingMenu = () => {
  const {
    isOpen,
    setIsOpen,
    creditsOpen,
    setCreditsOpen,
    invitesOpen,
    setInvitesOpen,
    notiOpen,
    setNotiOpen,
    accountOpen,
    setAccountOpen,
  } = useSignUpModal();
  const { onboarded, credits } = useAuth();
  const [loggedIn, setLoggedIn] = useState(false);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [section, setSection] = useState("library");

  useEffect(() => {
    if (!firebase.auth().currentUser) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [firebase.auth().currentUser]);

  // useEffect(() => {
  //   firebase.auth().signOut();
  // }, []);

  console.log(window.location.pathname);

  return (
    <>
      <MDBRow
        style={{
          width: "105vw",
          position: "fixed",
          backgroundColor: "#fff",
          zIndex: 970,
          height: 77,
          borderBottom: "1px solid #eaecf0",
        }}
      >
        <MDBCol
          style={{
            borderBottom: "0px solid rgb(234, 236, 240)",
            height: 77,
            position: "fixed",
            zIndex: 900,
            borderRadius: 0,
            width: "105vw",
            marginTop: 0,
          }}
          className="d-flex justify-content-between"
          size="12"
        >
          <MDBRow style={{ paddingTop: 23 }}>
            <MDBCol size="6">
              <NavLink onClick={() => setAccountOpen(false)} to="/" exact>
                <div
                  style={{
                    display: "flex",
                    marginLeft: 30,
                    color: "black",
                    width: 150,
                    opacity: 0.5,
                    marginTop: 5,
                  }}
                >
                  <FaChevronLeft
                    style={{ marginTop: 4, marginRight: 15, fontSize: 22 }}
                    className="d-inline"
                  />
                  <p style={{ fontFamily: "SSMedium", marginTop: 3 }}>Back</p>
                </div>
              </NavLink>
            </MDBCol>
          </MDBRow>
          <MDBRow
            className="d-flex justify-content-center"
            style={{ paddingTop: 16 }}
          >
            <NavLink
              to="/"
              exact
              style={{
                backgroundColor:
                  window.location.pathname === "/" && "rgb(249, 250, 251)",
                marginTop: 6,
                height: 36,
                borderRadius: 3,
                fontFamily: "SSMedium",
                fontSize: 20,
                paddingTop: 7,
                display: "inline-block",
                cursor: "pointer",
                color: "black",
                textAlign: "center",
                marginLeft: 80,
              }}
              onClick={() => setSection("library")}
            >
              <p>Plans</p>
            </NavLink>
          </MDBRow>

          <MDBRow
            style={{
              width: 720,
              marginRight: -440,
              backgroundColor: "transparent",
            }}
          >
            <MDBCol className="d-flex justify-content-between" size="8">
              {/* <div
                style={{
                  height: 52,
                  width: 200,
                  border: "2px solid #0c41ab",
                  borderRadius: 10,
                  marginTop: 12,
                  marginLeft: -180,
                  backgroundColor: "#0c41ab",
                  opacity: 14,
                  cursor: "pointer",
                  position: "absolute",
                }}
                onClick={() => {
                  setInvitesOpen(true);
                }}
                className="d-flex justify-content-center"
              >
                <p
                  style={{
                    display: "inline-block",
                    fontFamily: "PPMedium",
                    color: "#fff",
                    marginLeft: 0,
                    marginTop: 13,
                    marginRight: 0,
                    marginLeft: 0,
                    cursor: "pointer",
                    fontSize: 16,
                    opacity: 1,
                    width: 200,
                    textAlign: "center",
                  }}
                >
                  <FaDiscord
                    style={{ fontSize: 19, marginTop: -2, marginRight: 9 }}
                  />
                  Join our discord
                </p>
              </div> */}
              <div
                style={{
                  height: 39,
                  border: "1px solid rgb(208, 213, 221)",
                  borderRadius: 6,
                  width: 40,
                  marginTop: 18,
                  marginLeft: 208,
                }}
                className="d-flex justify-content-center"
              >
                <a href="mailto:help@rowads.co" target="_blank">
                  <GoQuestion
                    style={{
                      color: "black",
                      fontSize: 21,
                      cursor: "pointer",
                      marginTop: 9,
                    }}
                  />
                </a>
              </div>
              <Notifications />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(PricingMenu);
