import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import { TailSpin } from "react-loader-spinner";
import { useSignUpModal } from "../Context/SignUpModal";
import { useAuth } from "../Context/AuthContext";
import VideoInstructions from "./VideoInstructions";
import UploadSection from "./UploadSection";
import { useTranslation } from "react-i18next";

const Explainer = ({ credits }) => {
  const [inputValue, setInputValue] = useState("Hi how are you?");
  const [section, setSection] = useState(1);
  const history = useHistory();
  const { paid } = useAuth();
  const { t } = useTranslation();

  const handleChange = (event) => {
    setInputValue(event.target.value.substr(0, limit));
  };

  const limit = 5000;

  const renderSection = (section) => {
    switch (section) {
      case 1:
        return <VideoInstructions setSection={setSection} />;
      case 2:
        return <UploadSection setSection={setSection} />;
    }
  };

  return (
    <>
      <MDBRow
        style={{
          marginTop: 0,
          paddingTop: 0,
          paddingBottom: "0vh",
          paddingLeft: 0,
        }}
      >
        <MDBCol className="d-flex justify-content-center" size="12">
          <MDBRow style={{ width: 850, marginTop: 8 }}>
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "MAHeavy",
                  fontSize: 21,
                  color: "black",
                }}
              >
                {t("CreateCloneTitle")}
              </p>
              <p
                style={{
                  fontFamily: "MABold",
                  fontSize: 17,
                  marginTop: -16,
                  color: "black",
                }}
              >
                {t("CreateCloneSubtitle")}
              </p>

              <div
                style={{
                  paddingBottom: 0,
                  width: 800,
                  borderRadius: 9,
                  overflow: "hidden",
                  height: 700,
                  border: "2px solid black",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    paddingBottom: 0,
                    width: 800,
                    borderRadius: 20,
                    objectFit: "cover",
                    verticalAlign: "top",
                    backgroundColor: "white", // Added this line
                    aspectRatio: 9 / 16,
                  }}
                >
                  {renderSection(section)}
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(Explainer);
