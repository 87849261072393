import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen, HiSwitchHorizontal } from "react-icons/hi";
import { BiColorFill, BiHelpCircle, BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSignUpModal } from "../Context/SignUpModal";
import {
  RiCloseFill,
  RiDeleteBin5Line,
  RiRedPacketLine,
  RiTrophyLine,
} from "react-icons/ri";
import {
  FiCheck,
  FiCopy,
  FiCreditCard,
  FiHeadphones,
  FiMail,
  FiPlus,
  FiShoppingBag,
} from "react-icons/fi";
import { FaDownload, FaLink, FaRegBuilding, FaRegImage } from "react-icons/fa";
import { useDetectClickOutside } from "react-detect-click-outside";
import { AiOutlineLogout } from "react-icons/ai";
import { useAuth } from "../Context/AuthContext";
import { HexColorPicker } from "react-colorful";
import {
  TbAlignBoxCenterMiddle,
  TbAlignBoxLeftMiddle,
  TbAlignBoxRightMiddle,
  TbAlignCenter,
  TbAlignLeft,
  TbAlignRight,
  TbBoxAlignLeft,
} from "react-icons/tb";

const DownloadPopup = ({
  downloadPopUpOpen,
  setDownloadPopUpOpen,
  assetID,
  fileURL,
  fileName,
  index,
}) => {
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (downloadPopUpOpen) {
        setDownloadPopUpOpen(false);
      }
    },
  });

  const triggerDownload = async (url, fileName) => {
    try {
      // Fetch the file data
      const response = await fetch(url);
      if (!response.ok)
        throw new Error(`Network response was not ok: ${response.statusText}`);

      // Create a blob from the response data
      const blob = await response.blob();

      // Create a URL for the blob
      const blobUrl = URL.createObjectURL(blob);

      // Create a new anchor element
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = fileName; // Set the file name for the download

      // Append the anchor to the document
      document.body.appendChild(anchor);

      // Trigger the download by simulating a click
      anchor.click();

      // Remove the anchor from the document
      document.body.removeChild(anchor);

      // Revoke the blob URL to free up resources
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Failed to download the file:", error);
    }
  };

  return (
    <>
      <div
        ref={ref}
        style={{
          width: 140,
          borderRadius: 8,
          top: 0,
          boxShadow:
            "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          display: "flex",
          animation: downloadPopUpOpen ? "popUp 0.3s ease-in-out" : "none",
          visibility: downloadPopUpOpen ? "visible" : "hidden",
          position: "absolute",
          marginTop: 40,
          zIndex: 11000,
          border: "none",
          marginLeft: 0,
          backgroundColor: "rgb(32, 41, 65)",
          display: "flex",
          paddingTop: 18,
          paddingBottom: 5,
          paddingLeft: 18,
          paddingRight: 5,
          color: "white",
          opacity: 1,
          fontFamily: "PPMedium",
          fontSize: 14,
        }}
        className={`backy60 alwayson ${
          downloadPopUpOpen ? "popUpAnimation" : ""
        }`}
      >
        <MDBRow>
          <MDBCol size="12">
            <p
              onClick={() => {
                triggerDownload(fileURL, fileName);
                setDownloadPopUpOpen(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <FaDownload
                style={{ marginRight: 10, fontSize: 11, marginTop: -1 }}
              />
              Download
            </p>
          </MDBCol>
          <MDBCol size="12">
            <p
              onClick={() => {
                firebase
                  .firestore()
                  .collection("assets")
                  .doc(assetID)
                  .delete()
                  .then(() => {
                    setDownloadPopUpOpen(false);
                  });
              }}
              style={{ cursor: "pointer" }}
            >
              <RiDeleteBin5Line
                style={{ marginRight: 10, fontSize: 13, marginTop: -3 }}
              />
              Delete
            </p>
          </MDBCol>
        </MDBRow>
      </div>
    </>
  );
};

export default withRouter(DownloadPopup);
