import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import TopMenu from "../Layout/TopMenu";
import Collapsible from "react-collapsible";
import MenuDesktop from "../Layout/MenuDesktop";
import { MDBRow, MDBCol } from "mdbreact";
import { IoSearch } from "react-icons/io5";
import { NavLink, withRouter } from "react-router-dom";
import ReactInputVerificationCode from "react-input-verification-code";
import DashboardNavBar from "../Layout/DashboardNavBar";
import DashboardFooter from "../Layout/DashboardFooter";

import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiArrowRightSLine,
  RiQuestionLine,
} from "react-icons/ri";
import mixpanel from "mixpanel-browser";
import { HiLightningBolt, HiSortAscending } from "react-icons/hi";
import ReactStars from "react-stars";

import { firebase } from "../../../firebase/config";

import { SkyLightStateless } from "react-skylight";
import { TiTick } from "react-icons/ti";
import Scroll from "react-scroll";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const Match = (props) => {
  const [value, setValue] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const [recentOpen, setRecentOpen] = useState(false);
  const [pastOpen, setPastOpen] = useState(false);

  useEffect(() => {
    const uid = firebase.auth().currentUser.uid;
    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then((doc) => {
        setCode(doc.data().verification);
      });
  }, []);

  useEffect(() => {
    console.log(code, value);
  }, [code, value]);

  const verifyCode = () => {
    if (Number(value) === code) {
      window.location.href = "/verification";
    } else {
      setError("Verification code is wrong.");
    }
  };

  return (
    <div
      style={{
        borderTop: "0.5px solid #C8C8C8",
        paddingLeft: 40,
        cursor: "pointer",
      }}
      className="match"
    >
      <MDBRow style={{ paddingBottom: 10 }}>
        <MDBCol size="2">
          <div
            style={{
              height: 50,
              width: 50,
              backgroundColor: "#30312c",
              borderRadius: 100,
              marginTop: 20,
            }}
          ></div>
        </MDBCol>
        <MDBCol size="3">
          <MDBRow>
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  marginTop: 20,
                  opacity: 0.94,
                  marginLeft: -14,
                }}
              >
                {props.name}
              </p>
            </MDBCol>
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  marginTop: -9,
                  opacity: 0.5,
                  marginLeft: -14,
                  fontSize: 12.5,
                  width: 300,
                }}
              >
                Matched on {props.matchDate}
              </p>
            </MDBCol>
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  marginTop: -6,
                  opacity: 1,
                  marginLeft: -14,
                  fontSize: 13,
                  width: 300,
                  lineHeight: 1.6,
                }}
              >
                {props.description}...
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol size="2">
          <MDBRow>
            <MDBCol size="12">
              <div
                style={{
                  width: 121,
                  height: 34,
                  marginLeft: 180,
                  borderRadius: 4,
                  marginTop: 22,
                  boxShadow: "rgb(34 36 38 / 15%) 0px 0px 0px 1px inset",
                }}
                className="d-flex justify-content-center"
              >
                <p
                  style={{
                    fontFamily: "PlusJSMedium",
                    fontSize: 12,
                    marginTop: 7,
                    opacity: 0.7,
                    cursor: "pointer",
                  }}
                >
                  We met!
                </p>
              </div>
            </MDBCol>
            <MDBCol size="12">
              <div
                style={{
                  width: 121,
                  height: 34,
                  marginLeft: 180,
                  borderRadius: 4,
                  marginTop: 13,
                  cursor: "pointer",
                  boxShadow: "rgb(34 36 38 / 15%) 0px 0px 0px 1px inset",
                }}
                className="d-flex justify-content-center"
              >
                <p
                  style={{
                    fontFamily: "PlusJSMedium",
                    fontSize: 12,
                    marginTop: 7,
                    opacity: 0.7,
                  }}
                >
                  Send Message
                </p>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default withRouter(Match);
