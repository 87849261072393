import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { BiRightArrowAlt } from "react-icons/bi";
import {
  RiCloseFill,
  RiEditFill,
  RiLogoutCircleLine,
  RiMenu5Line,
  RiUserAddLine,
} from "react-icons/ri";
import { firebase } from "../../../firebase/config";
import Signup from "./Signup";
import { useSignUpModal } from "../Context/SignUpModal";
import { useAuth } from "../Context/AuthContext";
import Popup from "./Popup";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { FaCoins, FaPen, FaRegBell, FaSave } from "react-icons/fa";
import {
  IoClose,
  IoCloudDoneOutline,
  IoCloudDoneSharp,
  IoSearch,
} from "react-icons/io5";
import { BsArrowLeft, BsFillLightningChargeFill } from "react-icons/bs";
import { FiCheck, FiEdit2, FiPlay } from "react-icons/fi";
import { TailSpin } from "react-loader-spinner";

const ProjectMenu = ({
  projectName,
  projectID,
  setProjectName,
  workspaceID,
  lastTime,
  videoItems,
}) => {
  const { isOpen, setIsOpen } = useSignUpModal();
  const [loggedIn, setLoggedIn] = useState(false);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [section, setSection] = useState(1);
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const { ugc } = useAuth();
  const history = useHistory();

  const handleSave = () => {
    setSaved(true);
    firebase
      .firestore()
      .collection("projects")
      .doc(projectID)
      .update({ ugc: ugc })
      .then(() => {
        setTimeout(() => {
          setSaved(false);
        }, 2000);
      });
  };

  useEffect(() => {
    if (!firebase.auth().currentUser) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [firebase.auth().currentUser]);

  const handleGoBack = () => {
    history.goBack();
  };

  function calculateOutput(seconds) {
    return Math.ceil(seconds / 30);
  }

  const submitVideo = async () => {
    setLoading(true);

    // Check if any "actor" type items have an empty or non-existent videoURL field
    const hasEmptyActorURL = videoItems.some(
      (item) =>
        item.type === "actor" && (!item.videoURL || item.videoURL.trim() === "")
    );

    if (hasEmptyActorURL) {
      // Display an error message or perform any desired action
      alert(
        "Please ensure all actor items have a valid video URL before submitting."
      );
      return;
    }
    await firebase
      .firestore()
      .collection("projects")
      .doc(projectID)
      .update({ duration: lastTime });
    var createVideo = firebase.functions().httpsCallable("createVideo");
    createVideo({
      projectID,
      workspaceID,
      creditsRequired: calculateOutput(lastTime),
    }).then(async (result) => {
      if (result.data.status === "Success") {
        history.push("/my-projects");
      }
    });
  };

  // useEffect(() => {
  //   firebase.auth().signOut();
  // }, []);

  return (
    <>
      <Signup isOpen={isOpen} setIsOpen={setIsOpen} />
      <Popup popUpVisible={popUpVisible} setPopUpVisible={setPopUpVisible} />

      <MDBRow
        style={{
          width: "105vw",
          position: "fixed",
          backgroundColor: "rgb(22, 23, 26)",
          zIndex: 970,
          height: 60,
          borderBottom: "1px solid #404040",
        }}
      >
        <MDBCol
          style={{
            borderBottom: "0px solid rgb(234, 236, 240)",
            height: 60,
            position: "fixed",
            zIndex: 900,
            borderRadius: 0,
            width: "105vw",
            marginTop: 0,
          }}
          className="d-flex justify-content-between"
          size="12"
        >
          <MDBRow style={{ paddingTop: 12 }}>
            <MDBCol size="4">
              <div
                className="d-flex justify-content-center"
                style={{
                  height: 40,
                  width: 40,
                  backgroundColor: "transparent",
                  marginLeft: 19,
                  marginTop: -2,
                  borderRadius: 9,
                  cursor: "pointer",
                  border: "1px solid rgba(255,255,255,0.1)",
                }}
                onClick={() => handleGoBack()}
              >
                <BsArrowLeft
                  style={{
                    color: "white",
                    fontSize: 17,
                    marginTop: 11,
                  }}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  color: "white",
                  top: 9,
                  left: 100,
                  opacity: 0.8,
                  fontSize: 20,
                  display: "flex",
                  marginTop: 0,
                }}
              >
                <IoCloudDoneOutline />
                <p style={{ fontSize: 13, marginLeft: 7, marginTop: 1 }}>
                  Saved
                </p>
              </div>
            </MDBCol>
            <MDBCol
              size="4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: 0,
                width: 240,
                paddingTop: 0,
              }}
            ></MDBCol>
          </MDBRow>
          <div style={{ display: "flex" }}>
            <FaPen
              style={{
                color: "white",
                marginTop: 23.4,
                marginRight: 12,
                fontSize: 13,
              }}
            />

            <p
              contentEditable
              suppressContentEditableWarning
              onBlur={(e) => setProjectName(e.target.textContent)}
              style={{
                color: "white",
                fontFamily: "SSMedium",
                marginTop: 17,
                fontSize: 17,
              }}
            >
              {projectName}
            </p>
          </div>
          <MDBRow
            style={{
              width: 430,
              marginRight: -140,
              backgroundColor: "transparent",
            }}
          >
            <MDBCol className="d-flex justify-content-between" size="8">
              <div
                style={{
                  height: 42,
                  width: 120,
                  border: "0px solid #37383d",
                  borderRadius: 5,
                  marginTop: 9,
                  marginLeft: 0,
                  backgroundColor: "transparent",
                  opacity: 14,
                  cursor: "pointer",
                  marginRight: 10,
                }}
                className="d-flex justify-content-center"
              ></div>
              <div
                style={{
                  height: 42,
                  width: 120,
                  border: "2px solid #ed1165",
                  borderRadius: 5,
                  marginTop: 9,
                  marginLeft: 0,
                  backgroundColor: "#ed1165",
                  opacity: loading ? 0.7 : 1,
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!loading) {
                    submitVideo();
                  }
                }}
                className="d-flex justify-content-center"
              >
                {loading ? (
                  <TailSpin
                    height="20"
                    width="20"
                    color="#fff"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{ marginTop: 10 }}
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  <p
                    style={{
                      display: "inline-block",
                      fontFamily: "SSMedium",
                      color: "white",
                      marginLeft: 0,
                      marginTop: 8,
                      marginRight: 0,
                      marginLeft: 0,
                      cursor: "pointer",
                      fontSize: 16,
                      opacity: 1,
                      width: 140,
                      textAlign: "center",
                    }}
                  >
                    <FiCheck
                      style={{ fontSize: 19, marginRight: 9, marginTop: -2 }}
                    />
                    Submit
                  </p>
                )}
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(ProjectMenu);
