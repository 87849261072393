import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { TailSpin } from "react-loader-spinner";
import { FaShippingFast } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { MdOutlinePhonelink } from "react-icons/md";

const Step1 = ({
  setIsOpen,
  isOpen,
  videoID,
  productType,
  setProductType,
  setSection,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <MDBCol size="12">
      <div>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
          }}
        >
          Step 1 of 4
        </p>
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 24,
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          Choose your product type
        </p>

        <div
          onClick={() => setProductType("Ecommerce")}
          style={{
            height: 65,
            width: 410,
            borderRadius: 3,
            marginTop: 20,
            fontFamily: "SSRegular",
            color: "black",
            paddingTop: 10,
            cursor: "pointer",
            opacity: 0.6,
            border:
              productType === "Ecommerce"
                ? "2px solid #ed1165"
                : "2px solid #eaecf0",
            paddingLeft: 50,
            backgroundColor:
              productType === "Ecommerce" ? "#F8F8F8" : "transparent",
          }}
          className="d-flex justify-content-between"
        >
          <FaShippingFast
            style={{
              position: "absolute",
              left: 30,
              fontSize: 20,
              marginTop: 13,
              color: productType === "Ecommerce" ? "#ed1165" : "black",
            }}
          />

          <FiArrowRight
            style={{
              position: "absolute",
              right: 80,
              fontSize: 20,
              marginTop: 13,
              color: productType === "Ecommerce" ? "#ed1165" : "black",
            }}
          />

          <div>
            <p style={{ fontSize: 17, fontFamily: "SSMedium" }}>E-commerce</p>
            <p style={{ fontSize: 13, marginTop: -20 }}>
              You sell and ship physical products
            </p>
          </div>
        </div>
        <div
          onClick={() => setProductType("Digital")}
          style={{
            height: 65,
            width: 410,
            borderRadius: 3,
            marginTop: 20,
            fontFamily: "SSRegular",
            color: "black",
            paddingTop: 10,
            cursor: "pointer",
            opacity: 0.6,
            border:
              productType === "Digital"
                ? "2px solid #ed1165"
                : "2px solid #eaecf0",
            paddingLeft: 50,
            backgroundColor:
              productType === "Digital" ? "#F8F8F8" : "transparent",
          }}
          className="d-flex justify-content-between"
        >
          <MdOutlinePhonelink
            style={{
              position: "absolute",
              left: 30,
              fontSize: 20,
              marginTop: 13,
              color: productType === "Digital" ? "#ed1165" : "black",
            }}
          />

          <FiArrowRight
            style={{
              position: "absolute",
              right: 80,
              fontSize: 20,
              marginTop: 13,
              color: productType === "Digital" ? "#ed1165" : "black",
            }}
          />

          <div>
            <p style={{ fontSize: 17, fontFamily: "SSMedium" }}>Digital</p>
            <p style={{ fontSize: 13, marginTop: -20 }}>
              You sell digital products or services
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          height: 55,
          width: 410,
          backgroundColor: "#ed1165",
          borderRadius: 7,
          marginTop: 20,
          textAlign: "center",
          fontFamily: "SSRegular",
          color: "white",
          fontSize: 18,
          paddingTop: 14,
          cursor: "pointer",
          opacity: 1,
        }}
        onClick={() => {
          if (productType === "Ecommerce") {
            setSection("Step2Ecom");
          } else {
            setSection("Step2Digital");
          }
        }}
        className="d-flex justify-content-center"
      >
        {loading ? (
          <TailSpin
            height="25"
            width="25"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{ marginTop: 0 }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            <p>Continue</p>
          </>
        )}
      </div>
    </MDBCol>
  );
};

export default withRouter(Step1);
