import React, { Component } from "react";
import "../index.css";
import TopMenu from "../Layout/TopMenu";
import { MDBRow, MDBCol } from "mdbreact";
import { IoSearch } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { FiCopy, FiSearch } from "react-icons/fi";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <div
          style={{
            backgroundColor: "white",
            borderTop: "0.5px solid #E0E0E0",
            paddingBottom: "5vh",
            width: "100%",
          }}
        >
          <MDBRow style={{ width: "100%" }}>
            <MDBCol style={{ marginLeft: "10%" }} size="3">
              <p
                style={{
                  color: "#30312c",
                  fontFamily: "PlusJSExtraBold",
                  fontWeight: "600",
                  marginTop: 90,
                  fontSize: 16,
                  marginLeft: 100,
                }}
              >
                Product
              </p>
              <NavLink to="/" exact onClick={() => window.scrollTo(0, 0)}>
                <p
                  style={{
                    color: "#30312c",
                    cursor: "pointer",
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: 14,
                    fontSize: 14,
                    marginLeft: 100,
                    color: "#30312c",
                    opacity: 0.6,
                  }}
                >
                  Home
                </p>
              </NavLink>
              <NavLink
                to="/pricing"
                exact
                onClick={() => window.scrollTo(0, 0)}
              >
                <p
                  style={{
                    color: "#30312c",
                    cursor: "pointer",
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: 0,
                    fontSize: 14,
                    marginLeft: 100,
                    color: "#30312c",
                    opacity: 0.6,
                  }}
                >
                  Pricing
                </p>
              </NavLink>
              <NavLink
                to="/sign-in"
                exact
                onClick={() => window.scrollTo(0, 0)}
              >
                <p
                  style={{
                    color: "#30312c",
                    cursor: "pointer",
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: 0,
                    fontSize: 14,
                    marginLeft: 100,
                    color: "#30312c",
                    opacity: 0.6,
                  }}
                >
                  Login
                </p>
              </NavLink>
            </MDBCol>
            <MDBCol size="3">
              <p
                style={{
                  color: "#30312c",
                  fontFamily: "PlusJSExtraBold",
                  fontWeight: "600",
                  marginTop: 90,
                  fontSize: 16,
                  marginLeft: 100,
                }}
              >
                Company
              </p>

              <NavLink to="/about" exact onClick={() => window.scrollTo(0, 0)}>
                <p
                  style={{
                    color: "#30312c",
                    cursor: "pointer",
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: 14,
                    fontSize: 14,
                    marginLeft: 100,
                    color: "#30312c",
                    opacity: 0.6,
                  }}
                >
                  About
                </p>
              </NavLink>
              <NavLink
                to="/careers"
                exact
                onClick={() => window.scrollTo(0, 0)}
              >
                <p
                  style={{
                    color: "#30312c",
                    cursor: "pointer",
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: 0,
                    fontSize: 14,
                    marginLeft: 100,
                    color: "#30312c",
                    opacity: 0.6,
                  }}
                >
                  Careers
                </p>
              </NavLink>
              <a href="https://calendly.com/justin-energizeapi/30min" exact>
                <p
                  style={{
                    color: "#30312c",
                    cursor: "pointer",
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: 0,
                    fontSize: 14,
                    marginLeft: 100,
                    color: "#30312c",
                    opacity: 0.6,
                  }}
                >
                  Book a Demo
                </p>
              </a>
            </MDBCol>
            <MDBCol size="3">
              <p
                style={{
                  color: "#30312c",
                  fontFamily: "PlusJSExtraBold",
                  fontWeight: "600",
                  marginTop: 90,
                  fontSize: 16,
                  marginLeft: 100,
                }}
              >
                Support
              </p>
              <a
                href="mailto:help@cloneshort.com"
                target="_blank"
                exact
                onClick={() => this.props.executeScroll1()}
              >
                <p
                  style={{
                    color: "#30312c",
                    cursor: "pointer",
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: 14,
                    fontSize: 14,
                    marginLeft: 100,
                    color: "#30312c",
                    opacity: 0.6,
                  }}
                >
                  Contact us
                </p>
              </a>

              {/* <NavLink
                                to="/login"
                                exact
                                onClick={() => window.scrollTo(0, 0)}>
                                <p style={{ color: "#30312c", fontFamily: "PlusJSMedium", fontWeight: "500", marginTop: -13, fontSize: 17, marginLeft: 100, color: "#30312c", opacity: 0.8 }}>Log in</p>
                            </NavLink> */}
            </MDBCol>
            <MDBCol size="1">
              {/* <p style={{ color: "#30312c", fontFamily: "Grifter", fontWeight: "600", marginTop: 90, fontSize: 20, marginLeft: 50 }}>Find us on Facebook</p>
                            <a target="_blank" href="https://www.facebook.com/Wornandsold-115520131133526">
                                <img src={require("./facebookImage.png")} style={{ height: 40, width: "auto", marginLeft: 70, marginTop: 5 }} />
                            </a> */}
            </MDBCol>
            <MDBCol style={{ marginTop: 40, marginLeft: "10%" }} size="6">
              <p
                style={{
                  display: "inline-block",
                  color: "#30312c",
                  fontFamily: "PlusJSMedium",
                  fontWeight: "500",
                  fontSize: 15,
                  marginLeft: 100,
                  opacity: 0.8,
                }}
              >
                © 2022 Energize Inc. All rights reserved.
              </p>
            </MDBCol>
          </MDBRow>
        </div>
      </>
    );
  }
}

export default Footer;
