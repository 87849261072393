import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import { HiDotsHorizontal } from "react-icons/hi";
import {
  FaRegCompass,
  FaRegFolder,
  FaRegStar,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { RiRedPacketLine } from "react-icons/ri";
import { BiSolidVideos } from "react-icons/bi";
import SearchSection from "./SearchSection";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { TbTruckDelivery } from "react-icons/tb";
import { UploadButton } from "@bytescale/upload-widget-react";
import { useAuth } from "../Context/AuthContext";
import { TailSpin } from "react-loader-spinner";
import DownloadPopup from "./DownloadPopup";
import { BsFileMusicFill, BsFillFileEarmarkMusicFill } from "react-icons/bs";

const options = {
  apiKey: "public_FW25bFR5wx4rjPmAks71Pakd2omr", // This is your API key.
  maxFileCount: 1,
  mimeTypes: [
    // Image formats
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/svg+xml",
    // Video formats
    "video/mp4",
    "video/webm",
    "video/ogg",
    // Audio formats
    "audio/mpeg",
    "audio/ogg",
    "audio/wav",
    "audio/webm",
  ],
  maxFileSizeBytes: 524288000, // 500 MB
};

const AssetSection = ({ addItem, lastTime }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchSection, setSearchSection] = useState(false);
  const [fileURL, setFileURL] = useState(false);
  const [mime, setMime] = useState(null);
  const [assets, setAssets] = useState([]);
  const [fileName, setFileName] = useState("");
  const [downloadPopUpOpen, setDownloadPopUpOpen] = useState();
  const [currentAssetID, setCurrentAssetID] = useState();
  const { workspaceID } = useAuth();

  function categorizeMediaType(mediaType) {
    // Image formats
    const imageFormats = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/webp",
      "image/svg+xml",
    ];
    // Video formats
    const videoFormats = ["video/mp4", "video/webm", "video/ogg"];
    // Audio formats
    const audioFormats = ["audio/mpeg", "audio/ogg", "audio/wav", "audio/webm"];

    if (imageFormats.includes(mediaType)) {
      return "image";
    } else if (videoFormats.includes(mediaType)) {
      return "video";
    } else if (audioFormats.includes(mediaType)) {
      return "music";
    } else {
      return "unknown"; // Returns "unknown" if the format does not match any category
    }
  }

  useEffect(() => {
    if (workspaceID) {
      const unsubscribe = firebase
        .firestore()
        .collection("assets")
        .where("workspaceID", "==", workspaceID)
        .onSnapshot((querySnapshot) => {
          let updatedAssets = [];
          querySnapshot.forEach((doc) => {
            updatedAssets.push({ id: doc.id, ...doc.data() });
          });
          setAssets(updatedAssets);
        });

      // Cleanup function to unsubscribe from the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [workspaceID]);

  const prepareAsset = async (fileURL, mime, fileName) => {
    if (fileURL && mime) {
      firebase
        .firestore()
        .collection("assets")
        .add({
          status: "Loading",
          fileURL: fileURL,
          type: categorizeMediaType(mime),
          workspaceID: workspaceID,
          fileName: fileName,
        })
        .then((docRef) => {
          var prepareAsset = firebase.functions().httpsCallable("prepareAsset");
          prepareAsset({
            type: categorizeMediaType(mime),
            fileURL: fileURL,
            assetID: docRef.id,
          }).then((result) => {
            const finalAsset = result.data.assetID;
            if (finalAsset) {
              //navigate to the success page
              console.log("Successfully prepared asset");
              return null;
            }
          });
        });
    }
  };

  return searchSection ? (
    <SearchSection
      selectedCategory={selectedCategory}
      setSearchSection={setSearchSection}
    />
  ) : (
    <MDBRow
      style={{
        marginTop: 75,
        marginLeft: 0,
        overflowY: "hidden",
        color: "white",
      }}
    >
      <MDBCol size="12">
        <p
          style={{
            fontSize: 20,
            color: "#fff",
            fontFamily: "PPBold",
            opacity: 0.9,
          }}
        >
          Asset
        </p>
      </MDBCol>
      <MDBCol size="12">
        <MDBRow>
          <MDBCol size="12">
            <div style={{ display: "flex" }}>
              <UploadButton
                options={options}
                onComplete={(files) => {
                  setMime(files[0].originalFile.mime);
                  setFileName(files[0].originalFile.originalFileName);
                  setFileURL(files[0].fileUrl);
                  prepareAsset(
                    files[0].fileUrl,
                    files[0].originalFile.mime,
                    files[0].originalFile.originalFileName
                  );
                }}
              >
                {({ onClick }) => (
                  <div
                    onClick={onClick}
                    style={{
                      width: 120,
                      height: 120,
                      borderRadius: 3,
                      cursor: "pointer",
                      backgroundColor: "#1f2124",
                      marginRight: 15,
                      flexShrink: 0,
                      textAlign: "center",
                    }}
                  >
                    <AiOutlineCloudUpload
                      style={{ marginTop: 30, fontSize: 33 }}
                    />
                    <p
                      style={{
                        fontSize: 13,
                        fontFamily: "PPMedium",
                        marginTop: 5,
                      }}
                    >
                      Upload asset
                    </p>
                  </div>
                )}
              </UploadButton>
              {/* <div
                style={{
                  width: 120,
                  height: 120,
                  borderRadius: 3,
                  cursor: "pointer",
                  backgroundColor: "#1f2124",
                  marginRight: 15,
                  flexShrink: 0,
                  textAlign: "center",
                }}
              >
                <TbTruckDelivery style={{ marginTop: 30, fontSize: 33 }} />
                <p
                  style={{
                    fontSize: 13,
                    fontFamily: "PPMedium",
                    marginTop: 5,
                  }}
                >
                  Order video
                </p>
              </div> */}
            </div>
          </MDBCol>
          <MDBCol style={{ marginTop: 20 }} size="12">
            {assets.length > 0 && (
              <MDBRow>
                <p style={{ fontFamily: "PPMedium", marginLeft: 20 }}>Items</p>
              </MDBRow>
            )}
            <MDBRow
              style={{
                height: "44vh",
                overflowY: "scroll",
                paddingBottom: 40,
                color: "white",
                width: "90%",
                msOverflowStyle: "none",
                scrollbarWidth: "none",
                WebkitOverflowScrolling: "touch",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {assets.length > 0 &&
                assets.map((asset, index) => {
                  return (
                    <MDBCol size="6">
                      <div
                        style={{
                          width: 140,
                          height: 140,
                          borderRadius: 6,
                          cursor: "pointer",
                          backgroundColor: "#1f2124",
                          marginRight: 15,
                          flexShrink: 0,
                          textAlign: "center",
                          marginBottom: 20,
                        }}
                        onClick={() => {
                          if (asset.status === "Finished") {
                            if (asset.type === "video") {
                              addItem({
                                videoRef: React.createRef(),
                                videoURL: asset.fileURL,
                                startTime: 0,
                                initialStartTime: 0,
                                endTime: asset.duration,
                                initialEndTime: asset.duration,
                                trimStart: 0,
                                trimEnd: asset.duration,
                                x: 0,
                                y: 0,
                                width: "100%",
                                height: "100%",
                                layerIndex: 2,
                                type: "video",
                                thumbnail: asset.thumbnail,
                                color: "black",
                                volume: 0.5,
                              });
                            } else if (asset.type === "image") {
                              addItem({
                                videoRef: React.createRef(),
                                startTime: 0,
                                initialStartTime: 0,
                                endTime: lastTime,
                                initialEndTime: lastTime,
                                trimStart: 0,
                                trimEnd: lastTime,
                                x: 0,
                                y: 0,
                                width: "100%",
                                height: "100%",
                                layerIndex: 2,
                                type: "image",
                                thumbnail: asset.thumbnail,
                                color: "black",
                              });
                            } else if (asset.type === "music") {
                              addItem({
                                videoRef: React.createRef(),
                                videoURL: asset.fileURL,
                                startTime: 0,
                                initialStartTime: 0,
                                endTime: asset.duration,
                                initialEndTime: asset.duration,
                                trimStart: 0,
                                trimEnd: asset.duration,
                                name: asset.fileName,
                                x: 0,
                                y: 0,
                                width: "100%",
                                height: "100%",
                                layerIndex: 2,
                                type: "music",
                                color: "black",
                                volume: 0.5,
                              });
                            }
                          }
                        }}
                        className="d-flex justify-content-center asset-container"
                      >
                        {asset.status === "Loading" ? (
                          <TailSpin
                            height={29}
                            width={29}
                            color="white"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{ marginTop: 50 }}
                            wrapperClass=""
                            visible={true}
                          />
                        ) : asset.type === "image" || asset.type === "video" ? (
                          <>
                            <img src={asset.thumbnail} />
                            <p
                              style={{
                                position: "absolute",
                                fontSize: 12,
                                color: "white",
                                fontFamily: "PPMedium",
                                marginTop: 110,
                              }}
                            >
                              {asset.fileName.length > 10
                                ? `${asset.fileName.substring(0, 16)}...`
                                : asset.fileName}
                            </p>
                          </>
                        ) : (
                          <>
                            <BsFillFileEarmarkMusicFill
                              style={{
                                fontSize: 44,
                                marginTop: 40,
                                color: "white",
                                opacity: 0.6,
                              }}
                            />
                            <p
                              style={{
                                position: "absolute",
                                fontSize: 12,
                                color: "white",
                                fontFamily: "PPMedium",
                                marginTop: 110,
                              }}
                            >
                              {asset.fileName.length > 10
                                ? `${asset.fileName.substring(0, 16)}...`
                                : asset.fileName}
                            </p>
                          </>
                        )}
                        <div
                          style={{
                            position: "absolute",
                            height: 25,
                            width: 25,
                            backgroundColor: "rgb(32, 41, 65)",
                            borderRadius: 4,
                            zIndex: 3000,
                            marginTop: 10,
                            marginLeft: 84,
                          }}
                          className="menus"
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentAssetID(asset.assetID);
                          }}
                        >
                          <HiDotsHorizontal style={{ marginTop: -2 }} />
                        </div>
                      </div>
                      {currentAssetID === asset.assetID && (
                        <DownloadPopup
                          assetID={asset.assetID}
                          fileName={asset.fileName}
                          fileURL={asset.fileURL}
                          index={index}
                          setDownloadPopUpOpen={setCurrentAssetID}
                          downloadPopUpOpen={currentAssetID === asset.assetID}
                        />
                      )}
                    </MDBCol>
                  );
                })}
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(AssetSection);
