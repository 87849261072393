import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { TailSpin } from "react-loader-spinner";
import { FaArrowLeft, FaShippingFast } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { MdOutlinePhonelink } from "react-icons/md";
import { useAuthKit } from "@integrationos/authkit";

const Step3Digital = ({
  uid,
  setSection,
  description,
  setDescription,
  creatorID,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [IBAN, setIBAN] = useState("");

  const handlePayout = async () => {
    if (creatorID && IBAN && address) {
      setLoading(true);
      try {
        const createPayout = firebase.functions().httpsCallable("createPayout");
        const result = await createPayout({ creatorID, IBAN, address });
        if (result?.data?.status === "Success") {
          setSection("Step4Digital");
        }
      } catch (error) {
        console.error("Error starting verification process:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <MDBCol size="12">
      <div>
        <FaArrowLeft
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
            cursor: "pointer",
          }}
          onClick={() => setSection("Step2Digital")}
        />
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 5,
            marginBottom: 0,
            color: "grey",
          }}
        >
          Step 3 of 4
        </p>
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 24,
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          Banking Payouts
        </p>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 14,
            marginTop: 0,
            marginBottom: 0,
            width: "90%",
          }}
        >
          Provide your banking payout details below, where we will be sending
          your earnings from video projects.
        </p>
      </div>
      <div>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 16,
            marginTop: 16,
            marginBottom: 0,
          }}
        >
          Address
        </p>
        <input
          style={{
            height: 55,
            width: "90%",
            marginLeft: 0,
            border: "1px solid rgba(155, 158, 163, 0.5)",
            borderRadius: 7,
            display: "inline-block",
            color: "black",
            marginTop: 5,
            backgroundColor: "white",
            fontFamily: "SSMedium",
            paddingLeft: 17,
            fontSize: 15,
            paddingTop: 3,
          }}
          value={address}
          name="Address"
          onChange={(event) => setAddress(event.target.value)}
          placeholder="1234 Example Street Kyiv, 01001 Ukraine"
        />
      </div>
      <div>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 16,
            marginTop: 16,
            marginBottom: 0,
          }}
        >
          IBAN
        </p>
        <input
          style={{
            height: 55,
            width: "90%",
            marginLeft: 0,
            border: "1px solid rgba(155, 158, 163, 0.5)",
            borderRadius: 7,
            display: "inline-block",
            color: "black",
            marginTop: 5,
            backgroundColor: "white",
            fontFamily: "SSMedium",
            paddingLeft: 17,
            fontSize: 15,
            paddingTop: 3,
          }}
          value={IBAN}
          name="IBAN"
          placeholder="UA213223130000026007233566001"
          onChange={(event) => setIBAN(event.target.value)}
        />
      </div>
      <div
        style={{
          height: 55,
          width: "90%",
          backgroundColor: "blue",
          borderRadius: 7,
          marginTop: 20,
          textAlign: "center",
          fontFamily: "SSRegular",
          color: "white",
          fontSize: 18,
          paddingTop: 14,
          cursor: "pointer",
          opacity: IBAN && address ? 1 : 0.5,
        }}
        onClick={async () => {
          if (IBAN && address) {
            await handlePayout();
          }
        }}
        className="d-flex justify-content-center"
      >
        {loading ? (
          <TailSpin
            height="25"
            width="25"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{ marginTop: 0 }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            <p>Continue</p>
          </>
        )}
      </div>
    </MDBCol>
  );
};

export default withRouter(Step3Digital);
