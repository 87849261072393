import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import DashboardFooter from "../Layout/DashboardFooter";
import "react-phone-number-input/style.css";
import Heading from "./Heading";
import Explainer from "./Explainer";
import { FaChevronRight } from "react-icons/fa";

const Landing = ({ profiles }) => {
  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "white",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 0,
            }}
            size="12"
          >
            <MDBRow
              style={{
                marginTop: 0,
                position: "fixed",
                backgroundColor: "white",
                zIndex: 100,
              }}
            >
              <DashboardNavBar />
            </MDBRow>

            <MDBCol className="d-flex justify-content-center" size="12">
              <Heading profiles={profiles} />
            </MDBCol>
            <Explainer />
          </MDBCol>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(Landing);
