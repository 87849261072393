import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import { v4 as uuidv4 } from "uuid";
import {
  FaRegCompass,
  FaRegFolder,
  FaRegStar,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { RiRedPacketLine } from "react-icons/ri";
import { BiSolidVideos } from "react-icons/bi";
import Version1Subtitle from "./version1subtitle.mp4";
import Version2Subtitle from "./version2subtitle.mp4";
import Version3Subtitle from "./version3subtitle.mp4";
import Version4Subitle from "./version4subtitle.mp4";
import Version5Subtitle from "./version5subtitle.mp4";
import Version6Subtitle from "./version6subtitle.mp4";

const TextSection = ({ addItem, setVideoItems, videoItems, lastTime }) => {
  const [selectedCategory, setSelectedCategory] = useState("All Use Cases");
  const textStyles = [
    {
      URL: "https://storage.googleapis.com/ugcai-app.appspot.com/music/preset01.svg?GoogleAccessId=firebase-adminsdk-a73t4%40ugcai-app.iam.gserviceaccount.com&Expires=16447017600&Signature=e96CET3vWBJg%2B21AofWtI6wY2HcK2wkhUu03tCK%2BLnV3aWFJ4EIFthD4k%2FjC6VDJXb4L1eXnJFADQk5X03%2F1D6hZ7HTWlNXZNCbTStNZMXNuilMXrDc1Gz1vANVuY7F3%2FsXtt%2B37m12nDI9aQY3FynrXaxKssV7D2Fywmm4ooJ7lfdlbN6A1H8du9CPnwQx0is1Nlya9BfGZslmM6Jqvtp5w5kCJZBTbqtLN5fxEIyLsduVemUEqrABzZ%2FP1ggWUghPsIhPRlrHtHF40P%2FyWsZNiN56O0bPVMm58WihVDtyYL7ue06sikI32KB7TCXytrk9nqlkvtm6n6Z1gpgZoxw%3D%3D",
    },
    {
      URL: "https://storage.googleapis.com/ugcai-app.appspot.com/music/preset02.svg?GoogleAccessId=firebase-adminsdk-a73t4%40ugcai-app.iam.gserviceaccount.com&Expires=16447017600&Signature=nSVdnsmV%2BiBTpP4MBiYXzAzBRg5U2jJs0rc9YhZAOGQRxwbZotwu8kzYweMWg%2FlxFOFZbUtxJToUKLLRHN4f1fIk90c6coZSmHm8pyJ4he43GGt3J59o2hDyx5ory3ph6mjW8LfDX%2FDIaKUjOlYfCAh3jQ0Cw2HmIoaXPabNcQ%2FJH07LbJPdOMW%2BdSML3htosQxPgDs%2Fa7inwwyzzLcobx6ryIQEbXGXGRaL6T0vqBD3kxBuWcfnX4JR%2FzXiLDiEWOHUsKrVBLzzSoBRJsZ%2BlC%2BU31CTFuP5MfvaMXMoleEwPf939Ox13iFRfRFl0HhvUBUZVNTpsAZcTOYDP3XiwQ%3D%3D",
    },
    {
      URL: "https://storage.googleapis.com/ugcai-app.appspot.com/music/preset03.svg?GoogleAccessId=firebase-adminsdk-a73t4%40ugcai-app.iam.gserviceaccount.com&Expires=16447017600&Signature=n1o6bkLeg6kYtderAA3lUpGcfljIwFMY8RSriq7Qh3Y3YB9zUmhrZy6vSJsP4Sk2vZk5Ll371tgc1SrAyW7i0Zm6INZz3p2qKdsziFV75VpzGy7CLGG5N8U5D2iZVRqSGGkPaK%2FARAsfyKPXLE9gM5YhuwJM7I92N2at%2FF3yFunbS2PjDT%2FNOx9ucbCJDUDlodiPMJ6kGeo6%2Fbh3LGV%2Fn4ngw54um4sHnvXaXHKigpAPUGUobktFhJZqHiFWr1l%2BuHwsuVH7ukqrtDrVq8u1QApEzVu%2FPEzveFpcjjfOrHyWOqPHdp5b14l8hZXvpBQqJpeIN%2F4z7B8%2BOvxTLcYvDw%3D%3D",
    },
    {
      URL: "https://storage.googleapis.com/ugcai-app.appspot.com/music/preset05.svg?GoogleAccessId=firebase-adminsdk-a73t4%40ugcai-app.iam.gserviceaccount.com&Expires=16447017600&Signature=IHnzWI7OKg0Q%2F6Aoc%2FKQXTHpSF%2Btiii3FFJyKpuJp5k78tMxHR80lAeQeY6Dr8jwK8i%2FUuqLwyq5DLsOKmqRg%2BbudeRW0cQnoggUnI7kBn%2FJGCgJfOMrqucIP4r04y7pctQvD2nEsPhEZl8raUVoYeVwspA980XGqnO%2FAoDEXbyAYSbR8DUqwzlvByv73AYyzHIVXmgjO9Ye1vMqIQU7kDDHJG%2FuRwiVaXfY4znyLb0N%2FQzrcNz8H1WQOB6WUHimEjBRS9r5%2F8QgHrqP0qalm54J7azZcKszRsmGcduIANP5JlZZl2Zza%2BT3fipPRiLC2Lb5J61W8Qn4kYlxlTVGSg%3D%3D",
    },
    {
      URL: "https://storage.googleapis.com/ugcai-app.appspot.com/music/preset06.svg?GoogleAccessId=firebase-adminsdk-a73t4%40ugcai-app.iam.gserviceaccount.com&Expires=16447017600&Signature=X7hA9RmeWEdxzraeND3%2F%2FvmEyu7R9JRVEMVGk%2FjCJMXmSsIJwRuX7IoqPZOBa6egoomT5Y8zd9lLT4T2W%2F1tsnQ%2BTCvgGwc14Pe6aO%2BmUTTCkXOEgTovHVtt8IoWYMKO9xm795nnPtDesdJFfUt%2B3dk%2BEmEjdpXgRjKkj2mkhJv0XB73U%2F4Utm3Sa%2BuEWjeL61t6DB34Se54RwYyRMzZr64DgGR%2FAE%2F512GkCd8KaiV20bBU%2BhOywxV%2BBjmDRVRIBAWmkAQDUuOPhjB2iVC930UKCYU4jMQfjKInPmG4H%2FX9QQFJHcteYyublPp%2Bqxf925GfBYWwlYOyuKqfnyv7Gw%3D%3D",
    },
    {
      URL: "https://storage.googleapis.com/ugcai-app.appspot.com/music/preset07.svg?GoogleAccessId=firebase-adminsdk-a73t4%40ugcai-app.iam.gserviceaccount.com&Expires=16447017600&Signature=WToWxxxr6WETzkHCsqZ%2Btzkc%2B1Lp4n%2ByKgpG9GKhhp9bmf2UFsOAbKxDAahQ5OHIjVjJ%2FGdFBgXvdcSlHgWnv%2BD1c1TsPgcofN%2By6c4AbqvOhbdGlOKxctKX%2BifL2MdR1WLgMyZUNPaoFy88IglnTjXnxRtvYxGc4vDLiUOIh4%2BLkzBKmXS8qAygvKOjTkBwEJqa%2Bcwy5Hz2CP7RQSknmB2VwRwPqf6SDjJI1%2FxnoqvXb7qHcitIiQHglUXKbVaxTb9poQxrHyPVdrHt6yv3ib1ckvwdojoAVQZQ%2BCULjvopVjwXX%2BXjRrCHzJypANSuQtB5mSh3cyhbseENx1GBBw%3D%3D",
    },
    {
      URL: "https://storage.googleapis.com/ugcai-app.appspot.com/music/preset08.svg?GoogleAccessId=firebase-adminsdk-a73t4%40ugcai-app.iam.gserviceaccount.com&Expires=16447017600&Signature=UGe2mTwq2mKV3LS5gFx9gCl4rHBhnY1GyN%2FjAY6WHGtYCcDAKQ0IVY6mP0sc%2FHI0rTmIxC50W%2BLhEOAhULrxxpDuwKC2qqXyzO6%2BwB6I36FO0FGmKriihRT73bJx%2BEcxS23raJ6iB9iYbJWGcqUR9W7lRkY4eTagNGZ4KNU1eX0iHuwPN1i4y7uPZfU9e3SONcbKJA%2B1YDgkDGxgBptCKk4wEuTVC%2BsJB4ltI6okRqvVN7sSAGSA1qvkfZXMCCEFf%2BXMPnHawZFpyFpq3yr%2Fl3McDb9xKP%2Fg15wTxqjTnP60oA5VpK8lGIa9ubwGxeTx2lM5%2FZMHtjf0S0p1s02%2BpA%3D%3D",
    },
    {
      URL: "https://storage.googleapis.com/ugcai-app.appspot.com/music/preset09.svg?GoogleAccessId=firebase-adminsdk-a73t4%40ugcai-app.iam.gserviceaccount.com&Expires=16447017600&Signature=dHOcfY6rtOwlNoS838fppfj%2FsjED0KDcgrdgVlEhryYtW7lYj7xCBMfwO4YVaRd%2FBfa%2FIwXdMeeRgeugiwPJkMEUQ5pxyusye9KvlW3u5ED6GFBIOO1Hqjgypq%2BudoyvYiPP2OxS9OOzNOrG5BhmnBwH%2BTaLGNSRuvo4Sxpx9K9f3XODJLwFQDWrNDTex3HQcEtxOkAPBgq2SRjJoq%2B2oautyKRqpCu7It3hHx8iexF7nGsflvVVd%2FZgN9UFLs982Y97cC7z9f3al2qehVRXmebY%2B2534e8HM4x%2FerZzfFqiYTX%2FqmkoxOQYXIeTpMM9WElt3erxOSgiqXt8EZ5okg%3D%3D",
    },
    {
      URL: "https://storage.googleapis.com/ugcai-app.appspot.com/music/preset10.svg?GoogleAccessId=firebase-adminsdk-a73t4%40ugcai-app.iam.gserviceaccount.com&Expires=16447017600&Signature=d4mg4VhxuE5Zuudk5ipgTD1B9ChY46ExenYctdZOvuOdSgHZTClPp2lOd5l1TbJ4mONJJwM2DyF%2FguBGdwFBbgvbFX%2BicNlqWBLSkbPOgy3eUr0WKEucXU12cxaZ3xmLRZMssajSTf92F%2FDEnHZ%2FRH4eiBXEoVawtzfAwNoK4E4tLQoArB6u3hWsDkIvzzmBh%2FEVHDCIY%2F%2BSgIQ7plG1gfm4ww6vPwOQwEE8huA%2Fke%2BO2hZt0i8oRde8yGHYJ76XORX0bX8JcEyK6BK%2BXuv1ZwiHdb%2FWgpnO%2BHQJw8GF3pCNb3CA3rRZWP58nMXhbcnW%2FW8rCdhzFJeWXPTV3Uyzag%3D%3D",
    },
    {
      URL: "https://storage.googleapis.com/ugcai-app.appspot.com/music/preset11.svg?GoogleAccessId=firebase-adminsdk-a73t4%40ugcai-app.iam.gserviceaccount.com&Expires=16447017600&Signature=bb3EJzogCNt2Sq0g6527yHDQhz7YgBLLjxlDnjOSbZsQbf8HaM9WRlk1q%2BnJTZzjIL8ZV3Rwegojw8OSzofqoes63sRH60PXEZihxXM7sbV%2FFjP5hvePj7KliV45UVI5gDciCx22%2BMJ8yUxVplXEE4UucUPJW1U4EjDUF0aqzWBuZdcScp6XK8opqjYmfDtAUgp8EY10gYG0q0pK0p88VNN%2BY%2F18CLbxbnSNFjRMv4PJy%2BVgXkVmmPpo%2BJJSRM7AMYd9xGtPiswKSqd33%2FRv3eyAMuwjyzuBwsbyzSObjSaCmLViVvYtIKou%2FruAiO2afYhMfTKZumkAF5ga9kdzmg%3D%3D",
    },
  ];

  const categories = [
    "All Use Cases",
    "Advertisement",
    "Ecommerce",
    "Learning & Development",
    "Social Media",
  ];

  const items = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 6, 7, 8, 9, 10, 11, 12, 13,
    14,
  ];

  const subtitles = [
    "https://firebasestorage.googleapis.com/v0/b/aishoutouts.appspot.com/o/gifs%2Foutput-onlinegiftools.gif?alt=media&token=10f50a36-43e2-44a7-b2b4-d4c55718e588",
    "https://firebasestorage.googleapis.com/v0/b/aishoutouts.appspot.com/o/gifs%2Foutput-onlinegiftools%20(1).gif?alt=media&token=87ea2441-a5d5-434b-8f2c-64996a95f00c",
    "https://firebasestorage.googleapis.com/v0/b/aishoutouts.appspot.com/o/gifs%2Foutput-onlinegiftools%20(2).gif?alt=media&token=d04e2c60-9039-428f-9e88-0b78f98e8404",
    "https://firebasestorage.googleapis.com/v0/b/aishoutouts.appspot.com/o/gifs%2Foutput-onlinegiftools%20(3).gif?alt=media&token=12f7f232-5dbe-4420-a7b0-dfa20d301c10",
    "https://firebasestorage.googleapis.com/v0/b/aishoutouts.appspot.com/o/gifs%2Foutput-onlinegiftools%20(4).gif?alt=media&token=a2cf126c-84df-47da-8e73-54de51dbca31",
    "https://firebasestorage.googleapis.com/v0/b/aishoutouts.appspot.com/o/gifs%2Foutput-onlinegiftools%20(6).gif?alt=media&token=a86eaf70-4547-4061-8eea-ebbca81eb794",
  ];

  return (
    <MDBRow style={{ marginTop: 75, marginLeft: 0, overflowY: "hidden" }}>
      <MDBCol size="12">
        <p
          style={{
            fontSize: 20,
            color: "#fff",
            fontFamily: "PPBold",
            opacity: 0.9,
          }}
        >
          Add text
        </p>
      </MDBCol>

      <MDBCol style={{ marginTop: 0 }} size="12">
        <div
          style={{
            height: 80,
            width: "95%",
            backgroundColor: "#1f2124",
            borderRadius: 7,
            cursor: "pointer",
          }}
          onClick={() => {
            return addItem({
              // Replace with the URL of the second video
              videoRef: React.createRef(),
              startTime: 0,
              initialStartTime: 0,
              endTime: lastTime,
              initialEndTime: lastTime,
              trimStart: 0,
              trimEnd: lastTime,
              textContent: "Hello there!",
              x: 0,
              y: 0,
              width: "100%",
              height: "100%",
              layerIndex: 2,
              type: "text",
              fontSize: 26,
              color: "black",
              style: {},
              pickerID: uuidv4().replace(/[^a-zA-Z0-9]/g, ""),
            });
          }}
          className="d-flex justify-content-center"
        >
          <p
            style={{
              color: "white",
              fontFamily: "PPBold",
              fontSize: 33,
              marginTop: 16,
              opacity: 0.7,
            }}
          >
            Add Title
          </p>
        </div>
      </MDBCol>

      <MDBCol style={{ marginTop: 20 }} size="12">
        <div
          style={{
            height: 80,
            width: "95%",
            backgroundColor: "#1f2124",
            borderRadius: 7,
            cursor: "pointer",
          }}
          onClick={() => {
            return addItem({
              // Replace with the URL of the second video
              videoRef: React.createRef(),
              startTime: 0,
              initialStartTime: 0,
              endTime: lastTime,
              initialEndTime: lastTime,
              trimStart: 0,
              trimEnd: lastTime,
              textContent: "Hello there!",
              x: 0,
              y: 0,
              width: "100%",
              height: "100%",
              layerIndex: 2,
              type: "text",
              fontSize: 19,
              color: "black",
              style: {},
              pickerID: uuidv4().replace(/[^a-zA-Z0-9]/g, ""),
            });
          }}
          className="d-flex justify-content-center"
        >
          <p
            style={{
              color: "white",
              fontFamily: "PPMedium",
              fontSize: 17,
              marginTop: 30,
              opacity: 0.7,
            }}
          >
            Add Body Text
          </p>
        </div>
      </MDBCol>
      <MDBCol size="12">
        <p
          style={{
            fontSize: 20,
            color: "#fff",
            fontFamily: "PPBold",
            opacity: 0.9,
            marginTop: 20,
          }}
        >
          Choose Subtitle Style
        </p>
      </MDBCol>
      <MDBCol style={{ marginTop: -9 }} size="12">
        <MDBRow
          style={{
            overflowY: "scroll",
            height: "48vh",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            width: "100%",
            paddingBottom: 30,
            WebkitOverflowScrolling: "touch",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {subtitles.map((item, index) => {
            return (
              <MDBCol style={{ marginTop: 10 }} size="6">
                <div
                  style={{
                    width: "9vw",
                    aspectRatio: "1 / 1",
                    borderRadius: 3,
                    cursor: "pointer",
                    backgroundColor: "#1f2124",
                  }}
                  className="d-flex justify-content-center"
                  onClick={() => {
                    setVideoItems((prevItems) => {
                      const existingSubtitleItem = prevItems.find(
                        (item) => item.type === "subtitle"
                      );
                      if (existingSubtitleItem) {
                        const updatedSubtitleItem = {
                          ...existingSubtitleItem,
                          // Update only the properties that need to change
                          videoRef: React.createRef(),
                          startTime: 0,
                          initialStartTime: 0,
                          endTime: lastTime,
                          initialEndTime: lastTime,
                          trimStart: 0,
                          trimEnd: lastTime,
                          subtitleType: `type${index + 1}`,
                          fontSize: 26,
                          color: "black",
                          thumbnail: item,
                          transparentThumbnail: item,
                          pickerID: uuidv4().replace(/\[^a-zA-Z0-9\]/g, ""),
                        };
                        return prevItems.map((item) =>
                          item.type === "subtitle" ? updatedSubtitleItem : item
                        );
                      } else {
                        return [
                          ...prevItems,
                          {
                            // Replace with the URL of the second video
                            videoRef: React.createRef(),
                            startTime: 0,
                            initialStartTime: 0,
                            endTime: lastTime,
                            initialEndTime: lastTime,
                            trimStart: 0,
                            trimEnd: lastTime,
                            x: "50%",
                            y: "50%",
                            width: "100%",
                            height: "100%",
                            layerIndex: 2,
                            type: "subtitle",
                            subtitleType: `type${index + 1}`,
                            fontSize: 26,
                            color: "black",
                            thumbnail: item,
                            transparentThumbnail: item,
                            pickerID: uuidv4().replace(/\[^a-zA-Z0-9\]/g, ""),
                          },
                        ];
                      }
                    });
                  }}
                >
                  <img
                    src={item}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      marginTop: "0%",
                    }}
                  />
                </div>
              </MDBCol>
            );
          })}
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(TextSection);
