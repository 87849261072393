import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { LineWave, TailSpin } from "react-loader-spinner";
import Step1 from "./Step1";
import Step2Ecom from "./Step2Ecom";
import Step3Ecom from "./Step3Ecom";
import Step4Ecom from "./Step4Ecom";
import Step2Digital from "./Step2Digital";
import Step3Digital from "./Step3Digital";
import Step4Digital from "./Step4Digital";
import { useAuth } from "../Context/AuthContext";
import { IoIosCheckmarkCircle } from "react-icons/io";

const VerifyingModal = ({
  verifyingModalOpen,
  setVerifyingModalOpen,
  setIsOpen,
  isOpen,
  videoID,
  currentCampaign,
}) => {
  const { t } = useTranslation();
  const { fbApproved, verifying, currentPlan } = useAuth();

  const [section, setSection] = useState("Step1");

  useEffect(() => {
    const uid = firebase.auth().currentUser.uid;
    if (verifying && fbApproved !== null) {
      setTimeout(() => {
        firebase.firestore().collection("users").doc(uid).update({
          verifying: false,
        });
      }, 10000);
    }
  }, [fbApproved, verifying]);

  const handleFacebookLogin = () => {
    const uid = firebase.auth().currentUser.uid;
    window.location.href = `https://us-central1-ugcai-app.cloudfunctions.net/facebookLogin?uid=${uid}`;
  };

  const renderSection = () => {
    if (verifying) {
      return (
        <>
          {" "}
          <MDBCol className="d-flex justify-content-center" size="12">
            <LineWave
              height="120"
              width="120"
              color="#ed1165"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{ marginTop: -50 }}
              wrapperClass=""
              visible={true}
            />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p style={{ fontFamily: "SSMedium", fontSize: 23 }}>
              Analyzing ad data
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                fontFamily: "SSRegular",
                fontSize: 17,
                textAlign: "center",
                width: 350,
              }}
            >
              We are reviewing your ad account to determine the most suitable
              video plan. This will take 30 seconds.
            </p>
          </MDBCol>
        </>
      );
    } else if (!verifying && fbApproved) {
      return (
        <>
          {" "}
          <MDBCol className="d-flex justify-content-center" size="12">
            <IoIosCheckmarkCircle
              style={{
                marginTop: 20,
                fontSize: 40,
              }}
              color="#ed1165"
            />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p style={{ fontFamily: "SSMedium", fontSize: 23, marginTop: 10 }}>
              You're ready to go!
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                fontFamily: "SSRegular",
                fontSize: 17,
                textAlign: "center",
                width: 350,
              }}
            >
              We've analyzed your ad account, and our creators are ready to
              start making winning video ads to help you succeed. You are on the{" "}
              <p
                className="d-inline"
                style={{
                  fontFamily: "SSBold",
                }}
                onClick={() => handleFacebookLogin()}
              >
                {currentPlan?.name} Plan
              </p>{" "}
              so you'll be receiving {currentPlan?.monthlyQuota} videos every
              month.
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <div
              style={{
                height: 55,
                width: 410,
                backgroundColor: "#ed1165",
                borderRadius: 7,
                marginTop: 20,
                textAlign: "center",
                fontFamily: "SSRegular",
                color: "white",
                fontSize: 18,
                paddingTop: 14,
                cursor: "pointer",
                opacity: 1,
              }}
              onClick={() => {
                setVerifyingModalOpen(false);
              }}
              className="d-flex justify-content-center"
            >
              <p>Get started</p>
            </div>
          </MDBCol>
        </>
      );
    } else if (!verifying && !fbApproved) {
      return (
        <>
          {" "}
          <MDBCol className="d-flex justify-content-center" size="12">
            <RiCloseLine
              style={{ marginTop: 20, fontSize: 40, color: "red" }}
            />
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p style={{ fontFamily: "SSMedium", fontSize: 23, marginTop: 10 }}>
              Insufficient spend
            </p>
          </MDBCol>
          <MDBCol className="d-flex justify-content-center" size="12">
            <p
              style={{
                fontFamily: "SSRegular",
                fontSize: 17,
                textAlign: "center",
                width: 350,
              }}
            >
              Unfortunately we can't support your ad account due to insufficient
              spend. If you have another ad account,{" "}
              <p
                className="d-inline"
                style={{
                  color: "#ed1165",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => handleFacebookLogin()}
              >
                try connecting again.
              </p>{" "}
              If you still wish to work with{" "}
              <p
                style={{
                  color: "#ed1165",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                className="d-inline"
              >
                get in touch.
              </p>
            </p>
          </MDBCol>
        </>
      );
    }
  };

  return (
    <>
      <SkyLightStateless
        hideOnOverlayClicked={true}
        onOverlayClicked={async () => {
          if (!verifying && fbApproved) {
            return false;
          } else {
            return null;
          }
        }}
        onCloseClicked={async () => {
          if (!verifying && fbApproved) {
            return false;
          } else {
            return null;
          }
        }}
        isVisible={verifyingModalOpen}
        overlayStyles={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100%",
          zIndex: 9000,
          opacity: 0.6,
          backgroundColor: "rgba(255, 255, 255, 0.1)", // Semi-transparent background
          backdropFilter: "blur(10px)", // Blur effect for the background elements
        }}
        dialogStyles={{
          zIndex: 9000,
          position: "fixed",
          width: "100vw",
          minHeight: "60px",
          marginTop: "0%",
          top: "0%",
          height: "100vh",
          boxShadow: "none",
          left: "0%",
          right: "0%",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "rgba(255, 255, 255, 0.04)", // Semi-transparent white for the glass effect
          border: "1px solid transparent", // Slight border for depth
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for more depth
          backdropFilter: "blur(6px)", // Apply blur to the dialog for the glass effect
        }}
        closeButtonStyle={{
          color: "white",
          right: 650,
          position: "absolute",
          marginTop: 90,
          zIndex: 9600,
          fontSize: 50,
          height: 60,
          width: 60,
          display: "none",
        }}
      >
        <OutsideClickHandler
          onOutsideClick={async () => {
            return null;
          }}
        >
          <div
            style={{
              paddingBottom: 40,
              width: 480,
              backgroundColor: "#fff",
              borderRadius: 13,
              zIndex: 90000,
              top: "15%",
              position: "absolute",
              left: "0%",
              right: "0%",
              marginLeft: "auto",
              marginRight: "auto",
              border: "1px solid rgb(234, 236, 240)",
              paddingLeft: 0,
              paddingTop: 30,
            }}
            className="backy60"
          >
            <MDBRow>{renderSection()}</MDBRow>
          </div>
        </OutsideClickHandler>
      </SkyLightStateless>
    </>
  );
};

export default withRouter(VerifyingModal);
