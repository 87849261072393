import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./i18n/en.json";
import translationGB from "./i18n/gb.json";
import translationAUS from "./i18n/aus.json";
import translationCAD from "./i18n/cad.json";
import translationKO from "./i18n/ko.json";
import translationJA from "./i18n/jp.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xscs
  },
});

export default i18n;
