import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import "react-circular-progressbar/dist/styles.css";
import { BiRightArrowAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { firebase } from "../../../firebase/config";
import { TailSpin } from "react-loader-spinner";
import mixpanel from "mixpanel-browser";

const Section2 = ({ requestLoading }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [selectedPackage, setSelectedPackage] = useState(2);
  const { t, i18n } = useTranslation();

  const goBack = () => {
    history.goBack();
  };

  if (requestLoading === true) {
    return null;
  }

  const calculatePrice = (selectedPackage) => {
    switch (selectedPackage) {
      case 1:
        return t("priceString1");
      case 2:
        return t("priceString2");
      case 3:
        return t("priceString3");
    }
  };

  const trackCheckoutEvent = (selectedPackage) => {
    let priceValue;

    switch (selectedPackage) {
      case 1:
        priceValue = 10;
        break;
      case 2:
        priceValue = 15;
        break;
      case 3:
        priceValue = 25;
        break;
      default:
        // Handle unexpected package numbers or add a default value if needed
        console.error("Invalid package selected");
        return;
    }

    // Assuming mixpanel is already initialized and available
    mixpanel.track("Started Checkout", { price: priceValue });
  };

  const startPayment = async () => {
    setLoading(true);
    trackCheckoutEvent(selectedPackage);

    // Determine the current language from i18n
    const currentLanguage = i18n.language;

    // Define price mapping based on the language
    const prices = {
      en: [10, 15, 20], // Prices in USD
      ko: [26500, 33000, 39000], // Prices in KRW
      ja: [3000, 3800, 4400], // Prices in JPY
    };

    // Determine the currency based on the language
    const currencies = {
      en: "USD",
      ko: "KRW",
      ja: "JPY",
    };

    // Select price and currency based on the selected package and current language
    let price = prices[currentLanguage][selectedPackage - 1];
    let currency = currencies[currentLanguage];

    const uid = firebase.auth().currentUser.uid;
    const successUrlBase = window.location.origin;
    const successUrlParams = `?price=${price}&currency=${currency}`;
    const successUrl = successUrlBase + successUrlParams;
    const cancelUrl = window.location.origin + "/checkout";

    const docRef = await firebase
      .firestore()
      .collection("customers")
      .doc(uid)
      .collection("checkout_sessions")
      .add({
        mode: "payment",
        price: calculatePrice(selectedPackage), // One-time price created in Stripe
        success_url: successUrl,
        cancel_url: cancelUrl,
        automatic_tax: true,
        allow_promotion_codes: true,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  return (
    <>
      <MDBCol className="d-flex justify-content-center" size="12">
        <p
          style={{
            display: "inline-block",
            fontFamily: "MADemiBold",
            fontSize: 18,
            color: "black",
          }}
        >
          {t("pricingTopText")}
        </p>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <p
          style={{
            display: "inline-block",
            fontFamily: "MAHeavy",
            fontSize: 35,
            marginTop: -17,
          }}
        >
          {t("pricingTitle")}
        </p>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <p
          style={{
            display: "inline-block",
            fontFamily: "MABold",
            fontSize: 18,
            textAlign: "center",
            marginTop: -15,
          }}
        >
          {t("pricingSubtitle")}
        </p>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <div
          style={{
            height: 80,
            width: 680,
            backgroundColor: selectedPackage === 1 ? "#A0A0A0" : "white",
            borderRadius: 6,
            border:
              selectedPackage === 1 ? "1px solid black" : "1px solid black",
            paddingLeft: 20,
            paddingRight: 40,
            cursor: "pointer",
          }}
          onClick={() => setSelectedPackage(1)}
          className="d-flex justify-content-between"
        >
          <div>
            <p
              style={{
                fontFamily: "MAHeavy",
                color: "black",
                marginTop: 17,
                fontSize: 18,
              }}
            >
              {t("package1Title")}
            </p>
            <p
              style={{
                fontFamily: "MARegular",
                color: "black",
                marginTop: -16,
                fontSize: 14,
              }}
            >
              {t("package1Subtitle")}
            </p>
          </div>
          <p
            style={{
              fontFamily: "MAHeavy",
              fontSize: 24,
              color: "black",
              marginTop: 20,
            }}
          >
            {t("package1Price")}
          </p>
        </div>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <div
          style={{
            height: 80,
            width: 680,
            backgroundColor: selectedPackage === 2 ? "#A0A0A0" : "white",
            borderRadius: 6,
            border:
              selectedPackage === 2 ? "1px solid black" : "1px solid black",
            cursor: "pointer",
            paddingLeft: 20,
            paddingRight: 40,
            marginTop: 15,
          }}
          onClick={() => setSelectedPackage(2)}
          className="d-flex justify-content-between"
        >
          <div>
            <p
              style={{
                fontFamily: "MAHeavy",
                color: "black",
                marginTop: 17,
                fontSize: 18,
              }}
            >
              {t("package2Title")}
            </p>
            <p
              style={{
                fontFamily: "MARegular",
                color: "black",
                marginTop: -16,
                fontSize: 14,
              }}
            >
              {t("package2Subtitle")}
            </p>
          </div>
          <p
            style={{
              fontFamily: "MAHeavy",
              fontSize: 24,
              color: "black",
              marginTop: 20,
            }}
          >
            {t("package2Price")}
          </p>
        </div>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <div
          style={{
            height: 80,
            width: 680,
            backgroundColor: selectedPackage === 3 ? "#A0A0A0" : "white",
            borderRadius: 6,
            border:
              selectedPackage === 3 ? "1px solid black" : "1px solid black",
            cursor: "pointer",
            paddingLeft: 20,
            paddingRight: 40,
            marginTop: 15,
          }}
          onClick={() => setSelectedPackage(3)}
          className="d-flex justify-content-between"
        >
          <div>
            <p
              style={{
                fontFamily: "MAHeavy",
                color: "black",
                marginTop: 17,
                fontSize: 18,
              }}
            >
              {t("package3Title")}
            </p>
            <p
              style={{
                fontFamily: "MADemiBold",
                opacity: 0.9,
                color: "black",
                marginTop: -16,
                fontSize: 14,
              }}
            >
              {t("package3Subtitle")}
            </p>
          </div>
          <p
            style={{
              fontFamily: "MAHeavy",
              fontSize: 24,
              color: "black",
              marginTop: 20,
            }}
          >
            {t("package3Price")}
          </p>
        </div>
      </MDBCol>
      <MDBCol
        style={{ marginTop: 20 }}
        className="d-flex justify-content-center"
        size="12"
      >
        <div>
          <div
            style={{
              height: 10,
              width: 10,
              backgroundColor: "#f8284e",
              borderRadius: 200,
              display: "inline-block",
              opacity: 0.4,
            }}
          ></div>
          <div
            style={{
              height: 10,
              width: 10,
              backgroundColor: "#f8284e",
              borderRadius: 200,
              display: "inline-block",
              marginLeft: 10,
              opacity: 1,
            }}
          ></div>
          <div
            style={{
              height: 10,
              width: 10,
              backgroundColor: "#f8284e",
              borderRadius: 200,
              display: "inline-block",
              marginLeft: 10,
              opacity: 0.4,
            }}
          ></div>
        </div>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <div
          onClick={() => {
            startPayment();
          }}
          style={{
            height: 54,
            width: 370,
            border: "2px solid #f8284e",
            borderRadius: 10,
            marginTop: 19,
            backgroundColor: "#f8284e",
            cursor: "pointer",
            opacity: 1,
          }}
          className="d-flex justify-content-center"
        >
          {loading ? (
            <TailSpin
              height={30}
              width={30}
              color="white"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{ marginTop: 10 }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            <p
              style={{
                fontFamily: "MABold",
                fontSize: 16,
                display: "inline-block",
                color: "white",
                marginLeft: 0,
                marginTop: 13,
                zIndex: 300,
              }}
            >
              {t("checkoutContinue")}
              <BiRightArrowAlt
                style={{
                  marginTop: -3.1,
                  fontSize: 18,
                  color: "#dfc8e7",
                  marginLeft: 9,
                }}
                className="d-inline"
              />
            </p>
          )}
        </div>
      </MDBCol>
    </>
  );
};

export default withRouter(Section2);
