import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { BsPauseCircleFill, BsPlayCircleFill } from "react-icons/bs";
import Carousel from "./Carousel";
import { ReactComponent as Flowy } from "./modern.svg";
import ReactPlayer from "react-player";

const numOfItems = [1, 2, 3, 4, 5, 6];
const Section2 = () => {
  const history = useHistory();
  return (
    <MDBCol size="12" className="d-flex justify-content-center">
      <div
        style={{
          height: 460,
          width: 1100,
          border: "1px solid #eaecf0",
          marginTop: 70,
          borderRadius: 9,
          marginBottom: 50,
        }}
      >
        <MDBRow style={{ fontFamily: "SSRegular" }}>
          <MDBCol style={{ paddingLeft: 50 }} size="6">
            <p style={{ fontSize: 31, lineHeight: 1.2, marginTop: 90 }}>
              Explore{" "}
              <p style={{ fontStyle: "italic" }} className="d-inline">
                proven
              </p>{" "}
              UGC templates, tested on TikTok and Facebook, and easy to
              customize.
            </p>
            <p
              style={{
                fontSize: 18,
                lineHeight: 1.7,
                marginTop: 20,
                width: 400,
                marginLeft: 5,
              }}
            >
              Get inspired by our tested TikTok and Facebook UGC templates.
              Customize them for your product in just a few clicks.
            </p>
            <div
              style={{
                height: 50,
                width: 240,
                backgroundColor: "blue",
                color: "white",
                textAlign: "center",
                borderRadius: 7,
                fontFamily: "SSMedium",
                paddingTop: 13,
                marginTop: 30,
                cursor: "pointer",
              }}
              onClick={() => history.push("/auth")}
            >
              <p>Create your first ad</p>
            </div>
          </MDBCol>
          <MDBCol size="6">
            <div
              className="d-flex justify-content-center"
              style={{ width: "100%", textAlign: "center" }}
            >
              <p style={{ marginTop: 40 }}>Ads by RowAds</p>
            </div>
            <div
              style={{ width: "100%", textAlign: "center" }}
              className="d-flex justify-content-center"
            >
              <div
                style={{
                  height: 300,
                  width: "90%",
                  backgroundColor: "grey",
                  borderRadius: 7,
                }}
              ></div>
            </div>
            <div
              style={{ width: "100%", textAlign: "center" }}
              className="d-flex justify-content-center"
            >
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  marginTop: 26,
                  justifyContent: "space-evenly",
                  fontFamily: "SSMedium",
                }}
              >
                <p style={{ opacity: 1, cursor: "pointer" }}>SaaS</p>
                <p style={{ opacity: 0.5, cursor: "pointer" }}>Gaming</p>
                <p style={{ opacity: 0.5, cursor: "pointer" }}>Ecommerce</p>
                <p style={{ opacity: 0.5, cursor: "pointer" }}>Apps</p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    </MDBCol>
  );
};

export default withRouter(Section2);
