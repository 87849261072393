import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import DashboardFooter from "../Layout/DashboardFooter";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import Heading from "./Heading";
import Explainer from "./Explainer";
import { TailSpin } from "react-loader-spinner";
import { useAuth } from "../Context/AuthContext";

const CreateClone = ({
  email,
  popUpVisible,
  setPopUpVisible,
  ref,
  seconds,
}) => {
  const [requestLoading, setRequestLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const { paid, credits } = useAuth();

  const history = useHistory();

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#E8E8E8",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: "30vh",
            }}
            size="12"
          >
            <MDBRow style={{ marginTop: 0 }}>
              <DashboardNavBar
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
                email={email}
                seconds={seconds}
                ref={ref}
              />
            </MDBRow>

            {credits === null ? (
              <div
                className="d-flex justify-content-center"
                style={{ height: "100vh" }}
              >
                <TailSpin
                  height="50"
                  width="50"
                  color="#fff"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{ marginTop: 340 }}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <Heading />
                </MDBCol>
                <Explainer
                  loading={loading}
                  setLoading={setLoading}
                  credits={credits}
                />
              </>
            )}
          </MDBCol>
          <DashboardFooter />
        </div>
      </div>
    </>
  );
};

export default withRouter(CreateClone);
