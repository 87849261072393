import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";

import "react-phone-number-input/style.css";
import MainMenu from "../Layout/MainMenu";
import { FaExternalLinkAlt, FaRegCompass, FaRegStar } from "react-icons/fa";
import { BsBackpack } from "react-icons/bs";
import { IoFilterSharp } from "react-icons/io5";
import SideNav from "../Layout/SideNav";
import TopBar from "../Layout/TopBar";
import Categories from "./Categories";
import Templates from "./Templates";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useAuth } from "../Context/AuthContext";

const TeamSettings = () => {
  const categories = [1, 2, 3, 4, 5, 6, 7, 9, 3, 4, 5, 6, 7, 9];
  const [companyName, setCompanyName] = useState("");
  const { members } = useAuth();

  return (
    <div
      style={{
        width: 1300,
        height: 250,
        backgroundColor: "white",
        marginTop: 50,
        borderRadius: 10,
        boxShadow:
          "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
        display: "flex",
        paddingTop: 30,
        color: "black",
        border: "1px solid #eaecf0",
      }}
    >
      <div
        style={{
          width: 500,
          paddingLeft: 25,
          fontFamily: "SSMedium",
        }}
      >
        <p style={{ fontSize: 19, fontWeight: "700" }}>Team & Billing</p>
        <p style={{ fontSize: 14, marginTop: -12, opacity: 0.7 }}>
          Manage your team and billing settings
        </p>
      </div>
      <div style={{ width: 800 }}>
        <p style={{ fontFamily: "SSMedium", fontSize: 14 }}>Billing</p>
        <p
          style={{
            textDecoration: "underline",
            fontFamily: "SSMedium",
            fontSize: 14,
            cursor: "pointer",
            marginTop: -7,
          }}
        >
          Open Billing Portal{" "}
          <FaExternalLinkAlt
            style={{ fontSize: 12, marginLeft: 6, marginTop: -2 }}
          />
        </p>
        <p
          style={{
            fontFamily: "SSMedium",
            fontSize: 14,
            marginTop: 17,
          }}
        >
          Team Members
        </p>
        {members.map((member) => {
          return (
            <div
              style={{
                height: 56,
                width: "93%",
                marginLeft: 0,
                border: "1px solid rgba(155, 158, 163, 0.5)",
                borderRadius: 6,
                display: "inline-block",
                color: "#30312c",
                marginTop: -4,
                backgroundColor: "white",
                fontFamily: "SSMedium",
                paddingLeft: 17,
                fontSize: 13,
                paddingTop: 3,

                boxShadow:
                  "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
              }}
            >
              <MDBCol size="12" className="d-flex justify-content-between">
                <div style={{ diplay: "flex" }}>
                  <div
                    style={{
                      height: 20,
                      width: 20,
                      backgroundColor: "blue",
                      borderRadius: 100,
                      textAlign: "center",
                      opacity: 0.7,
                      paddingTop: 2,
                      color: "white",
                      marginTop: 15,
                      marginLeft: -16,
                      opacity: 0.7,
                      fontSize: 10,
                    }}
                  >
                    <p>{member.email.charAt(0).toUpperCase()}</p>
                  </div>
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "SSMedium",
                      marginLeft: 7,
                      marginTop: -21,
                      marginLeft: 20,
                      color: "black",
                      fontWeight: "700",
                      opacity: 1,
                    }}
                  >
                    <p
                      style={{
                        fontSize: 14,
                        fontFamily: "SSMedium",
                        marginLeft: 0,
                        marginTop: 0,
                        color: "black",
                        opacity: 0.7,
                      }}
                    >
                      {member.email}
                    </p>
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      fontFamily: "SSMedium",
                      fontSize: 15,
                      fontWeight: "700",
                      marginRight: 20,
                      marginTop: 14,
                      opacity: member.role === "Owner" ? 0.4 : 1,
                      color: "black",
                      cursor: "pointer",
                    }}
                  >
                    <RiDeleteBin6Line
                      style={{ marginRight: 5, marginTop: -2, fontSize: 16 }}
                    />{" "}
                    Remove
                  </p>
                </div>
              </MDBCol>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withRouter(TeamSettings);
