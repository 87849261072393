import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import { AiOutlinePlayCircle, AiOutlineRight } from "react-icons/ai";
import { useLoading } from "../Context/LoadingContext";
import { BsPlayCircle } from "react-icons/bs";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { BiRightArrowAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import mixpanel from "mixpanel-browser";
import { firebase } from "../../../firebase/config";

const Section1 = ({ requestLoading }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [selectedPackage, setSelectedPackage] = useState(2);
  const { t, i18n } = useTranslation();

  const goBack = () => {
    history.goBack();
  };

  if (requestLoading === true) {
    return null;
  }

  const calculatePrice = (selectedPackage) => {
    switch (selectedPackage) {
      case 1:
        return t("priceString1");
      case 2:
        return t("priceString2");
      case 3:
        return t("priceString3");
    }
  };

  const trackCheckoutEvent = (selectedPackage) => {
    let priceValue;

    switch (selectedPackage) {
      case 1:
        priceValue = 10;
        break;
      case 2:
        priceValue = 15;
        break;
      case 3:
        priceValue = 25;
        break;
      default:
        // Handle unexpected package numbers or add a default value if needed
        console.error("Invalid package selected");
        return;
    }

    // Assuming mixpanel is already initialized and available
    mixpanel.track("Started Checkout", { price: priceValue });
  };

  const startPayment = async () => {
    setLoading(true);
    trackCheckoutEvent(selectedPackage);

    // Determine the current language from i18n
    const currentLanguage = i18n.language;

    // Define price mapping based on the language
    const prices = {
      en: [10, 15, 20], // Prices in USD
      ko: [26500, 33000, 39000], // Prices in KRW
      ja: [3000, 3800, 4400], // Prices in JPY
    };

    // Determine the currency based on the language
    const currencies = {
      en: "USD",
      ko: "KRW",
      ja: "JPY",
    };

    // Select price and currency based on the selected package and current language
    let price = prices[currentLanguage][selectedPackage - 1];
    let currency = currencies[currentLanguage];

    const uid = firebase.auth().currentUser.uid;
    const successUrlBase = window.location.origin;
    const successUrlParams = `?price=${price}&currency=${currency}`;
    const successUrl = successUrlBase + successUrlParams;
    const cancelUrl = window.location.origin + "/checkout";

    const docRef = await firebase
      .firestore()
      .collection("customers")
      .doc(uid)
      .collection("checkout_sessions")
      .add({
        mode: "payment",
        price: calculatePrice(selectedPackage), // One-time price created in Stripe
        success_url: successUrl,
        cancel_url: cancelUrl,
        automatic_tax: true,
        allow_promotion_codes: true,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  const packages = [
    {
      id: 1,
      credits: t("package1Title"),
      description: t("package1Subtitle"),
      price: t("package1Price"),
      width: "90vw", // Width differs for the first package
    },
    {
      id: 2,
      credits: t("package2Title"),
      description: t("package2Subtitle"),
      price: t("package2Price"),
      width: "90vw",
    },
    {
      id: 3,
      credits: t("package3Title"),
      description: t("package3Subtitle"),
      price: t("package3Price"),
      width: "90vw",
    },
  ];

  return (
    <>
      <MDBCol className="d-flex justify-content-center" size="12">
        <p
          style={{
            display: "inline-block",
            fontFamily: "MADemiBold",
            fontSize: "3.7vw",
            color: "black",
            marginTop: 20,
          }}
        >
          {t("pricingTopText")}
        </p>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <p
          style={{
            display: "inline-block",
            fontFamily: "MAHeavy",
            fontSize: "6.4vw",
            marginTop: -9,
            textAlign: "center",
            lineHeight: 1.2,
            color: "black",
          }}
        >
          {t("pricingTitle")}
        </p>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <p
          style={{
            display: "inline-block",
            fontSize: "3.5vw",
            textAlign: "center",
            marginTop: -14,
            fontFamily: "MADemiBold",
            color: "black",
          }}
        >
          {t("pricingSubtitle")}
        </p>
      </MDBCol>
      {packages.map((pkg) => (
        <MDBCol className="d-flex justify-content-center" size="12">
          <div
            style={{
              paddingBottom: 10,
              width: pkg.width,
              backgroundColor: selectedPackage === pkg.id ? "#A0A0A0" : "white",
              borderRadius: 6,
              border: "1px solid black",
              paddingLeft: 20,
              paddingRight: 20,
              cursor: "pointer",
              marginTop: pkg.id === 1 ? 0 : 15, // Adjust top margin for the first package
            }}
            onClick={() => setSelectedPackage(pkg.id)}
            className="d-flex justify-content-between"
          >
            <div>
              <p
                style={{
                  fontFamily: "MAHeavy",
                  color: "black",
                  marginTop: 26,
                  fontSize: "4vw",
                }}
              >
                {pkg.credits}
              </p>
              <p
                style={{
                  fontFamily: "MARegular",
                  color: "black",
                  marginTop: -16,
                  fontSize: "3.4vw",
                  width: "60vw",
                }}
              >
                {pkg.description}
              </p>
            </div>
            <p
              style={{
                fontFamily: "MAHeavy",
                fontSize: "5vw",
                marginTop: 31,
                color: "black",
              }}
            >
              {pkg.price}
            </p>
          </div>
        </MDBCol>
      ))}
      <MDBCol
        style={{ marginTop: 20 }}
        className="d-flex justify-content-center"
        size="12"
      >
        <div>
          <div
            style={{
              height: "2.6vw",
              width: "2.6vw",
              backgroundColor: "#f8284e",
              borderRadius: 200,
              display: "inline-block",
              marginLeft: 10,
              opacity: 1,
            }}
          ></div>
          <div
            style={{
              height: "2.6vw",
              width: "2.6vw",
              backgroundColor: "#f8284e",
              borderRadius: 200,
              display: "inline-block",
              marginLeft: 10,
              opacity: 0.4,
            }}
          ></div>
          <div
            style={{
              height: "2.6vw",
              width: "2.6vw",
              backgroundColor: "#f8284e",
              borderRadius: 200,
              display: "inline-block",
              marginLeft: 10,
              opacity: 0.4,
            }}
          ></div>
        </div>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <div
          onClick={() => {
            startPayment();
          }}
          style={{
            height: 54,
            width: "75vw",
            border: "2px solid #f8284e",
            borderRadius: 8,
            marginTop: 19,
            backgroundColor: "#f8284e",
            cursor: 1,
          }}
          className="d-flex justify-content-center"
        >
          {loading ? (
            <TailSpin
              height={30}
              width={30}
              color="white"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{ marginTop: 10 }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            <p
              style={{
                fontFamily: "MABold",
                fontSize: 16,
                display: "inline-block",
                color: "white",
                marginLeft: 0,
                marginTop: 13,
                zIndex: 300,
              }}
            >
              {t("checkoutContinue")}
              <BiRightArrowAlt
                style={{
                  marginTop: -3.1,
                  fontSize: 18,
                  color: "#dfc8e7",
                  marginLeft: 9,
                }}
                className="d-inline"
              />
            </p>
          )}
        </div>
      </MDBCol>
    </>
  );
};

export default withRouter(Section1);
