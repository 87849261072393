import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBAnimation,
  MDBIcon,
  MDBTooltip,
} from "mdbreact";
import { firebase } from "../../../firebase/config";
import "../index.css";
import "./index.css";
import { NavLink } from "react-router-dom";
import {
  RiHomeSmile2Line,
  RiApps2Line,
  RiStore2Line,
  RiMoneyDollarBoxLine,
  RiSettings4Line,
  RiLogoutBoxLine,
  RiKey2Line,
  RiKeyFill,
  RiKey2Fill,
  RiTestTubeFill,
} from "react-icons/ri";
import { HiCash } from "react-icons/hi";
import { BiSearch, BiCart } from "react-icons/bi";
import { IoRadioSharp } from "react-icons/io5";
import { FaQuestion, FaTeethOpen } from "react-icons/fa";
class MenuDesktop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "Settings",
      isNavbar: true,
    };
  }

  render() {
    return (
      <>
        <MDBCol
          className="d-flex justify-content-between"
          style={{
            backgroundColor:
              "linear-gradient(90deg, rgb(244, 244, 243) 50%, rgb(255, 255, 255) 50%)",
            height: 86,
          }}
          size="12"
        >
          <NavLink to="/" exact onClick={() => window.scrollTo(0, 0)}>
            <p
              style={{
                fontFamily: "Grifter",
                color: "#2aa803",
                fontSize: 25,
                fontWeight: "900",
                marginTop: 25,
                display: "inline-block",
                marginLeft: 85,
              }}
            >
              orderless
            </p>
          </NavLink>

          <div style={{ display: "inline-block" }}>
            <NavLink to="/pricing" exact onClick={() => window.scrollTo(0, 0)}>
              {" "}
              <p
                style={{
                  cursor: "pointer",
                  fontFamily: "PlusJSMedium",
                  fontSize: 14,
                  marginTop: 38,
                  display: "inline-block",
                  marginLeft: 23,
                  marginRight: 23,
                  color: this.props.color === "dark" ? "white" : "#30312c",
                }}
              ></p>
            </NavLink>
            <NavLink to="/about" exact onClick={() => window.scrollTo(0, 0)}>
              <p
                style={{
                  color: this.props.color === "dark" ? "white" : "#30312c",
                  cursor: "pointer",
                  fontFamily: "PlusJSMedium",
                  fontSize: 14,
                  marginTop: 38,
                  display: "inline-block",
                  marginLeft: 23,
                  marginRight: 23,
                }}
              ></p>
            </NavLink>
            <NavLink to="/careers" exact onClick={() => window.scrollTo(0, 0)}>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  fontSize: 14,
                  marginTop: 38,
                  display: "inline-block",
                  marginLeft: 23,
                  marginRight: 23,
                  cursor: "pointer",
                  color: this.props.color === "dark" ? "white" : "#30312c",
                }}
              ></p>
            </NavLink>
            <NavLink to="/sign-in" exact onClick={() => window.scrollTo(0, 0)}>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  fontSize: 14,
                  marginTop: 36,
                  display: "inline-block",
                  marginLeft: 23,
                  marginRight: 23,
                  color: this.props.color === "dark" ? "white" : "#30312c",
                }}
              ></p>
            </NavLink>
            <a
              target="_blank"
              href="https://wa.link/mffqva"
              className={`menuButtonMobile ${
                this.props.color === "dark"
                  ? "menuButtonDark"
                  : "menuButtonLight"
              }`}
            >
              <p className="menuButtonText">Get started</p>
            </a>
          </div>
        </MDBCol>
      </>
    );
  }
}

export default MenuDesktop;
