import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen, HiSwitchHorizontal } from "react-icons/hi";
import { BiColorFill, BiHelpCircle, BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSignUpModal } from "../Context/SignUpModal";
import { RiCloseFill, RiRedPacketLine, RiTrophyLine } from "react-icons/ri";
import {
  FiCheck,
  FiCopy,
  FiCreditCard,
  FiHeadphones,
  FiMail,
  FiPlus,
  FiShoppingBag,
} from "react-icons/fi";
import { FaLink, FaRegBuilding, FaRegImage } from "react-icons/fa";
import { useDetectClickOutside } from "react-detect-click-outside";
import { AiOutlineLogout } from "react-icons/ai";
import { useAuth } from "../Context/AuthContext";
import { HexColorPicker } from "react-colorful";

const ColorPicker = ({
  colorPickerOpen,
  setColorPickerOpen,
  selectedColor,
  setSelectedColor,
}) => {
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (colorPickerOpen) {
        setColorPickerOpen(false);
      }
    },
  });

  return (
    <>
      <div
        ref={ref}
        style={{
          width: 200,
          borderRadius: 8,
          boxShadow:
            "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          display: "flex",
          animation: colorPickerOpen ? "popUp 0.3s ease-in-out" : "none",
          visibility: colorPickerOpen ? "visible" : "hidden",
          position: "absolute",
          marginTop: 240,
          zIndex: 9000,
          border: "none",
          marginLeft: -80,
        }}
        className={`backy60 ${colorPickerOpen ? "popUpAnimation" : ""}`}
      >
        <HexColorPicker color={selectedColor} onChange={setSelectedColor} />;
      </div>
    </>
  );
};

export default withRouter(ColorPicker);
