import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { TailSpin } from "react-loader-spinner";
import Step1 from "./Step1";
import Step2Ecom from "./Step2Ecom";
import Step3Ecom from "./Step3Ecom";
import Step4Ecom from "./Step4Ecom";
import Step2Digital from "./Step2Digital";
import Step3Digital from "./Step3Digital";
import Step4Digital from "./Step4Digital";

const BriefModal = ({ setIsOpen, isOpen, videoID, currentCampaign }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [productType, setProductType] = useState("Ecommerce");
  const [section, setSection] = useState("Step1");
  const [campaignName, setCampaignName] = useState("");
  const [productURL, setProductURL] = useState("");
  const [iosURL, setIOSURL] = useState("");
  const [androidURL, setAndroidURL] = useState("");
  const [description, setDescription] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  useEffect(() => {
    if (isOpen && !currentCampaign) {
      setSection("Step1");
      setProductType("Ecommerce");
      setCampaignName("");
      setProductURL("");
      setIOSURL("");
      setAndroidURL("");
      setDescription("");
      setLoginEmail("");
      setLoginPassword("");
    } else if (isOpen && currentCampaign) {
      setSection("Step1");
      firebase
        .firestore()
        .collection("campaigns")
        .doc(currentCampaign)
        .get()
        .then((doc) => {
          setProductType(doc.data().productType);
          setCampaignName(doc.data().campaignName);
          setProductURL(doc.data().productURL);
          setIOSURL(doc.data().iosURL);
          setAndroidURL(doc.data().androidURL);
          setDescription(doc.data().description);
          setLoginEmail(doc.data().loginEmail);
          setLoginPassword(doc.data().loginPassword);
        });
    }
  }, [isOpen, currentCampaign]);

  const createCampaign = async () => {
    if (currentCampaign) {
      return editCampaign();
    } else {
      const uid = firebase.auth().currentUser.uid;
      let campaignData = {
        uid,
        campaignName,
        productType,
        productURL,
        description,
        status: "INACTIVE",
        priority: "HIGH",
        videos: 0,
      };

      if (productType === "Digital") {
        campaignData = {
          ...campaignData,
          iosURL,
          androidURL,
          loginEmail,
          loginPassword,
        };
      }

      const docRef = await firebase
        .firestore()
        .collection("campaigns")
        .add(campaignData);
      await docRef.update({ campaignID: docRef.id });

      setIsOpen(false);
    }
  };

  const editCampaign = async () => {
    if (currentCampaign) {
      if (productType === "Ecommerce") {
        await firebase
          .firestore()
          .collection("campaigns")
          .doc(currentCampaign)
          .update({
            campaignName,
            productType,
            productURL,
            description,
          });
        setIsOpen(false);
      } else if (productType === "Digital") {
        await firebase
          .firestore()
          .collection("campaigns")
          .doc(currentCampaign)
          .update({
            campaignName,
            productType,
            productURL,
            iosURL,
            androidURL,
            description,
            loginEmail,
            loginPassword,
          });
        setIsOpen(false);
      }
    }
  };

  const renderSection = (section) => {
    const uid = firebase.auth().currentUser.uid;
    switch (section) {
      case "Step1":
        return (
          <Step1
            setSection={setSection}
            productType={productType}
            setProductType={setProductType}
          />
        );
      case "Step2Ecom":
        return (
          <Step2Ecom
            uid={uid}
            campaignName={campaignName}
            setCampaignName={setCampaignName}
            productURL={productURL}
            setProductURL={setProductURL}
            setSection={setSection}
            productType={productType}
            setProductType={setProductType}
          />
        );
      case "Step2Digital":
        return (
          <Step2Digital
            uid={uid}
            campaignName={campaignName}
            setCampaignName={setCampaignName}
            productURL={productURL}
            setProductURL={setProductURL}
            iosURL={iosURL}
            setIOSURL={setIOSURL}
            androidURL={androidURL}
            setAndroidURL={setAndroidURL}
            setSection={setSection}
            productType={productType}
            setProductType={setProductType}
          />
        );
      case "Step3Ecom":
        return (
          <Step3Ecom
            uid={uid}
            setSection={setSection}
            productType={productType}
            description={description}
            setDescription={setDescription}
            setProductType={setProductType}
          />
        );
      case "Step3Digital":
        return (
          <Step3Digital
            uid={uid}
            setSection={setSection}
            productType={productType}
            description={description}
            setDescription={setDescription}
            setProductType={setProductType}
          />
        );
      case "Step4Ecom":
        return (
          <Step4Ecom
            uid={uid}
            setSection={setSection}
            productType={productType}
            setProductType={setProductType}
            createCampaign={createCampaign}
          />
        );
      case "Step4Digital":
        return (
          <Step4Digital
            uid={uid}
            loginEmail={loginEmail}
            setLoginEmail={setLoginEmail}
            loginPassword={loginPassword}
            setLoginPassword={setLoginPassword}
            setSection={setSection}
            productType={productType}
            setProductType={setProductType}
            createCampaign={createCampaign}
          />
        );
    }
  };

  return (
    <>
      <SkyLightStateless
        hideOnOverlayClicked={true}
        onOverlayClicked={async () => {
          setIsOpen(false);
        }}
        onCloseClicked={async () => {
          setIsOpen(false);
        }}
        isVisible={isOpen}
        overlayStyles={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100%",
          zIndex: 9000,
          opacity: 0.6,
          backgroundColor: "rgba(255, 255, 255, 0.1)", // Semi-transparent background
          backdropFilter: "blur(10px)", // Blur effect for the background elements
        }}
        dialogStyles={{
          zIndex: 9000,
          position: "fixed",
          width: "100vw",
          minHeight: "60px",
          marginTop: "0%",
          top: "0%",
          height: "100vh",
          boxShadow: "none",
          left: "0%",
          right: "0%",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "rgba(255, 255, 255, 0.04)", // Semi-transparent white for the glass effect
          border: "1px solid transparent", // Slight border for depth
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for more depth
          backdropFilter: "blur(6px)", // Apply blur to the dialog for the glass effect
        }}
        closeButtonStyle={{
          color: "white",
          right: 650,
          position: "absolute",
          marginTop: 90,
          zIndex: 9600,
          fontSize: 50,
          height: 60,
          width: 60,
          display: "none",
        }}
      >
        <OutsideClickHandler
          onOutsideClick={async () => {
            setIsOpen(false);
          }}
        >
          <div
            style={{
              paddingBottom: 40,
              width: 480,
              backgroundColor: "#fff",
              borderRadius: 13,
              zIndex: 90000,
              top: "7%",
              position: "absolute",
              left: "0%",
              right: "0%",
              marginLeft: "auto",
              marginRight: "auto",
              border: "1px solid rgb(234, 236, 240)",
              paddingLeft: 30,
              paddingTop: 30,
            }}
            className="backy60"
          >
            <MDBRow>
              <MDBCol className="d-flex justify-content-end" size="12">
                <RiCloseLine
                  onClick={() => setIsOpen(false)}
                  style={{
                    marginRight: 20,
                    fontSize: 30,
                    color: "rgb(102, 112, 133)",
                    cursor: "pointer",
                  }}
                />
              </MDBCol>
              {renderSection(section)}
            </MDBRow>
          </div>
        </OutsideClickHandler>
      </SkyLightStateless>
    </>
  );
};

export default withRouter(BriefModal);
