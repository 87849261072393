import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { useTimer } from "react-use-precision-timer";
import "react-phone-number-input/style.css";
import SideNav from "./SideNav";
import ProjectMenu from "../Layout/ProjectMenu";
import LeftNav from "./LeftNav";
import { firebase } from "../../../firebase/config";
import IconRow from "./IconRow";
import TextBox from "./TextBox";
import PlayerControl from "./PlayerControl";
import Canvas from "./Canvas";
import { IoIosInformationCircle } from "react-icons/io";
import { useAuth } from "../Context/AuthContext";

const Project = () => {
  const [currentTime, setCurrentTime] = useState(0);
  const [startingTime, setStartingTime] = useState(0);
  const [duration, setDuration] = useState(34);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoItems, setVideoItems] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [history, setHistory] = useState([]);
  const [lastTime, setLastTime] = useState(0);
  const [fontArray, setFontArray] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [voiceID, setVoiceID] = useState("");
  const [historyUpdate, setHistoryUpdate] = useState(false);
  const [undoIndex, setUndoIndex] = useState(0);
  const [projectID, setProjectID] = useState("");
  const [projectName, setProjectName] = useState("");
  const [preparing, setPreparing] = useState(true);
  const [audioContext, setAudioContext] = useState(null);
  const { workspaceID } = useAuth();
  const canvasRef = useRef(null);

  useEffect(() => {
    // Create the AudioContext when the component mounts
    const context = new AudioContext();
    setAudioContext(context);

    // Clean up the AudioContext when the component unmounts
    return () => {
      context.close();
    };
  }, []);

  const timer = useTimer({ delay: 1 }, () => null);

  const updateProjectThumbnail = (thumbnail) => {
    if (projectID && thumbnail) {
      firebase.firestore().collection("projects").doc(projectID).update({
        projectThumbnail: thumbnail,
      });
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("projectID");
    const name = searchParams.get("projectName");
    setProjectID(id);
    setProjectName(name);
  }, [location.search]);

  useEffect(() => {
    setVideoItems((prevItems) => {
      return prevItems.map((item) => {
        if (item.type === "subtitle") {
          return {
            ...item,
            startTime: 0,
            endTime: lastTime,
            trimEnd: lastTime,
            initialEndTime: lastTime,
          };
        }
        return item;
      });
    });
  }, [lastTime]);

  useEffect(() => {
    if (videoItems.length > 0) {
      let maxLayerIndex = 0;
      videoItems.forEach((item, index) => {
        if (item.type !== "subtitle") {
          maxLayerIndex = Math.max(maxLayerIndex, item.layerIndex);
        }
      });
    }
  }, [videoItems.length]);

  useEffect(() => {
    if (videoItems.length > 0) {
      let maxEndTime = 0;
      videoItems.forEach((item, index) => {
        if (item.type !== "subtitle") {
          maxEndTime = Math.max(maxEndTime, item.endTime);
        }
      });

      if (maxEndTime === 0) {
        setLastTime(17);
      } else {
        setLastTime(maxEndTime);
      }
    }
  }, [videoItems]);

  useEffect(() => {
    setHistory((prevHistory) => prevHistory.concat([videoItems]));
    setCurrentIndex((prevIndex) => prevIndex + 1);
  }, [videoItems]);

  const undo = () => {
    setUndoIndex(currentIndex);
    const prevIndex = currentIndex - 1;
    setCurrentIndex(prevIndex);
    setVideoItems(history[prevIndex]);
  };

  const redo = () => {
    setVideoItems(history[history.length - 2]);
  };
  useEffect(() => {
    var getFonts = firebase.functions().httpsCallable("getFonts");
    getFonts().then(async (result) => {
      const fonts = result.data.fontArray;
      setFontArray(fonts);
    });
  }, []);

  useEffect(() => {
    if (videoItems.length > 0 && lastTime > 0) {
      videoItems.forEach((item, index) => {
        if (item.type === "actor") {
          const shouldDisplay =
            currentTime >= item.startTime && currentTime < item.endTime;
          if (shouldDisplay && !item.isPlaying) {
            if (isPlaying && timer.isRunning()) {
              fetch(item.videoURL)
                .then((response) => response.arrayBuffer())
                .then((arrayBuffer) =>
                  audioContext.decodeAudioData(arrayBuffer)
                )
                .then((audioBuffer) => {
                  if (!item.isPlaying) {
                    const sourceNode = audioContext.createBufferSource();
                    sourceNode.buffer = audioBuffer;
                    const gainNode = audioContext.createGain();

                    // Set volume before connecting to the destination
                    if (item.volume !== null && isFinite(item.volume)) {
                      gainNode.gain.value = item.volume;
                    } else {
                      gainNode.gain.value = 0.5; // default volume if not specified
                    }

                    // Connect nodes after setting volume
                    sourceNode.connect(gainNode);
                    gainNode.connect(audioContext.destination);

                    const startOffset =
                      currentTime - item.startTime + item.trimStart;
                    const endOffset = item.trimEnd;
                    const duration = endOffset - startOffset;

                    if (startOffset >= 0 && duration > 0) {
                      sourceNode.start(0, startOffset, duration);
                      console.log("Use effect sound is playing");
                      item.isPlaying = true;
                      item.sourceNode = sourceNode;
                    } else {
                      console.log("Invalid startOffset or duration");
                    }
                  }
                });
            }
          } else if (!shouldDisplay && item.isPlaying && item.sourceNode) {
            item.sourceNode.stop();
            item.isPlaying = false;
          }
        } else {
          const videoElement = item.videoRef.current;
          if (
            videoElement &&
            (item.type === "music" || item.type === "video")
          ) {
            const shouldDisplay =
              currentTime >= item.startTime && currentTime < item.endTime;
            if (shouldDisplay && videoElement.paused) {
              if (isPlaying && timer.isRunning()) {
                videoElement.currentTime = videoItems[index].trimStart;
                if (videoItems[index]?.volume) {
                  videoElement.volume = videoItems[index].volume;
                  videoElement.play();
                } else {
                  videoElement.play();
                }
              }
            } else if (!shouldDisplay && !videoElement.paused) {
              videoElement.pause();
              videoElement.currentTime = videoItems[index].trimStart;
            }
          }
        }
      });

      // Check if the current time is equal to or has surpassed the max endTime
      // Also ensure the timer is currently running before attempting to stop it
      if (currentTime >= lastTime && timer.isRunning()) {
        videoItems.forEach((item, index) => {
          if (
            (item.type === "video" || item.type === "music") &&
            item.videoRef.current
          ) {
            item.videoRef.current.pause();
          } else if (item.type === "actor" && item.sourceNode) {
            item.sourceNode.stop();
            item.isPlaying = false;
          }
        });
        timer.stop();
        setStartingTime(0);
        setIsPlaying(false);
      }
    }
  }, [currentTime, lastTime, isPlaying, timer]);

  useEffect(() => {
    let intervalId;

    if (timer.isRunning) {
      intervalId = setInterval(() => {
        setCurrentTime(startingTime + timer.getElapsedResumedTime() / 1000);
      }, 1); // Logs every millisecond
    } else {
      if (intervalId) clearInterval(intervalId); // Clear the interval when the timer is not running
    }

    return () => {
      if (intervalId) clearInterval(intervalId); // Clean up on component unmount or when isRunning changes
    };
  }, [timer.isRunning, startingTime]);

  const handleLoadedMetadata = () => {
    setDuration(videoItems[0].videoRef.current.duration);
  };

  const handleTimelineClick = (progress) => {
    setIsPlaying(false);
    timer.stop();
    const newCurrentTime = (progress * duration) / 100;
    setStartingTime(newCurrentTime);

    videoItems.forEach((item) => {
      if (item.type === "video" || item.type === "music") {
        item.videoRef.current.pause();
      } else if (item.type === "actor") {
        if (item.sourceNode) {
          item.sourceNode.stop();
          item.isPlaying = false;
        }
      }

      if (
        item.startTime <= newCurrentTime &&
        newCurrentTime <= item.endTime &&
        item.videoRef.current
      ) {
        const midPointTime = newCurrentTime - item.startTime + item.trimStart;
        console.log("Midpoint time is", midPointTime);

        const videoElement = item.videoRef.current;

        // If the video is already loaded enough to play, set currentTime immediately
        videoElement.currentTime = midPointTime;
        //comeback
        if (item.type === "video" || item.type === "music") {
          videoElement.pause();
        }
      }
    });
  };

  const updateActorFields = (
    thumbnail,
    transparentThumbnail,
    ugcID,
    name,
    transparentURL
  ) => {
    setVideoItems((prevItems) => {
      return prevItems.map((item) => {
        if (item.type === "actor") {
          return {
            ...item,
            thumbnail,
            transparentThumbnail,
            ugcID,
            name,
            transparentURL,
          };
        }
        return item;
      });
    });
  };
  useEffect(() => {
    if (projectID && videoItems.length > 0) {
      const serializedVideoItems = videoItems.map((item) => ({
        ...item,
        videoRef: null,
        sourceNode: null,
      }));

      firebase.firestore().collection("projects").doc(projectID).update({
        videoItems: serializedVideoItems,
      });
    }
  }, [videoItems, projectID]);

  useEffect(() => {
    if (projectID) {
      firebase
        .firestore()
        .collection("projects")
        .doc(projectID)
        .get()
        .then((doc) => {
          if (doc.data().videoItems) {
            const deserializedVideoItems = doc
              .data()
              .videoItems.map((item) => ({
                ...item,
                videoRef: React.createRef(),
              }));

            setVideoItems(deserializedVideoItems);

            // Find the first actor item and set its voiceID
            const actorItem = deserializedVideoItems.find(
              (item) => item.type === "actor"
            );
            if (actorItem) {
              setVoiceID(actorItem.voiceID);
            }
          } else {
            setVideoItems([]);
          }
        })
        .then(() => {
          setPreparing(false);
        });
    }
  }, [projectID]);
  // useEffect(() => {
  //   if (canvasRef && projectID) {
  //     firebase.firestore().collection("projects").doc(projectID).update({
  //       width: canvasRef.current.clientWidth,
  //       height: canvasRef.current.clientHeight,
  //     });
  //   }
  // }, [projectID, canvasRef]);
  const addItem = (item) => {
    setVideoItems((prev) => {
      const actorItems = prev.filter((item) => item.type === "actor");
      const nonActorItems = prev.filter((item) => item.type !== "actor");

      let newItem;
      if (item.type === "actor") {
        const maxActorLayerIndex = actorItems.reduce(
          (max, item) => Math.max(max, item.layerIndex || 0),
          0
        );
        newItem = {
          ...item,
          layerIndex: maxActorLayerIndex + 1,
        };
      } else {
        const maxNonActorLayerIndex = nonActorItems.reduce(
          (max, item) => Math.max(max, item.layerIndex || 0),
          actorItems.length
        );
        newItem = {
          ...item,
          layerIndex: maxNonActorLayerIndex + 1,
        };
      }

      const updatedItems = [...actorItems, ...nonActorItems, newItem];
      updatedItems.sort((a, b) => a.layerIndex - b.layerIndex);

      return updatedItems;
    });
  };

  useEffect(() => {
    console.log("New items are", videoItems);
  }, [videoItems]);

  const handleItemsChange = (updatedItems) => {
    setVideoItems(updatedItems);
  };

  const handleVideoItemUpdate = (index, updates) => {
    setVideoItems((currentItems) =>
      currentItems.map((item, i) => {
        if (i === index) {
          // Apply updates if the current item is the one being updated
          return { ...item, ...updates };
        }
        return item; // Return the item unchanged if it's not the one being updated
      })
    );
  };

  useEffect(() => {
    if (!isPlaying) {
      timer.stop();
      setStartingTime(currentTime);
      videoItems.forEach((item) => {
        if (
          item.videoRef.current &&
          (item.type === "video" || item.type === "music")
        ) {
          item.videoRef.current.pause();
        }
      });
    }
  }, [isPlaying]);

  const handlePlayClick = () => {
    const lastEndTime = videoItems.reduce((maxEndTime, item) => {
      return Math.max(maxEndTime, item.endTime);
    }, 0);

    setIsPlaying((prevIsPlaying) => {
      if (prevIsPlaying) {
        timer.stop();
        setStartingTime(currentTime);
        videoItems.forEach((item) => {
          if (item.type === "actor") {
            if (item.sourceNode) {
              item.sourceNode.stop();
              item.isPlaying = false;
            }
          } else if (
            item.videoRef.current &&
            (item.type === "video" || item.type === "music")
          ) {
            item.videoRef.current.pause();
          }
        });
      } else {
        setIsPlaying(true);
        timer.start();
        videoItems.forEach((item) => {
          if (item.type === "actor") {
            console.log("item start time is", item.startTime);
            if (
              item.startTime <= currentTime &&
              currentTime <= item.endTime &&
              !item.isPlaying
            ) {
              fetch(item.videoURL)
                .then((response) => response.arrayBuffer())
                .then((arrayBuffer) =>
                  audioContext.decodeAudioData(arrayBuffer)
                )
                .then((audioBuffer) => {
                  if (!item.isPlaying) {
                    const sourceNode = audioContext.createBufferSource();
                    sourceNode.buffer = audioBuffer;
                    const gainNode = audioContext.createGain();

                    // Set volume before connecting to the destination
                    if (item.volume !== null && isFinite(item.volume)) {
                      gainNode.gain.value = item.volume;
                    } else {
                      gainNode.gain.value = 0.5; // default volume if not specified
                    }

                    // Connect nodes after setting volume
                    sourceNode.connect(gainNode);
                    gainNode.connect(audioContext.destination);

                    const startOffset =
                      currentTime - item.startTime + item.trimStart;
                    const endOffset = item.trimEnd;
                    const duration = endOffset - startOffset;

                    if (startOffset >= 0 && duration > 0) {
                      sourceNode.start(0, startOffset, duration);
                      console.log("Use effect sound is playing");
                      item.isPlaying = true;
                      item.sourceNode = sourceNode;
                    } else {
                      console.log("Invalid startOffset or duration");
                    }
                  }
                });
            }
          } else if (
            item.videoRef.current &&
            (item.type === "video" || item.type === "music")
          ) {
            console.log("item start time is", item.startTime);
            if (item.startTime <= currentTime && currentTime <= item.endTime) {
              item.videoRef.current.volume = item.volume;
              item.videoRef.current.currentTime =
                currentTime - item.startTime + item.trimStart;
              item.videoRef.current.play();
            } else {
              item.videoRef.current.pause();
            }
          }
        });
      }

      return !prevIsPlaying;
    });

    if (currentTime >= lastEndTime && !timer.isRunning) {
      setCurrentTime(0); // If the current time is at or beyond the last end time, reset to the beginning
      videoItems.forEach((item) => {
        if (item.type === "actor") {
          if (item.sourceNode) {
            item.sourceNode.stop();
            item.isPlaying = false;
          }
        } else if (item.videoRef.current && item.videoURL) {
          item.videoRef.current.currentTime = 0;
        }
      });
      timer.start();
    }
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the Backspace key is pressed and if an item is selected
      if (
        event.key === "Backspace" &&
        selectedIndex !== null &&
        videoItems[selectedIndex].type !== "text" &&
        videoItems[selectedIndex].type !== "actor"
      ) {
        // Prevent default to avoid any unwanted side effects (e.g., navigating back in browser history)
        event.preventDefault();

        // Remove the selected item from the videoItems array
        const newVideoItems = videoItems.filter(
          (_, index) => index !== selectedIndex
        );
        handleItemsChange(newVideoItems);

        // Reset the selected index as no item is selected after removal
        setSelectedIndex(null);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedIndex, videoItems]); // Ensure the effect runs when the selectedIndex or videoItems change

  const deleteItem = (chosenIndex) => {
    const newVideoItems = videoItems.filter(
      (_, index) => index !== chosenIndex
    );
    handleItemsChange(newVideoItems);
    setSelectedIndex(null);
  };

  useEffect(() => {
    if (projectName && projectID) {
      firebase.firestore().collection("projects").doc(projectID).update({
        projectName,
      });
    }
  }, [projectName]);

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          overflowY: "hidden",
          height: "100vh",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#f5f5f7",
            paddingBottom: 50,
            overflowY: "hidden",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 0,
              overflowY: "hidden",
            }}
            size="12"
          >
            <MDBRow style={{ marginTop: 0 }}>
              <ProjectMenu
                projectName={projectName}
                setProjectName={setProjectName}
                projectID={projectID}
                workspaceID={workspaceID}
                lastTime={lastTime}
                videoItems={videoItems}
              />
            </MDBRow>
          </MDBCol>
          <MDBCol
            style={{
              paddingLeft: 0,
              overflowY: "hidden",
            }}
            size="12"
          >
            <div
              style={{
                display: "flex",
                height: "100vh",
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <LeftNav />
              <SideNav
                setVoiceID={setVoiceID}
                updateActorFields={updateActorFields}
                addItem={addItem}
                videoItems={videoItems}
                setVideoItems={setVideoItems}
                lastTime={lastTime}
                updateProjectThumbnail={updateProjectThumbnail}
              />
              <div
                style={{
                  zIndex: 4,
                  width: "82vw",
                  overflowX: "hidden",
                  overflowY: "hidden",
                }}
              >
                <div
                  style={{
                    marginTop: 0,
                    backgroundColor: "transparent",
                    height: "65%",
                  }}
                >
                  <IconRow
                    canvasRef={canvasRef}
                    undo={undo}
                    redo={redo}
                    deleteItem={deleteItem}
                    selectedIndex={selectedIndex}
                    videoItems={videoItems}
                    setVideoItems={setVideoItems}
                  />
                  <div className="d-flex justify-content-center">
                    {isPlaying && (
                      <div
                        style={{
                          position: "absolute",
                          height: 33,
                          paddingLeft: 15,
                          paddingRight: 15,
                          backgroundColor: "blue",
                          marginTop: "20vw",
                          zIndex: 13000,
                          borderRadius: 100,
                          display: "flex",
                          color: "white",
                          fontFamily: "SSMedium",
                          fontSize: 12,
                          paddingTop: 9,
                        }}
                      >
                        <IoIosInformationCircle
                          style={{
                            fontSize: 18,
                            marginTop: -1,
                            marginRight: 7,
                          }}
                        />

                        <p>Avatar animation is available after submitting</p>
                      </div>
                    )}

                    <Canvas
                      items={videoItems}
                      setIsPlaying={setIsPlaying}
                      currentTime={currentTime}
                      onItemsChange={handleItemsChange}
                      handleLoadedMetadata={handleLoadedMetadata}
                      timer={timer}
                      setSelectedIndex={setSelectedIndex}
                      selectedIndex={selectedIndex}
                      setStartingTime={setStartingTime}
                      canvasRef={canvasRef}
                      preparing={preparing}
                      setVideoItems={setVideoItems}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: 0,
                    backgroundColor: "#fff",
                    boxShadow:
                      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                    height: "17%",
                    paddingLeft: 35,
                    paddingTop: 20,
                    display: "flex",
                    overflowX: "hidden",
                  }}
                >
                  <TextBox
                    voiceID={voiceID}
                    selectedIndex={selectedIndex}
                    videoItems={videoItems}
                    setVideoItems={setVideoItems}
                  />
                </div>
                <div
                  style={{
                    marginTop: 0,
                    backgroundColor: "#fff",
                    height: "18%",
                    display: "flex",
                    paddingLeft: 35,
                  }}
                >
                  <PlayerControl
                    duration={duration}
                    currentTime={currentTime}
                    onTimelineClick={handleTimelineClick}
                    isPlaying={isPlaying}
                    onPlayClick={handlePlayClick}
                    setIsPlaying={setIsPlaying}
                    videoItems={videoItems}
                    lastTime={lastTime}
                    onItemsChange={handleItemsChange}
                    handleVideoItemUpdate={handleVideoItemUpdate}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                  />
                </div>
              </div>
            </div>
          </MDBCol>
        </div>
      </div>
    </>
  );
};

export default withRouter(Project);
