import React, { useState, useEffect } from "react";
import { BiUndo } from "react-icons/bi";
import { MdAnimation, MdOutlinePerson2 } from "react-icons/md";
import { FaRegEye, FaRegFaceGrinBeam } from "react-icons/fa6";
import { PiSelectionBackgroundFill } from "react-icons/pi";
import {
  TbAlignBoxCenterMiddle,
  TbAlignCenter,
  TbAlignLeft,
  TbAlignRight,
  TbBoxAlignLeft,
} from "react-icons/tb";
import { FiLayers } from "react-icons/fi";
import { MdOutlineContentCopy } from "react-icons/md";
import { IoChevronDownOutline } from "react-icons/io5";
import { FaFont } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

import {
  FaIcon1,
  FaIcon2,
  FaIcon3,
  FaIcon4,
  FaIcon5,
  FaIcon6,
  FaIcon7,
  FaIcon8,
  FaIcon9,
  FaIcon10,
  FaIcon11,
} from "react-icons/fa";
import {
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiDeleteBin5Line,
} from "react-icons/ri";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { BsCircleSquare } from "react-icons/bs";
import FontPicker from "font-picker-react";
import ColorPickerPopup from "./ColorPickerPopup";
import FontWeightPicker from "./FontWeightPicker";
import TextAlignPicker from "./TextAlignPicker";
import ObjectAlignPicker from "./ObjectAlignPicker";
import LayerAlignPicker from "./LayerAlignPicker";

const IconRow = ({
  deleteItem,
  selectedIndex,
  videoItems,
  setVideoItems,
  canvasRef,
  undo,
  redo,
}) => {
  const [activeFontFamily, setActiveFontFamily] = useState("Abel");
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#000");
  const [fontWeightPickerOpen, setFontWeightPickerOpen] = useState(false);
  const [textAlignPickerOpen, setTextAlignPickerOpen] = useState(null);
  const [selectedTextAlign, setSelectedTextAlign] = useState(null);
  const [objectAlignPickerOpen, setObjectAlignPickerOpen] = useState(false);
  const [selectedObjectAlign, setSelectedObjectAlign] = useState(null);
  const [layerAlignPickerOpen, setLayerAlignPickerOpen] = useState(null);
  const [selectedLayerAlign, setSelectedLayerAlign] = useState(null);
  const [selectedVolume, setSelectedVolume] = useState(0.5);
  const [selectedFontWeight, setSelectedFontWeight] = useState({
    fontWeight: null,
    fontStyle: null,
    textDecoration: null,
    textTransform: null,
  });

  useEffect(() => {
    if (selectedIndex) {
      if (videoItems[selectedIndex].color) {
        setSelectedColor(videoItems[selectedIndex].color);
      }
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (videoItems[selectedIndex]?.volume) {
      setSelectedVolume(videoItems[selectedIndex].volume);
    } else {
      setSelectedVolume(0.5);
    }
  }, [selectedIndex]);

  useEffect(() => {
    setVideoItems((prevItems) => {
      const updatedItems = [...prevItems];
      if (selectedIndex !== null) {
        updatedItems[selectedIndex] = {
          ...updatedItems[selectedIndex],
          color: selectedColor,
        };
      }
      return updatedItems;
    });
  }, [selectedColor]);

  useEffect(() => {
    setVideoItems((prevItems) => {
      const updatedItems = [...prevItems];
      if (selectedIndex !== null) {
        updatedItems[selectedIndex] = {
          ...updatedItems[selectedIndex],
          volume: selectedVolume,
        };
        const videoRef = updatedItems[selectedIndex].videoRef;
        if (videoRef?.current) {
          videoRef.current.volume = selectedVolume;
        }
      }
      return updatedItems;
    });
  }, [selectedVolume]);

  function percentToDecimal(percentString) {
    // Remove the '%' character from the string
    const percentValue = percentString.replace("%", "");

    // Convert the percentage value to a decimal
    const decimalValue = parseFloat(percentValue) / 100;

    // Return the decimal value
    return decimalValue;
  }

  // useEffect(() => {
  //   setVideoItems((prevItems) => {
  //     const canvasWidth = canvasRef.current.clientWidth;
  //     const updatedItems = [...prevItems];
  //     if (selectedIndex !== null && updatedItems.length > 0) {
  //       updatedItems[selectedIndex] = {
  //         ...updatedItems[selectedIndex],
  //         x:
  //           selectedObjectAlign === "left"
  //             ? 0
  //             : selectedObjectAlign === "center"
  //             ? canvasWidth / 2 -
  //               (percentToDecimal(updatedItems[selectedIndex].width) *
  //                 canvasWidth) /
  //                 2
  //             : selectedObjectAlign === "right"
  //             ? canvasWidth -
  //               percentToDecimal(updatedItems[selectedIndex].width) *
  //                 canvasWidth
  //             : updatedItems[selectedIndex].x,
  //         objectAlign: selectedObjectAlign,
  //       };
  //     }
  //     return updatedItems;
  //   });
  // }, [selectedObjectAlign]);

  useEffect(() => {
    setVideoItems((prevItems) => {
      const updatedItems = [...prevItems];
      if (selectedIndex !== null && updatedItems.length > 0) {
        updatedItems[selectedIndex] = {
          ...updatedItems[selectedIndex],
          textAlign: selectedTextAlign,
        };
      }
      return updatedItems;
    });
  }, [selectedTextAlign]);

  useEffect(() => {
    setVideoItems((prevItems) => {
      const updatedItems = [...prevItems];
      if (selectedIndex !== null && updatedItems.length > 0) {
        const currentItem = updatedItems[selectedIndex];
        const currentZIndex = currentItem.layerIndex || 0;

        // Increment layerIndex of all items
        updatedItems.forEach((item) => {
          item.layerIndex = item.layerIndex + 1;
        });

        if (selectedLayerAlign === "forward") {
          const higherZIndexItems = updatedItems.filter(
            (item) => (item.layerIndex || 0) > currentZIndex
          );
          if (higherZIndexItems.length > 0) {
            const nextHigherZIndex = Math.min(
              ...higherZIndexItems.map((item) => item.layerIndex || 1)
            );
            updatedItems[selectedIndex] = {
              ...currentItem,
              layerIndex: nextHigherZIndex + 1,
              layerAlign: "forward",
            };
          }
        } else if (selectedLayerAlign === "front") {
          const maxZIndex = Math.max(
            ...updatedItems.map((item) => item.layerIndex || 0)
          );
          updatedItems[selectedIndex] = {
            ...currentItem,
            layerIndex: maxZIndex + 1,
            layerAlign: "front",
          };
        } else if (selectedLayerAlign === "back") {
          if (currentZIndex > 0) {
            // Only decrease if not already the lowest
            const minZIndex = Math.min(
              ...updatedItems.map((item) => item.layerIndex || 1)
            );
            updatedItems[selectedIndex] = {
              ...currentItem,
              layerIndex: minZIndex - 1,
              layerAlign: "back",
            };
          }
        } else if (selectedLayerAlign === "backward") {
          const lowerZIndexItems = updatedItems.filter(
            (item) =>
              (item.layerIndex || 0) <= currentZIndex &&
              (item.layerIndex || 0) > 0
          );

          if (lowerZIndexItems.length > 0) {
            const nextLowerZIndex = Math.max(
              ...lowerZIndexItems.map((item) => item.layerIndex || 0)
            );
            updatedItems[selectedIndex] = {
              ...currentItem,
              layerIndex: nextLowerZIndex - 1,
              layerAlign: "backward",
            };
          }
        }
      }
      return updatedItems;
    });
  }, [selectedLayerAlign]);

  useEffect(() => {
    setVideoItems((prevItems) => {
      const updatedItems = [...prevItems];
      if (selectedIndex !== null) {
        updatedItems[selectedIndex] = {
          ...updatedItems[selectedIndex],
          style: selectedFontWeight,
        };
      }
      return updatedItems;
    });
  }, [selectedFontWeight]);

  const handleVolumeChange = (e) => {
    setSelectedVolume(parseFloat(e.target.value));
  };

  const icons = [
    <RiArrowGoBackLine
      style={{ cursor: "pointer" }}
      onClick={() => undo()}
      key="icon1"
    />,
    <RiArrowGoForwardLine
      style={{ cursor: "pointer" }}
      onClick={() => redo()}
      key="icon2"
    />,
    // <FaRegFaceGrinBeam key="icon4" />,
    videoItems[selectedIndex]?.type === "actor" && (
      <div style={{ display: "flex" }} key="volume-slider">
        <p
          style={{
            fontFamily: "PPMedium",
            fontSize: 15,
            marginLeft: 0,
            marginBottom: 0,
            marginRight: 12,
          }}
        >
          Volume
        </p>
        <input
          type="range"
          min={0}
          max={1}
          step={0.1}
          value={selectedVolume}
          onChange={handleVolumeChange}
          style={{ width: "100px", color: "red" }}
        />
        <p
          style={{
            fontFamily: "PPMedium",
            fontSize: 15,
            marginLeft: 12,
            marginBottom: -2,
          }}
        >
          {selectedVolume.toFixed(1)}
        </p>
      </div>
    ),
    <div
      style={{
        color:
          videoItems[selectedIndex] && videoItems[selectedIndex].transparent
            ? "white"
            : null,
        backgroundColor:
          videoItems[selectedIndex] && videoItems[selectedIndex].transparent
            ? "rgb(117, 89, 255)"
            : null,
        cursor: "pointer",
        paddingTop: 0,
        paddingLeft:
          videoItems[selectedIndex] && videoItems[selectedIndex].transparent
            ? 6
            : 0,
        paddingRight:
          videoItems[selectedIndex] && videoItems[selectedIndex].transparent
            ? 6
            : 0,
        borderRadius: 5,
      }}
      onClick={() => {
        setVideoItems((prevItems) => {
          if (
            selectedIndex !== null &&
            prevItems[selectedIndex].type === "actor"
          ) {
            const updatedItems = [...prevItems];
            updatedItems[selectedIndex] = {
              ...updatedItems[selectedIndex],
              transparent:
                updatedItems[selectedIndex].transparent !== null
                  ? !updatedItems[selectedIndex].transparent
                  : false,
            };
            return updatedItems;
          }
          return prevItems;
        });
      }}
    >
      <PiSelectionBackgroundFill
        style={{
          marginTop:
            videoItems[selectedIndex] && videoItems[selectedIndex].transparent
              ? -3
              : -3,
        }}
        key="icon5"
      />
    </div>,
    <>
      <ObjectAlignPicker
        objectAlignPickerOpen={objectAlignPickerOpen}
        setObjectAlignPickerOpen={setObjectAlignPickerOpen}
        selectedObjectAlign={selectedObjectAlign}
        setSelectedObjectAlign={setSelectedObjectAlign}
        selectedIndex={selectedIndex}
        videoItems={videoItems}
      />
      <TbAlignBoxCenterMiddle onClick={() => setObjectAlignPickerOpen(true)} />
    </>,
    <>
      <LayerAlignPicker
        layerAlignPickerOpen={layerAlignPickerOpen}
        setLayerAlignPickerOpen={setLayerAlignPickerOpen}
        selectedLayerAlign={selectedLayerAlign}
        setSelectedLayerAlign={setSelectedLayerAlign}
        selectedIndex={selectedIndex}
        videoItems={videoItems}
      />
      <FiLayers onClick={() => setLayerAlignPickerOpen(true)} key="icon8" />
    </>,
    <>
      <ColorPickerPopup
        colorPickerOpen={colorPickerOpen}
        setColorPickerOpen={setColorPickerOpen}
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
      />
      <div
        onClick={() => setColorPickerOpen(true)}
        key="icon5"
        style={{
          backgroundColor: selectedColor,
          borderRadius: 8,
          height: 23,
          width: 23,
          border: "2px solid rgb(229, 229, 229)",
        }}
      ></div>
    </>,
    <MdOutlineContentCopy
      onClick={() => {
        setVideoItems((prevItems) => {
          const selectedItem = prevItems[selectedIndex];
          const updatedItem = {
            ...selectedItem,
            videoRef: React.createRef(),
            pickerID: uuidv4().replace(/[^a-zA-Z0-9]/g, ""),
          };

          const actorItems = prevItems.filter((item) => item.type === "actor");
          const nonActorItems = prevItems.filter(
            (item) => item.type !== "actor"
          );

          let insertIndex;
          if (selectedItem.type === "actor") {
            insertIndex = actorItems.findIndex(
              (item) => item.layerIndex > selectedItem.layerIndex
            );
            if (insertIndex === -1) {
              insertIndex = actorItems.length;
            }
          } else {
            insertIndex =
              actorItems.length +
              nonActorItems.findIndex(
                (item) => item.layerIndex > selectedItem.layerIndex
              );
            if (insertIndex === actorItems.length - 1) {
              insertIndex = prevItems.length;
            }
          }

          const updatedItems = [
            ...prevItems.slice(0, insertIndex),
            updatedItem,
            ...prevItems.slice(insertIndex),
          ];

          // Update the layerIndex of affected items
          updatedItems.forEach((item, index) => {
            item.layerIndex = index + 1;
          });

          return updatedItems;
        });
      }}
      key="icon9"
    />,
    // <BsCircleSquare />,
    <RiDeleteBin5Line
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (selectedIndex !== null) {
          deleteItem(selectedIndex);
        }
      }}
      key="icon10"
    />,
  ];

  const nonActorIcons = [
    <RiArrowGoBackLine
      style={{ cursor: "pointer" }}
      onClick={() => undo()}
      key="icon1"
    />,
    <RiArrowGoForwardLine
      style={{ cursor: "pointer" }}
      onClick={() => redo()}
      key="icon2"
    />,
    videoItems[selectedIndex]?.type === "video" && (
      <div style={{ display: "flex" }} key="volume-slider">
        <p
          style={{
            fontFamily: "PPMedium",
            fontSize: 15,
            marginLeft: 0,
            marginBottom: 0,
            marginRight: 12,
          }}
        >
          Volume
        </p>
        <input
          type="range"
          min={0}
          max={1}
          step={0.1}
          value={selectedVolume}
          onChange={handleVolumeChange}
          style={{ width: "100px", color: "red" }}
        />
        <p
          style={{
            fontFamily: "PPMedium",
            fontSize: 15,
            marginLeft: 12,
            marginBottom: -2,
          }}
        >
          {selectedVolume.toFixed(1)}
        </p>
      </div>
    ),
    <>
      <ObjectAlignPicker
        objectAlignPickerOpen={objectAlignPickerOpen}
        setObjectAlignPickerOpen={setObjectAlignPickerOpen}
        selectedObjectAlign={selectedObjectAlign}
        setSelectedObjectAlign={setSelectedObjectAlign}
        selectedIndex={selectedIndex}
        videoItems={videoItems}
      />
      <TbAlignBoxCenterMiddle onClick={() => setObjectAlignPickerOpen(true)} />
    </>,
    <>
      <LayerAlignPicker
        layerAlignPickerOpen={layerAlignPickerOpen}
        setLayerAlignPickerOpen={setLayerAlignPickerOpen}
        selectedLayerAlign={selectedLayerAlign}
        setSelectedLayerAlign={setSelectedLayerAlign}
        selectedIndex={selectedIndex}
        videoItems={videoItems}
      />
      <FiLayers onClick={() => setLayerAlignPickerOpen(true)} key="icon8" />
    </>,
    videoItems[selectedIndex]?.type === "text" ||
      (videoItems[selectedIndex]?.type === "icon" && (
        <>
          <ColorPickerPopup
            colorPickerOpen={colorPickerOpen}
            setColorPickerOpen={setColorPickerOpen}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
          />
          <div
            onClick={() => setColorPickerOpen(true)}
            key="icon5"
            style={{
              backgroundColor: selectedColor,
              borderRadius: 8,
              height: 23,
              width: 23,
              border: "2px solid rgb(229, 229, 229)",
            }}
          ></div>
        </>
      )),
    <MdOutlineContentCopy
      onClick={() => {
        setVideoItems((prevItems) => {
          const selectedItem = prevItems[selectedIndex];
          const updatedItem = {
            ...selectedItem,
            videoRef: React.createRef(),
            pickerID: uuidv4().replace(/[^a-zA-Z0-9]/g, ""),
          };

          const actorItems = prevItems.filter((item) => item.type === "actor");
          const nonActorItems = prevItems.filter(
            (item) => item.type !== "actor"
          );

          let insertIndex;
          if (selectedItem.type === "actor") {
            insertIndex = actorItems.findIndex(
              (item) => item.layerIndex > selectedItem.layerIndex
            );
            if (insertIndex === -1) {
              insertIndex = actorItems.length;
            }
          } else {
            insertIndex =
              actorItems.length +
              nonActorItems.findIndex(
                (item) => item.layerIndex > selectedItem.layerIndex
              );
            if (insertIndex === actorItems.length - 1) {
              insertIndex = prevItems.length;
            }
          }

          const updatedItems = [
            ...prevItems.slice(0, insertIndex),
            updatedItem,
            ...prevItems.slice(insertIndex),
          ];

          // Update the layerIndex of affected items
          updatedItems.forEach((item, index) => {
            item.layerIndex = index + 1;
          });

          return updatedItems;
        });
      }}
      key="icon9"
    />,
    // <BsCircleSquare />,
    <RiDeleteBin5Line
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (selectedIndex !== null) {
          deleteItem(selectedIndex);
        }
      }}
      key="icon10"
    />,
  ];

  const textIcons = [
    <RiArrowGoBackLine
      style={{ cursor: "pointer" }}
      onClick={() => undo()}
      key="icon1"
    />,
    <RiArrowGoForwardLine
      style={{ cursor: "pointer" }}
      onClick={() => redo()}
      key="icon2"
    />,
    <FontPicker
      style={{ backgroundColor: "transparent" }}
      pickerId={`${
        videoItems[selectedIndex] && videoItems[selectedIndex].pickerID
      }`}
      apiKey="AIzaSyAqKNzkdBmZXHd5gVPmNL2SW42AhFTEpvU"
      activeFontFamily={
        videoItems[selectedIndex] && videoItems[selectedIndex].fontFamily
          ? videoItems[selectedIndex].fontFamily
          : activeFontFamily
      }
      onChange={(nextFont) => {
        setActiveFontFamily(nextFont.family);
        console.log(nextFont.family);
        setVideoItems((prevItems) => {
          const updatedItems = [...prevItems];
          if (selectedIndex !== null) {
            updatedItems[selectedIndex] = {
              ...updatedItems[selectedIndex],
              fontFamily: nextFont.family,
            };
          }
          return updatedItems;
        });
      }}
    />,
    <div
      key="icon4"
      style={{
        height: 32,
        width: 120,
        border: "1px solid #B0B0B0",
        borderRadius: 6,
        display: "flex",
        alignItems: "center",
        marginLeft: -9,
      }}
      className="d-flex justify-content-center"
    >
      <div
        style={{
          position: "absolute",
          marginLeft: -87,
          fontSize: 12,
          cursor: "pointer",
          borderRight: "1px solid #B0B0B0",
          height: 32,
          width: 32,
          paddingTop: 10,
        }}
        className="d-flex justify-content-center"
        onClick={() => {
          setVideoItems((prevItems) => {
            const updatedItems = [...prevItems];
            if (selectedIndex !== null) {
              updatedItems[selectedIndex] = {
                ...updatedItems[selectedIndex],
                fontSize: Math.max(0, updatedItems[selectedIndex].fontSize - 1),
              };
            }
            return updatedItems;
          });
        }}
      >
        <AiOutlineMinus />
      </div>

      <div
        style={{
          position: "absolute",
          marginLeft: 87,
          fontSize: 14,
          cursor: "pointer",
          borderLeft: "1px solid #B0B0B0",
          height: 32,
          width: 32,
          paddingTop: 10,
        }}
        className="d-flex justify-content-center"
        onClick={() => {
          setVideoItems((prevItems) => {
            const updatedItems = [...prevItems];
            if (selectedIndex !== null) {
              updatedItems[selectedIndex] = {
                ...updatedItems[selectedIndex],
                fontSize: updatedItems[selectedIndex].fontSize + 1,
              };
            }
            return updatedItems;
          });
        }}
      >
        <AiOutlinePlus />
      </div>

      <div
        style={{
          borderLeft: "1px solid #B0B0B0",
          paddingLeft: 20,
          paddingRight: 20,
          height: 32,
        }}
      >
        <p
          style={{
            fontFamily: "PPMedium",
            fontSize: 14,
            marginTop: 7,
            textAlign: "center",
            userSelect: "none",
          }}
        >
          {videoItems[selectedIndex] &&
            videoItems[selectedIndex].fontSize?.toFixed(0)}
        </p>
      </div>
    </div>,

    <>
      <ColorPickerPopup
        colorPickerOpen={colorPickerOpen}
        setColorPickerOpen={setColorPickerOpen}
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
      />
      <div
        onClick={() => setColorPickerOpen(true)}
        key="icon5"
        style={{
          backgroundColor: selectedColor,
          borderRadius: 8,
          height: 23,
          width: 23,
          border: "2px solid rgb(229, 229, 229)",
        }}
      ></div>
    </>,
    <>
      <FontWeightPicker
        fontWeightPickerOpen={fontWeightPickerOpen}
        setFontWeightPickerOpen={setFontWeightPickerOpen}
        selectedFontWeight={selectedFontWeight}
        setSelectedFontWeight={setSelectedFontWeight}
        selectedIndex={selectedIndex}
        videoItems={videoItems}
      />
      <FaFont onClick={() => setFontWeightPickerOpen(true)} key="icon6" />
    </>,
    <>
      <TextAlignPicker
        textAlignPickerOpen={textAlignPickerOpen}
        setTextAlignPickerOpen={setTextAlignPickerOpen}
        selectedTextAlign={selectedTextAlign}
        setSelectedTextAlign={setSelectedTextAlign}
        selectedIndex={selectedIndex}
        videoItems={videoItems}
      />
      {videoItems[selectedIndex] && videoItems[selectedIndex].textAlign ? (
        videoItems[selectedIndex].textAlign === "left" ? (
          <TbAlignLeft
            onClick={() => setTextAlignPickerOpen(true)}
            key="icon7"
          />
        ) : videoItems[selectedIndex].textAlign === "right" ? (
          <TbAlignRight
            onClick={() => setTextAlignPickerOpen(true)}
            key="icon7"
          />
        ) : (
          <TbAlignCenter
            onClick={() => setTextAlignPickerOpen(true)}
            key="icon7"
          />
        )
      ) : (
        <TbAlignCenter
          onClick={() => setTextAlignPickerOpen(true)}
          key="icon7"
        />
      )}
    </>,
    <>
      <ObjectAlignPicker
        objectAlignPickerOpen={objectAlignPickerOpen}
        setObjectAlignPickerOpen={setObjectAlignPickerOpen}
        selectedObjectAlign={selectedObjectAlign}
        setSelectedObjectAlign={setSelectedObjectAlign}
        selectedIndex={selectedIndex}
        videoItems={videoItems}
      />
      <TbAlignBoxCenterMiddle onClick={() => setObjectAlignPickerOpen(true)} />
    </>,
    <>
      <LayerAlignPicker
        layerAlignPickerOpen={layerAlignPickerOpen}
        setLayerAlignPickerOpen={setLayerAlignPickerOpen}
        selectedLayerAlign={selectedLayerAlign}
        setSelectedLayerAlign={setSelectedLayerAlign}
        selectedIndex={selectedIndex}
        videoItems={videoItems}
      />
      <FiLayers onClick={() => setLayerAlignPickerOpen(true)} key="icon8" />
    </>,
    <MdOutlineContentCopy
      onClick={() => {
        setVideoItems((prevItems) => {
          const selectedItem = prevItems[selectedIndex];
          const updatedItem = {
            ...selectedItem,
            videoRef: React.createRef(),
            pickerID: uuidv4().replace(/[^a-zA-Z0-9]/g, ""),
          };

          const actorItems = prevItems.filter((item) => item.type === "actor");
          const nonActorItems = prevItems.filter(
            (item) => item.type !== "actor"
          );

          let insertIndex;
          if (selectedItem.type === "actor") {
            insertIndex = actorItems.findIndex(
              (item) => item.layerIndex > selectedItem.layerIndex
            );
            if (insertIndex === -1) {
              insertIndex = actorItems.length;
            }
          } else {
            insertIndex =
              actorItems.length +
              nonActorItems.findIndex(
                (item) => item.layerIndex > selectedItem.layerIndex
              );
            if (insertIndex === actorItems.length - 1) {
              insertIndex = prevItems.length;
            }
          }

          const updatedItems = [
            ...prevItems.slice(0, insertIndex),
            updatedItem,
            ...prevItems.slice(insertIndex),
          ];

          // Update the layerIndex of affected items
          updatedItems.forEach((item, index) => {
            item.layerIndex = index + 1;
          });

          return updatedItems;
        });
      }}
      key="icon9"
    />,
    // <BsCircleSquare />,
    <RiDeleteBin5Line
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (selectedIndex !== null) {
          deleteItem(selectedIndex);
        }
      }}
      key="icon10"
    />,
  ];

  const basicIcons = [
    <RiArrowGoBackLine
      style={{ cursor: "pointer" }}
      onClick={() => undo()}
      key="icon1"
    />,
    <RiArrowGoForwardLine
      style={{ cursor: "pointer" }}
      onClick={() => redo()}
      key="icon2"
    />,
  ];

  const musicIcons = [
    <RiArrowGoBackLine
      style={{ cursor: "pointer" }}
      onClick={() => undo()}
      key="icon1"
    />,
    <RiArrowGoForwardLine
      style={{ cursor: "pointer" }}
      onClick={() => redo()}
      key="icon2"
    />,
    <div style={{ display: "flex" }} key="volume-slider">
      <p
        style={{
          fontFamily: "PPMedium",
          fontSize: 15,
          marginLeft: 0,
          marginBottom: 0,
          marginRight: 12,
        }}
      >
        Volume
      </p>
      <input
        type="range"
        min={0}
        max={1}
        step={0.1}
        value={selectedVolume}
        onChange={handleVolumeChange}
        style={{ width: "100px", color: "red" }}
      />
      <p
        style={{
          fontFamily: "PPMedium",
          fontSize: 15,
          marginLeft: 12,
          marginBottom: -2,
        }}
      >
        {selectedVolume.toFixed(1)}
      </p>
    </div>,
    <MdOutlineContentCopy
      onClick={() => {
        setVideoItems((prevItems) => {
          const selectedItem = prevItems[selectedIndex];
          const updatedItem = {
            ...selectedItem,
            videoRef: React.createRef(),
            pickerID: uuidv4().replace(/[^a-zA-Z0-9]/g, ""),
          };

          const actorItems = prevItems.filter((item) => item.type === "actor");
          const nonActorItems = prevItems.filter(
            (item) => item.type !== "actor"
          );

          let insertIndex;
          if (selectedItem.type === "actor") {
            insertIndex = actorItems.findIndex(
              (item) => item.layerIndex > selectedItem.layerIndex
            );
            if (insertIndex === -1) {
              insertIndex = actorItems.length;
            }
          } else {
            insertIndex =
              actorItems.length +
              nonActorItems.findIndex(
                (item) => item.layerIndex > selectedItem.layerIndex
              );
            if (insertIndex === actorItems.length - 1) {
              insertIndex = prevItems.length;
            }
          }

          const updatedItems = [
            ...prevItems.slice(0, insertIndex),
            updatedItem,
            ...prevItems.slice(insertIndex),
          ];

          // Update the layerIndex of affected items
          updatedItems.forEach((item, index) => {
            item.layerIndex = index + 1;
          });

          return updatedItems;
        });
      }}
      key="icon9"
    />,
    <RiDeleteBin5Line
      style={{ cursor: "pointer" }}
      onClick={() => {
        if (selectedIndex !== null) {
          deleteItem(selectedIndex);
        }
      }}
      key="icon10"
    />,
  ];

  const visibleIcons =
    selectedIndex && videoItems[selectedIndex]?.type === "text"
      ? textIcons
      : selectedIndex !== null
      ? videoItems[selectedIndex]?.type === "actor"
        ? icons
        : videoItems[selectedIndex]?.type === "music"
        ? musicIcons
        : nonActorIcons
      : basicIcons;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: "black",
        paddingTop: 65,
        fontSize: 25,
        paddingLeft: 30,
        marginTop: 6,
        zIndex: 90000,
        height: 105,
      }}
    >
      {visibleIcons.map(
        (icon, index) =>
          icon && (
            <span
              style={{
                marginRight: 20,
                opacity: 1,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                zIndex: 9000,
                userSelect: "none",
              }}
              key={index}
            >
              {icon}
            </span>
          )
      )}
      {/* {icons.length > 2 && <span>...</span>} */}
    </div>
  );
};

export default IconRow;
