import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation, useHistory } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";

import "react-phone-number-input/style.css";
import MainMenu from "../Layout/MainMenu";
import { FaRegCompass, FaRegStar } from "react-icons/fa";
import { BsBackpack, BsThreeDots } from "react-icons/bs";
import { IoFilterSharp } from "react-icons/io5";
import SideNav from "../Layout/SideNav";
import TopBar from "../Layout/TopBar";
import Categories from "./Categories";
import PackTemplates from "./PackTemplates";
import Templates from "./Templates";
import { useAuth } from "../Context/AuthContext";
import { LuPen } from "react-icons/lu";
import ProjectModal from "./ProjectModal";
import { TailSpin, Hourglass } from "react-loader-spinner";
import { GoDeviceCameraVideo } from "react-icons/go";
import { FiPlay } from "react-icons/fi";
import VideoModal from "./VideoModal";

const MyProjects = () => {
  const [projectsArray, setProjectsArray] = useState(null);
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentPlaybackID, setCurrentPlaybackID] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);
  useEffect(() => {
    if (firebase.auth().currentUser) {
      firebase
        .firestore()
        .collection("videoTasks")
        .where("uid", "==", firebase.auth().currentUser?.uid)
        .onSnapshot((querySnapshot) => {
          if (querySnapshot.empty) {
            setProjectsArray([]);
            setLoaded(true);
          } else {
            let initArray = [];
            querySnapshot.forEach((doc) => {
              initArray.push(doc.data());
            });
            setProjectsArray(initArray);
            setLoaded(true);
          }
        });
    }
  }, [firebase.auth().currentUser]);

  const formatDate = (timestamp) => {
    const date = timestamp.toDate(); // Convert Firebase timestamp to JavaScript Date object
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const projectsArrayFormat = [
    {
      status: "Processing",
      Progress: 80,
      playbackID: "hAmuhVbQVb02bErBAMzki6pPrY9mlTYK2wKIpEwefRa00",
      projectThumbnail: `https://image.mux.com/geLnlLTfethxYfPOtTI02yblFLNH8ANk4pkdSD3U5rbk/thumbnail.png`,
      stage: "Awaiting product",
      assetID: "hAmuhVbQVb02bErBAMzki6pPrY9mlTYK2wKIpEwefRa00",
      createdAt: new Date(),
      duration: 36,
      projectName: "RowAds Viral",
    },
  ];

  const videosArray = [];

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            color: "#30312c",
            backgroundColor: "white",
            overflowY: "scroll",
            height: "100vh",
            paddingBottom: 30,
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 0,
            }}
            size="12"
          >
            <MDBRow style={{ marginTop: 0 }}>
              <MainMenu />
            </MDBRow>
          </MDBCol>
          {projectsArray !== null && (
            <MDBCol
              style={{
                paddingLeft: 0,
                paddingLeft: 30,
                marginTop: 80,
                padddingRight: 30,
              }}
              size="12"
              className="d-flex justify-content-center"
            >
              <VideoModal
                videoOpen={videoOpen}
                setVideoOpen={setVideoOpen}
                playbackID={currentPlaybackID}
              />
              <MDBRow style={{ width: 1500 }}>
                {projectsArray &&
                  projectsArray.map((project, index) => {
                    const borderProgress = project.Progress; // Assuming 'progress' is the variable you have
                    const gradient = `linear-gradient(to right, #ed1165 ${borderProgress}%, #E7E7FC ${borderProgress}%, #E7E7FC 100%)`;

                    return (
                      <MDBCol size="3">
                        <div
                          className="project-card"
                          style={{
                            borderRadius: 12,
                            marginTop: 20,
                            cursor: "pointer",
                            paddingRight: 4,
                          }}
                          onClick={() => {
                            if (project.completed) {
                              setCurrentPlaybackID(project.playbackID);
                              setVideoOpen(true);
                            }
                          }}
                        >
                          {project.completed === false ? (
                            <div
                              style={{
                                width: 356.6,
                                height: 186,
                                objectFit: "cover",
                                backgroundColor: "black",
                                borderRadius: 10.5,
                                marginLeft: index % 4 === 0 ? 0.4 : 0,
                                borderBottom: `6px solid`,
                                borderImage: `${gradient} 1 / 0 0 6px 0`,
                                color: "white",
                                fontFamily: "SSMedium",
                                textAlign: "center",
                                fontSize: 24,
                                paddingTop: 60,
                              }}
                            >
                              <p>Creating video...</p>
                            </div>
                          ) : (
                            <div className="image-container">
                              <img
                                className="image-container"
                                src={`https://image.mux.com/${project.playbackID}/thumbnail.png`}
                                style={{
                                  width: 356.6,
                                  height: 186,
                                  objectFit: "cover",
                                  borderRadius: 10.5,
                                  marginLeft: index % 4 === 0 ? 0.4 : 0,
                                  borderBottom:
                                    project.completed === false
                                      ? `6px solid`
                                      : null, // Removed the gradient from here
                                  borderImage:
                                    project.completed === false
                                      ? `${gradient} 1 / 0 0 6px 0`
                                      : null, // Applying gradient only to the bottom border
                                }}
                              />
                            </div>
                          )}

                          {project.completed && (
                            <div
                              style={{
                                position: "absolute",
                                height: 40,
                                width: 40,
                                borderRadius: "50%",
                                backgroundColor: "#fff",
                                marginTop: -116,
                                marginLeft: 160,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setCurrentPlaybackID(project.playbackID);
                                setVideoOpen(true);
                              }}
                              className="d-flex justify-content-center edit-icon"
                            >
                              <FiPlay
                                style={{ color: "black", marginTop: 12 }}
                              />
                            </div>
                          )}
                          {project.completed === false && (
                            <>
                              <div
                                style={{
                                  position: "absolute",
                                  height: 30,
                                  width: 30,
                                  borderRadius: "50%",
                                  backgroundColor: "#fff",
                                  marginTop: -46,
                                  marginLeft: 10,
                                }}
                                className="d-flex justify-content-center"
                              >
                                <TailSpin
                                  height="17"
                                  width="17"
                                  color="#ed1165"
                                  ariaLabel="tail-spin-loading"
                                  radius="3"
                                  wrapperStyle={{ marginTop: 2 }}
                                  wrapperClass="d-inline"
                                  visible={true}
                                />
                              </div>
                              <div
                                style={{
                                  position: "absolute",
                                  height: 30,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  borderRadius: 90,
                                  backgroundColor: "white",
                                  marginTop: -46,
                                  marginLeft: 50,
                                  fontFamily: "SSMedium",
                                  fontSize: 14,
                                  paddingTop: 4,
                                  color: "#ed1165",
                                }}
                                className="d-flex justify-content-center"
                              >
                                {project.stage}
                              </div>
                            </>
                          )}
                          <div
                            style={{
                              position: "absolute",
                              height: 25,
                              width: 30,
                              borderRadius: 8,
                              backgroundColor: "#fff",
                              top: 35,
                              right: 10,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setCurrentProject(project.taskId);
                              setProjectModalOpen(true);
                            }}
                            className="d-flex justify-content-center dots-icon"
                          >
                            <BsThreeDots
                              style={{
                                color: "black",
                                marginTop: 2,
                                fontSize: 22,
                              }}
                            />
                          </div>
                          {currentProject === project.taskId &&
                            project.completed && (
                              <ProjectModal
                                projectModalOpen={projectModalOpen}
                                setProjectModalOpen={setProjectModalOpen}
                                projectID={project.taskId}
                                project={project}
                                assetID={project.assetID}
                              />
                            )}
                          {project.status === "Draft" && (
                            <div
                              style={{
                                position: "absolute",
                                backgroundColor: "#f8e4da",
                                fontFamily: "SSRegular",
                                textAlign: "center",
                                fontSize: 12,
                                marginTop: -30,
                                marginLeft: 10,
                                width: 50,
                                color: "rgb(255, 121, 26)",
                                height: 21,
                                paddingTop: 1.2,
                              }}
                              className="d-flex justify-content-center"
                            >
                              Draft
                            </div>
                          )}

                          {project.status === "Error" && (
                            <div
                              style={{
                                position: "absolute",
                                backgroundColor: "#FFCCCB",
                                fontFamily: "SSRegular",
                                textAlign: "center",
                                fontSize: 12,
                                marginTop: -30,
                                marginLeft: 10,
                                width: 50,
                                color: "red",
                                height: 21,
                                paddingTop: 1.2,
                              }}
                              className="d-flex justify-content-center"
                            >
                              Error
                            </div>
                          )}
                          {project.completed && (
                            <div
                              style={{
                                position: "absolute",
                                backgroundColor: "rgb(245, 245, 255)",
                                fontFamily: "SSRegular",
                                textAlign: "center",
                                fontSize: 12,
                                marginTop: -30,
                                marginLeft: 10,
                                paddingLeft: 10,
                                paddingRight: 10,
                                color: "#ed1165",
                                height: 21,
                                paddingTop: 1.4,
                              }}
                              className="d-flex justify-content-center"
                            >
                              <p>
                                <GoDeviceCameraVideo
                                  style={{ marginRight: 4, marginTop: -2.4 }}
                                  className="d-inline"
                                />
                                {project.duration?.toFixed(0)}s
                              </p>
                            </div>
                          )}
                          <div
                            style={{
                              fontFamily: "SSRegular",
                              marginLeft: 12,
                              marginTop: 10,
                            }}
                          >
                            <p>
                              {project.projectName.slice(0, 30)}
                              {project.projectName.length > 30 ? "..." : ""}
                            </p>
                            <p
                              style={{
                                fontSize: 11,
                                opacity: 0.7,
                                marginTop: -15,
                              }}
                            >
                              Arriving:{" "}
                              {project.arriving
                                ? formatDate(project.arriving)
                                : null}
                            </p>
                          </div>
                        </div>
                      </MDBCol>
                    );
                  })}
              </MDBRow>
            </MDBCol>
          )}
          {projectsArray !== null && projectsArray.length === 0 && (
            <>
              <MDBCol className="d-flex justify-content-center" size="12">
                <p
                  style={{
                    fontFamily: "SSBold",
                    fontSize: 30,
                    marginTop: 100,
                    textAlign: "center",
                  }}
                >
                  Create your first campaign
                </p>
              </MDBCol>

              <MDBCol size="12">
                <p
                  style={{
                    fontFamily: "SSMedium",
                    fontSize: 17,
                    textAlign: "center",
                    marginTop: -10,
                  }}
                >
                  Get started creating your first video campaign, and soon after
                  your first videos will appear here to download.
                </p>
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="12">
                <NavLink
                  to="/"
                  exact
                  style={{
                    height: 55,
                    width: 270,
                    backgroundColor: "#ed1165",
                    borderRadius: 7,
                    textAlign: "center",
                    fontFamily: "SSRegular",
                    color: "white",
                    fontSize: 18,
                    paddingTop: 14,
                    cursor: "pointer",
                    marginTop: 5,
                  }}
                >
                  <p>Create first campaign</p>
                </NavLink>
              </MDBCol>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(MyProjects);
