import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation, useHistory } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSignUpModal } from "../Context/SignUpModal";
import { RiCloseFill, RiDeleteBin6Line } from "react-icons/ri";
import {
  FiCheck,
  FiChevronRight,
  FiCopy,
  FiHeadphones,
  FiMail,
  FiPlus,
} from "react-icons/fi";
import { FaCheck, FaLink } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useAuth } from "../Context/AuthContext";
import { TailSpin } from "react-loader-spinner";

const CreateProject = ({ createProjectOpen, setCreateProjectOpen }) => {
  const { projects, workspaceID } = useAuth();
  const [projectName, setProjectName] = useState("");
  const [loading, setLoading] = useState(false);
  const [projectsAdded, setProjectsAdded] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setProjectName("");
    setLoading(false);
  }, [createProjectOpen]);

  const createProject = () => {
    if (projectName && workspaceID) {
      setLoading(true);
      firebase
        .firestore()
        .collection("projects")
        .add({
          ugc: [],
          projectName: projectName,
          workspaceID: workspaceID,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(), // Generate timestamp
        })
        .then((docRef) => {
          // Retrieve the projectID ID after adding the document
          const projectID = docRef.id;
          firebase
            .firestore()
            .collection("workspace")
            .doc(workspaceID)
            .update({
              projects: firebase.firestore.FieldValue.arrayUnion(projectID),
            })
            .then(() => {
              setCreateProjectOpen(false);
            });

          // Update the user document with the workspaceID
        });
    }
  };

  function convertServerTimestampToDate(serverTimestamp) {
    // Convert timestamp to milliseconds
    const milliseconds = serverTimestamp.toMillis();

    // Create a new Date object using the milliseconds
    const dateObject = new Date(milliseconds);

    // Format the date to "M/D/YY" format
    const formattedDate = dateObject.toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "2-digit",
    });

    return formattedDate;
  }

  useEffect(() => {
    setProjectsAdded([]);
  }, [createProjectOpen]);
  return (
    <>
      <SkyLightStateless
        hideOnOverlayClicked={true}
        onOverlayClicked={async () => {
          setCreateProjectOpen(false);
        }}
        onCloseClicked={async () => {
          setCreateProjectOpen(false);
        }}
        isVisible={createProjectOpen}
        overlayStyles={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9000,
          backgroundColor: "#181616",
          opacity: 0.6,
        }}
        dialogStyles={{
          zIndex: 9000,
          position: "fixed",
          width: "215px",
          minHeight: "60px",
          marginTop: "1%",
          top: "14%",
          height: 0,
          backgroundColor: "transparent",
          border: "0px solid #30312c",
          boxShadow: "none",
          left: "0%",
          right: "0%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        closeButtonStyle={{
          color: "#30312c",
          right: -90,
          position: "absolute",
          marginTop: 6,
          zIndex: 90000,
          height: 0,
          fontSize: 50,
          display: "none",
        }}
      >
        <div
          style={{
            paddingBottom: 20,
            width: 300,
            background: "white",
            borderRadius: 8,
            zIndex: 90000,
            paddingLeft: 0,
          }}
          className="backy60"
        >
          <MDBRow>
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                style={{
                  fontFamily: "PPMedium",
                  textAlign: "center",
                  marginTop: 27,
                  fontSize: 19,
                  color: "#2f1878",
                }}
              >
                Create a project
              </p>
            </MDBCol>

            <MDBCol className="d-flex justify-content-center" size="12">
              <input
                style={{
                  height: 40,
                  width: "93%",
                  marginLeft: 0,
                  border: "1px solid rgba(155, 158, 163, 0.5)",
                  borderRadius: 6,
                  display: "inline-block",
                  color: "#30312c",
                  marginTop: -11,
                  backgroundColor: "white",
                  fontFamily: "PPMedium",
                  paddingLeft: 17,
                  fontSize: 13,
                  paddingTop: 3,
                  opacity: 0.7,
                  boxShadow:
                    "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                }}
                maxLength={14}
                value={projectName}
                name="Project Name"
                onChange={(event) => setProjectName(event.target.value)}
                placeholder="Project Name"
              />
            </MDBCol>
            <MDBCol className="d-flex justify-content-center" size="12">
              <div
                onClick={() => {
                  if (!loading) {
                    createProject();
                  }
                }}
                style={{
                  height: 40,
                  width: "93%",
                  borderRadius: 6,
                  border: "1px solid #0c41ab",
                  backgroundColor: "#0c41ab",
                  color: "white",
                  fontSize: 13,
                  paddingTop: 9,
                  textAlign: "center",
                  fontFamily: "PPMedium",
                  cursor: "pointer",
                  marginTop: 10,
                  opacity: loading || !projectName ? 0.5 : 1,
                }}
                className="d-flex justify-content-center"
              >
                {loading ? (
                  <TailSpin
                    height={24}
                    width={24}
                    color="white"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{ marginTop: -3 }}
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  <p style={{ marginTop: 1 }}>Create Project</p>
                )}
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </SkyLightStateless>
    </>
  );
};

export default withRouter(CreateProject);
