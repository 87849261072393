import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";

import "react-phone-number-input/style.css";
import MainMenu from "../Layout/MainMenu";
import { FaRegCompass, FaRegStar } from "react-icons/fa";
import { BsBackpack } from "react-icons/bs";
import { IoFilterSharp } from "react-icons/io5";
import SideNav from "../Layout/SideNav";
import TopBar from "../Layout/TopBar";
import Categories from "./Categories";
import Templates from "./Templates";
import Pricing from "../PricingDashboard/Pricing";
import Questions from "../Dashboard/Questions";
import PricingMenu from "../Layout/PricingMenu";

const Upgrade = () => {
  const categories = [1, 2, 3, 4, 5, 6, 7, 9, 3, 4, 5, 6, 7, 9];
  return (
    <>
      <div
        style={{
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#fff",
            height: "100vh",
            overflowY: "scroll",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 0,
            }}
            size="12"
          >
            <MDBRow style={{ marginTop: 0 }}>
              <PricingMenu />
            </MDBRow>
          </MDBCol>
          <MDBCol
            className="d-flex justify-content-center"
            style={{ paddingLeft: 0 }}
            size="12"
          >
            <div style={{ width: "100%", paddingTop: 90, width: "100%" }}>
              <Pricing />
            </div>
          </MDBCol>
          <MDBCol size="12">
            <Questions />
          </MDBCol>
        </div>
      </div>
    </>
  );
};

export default withRouter(Upgrade);
