import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { LuSearch } from "react-icons/lu";
import "react-phone-number-input/style.css";
import LazyLoad from "react-lazyload";
import { createClient } from "pexels";
import { BsPause, BsPlay } from "react-icons/bs";
const client = createClient(
  "coH5syRJPC9wUWVZJes69rmt24k46YVsey0HtMbJtLFThle7iYAsZNex"
);

const SearchSection = ({
  setSearchSection,
  selectedCategory,
  initImages,
  initVideos,
  music,
  initIcons,
  shapes,
  addItem,
}) => {
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [loadedPages, setLoadedPages] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const searchImages = (searchQuery, page) => {
    if (!loadedPages.includes(page)) {
      setQuery(searchQuery);
      client.photos.search({ query, per_page: 30, page }).then((response) => {
        setSearchResults((prevResults) => [...prevResults, ...response.photos]);
        setLoadedPages((prevLoadedPages) => [...prevLoadedPages, page]);
      });
    }
  };

  const searchIcons = (searchQuery, page) => {
    if (!loadedPages.includes(page)) {
      setQuery(searchQuery);
      var getIcons = firebase.functions().httpsCallable("getIcons");
      getIcons({
        query: query,
        page: page,
      }).then(async (result) => {
        const icons = result.data.icons;
        setSearchResults((prevResults) => [
          ...prevResults,
          ...result.data.icons,
        ]);
        setLoadedPages((prevLoadedPages) => [...prevLoadedPages, page]);
      });
    }
  };

  const searchVideos = (searchQuery, page) => {
    if (!loadedPages.includes(page)) {
      setQuery(searchQuery);
      client.videos.search({ query, per_page: 30, page }).then((response) => {
        if (selectedCategory === "Image") {
          setSearchResults((prevResults) => [
            ...prevResults,
            ...response.photos,
          ]);
        } else if (selectedCategory === "Video") {
          setSearchResults((prevResults) => [
            ...prevResults,
            ...response.videos,
          ]);
        }
        setLoadedPages((prevLoadedPages) => [...prevLoadedPages, page]);
      });
    }
  };

  const handleSearch = (e) => {
    setLoadedPages([]);
    setSearchResults([]);
    if (e.key === "Enter") {
      if (selectedCategory === "Image") {
        searchImages(query, 1);
      } else if (selectedCategory === "Video") {
        searchVideos(query, 1);
      } else if (selectedCategory === "Icon") {
        searchIcons(query, 1);
      }
    }
  };

  const handleScroll = (e) => {
    if (searchResults) {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      const threshold = 3; // Adjust the threshold as needed

      if (Math.abs(scrollHeight - scrollTop - clientHeight) < threshold) {
        const nextPage = page + 1;
        if (!loadedPages.includes(nextPage)) {
          setPage(nextPage);
          if (selectedCategory === "Image") {
            searchImages(query, nextPage);
          } else if (selectedCategory === "Video") {
            searchVideos(query, nextPage);
          } else if (selectedCategory === "Icon") {
            searchIcons(query, nextPage);
          }
        }
      }
    }
  };

  const handlePlayMusic = (song) => {
    if (currentSong && currentSong.Name === song.Name) {
      // If the clicked song is already playing, toggle between play and pause
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } else {
      // If a new song is clicked, stop the current song and play the new one
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setCurrentSong(song);
      setIsPlaying(true);
      audioRef.current = new Audio(song.URL);
      audioRef.current.play();
    }
  };

  const renderSearchResults = () => {
    switch (selectedCategory) {
      case "Image":
        return searchResults.map((image) => (
          <MDBCol size="4" key={image.id}>
            <LazyLoad once>
              <div
                style={{
                  width: "6vw",
                  height: "6vw",
                  borderRadius: 6,
                  cursor: "pointer",
                  backgroundColor: "#1f2124",
                  marginRight: 15,
                  flexShrink: 0,
                  textAlign: "center",
                  marginTop: 10,
                }}
                onClick={() => {
                  addItem({
                    // Replace with the URL of the second video
                    videoRef: React.createRef(),
                    startTime: 0,
                    initialStartTime: 0,
                    endTime: 17,
                    initialEndTime: 17,
                    trimStart: 0,
                    trimEnd: 17,
                    x: 0,
                    y: 0,
                    width: "100%",
                    height: "100%",
                    layerIndex: 2,
                    type: "image",
                    thumbnail: image.src.tiny,
                    color: "black",
                  });
                }}
                className="d-flex justify-content-center"
              >
                <img
                  style={{
                    width: "80%",
                    height: "80%",
                    marginTop: "10%",
                    objectFit: "cover",
                  }}
                  src={image.src.tiny}
                  alt={image.alt}
                />
              </div>
            </LazyLoad>
          </MDBCol>
        ));
      case "Video":
        return searchResults.map((video) => (
          <MDBCol size="4" key={video.id}>
            <LazyLoad once>
              <div
                style={{
                  width: "6vw",
                  height: "6vw",
                  borderRadius: 6,
                  cursor: "pointer",
                  backgroundColor: "#1f2124",
                  marginRight: 15,
                  flexShrink: 0,
                  textAlign: "center",
                  marginTop: 10,
                }}
                onClick={() => {
                  addItem({
                    // Replace with the URL of the second video
                    videoRef: React.createRef(),
                    videoURL: video.video_files[0].link,
                    startTime: 0,
                    initialStartTime: 0,
                    endTime: 17,
                    initialEndTime: 17,
                    trimStart: 0,
                    trimEnd: 17,
                    x: 0,
                    y: 0,
                    width: "100%",
                    height: "100%",
                    layerIndex: 2,
                    type: "video",
                    thumbnail: video.image,
                    color: "black",
                  });
                }}
                className="d-flex justify-content-center"
              >
                <img
                  style={{
                    width: "80%",
                    height: "80%",
                    marginTop: "10%",
                    objectFit: "cover",
                  }}
                  src={video.image}
                />
              </div>
            </LazyLoad>
          </MDBCol>
        ));
      case "Icon":
        return searchResults.map((icon, index) => (
          <MDBCol size="4" key={index}>
            <LazyLoad once>
              <div
                style={{
                  width: "6vw",
                  height: "6vw",
                  borderRadius: 6,
                  cursor: "pointer",
                  backgroundColor: "#1f2124",
                  marginRight: 15,
                  flexShrink: 0,
                  textAlign: "center",
                  marginTop: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  addItem({
                    // Replace with the URL of the second video
                    videoRef: React.createRef(),
                    startTime: 0,
                    initialStartTime: 0,
                    endTime: 17,
                    initialEndTime: 17,
                    trimStart: 0,
                    trimEnd: 17,
                    x: 0,
                    y: 0,
                    width: "100%",
                    height: "100%",
                    layerIndex: 2,
                    type: "icon",
                    thumbnail: icon.preview,
                    color: "black",
                  });
                }}
                className="d-flex justify-content-center"
              >
                <div
                  style={{
                    width: "80%",
                    height: "80%",
                    marginTop: "10%",
                    color: "red",
                    backgroundImage: `url(${icon.preview})`,
                    backgroundSize: "cover",
                    filter:
                      "invert(67%) sepia(68%) saturate(5352%) hue-rotate(211deg) brightness(101%) contrast(102%)",
                  }}
                ></div>
              </div>
            </LazyLoad>
          </MDBCol>
        ));
      default:
        return null;
    }
  };

  const renderInitResults = (selectedCategory) => {
    switch (selectedCategory) {
      case "Image":
        return (
          initImages &&
          initImages.photos.map((image) => (
            <MDBCol size="4">
              <div
                style={{
                  width: "6vw",
                  height: "6vw",
                  borderRadius: 6,
                  cursor: "pointer",
                  backgroundColor: "#1f2124",
                  marginRight: 15,
                  flexShrink: 0,
                  textAlign: "center",
                  marginTop: 10,
                }}
                onClick={() => {
                  addItem({
                    // Replace with the URL of the second video
                    videoRef: React.createRef(),
                    startTime: 0,
                    initialStartTime: 0,
                    endTime: 17,
                    initialEndTime: 17,
                    trimStart: 0,
                    trimEnd: 17,
                    x: 0,
                    y: 0,
                    width: "100%",
                    height: "100%",
                    layerIndex: 2,
                    type: "image",
                    thumbnail: image.src.tiny,
                    color: "black",
                  });
                }}
                className="d-flex justify-content-center"
              >
                <img
                  style={{ width: "80%", height: "80%", marginTop: "10%" }}
                  src={image.src.tiny}
                />
              </div>
            </MDBCol>
          ))
        );
      case "Video":
        return (
          initVideos &&
          initVideos.videos.map((video) => (
            <MDBCol size="4">
              <div
                style={{
                  width: "6vw",
                  height: "6vw",
                  borderRadius: 6,
                  cursor: "pointer",
                  backgroundColor: "#1f2124",
                  marginRight: 15,
                  flexShrink: 0,
                  textAlign: "center",
                  marginTop: 10,
                }}
                onClick={() => {
                  addItem({
                    // Replace with the URL of the second video
                    videoRef: React.createRef(),
                    videoURL: video.video_files[0].link,
                    startTime: 0,
                    initialStartTime: 0,
                    endTime: 17,
                    initialEndTime: 17,
                    trimStart: 0,
                    trimEnd: 17,
                    x: 0,
                    y: 0,
                    width: "100%",
                    height: "100%",
                    layerIndex: 2,
                    type: "video",
                    thumbnail: video.image,
                    color: "black",
                  });
                }}
                className="d-flex justify-content-center"
              >
                <img
                  style={{ width: "80%", height: "80%", marginTop: "10%" }}
                  src={video.image}
                />
              </div>
            </MDBCol>
          ))
        );
      case "Icon":
        return (
          initIcons &&
          initIcons.map((icon) => (
            <MDBCol size="4">
              <div
                style={{
                  width: "6vw",
                  height: "6vw",
                  borderRadius: 6,
                  cursor: "pointer",
                  backgroundColor: "#1f2124",
                  marginRight: 15,
                  flexShrink: 0,
                  textAlign: "center",
                  marginTop: 10,
                }}
                onClick={() => {
                  addItem({
                    // Replace with the URL of the second video
                    videoRef: React.createRef(),
                    startTime: 0,
                    initialStartTime: 0,
                    endTime: 17,
                    initialEndTime: 17,
                    trimStart: 0,
                    trimEnd: 17,
                    x: 0,
                    y: 0,
                    width: "100%",
                    height: "100%",
                    layerIndex: 2,
                    type: "icon",
                    thumbnail: icon.preview,
                    color: "black",
                  });
                }}
                className="d-flex justify-content-center"
              >
                <div
                  style={{
                    width: "80%",
                    height: "80%",
                    marginTop: "10%",
                    color: "red",
                    backgroundImage: `url(${icon.preview})`,
                    backgroundSize: "cover",
                    filter:
                      "invert(67%) sepia(68%) saturate(5352%) hue-rotate(211deg) brightness(101%) contrast(102%)",
                  }}
                ></div>
              </div>
            </MDBCol>
          ))
        );
      case "Shape":
        return (
          shapes &&
          shapes.map((shape) => (
            <MDBCol size="4">
              <div
                style={{
                  width: "6vw",
                  height: "6vw",
                  borderRadius: 6,
                  cursor: "pointer",
                  backgroundColor: "#1f2124",
                  marginRight: 15,
                  flexShrink: 0,
                  textAlign: "center",
                  marginTop: 0,
                }}
                className="d-flex justify-content-center"
              >
                <div
                  style={{
                    width: "80%",
                    height: "80%",
                    marginTop: "10%",
                    color: "red",
                    backgroundImage: `url(${shape})`,
                    backgroundSize: "cover",
                    filter:
                      "invert(67%) sepia(68%) saturate(5352%) hue-rotate(211deg) brightness(101%) contrast(102%)",
                  }}
                ></div>
              </div>
            </MDBCol>
          ))
        );
      case "Music":
        return (
          music &&
          music.map((song, index) => (
            <MDBCol size="12">
              <div
                key={index}
                style={{
                  width: "98%",
                  height: "4vw",
                  borderRadius: 3,
                  cursor: "pointer",
                  backgroundColor: "#1f2124",
                  marginRight: 15,
                  flexShrink: 0,
                  marginTop: 10,
                  display: "flex",
                  color: "white",
                }}
                onClick={() => {
                  addItem({
                    // Replace with the URL of the second video
                    videoRef: React.createRef(),
                    startTime: 0,
                    initialStartTime: 0,
                    endTime: 17,
                    initialEndTime: 17,
                    trimStart: 0,
                    trimEnd: 17,
                    x: 0,
                    y: 0,
                    width: "100%",
                    height: "100%",
                    layerIndex: 2,
                    videoURL: song.URL,
                    name: song.Name,
                    type: "music",
                    color: "black",
                    volume: 0.5,
                  });
                }}
              >
                {currentSong && currentSong.Name === song.Name && isPlaying ? (
                  <BsPause
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePlayMusic(song);
                    }}
                    style={{ marginTop: 23, marginLeft: 23, fontSize: 33 }}
                  />
                ) : (
                  <BsPlay
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePlayMusic(song);
                    }}
                    style={{ marginTop: 23, marginLeft: 23, fontSize: 33 }}
                  />
                )}

                <p
                  style={{
                    fontFamily: "PPMedium",
                    fontSize: 16,
                    marginTop: 17,
                    marginLeft: 17,
                  }}
                >
                  {song.Name}
                  <p
                    style={{
                      fontFamily: "PPMedium",
                      fontSize: 12,
                      marginTop: 4,
                      marginLeft: 0,
                    }}
                  >
                    {song.Duration}
                  </p>
                </p>
              </div>
            </MDBCol>
          ))
        );
    }
  };

  return (
    <MDBRow style={{ marginTop: 95, marginLeft: 0, overflowY: "hidden" }}>
      <MDBCol size="12">
        <p
          style={{
            fontSize: 20,
            color: "#fff",
            fontFamily: "PPBold",
            opacity: 0.9,
          }}
        >
          <MdOutlineKeyboardBackspace
            onClick={() => setSearchSection(false)}
            style={{ marginRight: 17, marginTop: -2, cursor: "pointer" }}
          />
          {selectedCategory}
        </p>
      </MDBCol>
      {selectedCategory !== "Music" && selectedCategory !== "Shape" && (
        <MDBCol size="12">
          <input
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleSearch}
            placeholder={
              selectedCategory === "Music"
                ? "Search for music"
                : `Search for ${selectedCategory.toLowerCase()}s`
            }
            style={{
              backgroundColor: "transparent",
              border: "1px solid rgba(255, 255, 255, 0.1)",
              width: "90%",
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 40,
              borderRadius: 4,
              fontFamily: "PPMedium",
              fontSize: 14,
              color: "white",
            }}
          />
          <LuSearch
            style={{
              position: "absolute",
              color: "white",
              left: 30,
              fontSize: 15,
              marginTop: 14,
            }}
          />
        </MDBCol>
      )}
      <MDBRow
        onScroll={handleScroll}
        style={{
          overflowY: "scroll",
          height: selectedCategory === "Music" ? "85vh" : "75vh",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          width: "100%",
          paddingBottom: 30,
          marginTop: selectedCategory === "Music" ? 0 : 20,
          WebkitOverflowScrolling: "touch",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {searchResults.length > 0
          ? renderSearchResults(selectedCategory)
          : renderInitResults(selectedCategory)}
      </MDBRow>
    </MDBRow>
  );
};

export default withRouter(SearchSection);
