import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { TailSpin } from "react-loader-spinner";
import { FaShippingFast } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { MdOutlinePhonelink } from "react-icons/md";

const Step1 = ({ editorID, creatorID }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);

  const handleVerification = async () => {
    if (creatorID || editorID) {
      setLoading(true);
      try {
        const createVerification = firebase
          .functions()
          .httpsCallable("createVerification");
        const result = await createVerification({
          creatorID: creatorID,
          editorID: editorID,
        });
        return (window.location.href = result.data.sessionURL); // Redirect to the URL directly
      } catch (error) {
        console.error("Error starting verification process:", error);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <MDBCol size="12">
      <div>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
          }}
        >
          Step 1 of 4
        </p>
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 24,
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          Verify your identity
        </p>
        <p
          style={{
            fontFamily: "SSRegular",
            marginTop: 10,
            lineHeight: 1.7,
            width: 410,
          }}
        >
          Click on the button below to verify your identity. You'll need some
          identification ready like a passport or driving license and a selfie.
          It will take a couple of minutes.
        </p>
      </div>
      <div
        style={{
          height: 55,
          width: 410,
          backgroundColor: "#ed1165",
          borderRadius: 7,
          marginTop: 20,
          textAlign: "center",
          fontFamily: "SSRegular",
          color: "white",
          fontSize: 18,
          paddingTop: 14,
          cursor: "pointer",
          opacity: creatorID || editorID ? 1 : 0.5,
        }}
        onClick={() => {
          handleVerification();
        }}
        className="d-flex justify-content-center"
      >
        {loading ? (
          <TailSpin
            height="25"
            width="25"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{ marginTop: 0 }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            <p>Verify my identity</p>
          </>
        )}
      </div>
    </MDBCol>
  );
};

export default withRouter(Step1);
