import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import { AiOutlinePlayCircle, AiOutlineRight } from "react-icons/ai";
import { useLoading } from "../Context/LoadingContext";
import { BsPlayCircle } from "react-icons/bs";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import MuxPlayer from "@mux/mux-player-react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { BiRightArrowAlt } from "react-icons/bi";
import { FaDownload, FaLock } from "react-icons/fa";
import mixpanel from "mixpanel-browser";
import { useAuth } from "../Context/AuthContext";
import { firebase } from "../../../firebase/config";
import { TailSpin } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const HeadingMobile = ({ setIsOpen, requestLoading }) => {
  const [loading, setLoading] = useState(false);
  const [playbackID, setPlaybackID] = useState("");
  const [videoID, setVideoID] = useState("");
  const [error, setError] = useState("");
  const { paid } = useAuth();
  const { t } = useTranslation();

  if (requestLoading === true) {
    return null;
  }

  const triggerDownload = (url, fileName) => {
    // Create a new anchor element
    const anchor = document.createElement("a");

    // Set the href to the download URL
    anchor.href = url;

    // Optionally set the download attribute to a filename
    anchor.download = fileName || "downloaded_video.mp4";

    // Append the anchor to the document
    document.body.appendChild(anchor);

    // Trigger the download by simulating a click
    anchor.click();

    // Remove the anchor from the document
    document.body.removeChild(anchor);
  };

  const downloadVideo = async (videoID) => {
    setError(null);
    setLoading(true);
    if (videoID) {
      var downloadVideo = firebase.functions().httpsCallable("downloadVideo");
      downloadVideo({ videoID: videoID }).then((result) => {
        if (result.data.error) {
          mixpanel.track("Failed to download video", {
            reason: result.data.error,
          });
          console.log(result.data.error);
          setLoading(false);
          return setError(result.data.error);
        } else {
          const url = result.data.url;

          if (url) {
            mixpanel.track("User downloaded a video successfully");
            //navigate to the success page
            triggerDownload(url, "video.mp4");
            setLoading(false);
            setError(null);
          }
        }
      });
    }
  };

  useEffect(() => {
    let query = window.location.search;
    const queryParams = new URLSearchParams(query);
    const playbackID = queryParams.get("videoID");
    setPlaybackID(playbackID);

    if (playbackID) {
      // Query the Firestore database
      firebase
        .firestore()
        .collection("videos") // Adjust if your collection is named differently
        .where("playbackID", "==", playbackID) // Adjust field name as per your database schema
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0]; // Assuming playbackID is unique and only one document is expected
            setVideoID(doc.id); // Saving the videoID to state
          } else {
            console.log("No video found with the given playbackID");
          }
        })
        .catch((error) => {
          console.error("Error fetching video: ", error);
        });
    }
  }, []);
  return (
    <MDBRow
      style={{
        color: "white",
        paddingTop: 60,
        width: "100vw",
        paddingLeft: 10,
      }}
    >
      <MDBCol className="d-flex justify-content-center" size="12">
        <NavLink
          style={{
            display: "inline-block",
            cursor: "pointer",
            zIndex: 4000,
          }}
          to="/"
          exact
        >
          <div
            style={{
              color: "rgb(242, 241, 243)",
              fontSize: 24,
              display: "flex",
              alignItems: "center",
              marginLeft: 20,
              fontFamily: "MABlack",
              cursor: "pointer",
              zIndex: 4000,
            }}
          >
            <img
              src={require("./logos.png")}
              style={{
                height: 33,
                left: 21,
                marginTop: -1.6,
              }}
              className="d-inline"
            />
          </div>
        </NavLink>
      </MDBCol>
      <MDBCol
        className="d-flex justify-content-center"
        style={{ cursor: "pointer" }}
        size="12"
      >
        <p
          style={{
            fontFamily: "MAHeavy",
            fontSize: "6vw",
            marginTop: 30,
            textAlign: "center",
            color: "black",
          }}
        >
          {t("viralTitle")}
        </p>
      </MDBCol>
      <MDBCol
        className="d-flex justify-content-center"
        style={{ cursor: "pointer" }}
        size="12"
      >
        <NavLink
          onClick={() => {
            mixpanel.track("Viral page click back to home", {
              videoID: videoID,
            });
          }}
          to="/"
          exact
          style={{
            fontFamily: "CSBold",
            fontSize: "4.5vw",
            marginTop: -8,
            textDecoration: "underline",
            opacity: 1,
            color: "black",
          }}
        >
          {t("viralLink")}
        </NavLink>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <MDBRow style={{ width: 800 }}>
          <MDBCol className="d-flex justify-content-center" size="12">
            <div
              style={{
                position: "relative",
                display: "inline-block",
                height: "50vw",
                width: "90vw",
                borderRadius: 5,
                overflow: "hidden",
                marginTop: 27,
                border: "2px solid black",
              }}
              onClick={() => {
                mixpanel.track("Watched video on viral page", {
                  videoID: videoID,
                });
              }}
            >
              {videoID && (
                <MuxPlayer
                  streamType="on-demand"
                  accent-color="#f8284e"
                  playbackId={playbackID}
                  style={{
                    display: "inline-block",

                    height: "50vw",
                    width: "90vw",
                    marginLeft: 0,
                    borderRadius: 20,
                    objectFit: "cover",
                    verticalAlign: "top",
                    backgroundColor: "transparent", // Added this line
                    aspectRatio: 9 / 16,
                  }}
                  onPlay={() => {
                    if (!paid) {
                      mixpanel.track("Free user plays video", {
                        videoID: videoID,
                      });
                    }
                  }}
                />
              )}
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <p
          style={{
            fontFamily: "MABold",
            color: "black",
            fontSize: "4.8vw",
            marginTop: 50,
          }}
        >
          {t("shareText")}
        </p>
      </MDBCol>
      <MDBCol
        style={{ marginTop: 10 }}
        className="d-flex justify-content-center"
        size="12"
      >
        <div
          onClick={() => {
            mixpanel.track("Shared the video", {
              videoID: videoID,
            });
          }}
          style={{ marginTop: -7 }}
        >
          <FacebookShareButton
            style={{ display: "inline-block" }}
            url={window.location.href}
          >
            <FacebookIcon size={40} round />
          </FacebookShareButton>
          <LinkedinShareButton
            style={{ display: "inline-block", marginLeft: 10 }}
            url={window.location.href}
          >
            <LinkedinIcon size={40} round />
          </LinkedinShareButton>
          <WhatsappShareButton
            style={{ display: "inline-block", marginLeft: 10 }}
            url={window.location.href}
          >
            <WhatsappIcon size={40} round />
          </WhatsappShareButton>
          <RedditShareButton
            style={{ display: "inline-block", marginLeft: 10 }}
            url={window.location.href}
          >
            <RedditIcon size={40} round />
          </RedditShareButton>
          <TelegramShareButton
            style={{ display: "inline-block", marginLeft: 10 }}
            url={window.location.href}
          >
            <TelegramIcon size={40} round />
          </TelegramShareButton>
          <TwitterShareButton
            style={{ display: "inline-block", marginLeft: 10 }}
            url={window.location.href}
          >
            <TwitterIcon size={40} round />
          </TwitterShareButton>
        </div>
      </MDBCol>
      <MDBCol size="12" className="d-flex justify-content-center">
        {firebase.auth().currentUser && videoID && paid ? (
          <div
            onClick={async () => {
              if (!loading) {
                await downloadVideo(videoID);
              }
            }}
            exact
            style={{
              height: 54,
              width: "90vw",
              border: "2px solid #f8284e",
              borderRadius: 100,
              marginTop: 39,
              backgroundColor: "#f8284e",
              opacity: loading ? 0.5 : 1,
              cursor: loading ? null : "pointer",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            {loading ? (
              <TailSpin
                height={30}
                width={30}
                color="white"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{ margin: 0 }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <p
                style={{
                  fontFamily: "CSMedium",
                  fontSize: "3.9vw",
                  display: "inline-block",
                  color: "white",
                  margin: 0,
                  zIndex: 300,
                }}
              >
                {t("downloadButton")}
                <FaDownload
                  style={{ fontSize: "2.4vw", marginLeft: 11 }}
                  className="d-inline"
                />
              </p>
            )}
          </div>
        ) : (
          <NavLink
            to="/checkout"
            onClick={() => mixpanel.track("Clicked explore on Landing Page")}
            exact
            style={{
              height: 54,
              width: "90vw",
              border: "2px solid rgb(255, 215, 0)",
              borderRadius: 100,
              marginTop: 39,
              backgroundColor: "rgb(255, 215, 0)",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <p
              style={{
                fontFamily: "CSMedium",
                fontSize: "3.9vw",
                display: "inline-block",
                color: "black",
                marginLeft: 0,
                marginTop: 13,
                zIndex: 300,
              }}
            >
              <FaLock
                style={{ marginTop: -2.9, fontSize: "2.4vw", marginRight: 11 }}
                className="d-inline"
              />
              {t("UpgradeWatermark")}
            </p>
          </NavLink>
        )}
      </MDBCol>
      <MDBCol size="12" className="d-flex justify-content-center">
        {error && (
          <p
            style={{
              marginTop: 19,
              color: "red",
              fontFamily: "CSMedium",
            }}
          >
            {error}
          </p>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(HeadingMobile);
