import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { NavLink, withRouter } from "react-router-dom";
import { MDBCol, MDBRow } from "mdbreact";
import DashboardFooter from "../Layout/DashboardFooter";
import DashboardNavBarMobile from "../Layout/DashboardNavBarMobile";
import HeadingMobile from "./HeadingMobile";
import ExplainerMobile from "./ExplainerMobile";
import DashboardFooterMobile from "../Layout/DashboardFooterMobile";

const LandingMobile = ({ profiles }) => {
  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          width: "100vw",
          backgroundColor: "#E8E8E8",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#E8E8E8",
            width: "100vw",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 0,
            }}
            size="12"
          >
            <MDBRow
              className="d-flex justify-content-center"
              style={{ marginTop: 0 }}
            >
              <DashboardNavBarMobile />
            </MDBRow>

            <MDBCol className="d-flex justify-content-center" size="12">
              <HeadingMobile profiles={profiles} />
            </MDBCol>
            <ExplainerMobile />
          </MDBCol>
          <DashboardFooterMobile />
        </div>
      </div>
    </>
  );
};

export default withRouter(LandingMobile);
