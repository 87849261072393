import React, { createContext, useState, useEffect, useContext } from "react";
import { firebase } from "../../../firebase/config";

const RatesContext = createContext();

export const useRates = () => useContext(RatesContext);

export const RatesProvider = ({ children }) => {
  const [rates, setRates] = useState({});
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState("");
  const [currentRate, setCurrentRate] = useState(null);
  const [currentCurrencySymbol, setCurrentCurrencySymbol] = useState(""); // Default to GBP
  const [discount, setDiscount] = useState(false); // New state variable for discount

  // Fetch country code
  useEffect(() => {
    fetch("https://ipinfo.io/152.37.109.16?token=bcd3ee8b96bbc6")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setCountry(data.country);
        setDiscount(
          !["KR", "JP", "US", "GB"].includes(data.country) &&
            !euroZoneCountries.includes(data.country)
        );
      })
      .catch((error) => console.error("Error fetching country:", error));
  }, []);

  // Fetch rates and find the current rate
  useEffect(() => {
    const fetchRates = async () => {
      try {
        setLoading(true);
        const ratesCollection = await firebase
          .firestore()
          .collection("rates")
          .get();
        const ratesObj = ratesCollection.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data().value;
          return acc;
        }, {});
        setRates(ratesObj);

        // Set the current rate based on the country
        const rateKey = countryToRateKey(country);
        const currencySymbol = currencyCodeToSymbol(rateKey);
        setCurrentCurrencySymbol(currencySymbol);
        setCurrentRate(ratesObj[rateKey] || ratesObj["USD"]);
      } catch (error) {
        console.error("Error fetching rates:", error);
      } finally {
        setLoading(false);
      }
    };

    if (country) {
      fetchRates();
    }
  }, [country]);

  const currencyCodeToSymbol = (currencyCode) => {
    const symbolMapping = {
      BRL: "R$",
      EUR: "€",
      GBP: "£",
      IDR: "Rp",
      INR: "₹",
      JPY: "¥",
      KRW: "₩",
      MXN: "$",
      MYR: "RM",
      PHP: "₱",
      THB: "฿",
      TWD: "NT$",
      USD: "$",
      // Add other currency codes and symbols
    };
    return symbolMapping[currencyCode] || "£"; // Default to GBP if not found
  };

  // Map country code to the rate key
  const countryToRateKey = (countryCode) => {
    const euroZoneCountries = [
      "AT",
      "BE",
      "CY",
      "EE",
      "FI",
      "FR",
      "DE",
      "GR",
      "IE",
      "IT",
      "LV",
      "LT",
      "LU",
      "MT",
      "NL",
      "PT",
      "SK",
      "SI",
      "ES",
    ]; // List of Eurozone country codes
    const mapping = {
      BR: "BRL",
      GB: "GBP",
      ID: "IDR",
      IN: "INR",
      JP: "JPY",
      KR: "KRW",
      MX: "MXN",
      MY: "MYR",
      PH: "PHP",
      TH: "THB",
      TW: "TWD",
      US: "USD",
      // Add other non-Eurozone country codes and their corresponding rate keys
    };

    return euroZoneCountries.includes(countryCode)
      ? "EUR"
      : mapping[countryCode] || "USD";
  };

  return (
    <RatesContext.Provider
      value={{
        rates,
        loading,
        country,
        currentRate,
        currentCurrencySymbol,
        discount,
      }}
    >
      {children}
    </RatesContext.Provider>
  );
};

export default RatesContext;
