import React, { useState, useMemo, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { MDBCol, MDBRow } from "mdbreact";
import { TailSpin } from "react-loader-spinner";
import { firebase } from "../../../firebase/config";
import { useAuth } from "../Context/AuthContext";
import Select from "react-select";
import countryList from "react-select-country-list";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { customerId, initBillingDetails } = useAuth();
  const [loading, setLoading] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const [successfullySaved, setSuccessfullySaved] = useState(false);
  const [billingError, setBillingError] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    name: "",
    email: "",
    address: {
      line1: "",
      city: "",
      state: "",
      postal_code: "",
      country: "US",
    },
  });

  console.log("Options are", options);

  useEffect(() => {
    if (initBillingDetails) {
      setBillingDetails(initBillingDetails);
    }
  }, [initBillingDetails]);

  const handleSubmit = async (event) => {
    setBillingError(false);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const cardElement = elements.getElement(CardElement);

    try {
      const uid = firebase.auth().currentUser.uid;

      // Create Stripe Customer if needed
      let createCustomerResult;
      if (!customerId) {
        createCustomerResult = await firebase
          .functions()
          .httpsCallable("createStripeCustomer")({
          name: billingDetails.name,
          email: billingDetails.email,
          uid: uid,
        });
      }

      const newCustomerID = customerId
        ? customerId
        : createCustomerResult?.data?.customerId;

      // Create Setup Intent
      const setupIntentResult = await firebase
        .functions()
        .httpsCallable("createSetupIntent")({
        customerId: newCustomerID,
      });

      const clientSecret = setupIntentResult.data.clientSecret;

      // Confirm Setup Intent
      const { setupIntent, error } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: billingDetails,
          },
        }
      );

      if (error) {
        console.error("Error confirming Setup Intent:", error);
        setLoading(false);
        setBillingError(true);
        return;
      }

      console.log("Setup Intent confirmed:", setupIntent);

      // Save Billing Details and Payment Method
      const saveBillingDetailsResult = await firebase
        .functions()
        .httpsCallable("saveBillingDetails")({
        customerId: newCustomerID,
        paymentMethodId: setupIntent.payment_method,
        billingDetails,
      });

      await firebase.firestore().collection("users").doc(uid).update({
        billingDetails,
        paymentMethodId: setupIntent.payment_method,
      });

      if (saveBillingDetailsResult.data.success) {
        console.log("Card and billing details saved successfully");
      } else {
        setLoading(false);
        setBillingError(true);
        console.error("Failed to save card and billing details");
      }
    } catch (error) {
      setLoading(false);
      setBillingError(true);
      console.error("Error during payment process:", error);
    }

    setSuccessfullySaved(true);

    setTimeout(() => {
      setSuccessfullySaved(false);
      setLoading(false);
    }, 5000);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name in billingDetails.address) {
      setBillingDetails((prevDetails) => ({
        ...prevDetails,
        address: {
          ...prevDetails.address,
          [name]: value,
        },
      }));
    } else {
      setBillingDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <MDBRow style={{ width: 480 }}>
        <MDBCol size="6">
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={billingDetails.name}
            onChange={handleChange}
            required
            style={{
              height: 55,
              width: 230,
              marginLeft: 0,
              border: "1px solid rgba(155, 158, 163, 0.5)",
              borderRadius: 7,
              display: "inline-block",
              color: "black",
              marginTop: 5,
              backgroundColor: "white",
              fontFamily: "SSMedium",
              paddingLeft: 17,
              fontSize: 15,
              paddingTop: 3,
            }}
          />
        </MDBCol>
        <MDBCol size="6">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={billingDetails.email}
            onChange={handleChange}
            required
            style={{
              height: 55,
              width: 220,
              marginLeft: 0,
              border: "1px solid rgba(155, 158, 163, 0.5)",
              borderRadius: 7,
              display: "inline-block",
              color: "black",
              marginTop: 5,
              backgroundColor: "white",
              fontFamily: "SSMedium",
              paddingLeft: 17,
              fontSize: 15,
              paddingTop: 3,
            }}
          />
        </MDBCol>
        <MDBCol size="12">
          <input
            type="text"
            name="line1"
            placeholder="Address"
            value={billingDetails.address.line1}
            onChange={handleChange}
            required
            style={{
              height: 55,
              width: 460,
              marginLeft: 0,
              border: "1px solid rgba(155, 158, 163, 0.5)",
              borderRadius: 7,
              display: "inline-block",
              color: "black",
              marginTop: 5,
              backgroundColor: "white",
              fontFamily: "SSMedium",
              paddingLeft: 17,
              fontSize: 15,
              paddingTop: 3,
            }}
          />
        </MDBCol>
        <MDBCol size="6">
          <input
            type="text"
            name="city"
            placeholder="City"
            value={billingDetails.address.city}
            onChange={handleChange}
            required
            style={{
              height: 55,
              width: 230,
              marginLeft: 0,
              border: "1px solid rgba(155, 158, 163, 0.5)",
              borderRadius: 7,
              display: "inline-block",
              color: "black",
              marginTop: 5,
              backgroundColor: "white",
              fontFamily: "SSMedium",
              paddingLeft: 17,
              fontSize: 15,
              paddingTop: 3,
            }}
          />
        </MDBCol>
        <MDBCol size="6">
          <input
            type="text"
            name="state"
            placeholder="State"
            value={billingDetails.address.state}
            onChange={handleChange}
            required
            style={{
              height: 55,
              width: 220,
              marginLeft: 0,
              border: "1px solid rgba(155, 158, 163, 0.5)",
              borderRadius: 7,
              display: "inline-block",
              color: "black",
              marginTop: 5,
              backgroundColor: "white",
              fontFamily: "SSMedium",
              paddingLeft: 17,
              fontSize: 15,
              paddingTop: 3,
            }}
          />
        </MDBCol>
        <MDBCol size="6">
          <input
            type="text"
            name="postal_code"
            placeholder="Postal Code"
            value={billingDetails.address.postal_code}
            onChange={handleChange}
            required
            style={{
              height: 55,
              width: 230,
              marginLeft: 0,
              border: "1px solid rgba(155, 158, 163, 0.5)",
              borderRadius: 7,
              display: "inline-block",
              color: "black",
              marginTop: 5,
              backgroundColor: "white",
              fontFamily: "SSMedium",
              paddingLeft: 17,
              fontSize: 15,
              paddingTop: 3,
            }}
          />
        </MDBCol>
        <MDBCol size="6">
          <div
            style={{
              height: 55,
              width: 220,
              marginLeft: 0,
              border: "1px solid rgba(155, 158, 163, 0.5)",
              borderRadius: 7,
              display: "inline-block",
              marginTop: 5,
              color: "black",
              backgroundColor: "white",
              fontFamily: "SSMedium",
              paddingLeft: 17,
              fontSize: 15,
              paddingTop: 4.5,
              paddingRight: 20,
            }}
          >
            <select
              style={{
                height: 45,
                width: 190,
                marginLeft: 0,
                border: "0px solid rgba(155, 158, 163, 0.5)",
                borderRadius: 7,
                display: "inline-block",
                color: "black",
                backgroundColor: "white",
                fontFamily: "SSMedium",
                fontSize: 15,
              }}
              type="text"
              name="country"
              placeholder="Country"
              value={billingDetails.address.country}
              onChange={handleChange}
              required
              defaultValue={"US"}
            >
              {options.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
        </MDBCol>
        <MDBCol size="12">
          <div
            style={{
              height: 55,
              width: 460,
              marginLeft: 0,
              border: "1px solid rgba(155, 158, 163, 0.5)",
              borderRadius: 7,
              display: "inline-block",
              color: "black",
              marginTop: 5,
              backgroundColor: "white",
              fontFamily: "SSMedium",
              paddingLeft: 17,
              fontSize: 15,
              paddingTop: 20,
              paddingRight: 20,
            }}
          >
            <CardElement />
          </div>
        </MDBCol>
        <MDBCol size="12">
          {successfullySaved ? (
            <button
              style={{
                height: 55,
                width: 460,
                backgroundColor: "#32de84",
                borderRadius: 7,
                marginTop: 10,
                textAlign: "center",
                fontFamily: "SSRegular",
                color: "white",
                fontSize: 18,
                paddingTop: 14,
                cursor: "pointer",
                opacity: 1,
                border: "none",
              }}
              type="submit"
              disabled={!stripe || loading}
              className="d-flex justify-content-center"
            >
              <p>Saved!</p>
            </button>
          ) : (
            <button
              style={{
                height: 55,
                width: 460,
                backgroundColor: "rgb(237, 17, 101)",
                borderRadius: 7,
                marginTop: 10,
                textAlign: "center",
                fontFamily: "SSRegular",
                color: "white",
                fontSize: 18,
                paddingTop: 14,
                cursor: "pointer",
                opacity: 1,
                border: "none",
              }}
              type="submit"
              disabled={!stripe || loading}
              className="d-flex justify-content-center"
            >
              {loading ? (
                <TailSpin
                  height="25"
                  width="25"
                  color="#fff"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{ marginTop: 0 }}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                <>
                  <p>Save Billing Details</p>
                </>
              )}
            </button>
          )}
          {billingError && (
            <p
              style={{
                fontFamily: "SSRegular",
                marginTop: 5,
                fontSize: 13,
                opacity: 0.7,
                width: 480,
                fontStyle: "italic",
                color: "red",
                cursor: "pointer",
              }}
            >
              There was an error with your billing details. Try again.
            </p>
          )}
        </MDBCol>
      </MDBRow>
    </form>
  );
};

export default CheckoutForm;
