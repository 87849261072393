import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation, useHistory } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen, HiSwitchHorizontal } from "react-icons/hi";
import { BiColorFill, BiHelpCircle, BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSignUpModal } from "../Context/SignUpModal";
import { TfiDownload } from "react-icons/tfi";
import {
  RiCloseFill,
  RiDeleteBin5Line,
  RiDeleteBin7Line,
  RiRedPacketLine,
  RiTrophyLine,
} from "react-icons/ri";
import {
  FiCheck,
  FiCopy,
  FiCreditCard,
  FiHeadphones,
  FiMail,
  FiPlus,
  FiShoppingBag,
} from "react-icons/fi";
import {
  FaDownload,
  FaLink,
  FaRegBuilding,
  FaRegCopy,
  FaRegImage,
} from "react-icons/fa";
import { useDetectClickOutside } from "react-detect-click-outside";
import { AiOutlineLogout } from "react-icons/ai";
import { useAuth } from "../Context/AuthContext";
import { HexColorPicker } from "react-colorful";
import {
  TbAlignBoxCenterMiddle,
  TbAlignBoxLeftMiddle,
  TbAlignBoxRightMiddle,
  TbAlignCenter,
  TbAlignLeft,
  TbAlignRight,
  TbBoxAlignLeft,
} from "react-icons/tb";
import { TailSpin } from "react-loader-spinner";
import { LuPen } from "react-icons/lu";

const PriorityModal = ({
  priorityModalOpen,
  setPriorityModalOpen,
  campaignID,
}) => {
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (priorityModalOpen) {
        setPriorityModalOpen(false);
      }
    },
  });

  const history = useHistory();

  return (
    <>
      <div
        ref={ref}
        style={{
          width: 170,
          borderRadius: 8,
          top: 0,
          boxShadow:
            "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          display: "flex",
          animation: priorityModalOpen ? "popUp 0.3s ease-in-out" : "none",
          visibility: priorityModalOpen ? "visible" : "hidden",
          position: "absolute",
          marginTop: 130,
          zIndex: 11000,
          border: "none",
          marginLeft: 0,
          backgroundColor: "white",
          display: "flex",
          paddingTop: 18,
          paddingBottom: 20,
          paddingLeft: 18,
          paddingRight: 5,
          color: "#181818",
          opacity: 0.6,
          fontFamily: "SSRegular",
          fontSize: 15,
          left: 11,
        }}
        className={`backy60   alwayson ${
          priorityModalOpen ? "popUpAnimation" : ""
        }`}
      >
        <MDBRow>
          <MDBCol size="12">
            <div
              style={{
                height: 30,
                width: 130,
                backgroundColor: "#F0F0F0",
                borderRadius: 2,
                marginTop: 0,
                textAlign: "center",
                fontFamily: "SSMedium",
                paddingTop: 5,
                fontSize: 12,
                color: "#696969",
                display: "inline-block",
                cursor: "pointer",
              }}
              onClick={async () => {
                setPriorityModalOpen(false);

                await firebase
                  .firestore()
                  .collection("campaigns")
                  .doc(campaignID)
                  .update({ priority: "LOW" });
              }}
            >
              LOW PRIORITY
            </div>
          </MDBCol>

          <MDBCol size="12">
            <div
              style={{
                height: 30,
                width: 130,
                backgroundColor: "rgb(250, 250, 255)",
                borderRadius: 2,
                marginTop: 10,
                textAlign: "center",
                fontFamily: "SSMedium",
                paddingTop: 5,
                fontSize: 12,
                color: "#ed1165",
                display: "inline-block",
                cursor: "pointer",
              }}
              onClick={async () => {
                setPriorityModalOpen(false);

                await firebase
                  .firestore()
                  .collection("campaigns")
                  .doc(campaignID)
                  .update({ priority: "MEDIUM" });
              }}
            >
              MEDIUM PRIORITY
            </div>
          </MDBCol>

          <MDBCol size="12">
            <div
              style={{
                height: 30,
                width: 130,
                backgroundColor: "#f8ecf0",
                borderRadius: 2,
                marginTop: 10,
                textAlign: "center",
                fontFamily: "SSMedium",
                paddingTop: 5,
                fontSize: 12,
                color: "red",
                display: "inline-block",
                cursor: "pointer",
              }}
              onClick={async () => {
                setPriorityModalOpen(false);

                await firebase
                  .firestore()
                  .collection("campaigns")
                  .doc(campaignID)
                  .update({ priority: "HIGH" });
              }}
            >
              HIGH PRIORITY
            </div>
          </MDBCol>
        </MDBRow>
      </div>
    </>
  );
};

export default withRouter(PriorityModal);
