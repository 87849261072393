import React, { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { firebase } from "../../../firebase/config";
import { ThreeDots } from "react-loader-spinner";

function PricingBox({
  title,
  description,
  price,
  features,
  duration,
  subscribe,
}) {
  const [loading, setLoading] = useState(false);
  const handleSubscribe = async () => {
    if (!loading) {
      setLoading(true);
      if (price === "$10") {
        const docRef = await firebase
          .firestore()
          .collection("customers")
          .doc(firebase.auth().currentUser.uid)
          .collection("checkout_sessions")
          .add({
            price: "price_1NcVguKXhAhuLzvpffTFyGQz",
            success_url: window.location.origin,
            cancel_url: window.location.origin,
            allow_promotion_codes: true,
          });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
          const { error, url } = snap.data();
          if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
          }
        });
      }
      if (price === "$30") {
        const docRef = await firebase
          .firestore()
          .collection("customers")
          .doc(firebase.auth().currentUser.uid)
          .collection("checkout_sessions")
          .add({
            price: "price_1NGNYvKXhAhuLzvpPjrvxTG3",
            success_url: window.location.origin,
            cancel_url: window.location.origin,
            allow_promotion_codes: true,
          });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
          const { error, url } = snap.data();
          if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
          }
        });
      } else if (price === "$100") {
        const docRef = await firebase
          .firestore()
          .collection("customers")
          .doc(firebase.auth().currentUser.uid)
          .collection("checkout_sessions")
          .add({
            price: "price_1NJ25pKXhAhuLzvphvRsk26O",
            success_url: window.location.origin,
            cancel_url: window.location.origin,
            allow_promotion_codes: true,
          });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
          const { error, url } = snap.data();
          if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
          }
        });
      }
      if (subscribe === "Book a call") {
        window.location.href = "mailto:cloneshortaudio@gmail.com";
      } else {
        // Handle other subscription actions
      }
    }
  };
  return (
    <div className="flex flex-col justify-center items-center bg-gray-100 h-112 px-10 rounded-lg my-2 mx-2">
      <div className="w-full items-center">
        <div className="flex flex-col justify-start items-cente w-60">
          <div className="flex flex-col justify-start items-center">
            <div
              style={{ fontFamily: "Inter-Bold" }}
              className="flex flex-col justify-start w-full font-semibold text-xl"
            >
              {title}
            </div>
            <div
              style={{ fontFamily: "Inter-Medium" }}
              className="h-20 text-gray-400 text-sm w-full flex justify-start"
            >
              {description}
            </div>
            <div className="flex w-full justify-start items-center">
              <div
                style={{
                  fontFamily: "Inter-Bold",
                  fontSize: 32,
                  marginTop: price === "Custom" ? 21 : null,
                }}
                className="flex flex-col justify-start font-semibold text-4xl"
              >
                {price}
              </div>
              <div>
                <div
                  style={{ fontFamily: "Inter-Medium" }}
                  className="ml-2 flex flex-col justify-start font-semibold text-gray-400 text-sm"
                >
                  {duration}
                </div>
              </div>
            </div>
            <div className="w-full flex justify-start">
              <button
                style={{
                  background:
                    "linear-gradient(265deg, #FF0000 5%, #c20909  220%)",
                }}
                className="hover:bg-green-400 w-60 mt-2 bg-green-500 h-12 rounded-md text-white flex items-center justify-center font-bold"
                onClick={handleSubscribe}
              >
                {loading ? (
                  <ThreeDots
                    type="ThreeDots"
                    style={{
                      marginRight: 0,
                      position: "absolute",
                    }}
                    color="white"
                    height={10}
                    timeout={200000}
                    width={100}
                  />
                ) : (
                  "Subscribe"
                )}
              </button>
            </div>
            <div
              style={{
                fontFamily: "Inter-Medium",
                marginTop: 20,
                opacity: 0.8,
                fontSize: 15,
              }}
              className="w-full flex justify-start flex-col"
            >
              {features.map((feature, index) => (
                <div
                  className="mt-2 text-#30312c flex items-center justify-start text-sm"
                  key={index}
                >
                  <FaCheckCircle
                    style={{ fontSize: 13 }}
                    className="text-gray-400 text-sm w-8"
                  />
                  <div style={{ fontSize: 13 }} className="w-60">
                    {feature}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingBox;
