import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSignUpModal } from "../Context/SignUpModal";
import { RiCloseFill } from "react-icons/ri";
import { FiCheck, FiHeadphones } from "react-icons/fi";
import { TailSpin } from "react-loader-spinner";
import { useAuth } from "../Context/AuthContext";

const Credits = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");
  const { creditsOpen, setCreditsOpen } = useSignUpModal();
  const [error, setError] = useState("");
  const [finishedReset, setFinishedReset] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [signUpMode, setSignUpMode] = useState(true);
  const [verificationError, setVerificationError] = useState(false);
  const { active, currentPlan, workspaceID } = useAuth();
  const [planType, setPlanType] = useState("Monthly");
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [planTitle, setPlanTitle] = useState("");

  const startPayment = async (price) => {
    setLoading(true);

    const uid = firebase.auth().currentUser.uid;
    const cancelUrl = window.location.origin + "/";
    const successUrl = cancelUrl;

    const docRef = await firebase
      .firestore()
      .collection("customers")
      .doc(uid)
      .collection("checkout_sessions")
      .add({
        price: price, // One-time price created in Stripe
        success_url: successUrl,
        cancel_url: cancelUrl,
        automatic_tax: true,
        metadata: {
          workspaceID: workspaceID,
        },
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  const cancelSubscriptions = async () => {
    setPlanTitle("Free");
    setLoading(true);
    var cancelSubscriptions = firebase
      .functions()
      .httpsCallable("cancelSubscriptions");
    cancelSubscriptions({
      workspaceID: workspaceID,
    }).then(async (result) => {
      if (result.data.status !== "Success") {
        console.log(result.data.message);
        setLoading(false);
        return setError(result.data.message);
      } else {
        setCreditsOpen(false);
      }
    });
  };

  const plans = [
    {
      title: "Free",
      price: 0,
      features: [
        "5 Free Credits",
        "1 Space Seat",
        "10 UGC avatars",
        "Use your own assets",
      ],
    },
    {
      title: "Creator",
      price: planType === "Monthly" ? 50 : 50 * 0.8,
      features: [
        "50 credits",
        "1 Space Seat",
        "100+ UGC Avatars",
        "Use your own assets",
        "Max 3 Projects",
        "Max 1 custom clone",
      ],
      monthlyPrice: "price_1Oyc6yHLaTqDI0ZJVrOCyteX",
      yearlyPrice: "price_1Oyc7hHLaTqDI0ZJWH4UxpaZ",
    },
    {
      title: "Team",
      price: planType === "Monthly" ? 300 : 300 * 0.8,
      features: [
        "300 credits",
        "3 Space Seats",
        "100+ UGC Avatars",
        "Use your own assets",
        "Max 20 projects",
        "Max 3 custom clones",
        "Unboxing UGC templates",
        "Web/App UGC templates",
      ],
      monthlyPrice: "price_1Oyh1THLaTqDI0ZJzAW78sZo",
      yearlyPrice: "price_1Oyh36HLaTqDI0ZJKqhvfV8k",
    },
    {
      title: "Enterprise",
      price: "Let's talk",
      features: [
        "Unlimited credits",
        "Unlimited seats",
        "100+ UGC Avatars",
        "Use your own assets",
        "Unlimited projects",
        "Unlimited custom clones",
        "Unboxing UGC templates",
        "Web/App UGC templates",
        "API Access",
      ],
    },
  ];

  return (
    <>
      <SkyLightStateless
        hideOnOverlayClicked={true}
        onOverlayClicked={async () => {
          setCreditsOpen(false);
        }}
        onCloseClicked={async () => {
          setCreditsOpen(false);
        }}
        isVisible={creditsOpen}
        overlayStyles={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9000,
          backgroundColor: "#181616",
          opacity: 0.6,
        }}
        dialogStyles={{
          zIndex: 9000,
          position: "fixed",
          width: "430px",
          minHeight: "60px",
          marginTop: "1%",
          top: "1%",
          height: 500,
          backgroundColor: "transparent",
          border: "0px solid #30312c",
          boxShadow: "none",
          left: "0%",
          right: "30%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        closeButtonStyle={{
          color: "#30312c",
          right: -90,
          position: "absolute",
          marginTop: 6,
          zIndex: 90000,
          height: 0,
          fontSize: 50,
          display: "none",
        }}
      >
        <div
          style={{
            paddingBottom: 50,
            width: 1000,
            background: "linear-gradient(180deg, #eee9ff 0%, #FFF 100%)",
            borderRadius: 8,
            height: 650,
            zIndex: 90000,
            paddingRight: 20,
            paddingLeft: 30,
          }}
          className="backy60"
        >
          <MDBRow style={{ paddingLeft: 20 }}>
            <MDBCol className="d-flex justify-content-between" size="12">
              <p
                style={{
                  fontWeight: "900",
                  fontFamily: "PPMedium",
                  fontSize: 23,
                  opacity: 1,
                  lineHeight: 1.2,
                  textAlign: "center",
                  color: "#000",
                  display: "flex",
                  marginTop: 34,
                  marginLeft: 0,
                }}
              >
                <div
                  className="d-flex justify-content-center"
                  style={{
                    height: 42,
                    width: 42,
                    background:
                      "linear-gradient(#632297,#4a1d88 27%,#2f1878 61%,#2f1878)",
                    marginRight: 10,
                    marginTop: -7.4,
                    borderRadius: 9,
                  }}
                >
                  <img
                    style={{
                      height: 20,
                      marginTop: 10,
                    }}
                    src={require("./ugclogos.png")}
                  />
                </div>
                Pricing
              </p>
              <div
                style={{
                  marginTop: 26,
                  height: 40,
                  width: 370,
                  border: "1px solid #DCDCDC",
                  borderRadius: 100,
                  marginLeft: -70,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    height: 32,
                    width: 180,
                    borderRadius: 100,
                    marginTop: 3,
                    marginLeft: 3.5,
                    textAlign: "center",
                    cursor: "pointer",
                    background:
                      planType === "Monthly"
                        ? "linear-gradient(#632297,#4a1d88 27%,#2f1878 61%,#2f1878)"
                        : "transparent",
                  }}
                  onClick={() => setPlanType("Monthly")}
                >
                  <p
                    style={{
                      marginTop: 7.4,
                      fontFamily: "PPMedium",
                      fontSize: 13,
                      color: planType === "Monthly" ? "white" : "black",
                    }}
                  >
                    Pay Monthly
                  </p>
                </div>
                <div
                  style={{
                    height: 32,
                    width: 180,
                    borderRadius: 100,
                    marginTop: 3,
                    marginLeft: 2,
                    textAlign: "center",
                    cursor: "pointer",
                    background:
                      planType === "Yearly"
                        ? "linear-gradient(#632297,#4a1d88 27%,#2f1878 61%,#2f1878)"
                        : "transparent",
                  }}
                  onClick={() => setPlanType("Yearly")}
                >
                  <p
                    style={{
                      marginTop: 7.4,
                      fontFamily: "PPMedium",
                      fontSize: 13,
                      color: planType === "Yearly" ? "white" : "black",
                      marginLeft: -50,
                    }}
                  >
                    Pay Yearly{" "}
                  </p>
                  <div
                    style={{
                      borderRadius: 100,
                      width: 60,
                      backgroundColor: "orange",
                      fontSize: 11,
                      color: "white",
                      position: "absolute",
                      fontFamily: "PPMedium",
                      top: 37.5,
                      marginLeft: 15,
                      paddingTop: 1,
                    }}
                    className="d-inline"
                  >
                    20% off
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 30, marginRight: 45, display: "flex" }}>
                <FiHeadphones
                  style={{
                    marginRight: 26,
                    marginTop: 3,
                    fontSize: 20,
                    opacity: 0.8,
                  }}
                />
                <RiCloseFill
                  onClick={() => setCreditsOpen(false)}
                  style={{ fontSize: 27, opacity: 0.8, cursor: "pointer" }}
                />
              </div>
            </MDBCol>
            <MDBCol
              style={{ paddingTop: 10 }}
              className="d-flex justify-content-between"
              size="12"
            >
              {plans.map((plan, index) => (
                <div
                  key={index}
                  style={{
                    width: 330,
                    paddingBottom: 20,
                    marginRight: 10,
                    marginLeft: index === 0 ? -30 : 0,
                    borderRadius: 8,
                    border: "1px solid rgba(35, 40, 51, 0.1)",
                    boxShadow:
                      "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "white",
                  }}
                >
                  {plan.title && (
                    <MDBRow style={{ paddingLeft: 18, paddingTop: 20 }}>
                      <MDBCol size="12">
                        <p style={{ fontFamily: "PPBold", fontSize: 20 }}>
                          {plan.title}
                        </p>
                      </MDBCol>
                      <MDBCol style={{ marginTop: -13 }} size="12">
                        <p
                          style={{
                            fontFamily: "PPBold",
                            fontSize: 41,
                            marginTop: -55,
                            opacity: 0.9,
                          }}
                          className="d-inline"
                        >
                          {plan.title === "Enterprise"
                            ? plan.price
                            : "$" + plan.price.toString()}
                          {plan.title !== "Enterprise" &&
                            plan.title !== "Free" && (
                              <p
                                className="d-inline"
                                style={{
                                  fontSize: 13,
                                  marginLeft: 6,
                                  marginTop: 30,
                                  marginBottom: 3,
                                  position: "absolute",
                                }}
                              >
                                / month
                              </p>
                            )}
                        </p>
                      </MDBCol>
                      <MDBCol size="12">
                        <p
                          style={{
                            fontFamily: "PPMedium",
                            fontSize: 13,
                            opacity: 0.7,
                            marginTop: 4,
                          }}
                        >
                          {plan.title !== "Free"
                            ? "Upgrade to use"
                            : "No Credit Card needed."}
                        </p>
                      </MDBCol>
                      {plan.features.slice(0, 2).map((feature, index) => (
                        <MDBCol size="12" key={index}>
                          <p
                            style={{
                              fontFamily: "PPMedium",
                              fontSize: 13,
                              marginTop: index === 0 ? 0 : -7,
                              color: index === 0 ? "#2f1878" : "black",
                            }}
                          >
                            <FiCheck
                              style={{
                                color: "green",
                                marginRight: 6,
                                fontSize: 17,
                              }}
                            />
                            {feature}
                          </p>
                        </MDBCol>
                      ))}
                      <MDBCol size="12">
                        <p
                          style={{
                            fontFamily: "PPMedium",
                            fontSize: 13,
                            marginTop: 10,
                            color: "#2f1878",
                          }}
                        >
                          Features in {plan.title} plan:
                        </p>
                      </MDBCol>
                      {plan.features.slice(2).map((feature, index) => (
                        <MDBCol size="12" key={index}>
                          <p
                            style={{
                              fontFamily: "PPMedium",
                              fontSize: 13,
                              marginTop: index === 0 ? -8 : -7,
                            }}
                          >
                            <FiCheck
                              style={{
                                color: "green",
                                marginRight: 6,
                                fontSize: 17,
                              }}
                            />
                            {feature}
                          </p>
                        </MDBCol>
                      ))}
                      <MDBCol size="12">
                        <div
                          style={{
                            height: 40,
                            width: 190,
                            marginTop:
                              plan.title === "Free"
                                ? 156
                                : plan.title === "Creator"
                                ? 97
                                : plan.title === "Team"
                                ? 40
                                : 12,
                            backgroundColor:
                              (currentPlan === plan.title && active) ||
                              (!currentPlan && !active && plan.title === "Free")
                                ? "white"
                                : "#2f1878",
                            borderRadius: 5,
                            textAlign: "center",
                            fontFamily: "PPMedium",
                            color:
                              (currentPlan === plan.title && active) ||
                              (!currentPlan && !active && plan.title === "Free")
                                ? "#2f1878"
                                : "white",
                            paddingTop: 10,
                            fontSize: 13.3,
                            cursor: "pointer",
                            border:
                              (currentPlan === plan.title && active) ||
                              (!currentPlan && !active && plan.title === "Free")
                                ? "1px solid #2f1878"
                                : null,
                          }}
                          onClick={() => {
                            if (
                              !(
                                (currentPlan === plan.title && active) ||
                                (!currentPlan &&
                                  !active &&
                                  plan.title === "Free")
                              )
                            ) {
                              if (
                                currentPlan &&
                                currentPlan !== "Free" &&
                                plan.title === "Free"
                              ) {
                                cancelSubscriptions();
                              } else if (
                                !loading &&
                                plan.monthlyPrice &&
                                plan.yearlyPrice
                              ) {
                                if (planType === "Monthly") {
                                  setPlanTitle(plan.title);
                                  startPayment(plan.monthlyPrice);
                                } else {
                                  setPlanTitle(plan.title);
                                  startPayment(plan.yearlyPrice);
                                }
                              }
                            }
                          }}
                          className="d-flex justify-content-center"
                        >
                          {loading && planTitle === plan.title ? (
                            <TailSpin
                              height={20}
                              width={20}
                              color="white"
                              ariaLabel="tail-spin-loading"
                              radius="1"
                              wrapperStyle={{ marginTop: 0 }}
                              wrapperClass=""
                              visible={true}
                            />
                          ) : (currentPlan === plan.title && active) ||
                            (!currentPlan &&
                              !active &&
                              plan.title === "Free") ? (
                            <p>Current plan</p>
                          ) : plan.title === "Enterprise" ? (
                            <a
                              style={{ color: "white" }}
                              target="_blank"
                              href="https://calendly.com"
                            >
                              Book a call
                            </a>
                          ) : plan.title === "Free" ? (
                            <p>Cancel plans</p>
                          ) : (
                            <p>Get Started</p>
                          )}
                        </div>
                      </MDBCol>
                    </MDBRow>
                  )}
                </div>
              ))}
            </MDBCol>
          </MDBRow>
        </div>
      </SkyLightStateless>
    </>
  );
};

export default withRouter(Credits);
