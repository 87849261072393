import React, { Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { firebase } from "../../../firebase/config";
import { NavLink } from "react-router-dom";
import { RiExternalLinkLine } from "react-icons/ri";

class TopMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: null,
    };
  }

  render() {
    return (
      <>
        <div
          style={{
            height: "60px",
            width: "100%",
            position: "fixed",
            zIndex: this.props.isNavbar ? 200 : 0,
            borderBottom: "0.8px solid #ebebeb",
            backgroundColor: "white",
          }}
        ></div>

        <div
          className="d-flex justify-content-center "
          style={{
            cursor: "pointer",
            position: "fixed",
            fontSize: "15.6px",
            fontFamily: "Biotif-Book",
            zIndex: 3,
            left: 340,
            top: 16,
            color: "#27C973",
            zIndex: 200,
          }}
        >
          {/*<p style={{ marginTop: '8.5px' }}>Refer a friend to get 1 month free</p>} */}
        </div>

        {this.props.status !== "active" ? (
          <NavLink to="/settings" exact>
            <div
              className="d-flex justify-content-center "
              style={{
                cursor: "pointer",
                position: "fixed",
                fontSize: "15.6px",
                fontFamily: "Biotif-Book",
                zIndex: 3,
                right: 260,
                top: 16,
                color: "#30312c",
                zIndex: 200,
              }}
            >
              <p style={{ marginTop: "8.5px" }}>Settings</p>
            </div>
          </NavLink>
        ) : (
          <div
            className="d-flex justify-content-center "
            style={{
              cursor: "pointer",
              position: "fixed",
              fontSize: "15.6px",
              fontFamily: "Biotif-Book",
              zIndex: 3,
              right: 260,
              top: 7.6,
              color: "#30312c",
              zIndex: 200,
            }}
          >
            <p style={{ marginTop: "8.5px" }}>Settings</p>
          </div>
        )}
        <div
          className="d-flex justify-content-center "
          style={{
            cursor: "pointer",
            position: "fixed",
            fontSize: "15.6px",
            fontFamily: "Biotif-Book",
            zIndex: 3,
            right: 100,
            top: 16,
            color: "#30312c",
            zIndex: 200,
          }}
        >
          <a
            href="https://www.trygreatlink.com/copy-of-privacy-policy"
            target="_blank"
            style={{ marginTop: "8.5px", color: "#30312c" }}
          >
            Privacy policy
            <RiExternalLinkLine
              style={{
                fontSize: "18px",
                marginLeft: "6px",
                marginBottom: "4px",
              }}
            />
          </a>
        </div>
        <div
          onClick={() =>
            this.props.setaccountVisible(!this.props.accountVisible)
          }
          className="accountIcon d-flex justify-content-center "
          style={{
            cursor: "pointer",
            position: "fixed",
            fontSize: "10px",
            fontFamily: "MarkProMedium",
            zIndex: 200,
            right: 20,
            top: 18,
            color: "white",
            borderRadius: "40px",
            width: "30px",
            height: "30px",
          }}
        >
          <p style={{ marginTop: "8.5px" }}>
            {!!this.props.CurrentUser.firstName &&
              this.props.CurrentUser.firstName[0] +
                this.props.CurrentUser.lastName[0]}
          </p>
        </div>
        {this.props.accountVisible ? (
          <div
            className="z-depth-1"
            style={{
              position: "fixed",
              fontFamily: "Inter-SemiBold",
              fontSize: "13px",
              right: 20,
              backgroundColor: "white",
              borderRadius: "7px",
              zIndex: 300,
              top: 54,
              padding: "20px",
              display: this.props.accountVisible,
            }}
          >
            <MDBCol>
              <MDBRow
                style={{ cursor: "pointer" }}
                className="d-flex justify-content-start"
                size="12"
              >
                <NavLink to="/settings" exact>
                  {" "}
                  Settings
                </NavLink>
              </MDBRow>
              <MDBRow
                style={{ cursor: "pointer" }}
                onClick={() => {
                  firebase
                    .auth()
                    .signOut()
                    .then(() => {
                      window.location = "/";
                    });
                }}
                className="d-fle justify-content-start mt-3"
                size="12"
              >
                Log out
              </MDBRow>
            </MDBCol>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default TopMenu;
