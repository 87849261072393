import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

const SignupMobile = ({ setIsOpen, isOpen }) => {
  const [loginEmail, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [finishedReset, setFinishedReset] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [signUpMode, setSignUpMode] = useState(true);
  const [verificationError, setVerificationError] = useState(false);
  const [profile, setProfile] = useState("");
  const location = useLocation();
  const { t } = useTranslation();

  const googleProvider = new firebase.auth.GoogleAuthProvider();

  useEffect(() => {
    let query = window.location.search; // Without React Router
    const queryParams = new URLSearchParams(query);
    const id = queryParams.get("id");
    const name = queryParams.get("name");
    let profile;
    if (name) {
      setProfile(name.toLowerCase());
    } else if (id) {
      setProfile(id.toLowerCase());
    }
  }, [isOpen]);

  useEffect(() => {
    setLoginEmail("");
    setPassword("");
    setError("");
  }, [isOpen]);

  const signIn = async (Email, Password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(Email, Password)
      .then(() => {
        mixpanel.track("User signed in", {
          "Sign in method": "Email",
        });
        if (firebase.auth()?.currentUser?.emailVerified === true) {
          setIsOpen(false);
          setError(null);
        } else {
          setError(null);
        }
      })
      .catch((signUpError) => {
        // Sign up failed
        setError(formatErrorMessage(signUpError.message));
      });
  };

  const signUp = async (Email, Password) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(Email, Password)
      .then(async (userCredential) => {
        // Send verification email
        mixpanel.track("User created", {
          "Sign up method": "Email",
        });

        userCredential.user
          .sendEmailVerification()
          .then(() => {
            console.log("Verification email sent.");
          })
          .catch((verificationError) => {
            console.error(
              "Error sending verification email:",
              verificationError
            );
          });

        // Save user data to Firestore
        const uid = userCredential.user.uid;

        if (window.tolt) {
          window.tolt.signup(Email);
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "completeRegistration",
          event_id: uuidv4(), // Replace 'currency' with the transaction currency code
        });

        const signUpTime = firebase.firestore.FieldValue.serverTimestamp(); // Record the sign-up time

        return firebase.firestore().collection("users").doc(uid).set({
          email: Email,
          uid: uid,
          credits: 1,
          signUpTime: signUpTime, // Add this line
        });
      })
      .then(() => {
        window.scrollTo(0, 0);
        setError(null);
      })
      .catch((signUpError) => {
        // Sign up failed
        setError(formatErrorMessage(signUpError.message));
      });
  };

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then(async (result) => {
        if (result.user) {
          const user = result.user;
          const uid = user.uid;

          // Check if the user exists in Firestore
          const doc = await firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .get();

          if (!doc.exists) {
            // Handle new user logic
            mixpanel.track("User created", {
              "Sign up method": "Google",
            });

            if (window.tolt) {
              window.tolt.signup(user.email);
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "completeRegistration",
              event_id: uuidv4(), // Replace 'currency' with the transaction currency code
            });
            const signUpTime = firebase.firestore.FieldValue.serverTimestamp(); // Record the sign-up time

            // Save the new user data to Firestore
            await firebase.firestore().collection("users").doc(uid).set({
              email: user.email,
              uid: uid,
              credits: 1,
              signUpTime: signUpTime, // Add this line
            });
          } else {
            // Handle existing user logic
            mixpanel.track("User signed in", {
              "Sign in method": "Google",
            });
          }

          // UI updates, like closing modal, resetting errors
          setIsOpen(false);
          setError(null);
        }
      })
      .catch((error) => {
        // Handle sign-in errors here
        setError(error.message);
      });
  }, []);

  const onSubmitGoogle = async () => {
    firebase.auth().signInWithRedirect(googleProvider);
  };

  function formatErrorMessage(errorMessage) {
    // Remove "Firebase:" prefix if it exists
    let formattedMessage = errorMessage.startsWith("Firebase:")
      ? errorMessage.replace("Firebase:", "").trim()
      : errorMessage;

    // Remove error code in parentheses at the end
    formattedMessage = formattedMessage.replace(/\(.*?\)\.?$/, "").trim();

    return formattedMessage;
  }

  return (
    <>
      <SkyLightStateless
        hideOnOverlayClicked={true}
        onOverlayClicked={async () => {
          const user = firebase.auth().currentUser;
          if (!user) {
            setIsOpen(false);
          } else {
            await user.reload();
            if (user.emailVerified) {
              setIsOpen(false);
            }
          }
        }}
        onCloseClicked={async () => {
          console.log(location.pathname);
          const user = firebase.auth().currentUser;
          if (!user) {
            if (location.pathname.includes("/checkout")) {
              return; // Do nothing if we are on the checkout page
            } else {
              return setIsOpen(false);
            }
          } else {
            await user.reload();
            if (user.emailVerified) {
              return setIsOpen(false);
            }
          }
        }}
        isVisible={isOpen}
        overlayStyles={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9000,
          opacity: 0.6,
          backgroundColor: "rgba(24, 22, 22, 0.6)", // Semi-transparent background
          backdropFilter: "blur(10px)", // Blur effect for the background elements
        }}
        dialogStyles={{
          zIndex: 9000,
          position: "fixed",
          width: "100vw",
          minHeight: "60px",
          marginTop: "0%",
          top: "0%",
          height: "100vh",
          boxShadow: "none",
          left: "0%",
          right: "0%",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "rgba(18, 17, 20, 0.04)", // Semi-transparent white for the glass effect
          border: "1px solid transparent", // Slight border for depth
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Soft shadow for more depth
          backdropFilter: "blur(6px)", // Apply blur to the dialog for the glass effect
        }}
        closeButtonStyle={{
          color: "black",
          right: -90,
          position: "absolute",
          marginTop: 6,
          zIndex: 90000,
          height: 0,
          fontSize: 50,
          display: "none",
        }}
      >
        <OutsideClickHandler
          onOutsideClick={async () => {
            const user = firebase.auth().currentUser;
            if (!user) {
              if (location.pathname.includes("/checkout")) {
                return; // Do nothing if we are on the checkout page
              } else {
                return setIsOpen(false);
              }
            } else {
              await user.reload();
              if (user.emailVerified) {
                setIsOpen(false);
              }
            }
          }}
        >
          <div
            style={{
              paddingBottom: 40,
              width: "80vw",
              backgroundColor: "white",
              borderRadius: 8,
              zIndex: 90000,
              paddingLeft: 0,
              top: "9%",
              position: "absolute",
              left: "0%",
              right: "0%",
              marginLeft: "auto",
              marginRight: "auto",
              border: "1px solid #505050",
            }}
            className="backy60"
          >
            {resetPassword ? (
              finishedReset ? (
                <MDBRow style={{ paddingLeft: 0 }}>
                  <MDBCol size="12" className="d-flex justify-content-center">
                    <HiOutlineMailOpen
                      style={{ marginTop: 45, fontSize: 50, color: "white" }}
                    />
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontWeight: "900",
                        fontFamily: "MABold",
                        fontSize: 22,
                        opacity: 1,
                        lineHeight: 1.2,
                        textAlign: "center",
                        color: "black",
                        display: "flex",
                        marginTop: 20,
                      }}
                    >
                      {t("resetMessage")}
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "MABold",
                        fontSize: 15,
                        color: "#9b9ea3",
                        opacity: 1,
                        lineHeight: 1.6,
                        marginTop: 10,
                        textAlign: "center",
                      }}
                    >
                      {t("resetMessageSubtitle")}
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "MABold",
                        fontSize: 15,
                        color: "black",
                        opacity: 1,
                        lineHeight: 1.6,
                        marginTop: 16,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setResetPassword(false);
                      }}
                    >
                      <BiLeftArrowAlt
                        style={{ fontSize: 21, marginRight: 0, marginTop: -1 }}
                        className="d-inline"
                      />{" "}
                      {t("resetButton")}
                    </p>
                  </MDBCol>
                </MDBRow>
              ) : (
                <MDBRow style={{ paddingLeft: 0 }}>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontWeight: "900",
                        fontFamily: "MABold",
                        fontSize: 23,
                        opacity: 1,
                        lineHeight: 1.2,
                        textAlign: "center",
                        color: "black",
                        display: "flex",
                        marginTop: 30,
                      }}
                    >
                      {t("resetPasswordTitle")}
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "MABold",
                        fontSize: 15,
                        color: "#9b9ea3",
                        opacity: 1,
                        lineHeight: 1.5,
                        marginTop: -8,
                        textAlign: "center",
                      }}
                    >
                      Choose a new password
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <input
                      style={{
                        height: 45,
                        width: "89%",
                        marginLeft: 0,
                        border: "1px solid #696969",
                        borderRadius: 100,
                        display: "inline-block",
                        color: "black",
                        marginTop: 0,
                        backgroundColor: "white",
                        fontFamily: "MADemiBold",
                        paddingLeft: 17,
                        fontSize: 15,
                        paddingTop: 6,
                      }}
                      value={loginEmail}
                      name="Email"
                      onChange={(event) => setLoginEmail(event.target.value)}
                      placeholder={t("resetEmailInput")}
                    />
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <div
                      style={{
                        height: 50,
                        width: "89%",
                        marginLeft: 0,
                        borderRadius: 100,
                        display: "inline-block",
                        color: "white",
                        marginTop: 12,
                        backgroundColor: "black",
                        fontFamily: "MABold",
                        fontSize: 13,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        mixpanel.track("User has reset password");
                        firebase
                          .auth()
                          .sendPasswordResetEmail(loginEmail)
                          .then(() => {
                            setFinishedReset(true);
                          });
                      }}
                      className="d-flex justify-content-center"
                    >
                      <p
                        style={{
                          color: "white",
                          marginTop: 14.9,
                          fontFamily: "MABold",
                          fontSize: 15,
                        }}
                      >
                        {t("resetPasswordButton")}
                      </p>
                    </div>
                  </MDBCol>
                  {error && (
                    <MDBCol size="12" className="d-flex justify-content-center">
                      <p
                        style={{
                          fontFamily: "MABold",
                          fontSize: 15,
                          marginTop: 14,
                          color: "red",
                          marginLeft: -34,
                          textAlign: "center",
                        }}
                      >
                        {error}
                      </p>
                    </MDBCol>
                  )}
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "MABold",
                        fontSize: 15,
                        marginTop: 14,
                        color: "#9b9ea3",
                      }}
                    >
                      <p
                        style={{
                          marginLeft: 5,
                          color: "black",
                          fontFamily: "MABold",
                          cursor: "pointer",
                        }}
                        onClick={() => setResetPassword(false)}
                        className="d-inline"
                      >
                        {t("resetPasswordButtonUnder")}
                      </p>
                    </p>
                  </MDBCol>

                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "MABold",
                        fontSize: 13,
                        marginTop: 4,
                        color: "#9B9EA3",
                        textAlign: "center",
                      }}
                    >
                      {t("passwordTip")}
                    </p>
                  </MDBCol>
                </MDBRow>
              )
            ) : firebase.auth()?.currentUser?.emailVerified === false ? (
              <MDBRow>
                <MDBCol size="12" className="d-flex justify-content-center">
                  <HiOutlineMailOpen
                    style={{ marginTop: 45, fontSize: 50, color: "black" }}
                  />
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontWeight: "900",
                      fontFamily: "MABold",
                      fontSize: 22,
                      opacity: 1,
                      lineHeight: 1.2,
                      textAlign: "center",
                      color: "black",
                      display: "flex",
                      marginTop: 20,
                    }}
                  >
                    {t("resetMessage")}
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "MABold",
                      fontSize: 15,
                      color: "#9b9ea3",
                      opacity: 1,
                      lineHeight: 1.6,
                      marginTop: 10,
                      textAlign: "center",
                    }}
                  >
                    {t("verificationSubtitle")}
                    <br />
                    {firebase.auth().currentUser
                      ? firebase.auth().currentUser.email
                      : null}
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <div
                    style={{
                      height: 50,
                      width: "89%",
                      marginLeft: 0,
                      borderRadius: 100,
                      display: "inline-block",
                      color: "black",
                      marginTop: 12,
                      backgroundColor: "black",
                      fontFamily: "MABold",
                      fontSize: 13,
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      const user = firebase.auth().currentUser;
                      await user.reload();
                      console.log(user.emailVerified);
                      if (user.emailVerified) {
                        mixpanel.track("User verified email");
                        setIsOpen(false);
                      } else {
                        mixpanel.track("Email verification failed for user");
                        setVerificationError(true);
                      }
                    }}
                    className="d-flex justify-content-center"
                  >
                    <p
                      style={{
                        color: "white",
                        marginTop: 14.9,
                        fontFamily: "MABold",
                        fontSize: 15,
                      }}
                    >
                      {t("verificationButton")}
                    </p>
                  </div>
                </MDBCol>
                {verificationError && (
                  <MDBCol size="12" className="d-flex justify-content-center">
                    <p
                      style={{
                        fontFamily: "MABold",
                        fontSize: 15,
                        marginTop: 14,
                        color: "red",
                        textAlign: "center",
                      }}
                    >
                      {t("verificationFail")}
                    </p>
                  </MDBCol>
                )}
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "MABold",
                      fontSize: 15,
                      color: "black",
                      opacity: 1,
                      lineHeight: 1.6,
                      marginTop: 16,
                      textAlign: "center",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      firebase.auth().currentUser.sendEmailVerification();
                    }}
                  >
                    {t("resendVerificationButton")}
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "MABold",
                      fontSize: 15,
                      color: "black",
                      opacity: 1,
                      lineHeight: 1.6,
                      marginTop: 16,
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      firebase
                        .auth()
                        .signOut()
                        .then(() => {
                          setVerificationError("");
                          setError("");
                        });
                    }}
                  >
                    <BiLeftArrowAlt
                      style={{ fontSize: 21, marginRight: 0, marginTop: -1 }}
                      className="d-inline"
                    />{" "}
                    {t("verificationReturn")}
                  </p>
                </MDBCol>
              </MDBRow>
            ) : (
              <MDBRow style={{ paddingLeft: 0 }}>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontWeight: "900",
                      fontFamily: "MAHeavy",
                      fontSize: 23,
                      opacity: 1,
                      lineHeight: 1.2,
                      textAlign: "center",
                      color: "black",
                      display: "flex",
                      marginTop: 30,
                    }}
                  >
                    {t("signupModalTitle")}
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "MABold",
                      fontSize: 15,
                      marginTop: -10,
                      color: "#9b9ea3",
                    }}
                  >
                    {t("SwitchMode")}
                    <p
                      style={{
                        marginLeft: 5,
                        color: "black",
                        fontFamily: "MABold",
                        cursor: "pointer",
                      }}
                      onClick={() => setSignUpMode(!signUpMode)}
                      className="d-inline"
                    >
                      {!signUpMode ? t("signUp") : t("login")}
                    </p>
                  </p>
                </MDBCol>

                <MDBCol className="d-flex justify-content-center" size="12">
                  <input
                    style={{
                      height: 45,
                      width: "85%",
                      marginLeft: 0,
                      borderRadius: 100,
                      display: "inline-block",
                      color: "black",
                      marginTop: -5,
                      backgroundColor: "white",
                      fontFamily: "MABold",
                      paddingLeft: 17,
                      fontSize: 15,
                      paddingTop: 6,
                      border: "1px solid rgba(155, 158, 163, 0.5)",
                    }}
                    value={loginEmail}
                    name="Email"
                    onChange={(event) => setLoginEmail(event.target.value)}
                    placeholder={t("signUpFormEmail")}
                  />
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <input
                    style={{
                      height: 45,
                      width: "85%",
                      marginLeft: 0,
                      border: "1px solid rgba(155, 158, 163, 0.5)",
                      borderRadius: 100,
                      display: "inline-block",
                      color: "black",
                      marginTop: 9,
                      backgroundColor: "white",
                      fontFamily: "MABold",
                      paddingLeft: 17,
                      fontSize: 15,
                      paddingTop: 6,
                    }}
                    value={password}
                    type="password"
                    name="Password"
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder={t("signUpFormPassword")}
                  />
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <div
                    style={{
                      height: 50,
                      width: "85%",
                      marginLeft: 0,
                      borderRadius: 100,
                      display: "inline-block",
                      color: "black",
                      marginTop: 12,
                      backgroundColor: "black",
                      fontFamily: "MABold",
                      fontSize: 13,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (signUpMode) {
                        signUp(loginEmail, password);
                      } else {
                        signIn(loginEmail, password);
                      }
                    }}
                    className="d-flex justify-content-center"
                  >
                    <p
                      style={{
                        color: "white",
                        marginTop: 14.9,
                        fontFamily: "MABold",
                        fontSize: 15,
                      }}
                    >
                      {signUpMode
                        ? t("createAccountButton")
                        : t("signInButton")}
                    </p>
                  </div>
                </MDBCol>

                <MDBCol size="12" className="d-flex justify-content-center">
                  <p
                    style={{
                      fontFamily: "MABold",
                      fontSize: 15,
                      marginTop: 14,
                      color: "red",
                      textAlign: "center",
                      width: 290,
                    }}
                  >
                    {error}
                  </p>
                </MDBCol>

                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "MABold",
                      fontSize: 15,
                      marginTop: -10,
                      color: "#000",
                    }}
                  >
                    OR
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <div
                    style={{
                      height: 50,
                      width: "85%",
                      borderRadius: 100,
                      backgroundColor: "white",
                      fontFamily: "MABold",
                      border: "1px solid black",
                      fontSize: 13,
                      cursor: "pointer",
                      display: "flex", // Use Flexbox for alignment
                      alignItems: "center", // Vertically center the contents
                      justifyContent: "center", // Horizontally center the contents
                    }}
                    onClick={() => {
                      onSubmitGoogle();
                    }}
                  >
                    <img
                      src={require("./Google.png")}
                      style={{
                        height: 34,
                        marginRight: 10, // Add some space between the icon and the text
                      }}
                    />
                    <p
                      style={{
                        color: "black",
                        fontFamily: "MABold",
                        fontSize: 15,
                        marginTop: 19, // Remove margin to align text properly
                      }}
                    >
                      {signUpMode ? t("GoogleSignUp") : t("GoogleSignIn")}
                    </p>
                  </div>
                </MDBCol>
                {signUpMode ? (
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "MABold",
                        fontSize: 13,
                        marginTop: 24,
                        color: "#9B9EA3",
                        textAlign: "center",
                        width: 280,
                      }}
                    >
                      {t("TermsOfService")}
                    </p>
                  </MDBCol>
                ) : (
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "MABold",
                        fontSize: 13,
                        marginTop: 24,
                        color: "#9B9EA3",
                        textAlign: "center",
                        width: "60vw",
                      }}
                    >
                      <p
                        className="d-inline"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => setResetPassword(true)}
                      >
                        {t("forgotPassword")}
                      </p>
                    </p>
                  </MDBCol>
                )}
              </MDBRow>
            )}
          </div>
        </OutsideClickHandler>
      </SkyLightStateless>
    </>
  );
};

export default withRouter(SignupMobile);
