import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import DashboardFooter from "../Layout/DashboardFooter";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import Heading from "./Heading";
import ExplainerMobile from "./ExplainerMobile";
import { TailSpin } from "react-loader-spinner";
import { useAuth } from "../Context/AuthContext";
import DashboardNavBarMobile from "../Layout/DashboardNavBarMobile";
import HeadingMobile from "./HeadingMobile";
import DashboardFooterMobile from "../Layout/DashboardFooterMobile";

const RequestMobile = ({
  email,
  popUpVisible,
  setPopUpVisible,
  ref,
  seconds,
}) => {
  const [requestLoading, setRequestLoading] = useState(true);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [playbackID, setPlaybackID] = useState("");
  const [profileID, setProfileID] = useState("");
  const { paid } = useAuth();

  const history = useHistory();

  useEffect(() => {
    let query = window.location.search; // Without React Router
    const queryParams = new URLSearchParams(query);
    const id = queryParams.get("id");

    setProfileID(id);
    firebase
      .firestore()
      .collection("profiles")
      .doc(id)
      .get()
      .then((doc) => {
        const { name, playbackID, free } = doc.data();
        if (!free && !paid) {
          history.push("/");
        }
        setPlaybackID(playbackID);
        setName(name);
      })
      .then(() => {
        setRequestLoading(false);
      });
  }, [window.location]);

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          width: "100vw",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#E8E8E8",
            width: "100vw",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: "30vh",
            }}
            size="12"
          >
            <MDBRow
              className="d-flex justify-content-center"
              style={{ marginTop: 0 }}
            >
              <DashboardNavBarMobile
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
                email={email}
                seconds={seconds}
                ref={ref}
              />
            </MDBRow>

            {requestLoading || !name || !playbackID || !profileID ? (
              <div
                className="d-flex justify-content-center"
                style={{ height: "100vh" }}
              >
                <TailSpin
                  height="50"
                  width="50"
                  color="#000"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{ marginTop: 340 }}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <HeadingMobile name={name} />
                </MDBCol>
                <ExplainerMobile
                  name={name}
                  loading={loading}
                  setLoading={setLoading}
                  playbackID={playbackID}
                  profileID={profileID}
                />
              </>
            )}
          </MDBCol>
          <DashboardFooterMobile />
        </div>
      </div>
    </>
  );
};

export default withRouter(RequestMobile);
