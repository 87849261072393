import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import {
  FaRegCompass,
  FaRegFolder,
  FaRegStar,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { RiRedPacketLine } from "react-icons/ri";
import { BiSolidVideos } from "react-icons/bi";

const TemplateSection = ({ templates }) => {
  const [selectedCategory, setSelectedCategory] = useState("All Use Cases");

  const categories = [
    "All Use Cases",
    "Advertisement",
    "Ecommerce",
    "Learning & Development",
    "Social Media",
  ];

  const items = [1];

  return (
    <MDBRow style={{ marginTop: 75, marginLeft: 0, overflowY: "hidden" }}>
      <MDBCol size="12">
        <p
          style={{
            fontSize: 18,
            color: "#fff",
            fontFamily: "SSBold",
            opacity: 0.9,
          }}
        >
          Start with a Template
        </p>
      </MDBCol>
      <MDBCol size="12">
        <p
          style={{
            fontSize: 18,
            color: "#fff",
            fontFamily: "SSBold",
            opacity: 0.9,
          }}
        >
          Public templates
        </p>
      </MDBCol>
      <MDBCol
        style={{
          display: "flex",
          overflowX: "auto",
          whiteSpace: "nowrap",
          color: "white",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          marginTop: -9,
          WebkitOverflowScrolling: "touch",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        size="12"
      >
        {categories.map((category, index) => (
          <div
            style={{
              backgroundColor: "transparent",
              marginRight: 15,
              cursor: "pointer",
              fontFamily: "SSMedium",
              color: "white",
              borderBottom:
                category === selectedCategory ? "2px solid white" : null,
              paddingBottom: 7,
              opacity: category === selectedCategory ? 1 : 0.7,
              fontSize: 14,
            }}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </div>
        ))}
      </MDBCol>
      <MDBCol size="12">
        <MDBRow
          style={{
            overflowY: "scroll",
            height: "76vh",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            width: "100%",
            paddingBottom: 30,
            WebkitOverflowScrolling: "touch",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {templates.length > 0 &&
            templates.map((item) => {
              return (
                <MDBCol style={{ marginTop: 10 }} size="6">
                  <img
                    src={`https://image.mux.com/geLnlLTfethxYfPOtTI02yblFLNH8ANk4pkdSD3U5rbk/animated.gif`}
                    style={{
                      width: "9vw",
                      aspectRatio: "9 / 16",
                      borderRadius: 3,
                      cursor: "pointer",
                    }}
                  />
                </MDBCol>
              );
            })}
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(TemplateSection);
