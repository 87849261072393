import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import { BsPauseCircleFill, BsPlayCircleFill } from "react-icons/bs";
import Carousel from "./Carousel";
import { ReactComponent as Flowy } from "./modern.svg";
import ReactPlayer from "react-player";
import Box from "./Box";

const numOfItems = [1, 2, 3, 4, 5, 6];
const Section2 = () => {
  return (
    <MDBCol className="d-flex justify-content-center" size="12">
      <div
        style={{
          height: 460,
          width: 1100,
          marginTop: -50,
          borderRadius: 9,
          padding: 0,
          justifyContent: "space-evenly",
          display: "flex",
          paddingLeft: 10,
        }}
      >
        <Box
          Title="Fast Creation"
          Description="Generate video ads in just a few minutes, spend more time growing."
          playbackID="00l6mzYrwU5XHgiiHhhFap02njL48MBPme202jOvi902w500"
        />
        <Box
          Title="1m+ Stock Assets"
          playbackID="n1BIvbYAY5uAvo8sBv32lUhZ732eUWev3Z54stlXEDY"
          Description="Use our catalogue of millions of stock videos, photos and music."
        />
        <Box
          Title="Product Videos"
          Description="Order product videos from our team and have them to ready to use in days."
          playbackID="SgVdzjkcjxJJoTtV7I2dLClndplZ8f2UqlvGUbjQqxc"
        />
      </div>
    </MDBCol>
  );
};

export default withRouter(Section2);
