import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { TailSpin } from "react-loader-spinner";
import { FaArrowLeft, FaShippingFast } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { MdOutlinePhonelink } from "react-icons/md";
import { useAuthKit } from "@integrationos/authkit";

const Step4Digital = ({
  uid,
  setSection,
  loginEmail,
  setLoginEmail,
  loginPassword,
  setLoginPassword,
  createCampaign,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <MDBCol size="12">
      <div>
        <FaArrowLeft
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
            cursor: "pointer",
          }}
          onClick={() => setSection("Step3Digital")}
        />

        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 5,
            marginBottom: 0,
            color: "grey",
          }}
        >
          Step 4 of 4
        </p>
        <p
          style={{
            fontFamily: "SSBold",
            fontSize: 24,
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          Give access (optional)
        </p>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 14,
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          Share login details for an account that creators can you use in your
          app or website.
        </p>
      </div>
      <div>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 16,
            marginTop: 16,
            marginBottom: 0,
          }}
        >
          Username
        </p>
        <input
          style={{
            height: 55,
            width: 410,
            marginLeft: 0,
            border: "1px solid rgba(155, 158, 163, 0.5)",
            borderRadius: 7,
            display: "inline-block",
            color: "black",
            marginTop: 5,
            backgroundColor: "white",
            fontFamily: "SSMedium",
            paddingLeft: 17,
            fontSize: 15,
            paddingTop: 3,
          }}
          value={loginEmail}
          name="loginEmail"
          onChange={(event) => setLoginEmail(event.target.value)}
          placeholder="acme@gmail.com"
        />
      </div>
      <div>
        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 16,
            marginTop: 16,
            marginBottom: 0,
          }}
        >
          Password
        </p>
        <input
          style={{
            height: 55,
            width: 410,
            marginLeft: 0,
            border: "1px solid rgba(155, 158, 163, 0.5)",
            borderRadius: 7,
            display: "inline-block",
            color: "black",
            marginTop: 5,
            backgroundColor: "white",
            fontFamily: "SSMedium",
            paddingLeft: 17,
            fontSize: 15,
            paddingTop: 3,
          }}
          value={loginPassword}
          name="loginPassword"
          onChange={(event) => setLoginPassword(event.target.value)}
          placeholder="acme12345"
        />
      </div>
      <div
        style={{
          height: 55,
          width: 410,
          backgroundColor: "#ed1165",
          borderRadius: 7,
          marginTop: 20,
          textAlign: "center",
          fontFamily: "SSRegular",
          color: "white",
          fontSize: 18,
          paddingTop: 14,
          cursor: "pointer",
          opacity: 1,
        }}
        onClick={() => {
          setLoading(true);
          createCampaign();
        }}
        className="d-flex justify-content-center"
      >
        {loading ? (
          <TailSpin
            height="25"
            width="25"
            color="#fff"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{ marginTop: 0 }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            <p>Submit</p>
          </>
        )}
      </div>
    </MDBCol>
  );
};

export default withRouter(Step4Digital);
