import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSignUpModal } from "../Context/SignUpModal";
import { useAuth } from "../Context/AuthContext";
import { RiCloseFill } from "react-icons/ri";
import { FiCheck, FiCopy, FiHeadphones, FiMail, FiPlus } from "react-icons/fi";
import { FaLink } from "react-icons/fa";
import { TailSpin } from "react-loader-spinner";

const Onboarding = () => {
  const [inviteEmail, setInviteEmail] = useState("");
  const [password, setPassword] = useState("");
  const { onboarded, setOnboarded } = useAuth();
  const [error, setError] = useState("");
  const [finishedReset, setFinishedReset] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [signUpMode, setSignUpMode] = useState(true);
  const [verificationError, setVerificationError] = useState(false);
  const [planType, setPlanType] = useState("Monthly");
  const [profile, setProfile] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [companyType, setCompanyType] = useState(null);
  const [companySize, setCompanySize] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (onboarded === false) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [onboarded]);

  return (
    <>
      <SkyLightStateless
        hideOnOverlayClicked={true}
        onOverlayClicked={async () => {
          if (onboarded) {
            setIsOpen(false);
          }
        }}
        onCloseClicked={async () => {
          if (onboarded) {
            setIsOpen(false);
          }
        }}
        isVisible={isOpen}
        overlayStyles={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9000,
          backgroundColor: "#181616",
          opacity: 0.6,
        }}
        dialogStyles={{
          zIndex: 9000,
          position: "fixed",
          width: "430px",
          minHeight: "60px",
          marginTop: "1%",
          top: "14%",
          height: 500,
          backgroundColor: "transparent",
          border: "0px solid #30312c",
          boxShadow: "none",
          left: "0%",
          right: "24%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        closeButtonStyle={{
          color: "#30312c",
          right: -90,
          position: "absolute",
          marginTop: 6,
          zIndex: 90000,
          height: 0,
          fontSize: 50,
          display: "none",
        }}
      >
        <div
          style={{
            paddingBottom: 50,
            width: 850,
            background: "linear-gradient(180deg, #F0F8FF 0%, #FFF 100%)",
            borderRadius: 8,
            height: 470,
            zIndex: 90000,
            paddingLeft:
              (resetPassword && finishedReset) ||
              firebase.auth()?.currentUser?.emailVerified === false
                ? 0
                : 20,
            display: "flex",
          }}
          className="backy60"
        >
          <div
            style={{
              width: 425,
              height: 470,
              paddingLeft: 15,
              paddingTop: 40,
              paddingRight: 30,
            }}
          >
            <p style={{ fontFamily: "PPBold", color: "#0c41ab", fontSize: 25 }}>
              Welcome to
              <p
                style={{
                  fontFamily: "PPBold",
                  color: "#0c41ab",
                  fontSize: 39,
                  marginTop: -6,
                }}
              >
                UGCAI
              </p>
              <p
                style={{
                  fontFamily: "PPMedium",
                  color: "#0c41ab",
                  fontSize: 14,
                  marginTop: -15,
                }}
              >
                Since it's your first time here, we'd love to
                <br /> learn a bit more about you.
              </p>
            </p>
            <MDBCol
              style={{
                marginTop: 0,
                position: "absolute",
                bottom: 40,
                left: 35,
              }}
              size="12"
            >
              <div>
                <div
                  style={{
                    height: 14,
                    width: 14,
                    backgroundColor: "#0c41ab",
                    borderRadius: 200,
                    display: "inline-block",
                    opacity: 1,
                  }}
                ></div>
                <div
                  style={{
                    height: 14,
                    width: 14,
                    backgroundColor: "#0c41ab",
                    borderRadius: 200,
                    display: "inline-block",
                    marginLeft: 10,
                    opacity: 0.4,
                  }}
                ></div>
                <div
                  style={{
                    height: 14,
                    width: 14,
                    backgroundColor: "#0c41ab",
                    borderRadius: 200,
                    display: "inline-block",
                    marginLeft: 10,
                    opacity: 0.4,
                  }}
                ></div>
              </div>
            </MDBCol>
          </div>
          <div
            style={{
              width: 425,
              height: 470,
              backgroundColor: "white",
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              paddingLeft: 25,
              paddingTop: 10,
            }}
          >
            <div
              style={{
                marginTop: 20,
                width: 340,
                backgroundColor: "transparent",
                borderRadius: 7,
                paddingLeft: 0,
                paddingTop: 15,
              }}
            >
              <p
                style={{
                  fontFamily: "PPMedium",
                  color: "#0c41ab",
                  fontSize: 14,
                  marginBottom: 12,
                }}
              >
                Company Name
              </p>
              <input
                style={{
                  height: 40,
                  width: "93%",
                  marginLeft: 0,
                  border: "1px solid #0c41ab",
                  borderRadius: 6,
                  display: "inline-block",
                  color: "#0c41ab",
                  marginTop: -16,
                  backgroundColor: "white",
                  fontFamily: "PPMedium",
                  paddingLeft: 17,
                  fontSize: 13,
                  paddingTop: 3,
                  opacity: 1,

                  boxShadow:
                    "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                }}
                value={companyName}
                name="Company Name"
                onChange={(event) => setCompanyName(event.target.value)}
                placeholder="My Company"
              />
              <p
                style={{
                  fontFamily: "PPMedium",
                  marginTop: 16,
                  color: "#0c41ab",
                  fontSize: 14,
                  marginBottom: 12,
                }}
              >
                Website
              </p>
              <input
                style={{
                  height: 40,
                  width: "93%",
                  marginLeft: 0,
                  border: "1px solid #0c41ab",
                  borderRadius: 6,
                  display: "inline-block",
                  color: "#0c41ab",
                  marginTop: -16,
                  backgroundColor: "white",
                  fontFamily: "PPMedium",
                  paddingLeft: 17,
                  fontSize: 13,
                  paddingTop: 3,
                  opacity: 1,
                  boxShadow:
                    "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                }}
                value={website}
                name="Website"
                onChange={(event) => setWebsite(event.target.value)}
                placeholder="www.mycompany.com"
              />
              <p
                style={{
                  fontFamily: "PPMedium",
                  marginTop: 16,
                  color: "#0c41ab",
                  fontSize: 14,
                  marginBottom: 12,
                }}
              >
                Company Type
              </p>
              <select
                style={{
                  height: 40,
                  width: "93%",
                  marginLeft: 0,
                  border: "1px solid #0c41ab",
                  borderRadius: 6,
                  display: "inline-block",
                  color: "#0c41ab",
                  marginTop: -16,
                  backgroundColor: "white",
                  fontFamily: "PPMedium",
                  paddingLeft: 17,
                  fontSize: 13,
                  paddingTop: 0,
                  opacity: 1,
                  boxShadow:
                    "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                }}
                value={companyType}
                name="Company Type"
                onChange={(event) => setCompanyType(event.target.value)}
              >
                <option value={null}>None Selected</option>
                <option value="Agency">Agency/Consultant</option>
                <option value="Brand">Brand</option>
              </select>
              <p
                style={{
                  fontFamily: "PPMedium",
                  marginTop: 16,
                  color: "#0c41ab",
                  fontSize: 14,
                  marginBottom: 12,
                }}
              >
                Company Size
              </p>
              <select
                style={{
                  height: 40,
                  width: "93%",
                  marginLeft: 0,
                  border: "1px solid #0c41ab",
                  borderRadius: 6,
                  display: "inline-block",
                  color: "#0c41ab",
                  marginTop: -16,
                  backgroundColor: "white",
                  fontFamily: "PPMedium",
                  paddingLeft: 17,
                  fontSize: 13,
                  paddingTop: 0,
                  opacity: 1,
                  boxShadow:
                    "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                }}
                value={companySize}
                name="Company Size"
                onChange={(event) => setCompanySize(event.target.value)}
              >
                <option value={null}>None Selected</option>
                <option value="1">Individual</option>
                <option value="2-10">2-10</option>
                <option value="10-49">10-49</option>
                <option value="50-100">50-500</option>
                <option value="500+">500+</option>
              </select>
              <div
                onClick={() => {
                  if (website && companyType && companyName && companySize) {
                    setLoading(true);
                    const uid = firebase.auth().currentUser.uid; // Get the current user's UID
                    // Add the workspace document to Firestore
                    firebase
                      .firestore()
                      .collection("workspace")
                      .add({
                        companyName: companyName,
                        credits: 20,
                        members: [
                          {
                            uid: uid,
                            role: "Owner",
                            email: firebase.auth().currentUser.email,
                          },
                        ],
                        website: website,
                        companyType: companyType,
                        companySize: companySize,
                      })
                      .then((docRef) => {
                        // Retrieve the workspace ID after adding the document
                        const workspaceID = docRef.id;

                        // Update the user document with the workspaceID
                        firebase
                          .firestore()
                          .collection("users")
                          .doc(uid)
                          .update({
                            workspaces:
                              firebase.firestore.FieldValue.arrayUnion(
                                workspaceID
                              ),
                            onboarded: true,
                            activeWorkSpace: workspaceID,
                          })
                          .then(() => {
                            console.log(
                              "Workspace ID added to user document successfully!"
                            );
                          })
                          .catch((error) => {
                            console.error(
                              "Error updating user document:",
                              error
                            );
                          });
                      })
                      .catch((error) => {
                        console.error(
                          "Error adding workspace document:",
                          error
                        );
                      });
                  }
                }}
                style={{
                  height: 40,
                  width: "93%",
                  borderRadius: 6,
                  border: "1px solid #0c41ab",
                  backgroundColor: "#0c41ab",
                  color: "white",
                  fontSize: 14,
                  paddingTop: 9,
                  textAlign: "center",
                  fontFamily: "PPMedium",
                  cursor: "pointer",
                  marginTop: 40,
                  opacity:
                    website && companyType && companyName && companySize
                      ? 1
                      : 0.5,
                }}
                className="d-flex justify-content-center"
              >
                {loading ? (
                  <TailSpin
                    height={24}
                    width={24}
                    color="white"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{ marginTop: -2 }}
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  <p>Continue</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </SkyLightStateless>
    </>
  );
};

export default withRouter(Onboarding);
