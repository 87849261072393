import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import axios from "axios";
import "react-phone-number-input/style.css";
import MainMenu from "../Layout/MainMenu";
import { FaRegCompass, FaRegStar } from "react-icons/fa";
import { BsBackpack } from "react-icons/bs";
import { IoFilterSharp } from "react-icons/io5";
import SideNav from "../Layout/SideNav";
import TopBar from "../Layout/TopBar";
import Checkout from "./Checkout";
import { TailSpin } from "react-loader-spinner";
import { useAuth } from "../Context/AuthContext";
import { IoIosCheckmarkCircle } from "react-icons/io";
import BriefModal from "./BriefModal";
import BriefModalMobile from "./BriefModalMobile";

const CreatorUploadMobile = () => {
  const categories = [1, 2, 3, 4, 5, 6, 7, 9, 3, 4, 5, 6, 7, 9];
  const [loading, setLoading] = useState(false);
  const { fbConnected, paymentMethodId, customerId, subscriptionStatus } =
    useAuth();
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [billingPortalLoading, setBillingPortalLoading] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          backgroundColor: "#fff",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#fff",
          }}
        >
          <MDBCol
            style={{
              width: "105vw",
              overflowX: "hidden",
              paddingBottom: 0,
              paddingBottom: 0,
              marginTop: 50,
            }}
            size="12"
            className="d-flex justify-content-center"
          >
            <BriefModalMobile />
          </MDBCol>
        </div>
      </div>
    </>
  );
};

export default withRouter(CreatorUploadMobile);
