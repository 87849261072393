import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC66Hc8QKqCdF0ZgsFjxj8ONIwWz99iSM0",
  authDomain: "rowads-5bf77.firebaseapp.com",
  projectId: "rowads-5bf77",
  storageBucket: "rowads-5bf77.appspot.com",
  messagingSenderId: "443394810408",
  appId: "1:443394810408:web:9985e6d3efaf0e4c144b1d",
  measurementId: "G-N03CD52TCP",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export { firebase };
