import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import { AiOutlinePlayCircle, AiOutlineRight } from "react-icons/ai";
import { useLoading } from "../Context/LoadingContext";
import { BsPlayCircle } from "react-icons/bs";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CongratModalMobile from "./CongratModalMobile";
import { RiCloseLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";

const HeadingMobile = ({ setIsOpen, requestLoading, clones }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [profileID, setProfileID] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();

  const goBack = () => {
    history.goBack();
  };

  if (requestLoading === true) {
    return null;
  }

  const duration = 180 * 60 * 1000; // 5 minutes in milliseconds

  function calculatePercentage(createdAt) {
    const createdAtDate = new Date(createdAt.seconds * 1000);
    const now = new Date();
    const timeElapsed = now - createdAtDate;
    let percentage = (timeElapsed / duration) * 100;
    return Math.min(percentage, 100); // Cap at 100%
  }

  const sortedClones = clones.sort(
    (a, b) => b.createdAt.seconds - a.createdAt.seconds
  );

  function formatDateFromTimestamp(timestamp) {
    // Create a Date object from the timestamp
    const date = new Date(timestamp.seconds * 1000);

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is 0-indexed
    const year = date.getFullYear().toString().substr(-2); // Get last two digits of year

    // Format the date as dd/mm/yy
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Trigger a re-render
      setLoading((loading) => !loading);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <MDBRow
      style={{
        color: "white",
        paddingTop: 140,
        width: "100vw",
        paddingLeft: 10,
      }}
    >
      <CongratModalMobile
        profileID={profileID}
        isOpen={deleteOpen}
        setIsOpen={setDeleteOpen}
      />
      <MDBCol style={{ cursor: "pointer" }} size="12">
        <NavLink
          to="/"
          exact
          style={{
            textDecoration: "underline",
            display: "inline-block",
            fontSize: "3.8vw",
            fontFamily: "MAHeavy",
            color: "black",
          }}
        >
          {t("HomeNav")}
        </NavLink>
        <AiOutlineRight
          style={{
            fontSize: "2vw",
            marginLeft: 6,
            marginRight: 6,
            color: "#502274",
          }}
          className="d-inline"
        />
        <p
          onClick={() => goBack()}
          style={{
            textDecoration: "underline",
            display: "inline-block",
            fontSize: "3.8vw",
            fontFamily: "MAHeavy",
            color: "black",
          }}
        >
          {t("CloneNav")}
        </p>
      </MDBCol>
      <MDBCol style={{ cursor: "pointer" }} size="12">
        <MDBRow style={{ width: "100vw", marginTop: 8 }}>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "MAHeavy",
                fontSize: "4vw",
                color: "black",
              }}
            >
              {t("CloneTitle")}
            </p>
          </MDBCol>
          <MDBCol size="12">
            <p
              style={{
                fontFamily: "MABold",
                fontSize: "3.4vw",
                color: "black",
                marginTop: -16,
                opacity: 0.7,
              }}
            >
              {clones.length > 0 ? t("CloneSubtitle") : t("NoClone")}
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ width: 800 }}>
          {sortedClones.length > 0 &&
            sortedClones.map((clone) => {
              const isFinished = clone.status === "Finished";
              const percentage = isFinished
                ? 100
                : calculatePercentage(clone.createdAt).toFixed(1);
              return (
                <MDBCol size="12">
                  <div
                    target="_blank"
                    key={clone.profileID}
                    onClick={() => {
                      if (clone.status === "Finished") {
                        history.push(`/request?id=${clone.profileID}`);
                      }
                    }}
                    style={{
                      position: "relative",
                      display: "inline-block",
                      height: "50vw",
                      width: "90vw",
                      marginRight: 6,
                      borderRadius: 5,
                      overflow: "hidden",
                      marginBottom: 16,
                      cursor: clone.status === "Finished" ? "pointer" : null,
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {clone.status !== "Finished" ? (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          height: "20vw",
                          width: "20vw",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 100,
                          top: "50%",
                        }}
                      >
                        {clone.status === "Error" ? (
                          <>
                            <RiCloseLine
                              style={{
                                position: "absolute",
                                zIndex: 10,
                                color: "red",
                                right: 5,
                                top: 5,
                                fontSize: 30,
                              }}
                              onClick={(e) => {
                                e.stopPropagation(); // This will prevent the event from bubbling up to the parent div
                                setDeleteOpen(true);
                                setProfileID(clone.profileID); // This will open the congratModal
                              }}
                            />
                            <p
                              style={{
                                fontFamily: "CSBold",
                                fontSize: "5vw",
                                color: "red",
                              }}
                            >
                              {t("Failed")}
                            </p>
                          </>
                        ) : clone.status === "Pending" ? (
                          <p
                            style={{
                              fontFamily: "CSBold",
                              fontSize: "5vw",
                              color: "white",
                            }}
                          >
                            {t("Queued")}...
                          </p>
                        ) : (
                          <CircularProgressbar
                            styles={{
                              path: { stroke: "rgb(128, 54, 224)" },
                              text: { fill: "white", fontSize: "3.7vw" },
                            }}
                            value={percentage}
                            text={`${percentage}%`}
                          />
                        )}
                      </div>
                    ) : (
                      <>
                        {" "}
                        <RiCloseLine
                          style={{
                            position: "absolute",
                            zIndex: 10,
                            color: "red",
                            right: 5,
                            top: 5,
                            fontSize: 30,
                          }}
                          onClick={(e) => {
                            e.stopPropagation(); // This will prevent the event from bubbling up to the parent div
                            setDeleteOpen(true);
                            setProfileID(clone.profileID); // This will open the congratModal
                          }}
                        />
                        <BsPlayCircle
                          style={{
                            position: "absolute",
                            color: "white",
                            fontSize: "20vw",
                            zIndex: 100,
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                        <div
                          style={{
                            backgroundColor: "rgb(32, 30, 35)",
                            position: "absolute",
                            height: 26,
                            width: 80,
                            marginBottom: 10,
                            zIndex: 130,
                            bottom: 0,
                            right: 10,
                            textAlign: "center",
                            borderRadius: 100,
                            fontFamily: "CSSemiBold",
                            paddingTop: 4,
                            fontSize: 13,
                          }}
                          className="d-flex justify-content-center"
                        >
                          <p>20 {t("Credit")}</p>
                        </div>
                        <div
                          style={{
                            backgroundColor: "rgb(32, 30, 35)",
                            position: "absolute",
                            height: 26,
                            width: 93,
                            marginBottom: 10,
                            zIndex: 130,
                            bottom: 0,
                            left: 10,
                            textAlign: "center",
                            borderRadius: 100,
                            fontFamily: "CSSemiBold",
                            paddingTop: 4,
                            fontSize: 13,
                          }}
                          className="d-flex justify-content-center"
                        >
                          <p>{formatDateFromTimestamp(clone.createdAt)}</p>
                        </div>
                      </>
                    )}

                    <img
                      src={`https://image.mux.com/${clone.playbackID}/animated.gif`}
                      style={{
                        display: "inline-block",
                        height: "50vw",
                        width: "90vw",
                        borderRadius: 10,
                        objectFit: "cover",
                        verticalAlign: "top",
                        backgroundColor: "transparent", // Added this line
                        filter: "blur(2px)",
                      }}
                    />
                  </div>
                </MDBCol>
              );
            })}
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(HeadingMobile);
