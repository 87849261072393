import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import ReactStars from "react-stars";
import { BiRightArrowAlt } from "react-icons/bi";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FaLock } from "react-icons/fa";
import { useAuth } from "../Context/AuthContext";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { useSignUpModal } from "../Context/SignUpModal";
import { TailSpin } from "react-loader-spinner";
import ProfileRowsMobile from "./ProfileRowsMobile";
import MuxPlayer from "@mux/mux-player-react";
import { v4 as uuidv4 } from "uuid";

const HeadingMobile = ({ profiles }) => {
  const { setIsOpen } = useSignUpModal();
  const { paid } = useAuth();
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState("selenagomez");
  const [playbackID, setPlaybackID] = useState(
    "SkKyZxKECmLtYn3XldJ0100ZSYk191OMgrR5T7D02TLczQ"
  );
  const [imageLoaded, setImageLoaded] = useState({});

  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const [count, setCount] = useState(105);
  const [profileFree, setProfileFree] = useState(true);
  const [profileName, setProfileName] = useState("Selena");
  const { i18n } = useTranslation();
  // Starting with 105

  const switchVideo = (language) => {
    switch (language) {
      case "en":
        return "SkKyZxKECmLtYn3XldJ0100ZSYk191OMgrR5T7D02TLczQ";
        break;
      case "ko":
        return "1da3XPGQ00t9pSZexqrj5jgDu02bFn02qV5hnGI2iWljis";
        break;
      case "ja":
        return "CsbgSyeu8yiccnd7lHM1Bd6J402RD1UtdJd402fCnytBE";
    }
  };

  useEffect(() => {
    // Function to parse query parameters from window.location
    const getQueryParams = () => {
      const params = new URLSearchParams(window.location.search);
      return {
        currency: params.get("currency"),
        price: params.get("price"),
      };
    };

    // Extract the currency and price query parameters
    const { currency, price } = getQueryParams();

    // If both currency and price exist, track the Purchase event with Facebook Pixel
    if (currency && price) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "purchase",
        value: price, // Replace 'price' with the transaction value
        currency: currency,
        content_id: "Credits",
        event_id: uuidv4(), // Replace 'currency' with the transaction currency code
        // Replace 'currency' with the transaction currency code
        // Replace 'currency' with the transaction currency code
      });
    }
  }, []);

  useEffect(() => {
    setPlaybackID(switchVideo(i18n.language));
  }, [i18n.language]);

  useEffect(() => {
    // Function to update the count and set a new timeout
    const updateCount = () => {
      // Increment the count
      setCount((prevCount) => prevCount + 1);

      // Determine the next timeout duration (between 1 and 5 seconds)
      const nextUpdate = Math.random() * (10000 - 1000) + 1000;

      // Set the next update
      setTimeout(updateCount, nextUpdate);
    };

    // Start the first update
    const timeout = setTimeout(updateCount, 1000); // Start after 1 second

    // Cleanup on component unmount
    return () => clearTimeout(timeout);
  }, []);

  // Handle dropdown value change
  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    console.log("Selected value is ", selectedValue);
  }, [selectedValue]);

  const submitVideo = async () => {
    setError(null);
    setLoading(true);
    if (inputValue) {
      var createVideo = firebase.functions().httpsCallable("createVideo");
      createVideo({
        inputValue: inputValue,
        profileID: selectedValue,
      }).then(async (result) => {
        if (result.data.error) {
          mixpanel.track("Failed to create a video", {
            reason: result.data.message,
          });
          console.log(result.data.message);
          setLoading(false);
          return setError(result.data.message);
        } else {
          const videoID = result.data.videoID;

          if (videoID) {
            mixpanel.track("User created a video", {
              profile: name,
              length: inputValue.length,
            });
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "viewContent",
              content_id: "Video",
              event_id: uuidv4(), // Replace 'currency' with the transaction currency code
            });
            const uid = firebase.auth().currentUser.uid;
            const email = firebase.auth().currentUser.email;
            const userDoc = await firebase
              .firestore()
              .collection("users")
              .doc(uid)
              .get();
            const userData = userDoc.data();
            const hashedEmail = userData.hashedEmail;

            //navigate to the success page
            console.log(
              "Successfully generated a video with video id",
              videoID
            );
            return history.push("/videos");
          }
        }
      });
    }
  };

  const limit = paid ? 5000 : 100;

  const handleChange = (event) => {
    setInputValue(event.target.value.substr(0, limit));
  };

  const handleImageLoaded = (name) => {
    setImageLoaded((prev) => ({ ...prev, [name]: true }));
  };

  const placeholderStyle = {
    height: 110,
    width: 110,
    borderRadius: 100,
    backgroundColor: "grey",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const sortedProfiles = profiles.sort((a, b) =>
    b.free === a.free ? 0 : a.free ? -1 : 1
  );

  function getFirstName(fullName) {
    // Split the full name by space and return the first element of the array
    return fullName.split(" ")[0];
  }
  return (
    <MDBRow
      style={{
        color: "white",
        paddingTop: 130,
        width: "100vw",
        paddingLeft: 0,
        paddingBottom: "4vh",
      }}
    >
      <MDBCol className="d-flex justify-content-center" size="12">
        <div
          className="d-flex justify-content-center align-items-center" // Added align-items-center for vertical alignment
          style={{
            height: 30,
            width: "45vw",
            borderRadius: 200,
            backgroundColor: "white",
            fontFamily: "MAHeavy",
            border: "1px solid black",
            fontSize: "3.4vw",
            color: "black",
          }}
        >
          <img
            src={require("./TikTok.png")}
            style={{
              height: "4vw", // Adjust this to align with the text size
              marginRight: 6,
            }}
          />
          <p style={{ margin: 0, marginTop: 2.8 }}>{t("tiktokHeader")}</p>{" "}
          {/* Removed inline-block and added margin: 0 */}
        </div>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <p
          style={{
            fontFamily: "MAHeavy",
            fontSize: "6vw",
            opacity: 1,
            lineHeight: 1.2,
            textAlign: "center",
            marginTop: 13,
            color: "black",
          }}
        >
          {t(`heading`)}
        </p>
      </MDBCol>
      <MDBCol
        className="d-flex justify-content-center align-items-center"
        size="12"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Images */}
          <div style={{ display: "flex", marginRight: 10 }}>
            {[1, 2, 3, 4, 5].map((num) => (
              <img
                key={num}
                src={require(`./profile${num}.jpg`)}
                style={{
                  height: "5vw",
                  width: "5vw",
                  borderRadius: "50%", // 100 is not a valid value here
                  objectFit: "cover",
                  border: "2px solid white",
                  marginLeft: num === 1 ? 0 : -9, // Apply margin-left only for images after the first
                }}
              />
            ))}
          </div>

          {/* Text */}
          <p
            style={{
              fontFamily: "MABold",
              fontSize: "3.5vw",
              opacity: 0.7,
              lineHeight: 1.2,
              color: "black",
              textAlign: "center",
              margin: "0px 5px 0px 0px",
              marginTop: 3, // Add horizontal margin
            }}
          >
            {t("numOfVideos")}
          </p>

          {/* Stars */}
          <div style={{ marginTop: 0 }}>
            <ReactStars
              color1={"#000"}
              color2={"#000"}
              count={5}
              value={5}
              edit={false}
              size={9}
              className="d-inline"
            />
          </div>
        </div>
      </MDBCol>

      <MDBCol size="12" className="d-flex justify-content-center">
        <NavLink
          to="/explore"
          onClick={() => mixpanel.track("Clicked explore on Landing Page")}
          exact
          style={{
            height: 54,
            width: "76vw",
            marginTop: 15,
            border: "2px solid #f8284e",
            borderRadius: 7,
            backgroundColor: "#f8284e",
            display: "flex", // Flex container
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        >
          <p
            style={{
              fontFamily: "MABold",
              fontSize: 16,
              color: "white",
              margin: 0, // Remove any margin
              zIndex: 300,
              marginTop: 2,
            }}
          >
            {t("exploreButton")}{" "}
            <BiRightArrowAlt style={{ fontSize: 18 }} className="d-inline" />
          </p>
        </NavLink>
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(HeadingMobile);
