import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { TiTick } from "react-icons/ti";
import PriceHeading from "./PriceHeading";
import { MdOutlineOutlinedFlag } from "react-icons/md";
import { AiTwotoneExperiment } from "react-icons/ai";
import { FaRegSmile } from "react-icons/fa";
import { CgShutterstock } from "react-icons/cg";
import { RiUserVoiceLine } from "react-icons/ri";
import { TbTimelineEvent } from "react-icons/tb";
import { BsLightningCharge } from "react-icons/bs";
import { LiaCloneSolid } from "react-icons/lia";
import { IoCodeOutline } from "react-icons/io5";
import { useAuth } from "../Context/AuthContext";

const Pricing = ({ subscribed, discountRate, subscribedPlans, setIsOpen }) => {
  const [value, setValue] = useState(25);
  const { workspaceID, currentPlan } = useAuth();
  const [monthly, setMonthly] = useState(false);
  const [sliderValues, setSliderValues] = useState({
    Starter: 0,
    Growing: 0,
    Scale: 0,
  });

  const handleChange = (event) => {
    setValue(Number(event.target.value));
  };

  const handleSliderChange = (tier, newValue) => {
    setSliderValues((prevValues) => ({
      ...prevValues,
      [tier]: newValue,
    }));
  };

  const featureIcons = {
    "10 high quality UGC ads": AiTwotoneExperiment,
    "15 high quality UGC ads": AiTwotoneExperiment,
    "20 high quality UGC ads": AiTwotoneExperiment,
    "Custom number of UGC ads": AiTwotoneExperiment,
    "Thousands of diverse creators": FaRegSmile,
    "In-house professional editors": CgShutterstock,
    "Native english speakers": RiUserVoiceLine,
    "Pay for performance model": TbTimelineEvent,
    "Fast turnaround": BsLightningCharge,
    "Faster generation": BsLightningCharge,
    "Fastest generation": BsLightningCharge,
    "200 tested, effective UGC templates": AiTwotoneExperiment,
    "Slack support channel": LiaCloneSolid,
    "All tested, effective UGC templates": AiTwotoneExperiment,
    "Create 20 custom AI actors": LiaCloneSolid,
    "Unlimited custom AI actors": LiaCloneSolid,
    "Dedicated campaign manager": IoCodeOutline,
  };

  const plans = [
    {
      tier: "Starter",
      features: [
        "10 high quality UGC ads",
        "Thousands of diverse creators",
        "In-house professional editors",
        "Native english speakers",
        "Pay for performance model",
        "Fast turnaround",
      ],
    },
    {
      tier: "Growing",
      features: [
        "15 high quality UGC ads",
        "Thousands of diverse creators",
        "In-house professional editors",
        "Native english speakers",
        "Pay for performance model",
        "Fast turnaround",
        "Slack support channel",
      ],
    },
    {
      tier: "Scale",
      features: [
        "20 high quality UGC ads",
        "Thousands of diverse creators",
        "In-house professional editors",
        "Native english speakers",
        "Pay for performance model",
        "Fast turnaround",
        "Slack support channel",
      ],
    },
    {
      tier: "Enterprise",
      features: [
        "Custom number of UGC ads",
        "Thousands of diverse creators",
        "In-house professional editors",
        "Native english speakers",
        "Pay for performance model",
        "Fast turnaround",
        "Slack support channel",
        "Dedicated campaign manager",
      ],
    },
  ];

  const pricesArray = {
    Starter: [
      { 10: { monthly: "10%", annual: "10%", templates: 20, ads: 10 } },
      { 20: { monthly: 120, annual: 96, templates: 20, ads: 20 } },
    ],
    Growing: [
      { 30: { monthly: 150, annual: "7%", templates: 200, ads: 15 } },
      { 60: { monthly: 300, annual: 240, templates: 200, ads: 60 } },
      { 90: { monthly: 450, annual: 360, templates: 200, ads: 90 } },
    ],
    Scale: [
      { 250: { monthly: 750, annual: "5%", templates: "All", ads: 20 } },
      { 375: { monthly: 1125, annual: 900, templates: "All", ads: 375 } },
      { 500: { monthly: 1500, annual: 1200, templates: "All", ads: 500 } },
      { 1000: { monthly: 3000, annual: 2400, templates: "All", ads: 1000 } },
    ],
  };

  return (
    <>
      <MDBRow
        style={{ marginTop: 30 }}
        className="d-flex justify-content-center"
      >
        <MDBCol className="d-flex justify-content-center" size="12">
          <MDBRow
            className="d-flex justify-content-center"
            style={{
              width: 1320,
              marginTop: 10,
              color: "#30312c",
              paddingRight: 0,
              paddingLeft: 0,
              marginLeft: -20,
            }}
          >
            {plans.map((plan, index) => (
              <MDBCol size="3" key={index}>
                {currentPlan?.name === plan.tier && (
                  <div
                    style={{
                      position: "absolute",
                      height: 30,
                      width: 120,
                      borderTop: "2px solid blue",
                      borderRight: "2px solid blue",
                      borderLeft: "2px solid blue",
                      borderBottom: "1px solid blue",
                      marginTop: -29,
                      marginLeft: 15,
                      textAlign: "center",
                      fontFamily: "SSRegular",
                      paddingTop: 2,
                      borderRadius: 3,
                      backgroundColor: "rgb(245, 245, 255)",
                    }}
                  >
                    <p>Current plan</p>
                  </div>
                )}
                <div
                  style={{
                    paddingLeft: 20,
                    fontFamily: "PPMedium",
                    paddingLeft: 20,
                    width: 320,
                    paddingTop: 40,
                    borderRadius: 12,
                    border:
                      currentPlan?.name === plan.tier
                        ? "2px solid blue"
                        : "1px solid rgb(234, 236, 240)",
                    backgroundColor:
                      currentPlan?.name === plan.tier
                        ? "rgb(245, 245, 255)"
                        : "#fff",
                    height: 650,
                  }}
                >
                  <PriceHeading
                    subscribedPlans={subscribedPlans}
                    planType="Monthly"
                    tier={plan.tier}
                    setIsOpen={setIsOpen}
                    monthly={monthly}
                    price={
                      plan.tier !== "Enterprise" &&
                      Object.values(
                        pricesArray[plan.tier][sliderValues[plan.tier]]
                      )[0]
                    }
                  />

                  <p
                    style={{
                      marginTop: 15,
                      fontSize: 16,
                      fontFamily: "SSSemiBold",
                      paddingBottom: 22,
                    }}
                  >
                    Features
                  </p>
                  {plan.features.map((feature, index) => {
                    const IconComponent = featureIcons[feature];

                    return (
                      <div
                        key={index}
                        style={{ marginTop: index === 0 ? -25 : 12 }}
                      >
                        <div
                          style={{
                            height: 22,
                            width: 22,
                            borderRadius: 200,
                            border: "0px solid white",
                            paddingTop: 1.7,
                            display: "inline-block",
                          }}
                          className="d-flex justify-content-center"
                        >
                          {IconComponent && (
                            <IconComponent style={{ fontSize: 24 }} />
                          )}
                        </div>
                        <p
                          style={{
                            display: "inline-block",
                            position: "absolute",
                            marginTop: -19.5,
                            marginLeft: 28,
                            fontSize: 15,
                            fontFamily: "SSMedium",
                          }}
                        >
                          {feature}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default Pricing;
