import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { useAuth } from "../Context/AuthContext";

const DeleteModal = ({ setDeleteOpen, deleteOpen, videoID, projectID }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { workspaceID } = useAuth();

  return (
    <>
      <SkyLightStateless
        hideOnOverlayClicked={true}
        onOverlayClicked={async () => {
          setDeleteOpen(false);
        }}
        onCloseClicked={async () => {
          setDeleteOpen(false);
        }}
        isVisible={deleteOpen}
        overlayStyles={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9000,
          backgroundColor: "#181616",
          opacity: 0.6,
        }}
        dialogStyles={{
          zIndex: 9000,
          position: "fixed",
          width: "100vw",
          minHeight: "60px",
          marginTop: "0%",
          top: "0%",
          height: "100vh",
          boxShadow: "none",
          left: "0%",
          right: "0%",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "transparent",
          border: "none",
        }}
        closeButtonStyle={{
          color: "black",
          right: 650,
          position: "absolute",
          marginTop: 90,
          zIndex: 9600,
          fontSize: 50,
          height: 60,
          width: 60,
          display: "none",
        }}
      >
        <OutsideClickHandler
          onOutsideClick={async () => {
            setDeleteOpen(false);
          }}
        >
          <div
            style={{
              paddingBottom: 40,
              width: 360,
              paddingBottom: 26,
              backgroundColor: "white",
              borderRadius: 8,
              zIndex: 90000,
              paddingLeft: 0,
              top: "22%",
              position: "absolute",
              left: "0%",
              right: "0%",
              marginLeft: "auto",
              marginRight: "auto",
              border: "1px solid #505050",
            }}
            className="backy60"
          >
            <MDBRow>
              <MDBCol className="d-flex justify-content-center" size="12">
                <p
                  style={{
                    fontFamily: "PPBold",
                    color: "black",
                    fontSize: 24,
                    marginTop: 40,
                    textAlign: "center",
                    lineHeight: 1.3,
                  }}
                >
                  Confirm that you want to
                  <br />
                  delete this video
                </p>
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="12">
                <hr
                  style={{
                    height: 4,
                    width: 60,
                    backgroundColor: "white",
                    color: "black",
                    marginTop: -8,
                  }}
                />
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="12">
                <p
                  style={{
                    fontFamily: "PPMedium",
                    color: "black",
                    fontSize: 14,
                    marginTop: 0,
                    textAlign: "center",
                    width: 310,
                    lineHeight: 1.4,
                    opacity: 0.9,
                    marginTop: -1,
                  }}
                >
                  This is not reversible. After you delete this video, unless
                  you have downloaded it, all copies will be lost.
                </p>
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="12">
                <MDBRow style={{ width: 350, marginTop: -5 }}>
                  <MDBCol className="d-flex justify-content-center" size="6">
                    <div
                      style={{
                        width: "100%",
                        height: 50,
                        border: "0.5px solid #c2d2f2",
                        fontFamily: "PPMedium",
                        color: "#2f1878",
                        cursor: "pointer",
                        marginTop: 16,
                        display: "inline-block",
                        fontSize: 14,
                      }}
                      onClick={() => setDeleteOpen(false)}
                      className="d-flex justify-content-center"
                    >
                      <p style={{ marginTop: 14 }}>Cancel</p>
                    </div>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="6">
                    <div
                      style={{
                        width: "100%",
                        height: 50,
                        border: "0.5px solid red",
                        fontFamily: "PPMedium",
                        color: "#2f1878",
                        cursor: "pointer",
                        marginTop: 16,
                        display: "inline-block",
                        fontSize: 14,
                      }}
                      onClick={() => {
                        setLoading(true);
                        firebase
                          .firestore()
                          .collection("videos")
                          .doc(videoID)
                          .delete()
                          .then(() => {
                            setDeleteOpen(false);
                            setLoading(false);
                          });
                      }}
                      className="d-flex justify-content-center"
                    >
                      {loading ? (
                        <TailSpin
                          height={30}
                          width={30}
                          color="red"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{ marginTop: 10 }}
                          wrapperClass=""
                          visible={true}
                        />
                      ) : (
                        <p style={{ marginTop: 14, color: "red" }}>
                          Delete video
                        </p>
                      )}
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </div>
        </OutsideClickHandler>
      </SkyLightStateless>
    </>
  );
};

export default withRouter(DeleteModal);
