import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import { AiOutlineRight } from "react-icons/ai";
import { BiRightArrowAlt } from "react-icons/bi";
import { FaHandsClapping } from "react-icons/fa6";

const Heading = ({ setIsOpen }) => {
  return (
    <MDBRow
      style={{
        color: "white",
        paddingTop: 160,
        width: 840,
        paddingLeft: 0,
      }}
    >
      <MDBCol
        className="d-flex justify-content-center"
        style={{ cursor: "pointer" }}
        size="12"
      >
        <div
          style={{
            backgroundColor: "#201e23",
            height: 200,
            width: 200,
            marginTop: 40,
            borderRadius: 100,
          }}
          className="d-flex justify-content-center"
        >
          <FaHandsClapping
            style={{ marginTop: 44, fontSize: 100, color: "rgb(128, 54, 224)" }}
          />
        </div>
      </MDBCol>
      <MDBCol
        className="d-flex justify-content-center"
        style={{ cursor: "pointer" }}
        size="12"
      >
        <p
          style={{
            color: "white",
            fontFamily: "CSBold",
            fontSize: 35,
            textAlign: "center",
            lineHeight: 1.2,
            marginTop: 35,
          }}
        >
          Congratulations! Your video
          <br />
          is on it's way to you.
        </p>
      </MDBCol>
      <MDBCol
        className="d-flex justify-content-center"
        style={{ cursor: "pointer" }}
        size="12"
      >
        <p
          style={{
            color: "white",
            fontFamily: "CSRegular",
            fontSize: 18,
            textAlign: "center",
            lineHeight: 1.2,
            marginTop: 5,
          }}
        >
          Can't believe you will join our trip An email with
          <br />
          all the details has been sent to you.
        </p>
      </MDBCol>
      <MDBCol className="d-flex justify-content-center" size="12">
        <NavLink
          exact
          to={`/`}
          style={{
            height: 54,
            width: 400,
            border: "2px solid rgb(128, 54, 224)",
            borderRadius: 100,
            marginTop: 6,
            backgroundColor: "rgb(128, 54, 224)",
          }}
          className="d-flex justify-content-center"
        >
          <p
            style={{
              fontFamily: "CSMedium",
              fontSize: 16,
              display: "inline-block",
              color: "white",
              marginLeft: 0,
              marginTop: 15,
              zIndex: 300,
            }}
          >
            Back to homepage
          </p>
        </NavLink>
      </MDBCol>
    </MDBRow>
  );
};

export default withRouter(Heading);
