import React, { useRef, useEffect, useState } from "react";
import { Rnd } from "react-rnd";
import { useDetectClickOutside } from "react-detect-click-outside";
import Moveable from "react-moveable";

const Canvas = ({
  items,
  onItemsChange,
  currentTime,
  setSelectedIndex,
  selectedIndex,
  canvasRef,
  preparing,
  setVideoItems,
}) => {
  const [mounted, setMounted] = useState(false);
  const [currentRef, setCurrentRef] = useState(null);
  const [currentType, setCurrentType] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const moveableRef = useRef();
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (currentRef) {
        setCurrentRef(null);
      }
    },
  });
  const [editMode, setEditMode] = useState(false);

  const handleTransform = async (index, transform) => {
    setVideoItems((currentItems) =>
      currentItems.map((item, i) => {
        if (i === index) {
          // Apply updates if the current item is the one being updated
          return { ...item, transform: transform };
        }
        return item; // Return the item unchanged if it's not the one being updated
      })
    );
  };

  function hexToFilter(hexColor) {
    // Remove the '#' symbol if present
    hexColor = hexColor.replace("#", "");

    // Convert the hex color to RGB values
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate the RGB values for the inverted color
    const invertedR = 255 - r;
    const invertedG = 255 - g;
    const invertedB = 255 - b;

    // Calculate the percentages for the filter values
    const invert = Math.round(
      ((invertedR + invertedG + invertedB) / 765) * 100
    );
    const sepia = 100;
    const saturate = 5000;
    const hueRotate = Math.round(
      (Math.atan2(Math.sqrt(3) * (g - b), 2 * r - g - b) * 180) / Math.PI
    );
    const brightness = 100;
    const contrast = 100;

    // Construct the filter value string
    const filterValue = `invert(${invert}%) sepia(${sepia}%) saturate(${saturate}%) hue-rotate(${hueRotate}deg) brightness(${brightness}%) contrast(${contrast}%)`;

    return filterValue;
  }

  function parseScaleFromTransform(transformString) {
    const scaleRegex = /scale\((\d+(?:\.\d+)?), (\d+(?:\.\d+)?)\)/;
    const match = transformString.match(scaleRegex);

    if (match) {
      const [, scaleX, scaleY] = match;
      const widthPercent = (parseFloat(scaleX) * 100).toFixed(2) + "%";
      const heightPercent = (parseFloat(scaleY) * 100).toFixed(2) + "%";
      return { widthPercent, heightPercent };
    }

    return { widthPercent: "100%", heightPercent: "100%" }; // Default if no scale is found
  }

  function getTransformCoordinates(transformString, element, container) {
    const transformValues = transformString.match(/-?\d+\.?\d*/g);
    const translateX = parseFloat(transformValues[0]);
    const translateY = parseFloat(transformValues[1]);

    const elementRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    const elementCenterX = elementRect.left + elementRect.width / 2;
    const elementCenterY = elementRect.top + elementRect.height / 2;

    const offsetX = elementCenterX - containerRect.left;
    const offsetY = elementCenterY - containerRect.top;

    const percentX = (offsetX / containerRect.width) * 100;
    const percentY = (offsetY / containerRect.height) * 100;

    return { x: percentX + "%", y: percentY + "%" };
  }
  return (
    <div
      style={{
        width: 200,
        backgroundColor: "black",
        height: 350,
        marginTop: "2vh",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        position: "relative",
        padding: 0,
        overflow: "hidden",
      }}
      ref={ref}
      // ref={
      //   items[selectedIndex] && items[selectedIndex].type === "text"
      //     ? null
      //     : ref
      // }
    >
      {!preparing &&
        preparing !== null &&
        items.map((item, index) => (
          <>
            {item.type === "text" &&
              item.startTime <= currentTime &&
              currentTime <= item.endTime && (
                <p
                  contentEditable={true}
                  onBlur={(e) => {
                    const newTextContent = e.target.innerText;
                    setVideoItems((prevItems) =>
                      prevItems.map((prevItem, i) =>
                        i === index
                          ? { ...prevItem, textContent: newTextContent }
                          : prevItem
                      )
                    );
                  }}
                  ref={item.videoRef}
                  onClick={(e) => {
                    if (e.detail === 1) {
                      console.log("Only clicked once");
                      setCurrentRef(item.videoRef);
                      setCurrentType(item.type);
                      setSelectedIndex(index);
                    } else if (e.detail > 1) {
                      console.log("Clicked multiple times");
                      setEditMode(true);
                      setCurrentRef(item.videoRef);
                      setCurrentType(item.type);
                      setSelectedIndex(index);
                    }
                  }}
                  className="drag-handle"
                  style={{
                    margin: 0,
                    zIndex: item.layerIndex,
                    padding: 5,
                    textAlign: "center",
                    fontSize: `${item.fontSize}px`,
                    fontFamily: item.fontFamily,
                    color: item.color,
                    outline: "none",
                    width: "100%", // Add width: 100% to make the text fill the div
                    overflowWrap: "break-word",
                    fontWeight: item.style.fontWeight,
                    textDecoration: item.style.textDecoration,
                    fontStyle: item.style.fontStyle,
                    textTransform: item.style.textTransform,
                    textAlign: item.textAlign,
                    transform: item.transform,
                  }}
                >
                  {item.textContent}
                </p>
              )}
            {item.type === "icon" &&
              item.startTime <= currentTime &&
              currentTime <= item.endTime && (
                <div
                  onClick={() => {
                    setCurrentRef(item.videoRef);
                    setCurrentType(item.type);
                    setSelectedIndex(index);
                  }}
                  className="drag-handle"
                  ref={item.videoRef}
                  src={item.thumbnail}
                  controls={false}
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    zIndex: item.layerIndex,
                    userSelect: "none",
                    WebkitUserDrag: "none",
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    msUserSelect: "none",
                    backgroundImage: `url(${item.thumbnail})`,
                    filter: hexToFilter(item.color),
                    transform: item.transform,
                  }}
                />
              )}
            {(item.type === "image" || item.type === "subtitle") &&
              item.startTime <= currentTime &&
              currentTime <= item.endTime && (
                <img
                  onClick={() => {
                    setCurrentRef(item.videoRef);
                    setCurrentType(item.type);
                    setSelectedIndex(index);
                  }}
                  className="drag-handle"
                  ref={item.videoRef}
                  src={item.thumbnail}
                  controls={false}
                  style={{
                    width: "100%",
                    height: "100%",
                    height: item.type === "subtitle" ? "30%" : "100%",
                    objectFit: "contain",
                    zIndex: item.type === "subtitle" ? 10000 : item.layerIndex,
                    userSelect: "none",
                    WebkitUserDrag: "none",
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    msUserSelect: "none",
                    transform: item.transform,
                  }}
                />
              )}
            {item.type === "actor" &&
              item.startTime <= currentTime &&
              currentTime <= item.endTime && (
                <>
                  <img
                    onClick={() => {
                      setCurrentRef(item.videoRef);
                      setCurrentType(item.type);
                      setSelectedIndex(index);
                    }}
                    className="drag-handle"
                    ref={item.videoRef}
                    src={
                      item.transparent
                        ? item.transparentThumbnail
                        : item.thumbnail
                    }
                    controls={false}
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      aspectRatio: "9/16",
                      zIndex: item.layerIndex,
                      userSelect: "none",
                      WebkitUserDrag: "none",
                      WebkitUserSelect: "none",
                      MozUserSelect: "none",
                      msUserSelect: "none",
                      transform: item.transform,
                    }}
                  />
                  {/* <video
                    onClick={() => {
                      setCurrentRef(item.videoRef);
                      setCurrentType(item.type);
                      setSelectedIndex(index);
                    }}
                    ref={item.videoRef}
                    src={item.videoURL}
                    controls={false}
                    style={{
                      height: 0,
                      width: 0,
                      position: "absolute",
                      visibility: "hidden",
                      display: "none",
                      marginTop: 1000,
                    }}
                  /> */}
                </>
              )}
            {item.type === "music" &&
              item.startTime <= currentTime &&
              currentTime <= item.endTime && (
                <video
                  onClick={() => {
                    setCurrentRef(item.videoRef);
                    setCurrentType(item.type);
                    setSelectedIndex(index);
                  }}
                  className="drag-handle"
                  ref={item.videoRef}
                  src={item.videoURL}
                  controls={false}
                  style={{
                    height: 0,
                    width: 0,
                  }}
                />
              )}
            {item.type === "video" &&
              item.startTime <= currentTime &&
              currentTime <= item.endTime && (
                <video
                  onClick={() => {
                    setCurrentRef(item.videoRef);
                    setCurrentType(item.type);
                    setSelectedIndex(index);
                  }}
                  className="drag-handle"
                  ref={item.videoRef}
                  src={item.videoURL}
                  controls={false}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    zIndex: item.layerIndex,
                    transform: item.transform,
                  }}
                />
              )}
          </>
        ))}

      {currentRef && (
        <Moveable
          container={ref.current}
          ref={moveableRef}
          target={currentRef}
          draggable={true}
          throttleDrag={1}
          edgeDraggable={false}
          startDragRotate={0}
          throttleDragRotate={0}
          scalable={true}
          keepRatio={true}
          throttleScale={0}
          snappable={true}
          rotatable={true}
          snapGridWidth={10}
          snapGridHeight={10}
          isDisplayGridGuidelines={false}
          onDrag={(e) => {
            const coordinates = getTransformCoordinates(
              e.transform,
              items[selectedIndex].videoRef.current,
              ref.current
            );
            setVideoItems((prevItems) => {
              const updatedItems = [...prevItems];
              updatedItems[selectedIndex].x = coordinates.x;
              updatedItems[selectedIndex].y = coordinates.y;
              return updatedItems;
            });
            handleTransform(selectedIndex, e.transform);
          }}
          snapRotationDegrees={[0, 45, 90, 135, 180, 225, 270, 315, 360]}
          onScale={(e) => {
            setVideoItems((prevItems) => {
              const updatedItems = [...prevItems];
              updatedItems[selectedIndex].width = parseScaleFromTransform(
                e.transform
              ).widthPercent;
              updatedItems[selectedIndex].height = parseScaleFromTransform(
                e.transform
              ).heightPercent;
              return updatedItems;
            });
            handleTransform(selectedIndex, e.transform);
          }}
          onRotate={(e) => {
            setVideoItems((prevItems) => {
              const updatedItems = [...prevItems];
              updatedItems[selectedIndex].rotation = e.absoluteRotation;
              return updatedItems;
            });
            handleTransform(selectedIndex, e.transform);
          }}
          onBound={(e) => {
            console.log(e);
          }}
        />
      )}
    </div>
  );
};

export default Canvas;
