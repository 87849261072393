import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import DashboardFooter from "../Layout/DashboardFooter";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import Heading from "./Heading";
import { TailSpin } from "react-loader-spinner";
import DashboardNavBarMobile from "../Layout/DashboardNavBarMobile";
import HeadingMobile from "./HeadingMobile";
import DashboardFooterMobile from "../Layout/DashboardFooterMobile";

const ClonesMobile = ({
  email,
  popUpVisible,
  setPopUpVisible,
  ref,
  seconds,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [videoLoading, setVideoLoading] = useState(false);
  const [clones, setClones] = useState(null);

  useEffect(() => {
    const uid = firebase.auth().currentUser.uid;
    firebase
      .firestore()
      .collection("profiles")
      .where("uid", "==", uid)
      .where("private", "==", true)
      .onSnapshot((querySnapshot) => {
        let initClones = [];
        if (querySnapshot.empty) {
          setClones([]);
        } else {
          querySnapshot.forEach((doc) => {
            initClones.push(doc.data());
          });
          setClones(initClones);
        }
      });
  }, []);

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          width: "100vw",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            paddingBottom: 0,
            color: "#30312c",
            backgroundColor: "#E8E8E8",
            width: "100vw",
          }}
        >
          <MDBCol
            style={{
              width: "100vw",
              overflowX: "hidden",
              paddingBottom: "10vh",
            }}
            size="12"
          >
            <MDBRow style={{ marginTop: 0 }}>
              <DashboardNavBarMobile
                popUpVisible={popUpVisible}
                setPopUpVisible={setPopUpVisible}
                email={email}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                seconds={seconds}
                ref={ref}
              />
            </MDBRow>

            {clones === null ? (
              <div
                className="d-flex justify-content-center"
                style={{ height: "100vh" }}
              >
                <TailSpin
                  height="50"
                  width="50"
                  color="#000"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{ marginTop: 340 }}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <HeadingMobile clones={clones} setIsOpen={setIsOpen} />
                </MDBCol>
              </>
            )}
          </MDBCol>
          <DashboardFooterMobile />
        </div>
      </div>
    </>
  );
};

export default withRouter(ClonesMobile);
