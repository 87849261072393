import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import {
  FaClone,
  FaHeart,
  FaRegCompass,
  FaRegFolder,
  FaRegHeart,
  FaRegStar,
} from "react-icons/fa";
import { IoFilterSharp } from "react-icons/io5";
import { RiRedPacketLine, RiUploadCloud2Fill } from "react-icons/ri";
import { BiSolidVideos } from "react-icons/bi";
import { useSignUpModal } from "../Context/SignUpModal";
import Filter from "./Filter";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FiPlus } from "react-icons/fi";
import CreateProject from "./CreateProject";
import CloneModal from "./CloneModal";

const TopBar = () => {
  const [queryID, setQueryID] = useState();
  const location = useLocation();
  const {
    filterOpen,
    setFilterOpen,
    createProjectOpen,
    setCreateProjectOpen,
    setAssetModalOpen,
    setCloneModalOpen,
    favMode,
    setFavMode,
  } = useSignUpModal();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const packID = searchParams.get("packID");
    setQueryID(packID);
  }, [location]);

  const renderText = (route) => {
    switch (route) {
      case "/":
        return (
          <p
            style={{
              color: "#2f1878",

              fontFamily: "PPMedium",
              fontSize: 19,
              marginLeft: 40,
              marginTop: 29,
            }}
          >
            <FaRegCompass
              style={{
                marginRight: 10,
                fontSize: 23,
                opacity: 0.7,
                marginTop: -2.8,
              }}
            />
            Discover
          </p>
        );
      case "/packs":
        return (
          <p
            style={{
              color: "#2f1878",

              fontFamily: "PPMedium",
              fontSize: 19,
              marginLeft: 40,
              marginTop: 29,
            }}
          >
            <RiRedPacketLine
              style={{
                marginRight: 10,
                fontSize: 23,
                opacity: 0.7,
                marginTop: -2.8,
              }}
            />
            {queryID ? "Packs / Valentine's day" : "Packs"}
          </p>
        );
      case "/my-projects":
        return (
          <p
            style={{
              color: "#2f1878",
              fontFamily: "PPMedium",
              fontSize: 19,
              marginLeft: 40,
              marginTop: 29,
            }}
          >
            <FaRegFolder
              style={{
                marginRight: 10,
                fontSize: 23,
                opacity: 0.7,
                marginTop: -2.8,
              }}
            />
            My Projects
          </p>
        );
      case "/my-videos":
        return (
          <p
            style={{
              color: "#2f1878",
              fontFamily: "PPMedium",
              fontSize: 19,
              marginLeft: 40,
              marginTop: 29,
            }}
          >
            <BiSolidVideos
              style={{
                marginRight: 10,
                fontSize: 23,
                opacity: 0.7,
                marginTop: -2.8,
              }}
            />
            My Videos
          </p>
        );
      case "/my-assets":
        return (
          <p
            style={{
              color: "#2f1878",
              fontFamily: "PPMedium",
              fontSize: 19,
              marginLeft: 40,
              marginTop: 29,
            }}
          >
            <RiUploadCloud2Fill
              style={{
                marginRight: 10,
                fontSize: 23,
                opacity: 0.7,
                marginTop: -2.8,
              }}
            />
            My Assets
          </p>
        );
      case "/my-clones":
        return (
          <p
            style={{
              color: "#2f1878",
              fontFamily: "PPMedium",
              fontSize: 19,
              marginLeft: 40,
              marginTop: 29,
            }}
          >
            <FaClone
              style={{
                marginRight: 10,
                fontSize: 23,
                opacity: 0.7,
                marginTop: -2.8,
              }}
            />
            My Clones
          </p>
        );
    }
  };

  const renderButtons = (path) => {
    switch (path) {
      case "/":
        return (
          <>
            <div
              style={{
                width: 140,
                height: 40,
                border: "0px solid black",
                marginRight: 10,
                marginTop: 20,
                cursor: "pointer",
                backgroundColor: favMode ? "#2f1878" : "white",
                paddingTop: 7,
                boxShadow:
                  "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                borderRadius: 4,
              }}
              onClick={() => setFavMode(!favMode)}
              className="d-flex justify-content-center"
            >
              <p
                style={{
                  color: favMode ? "white" : "#2f1878",
                  fontFamily: "PPMedium",
                  fontSize: 17,
                }}
              >
                {favMode ? (
                  <FaHeart
                    style={{
                      marginRight: 10,
                      fontSize: 20,
                      opacity: 1,
                      marginTop: -2.8,
                      color: "white",
                    }}
                  />
                ) : (
                  <FaRegHeart
                    style={{
                      marginRight: 10,
                      fontSize: 20,
                      opacity: 0.7,
                      marginTop: -2.8,
                    }}
                  />
                )}
                Favorites
              </p>
            </div>
            <div
              style={{
                width: 120,
                height: 40,
                border: "0px solid black",
                marginRight: 60,
                marginTop: 20,
                cursor: "pointer",
                paddingTop: 7,
                boxShadow:
                  "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                borderRadius: 4,
              }}
              className="d-flex justify-content-center"
              onClick={() => setFilterOpen(true)}
            >
              <p
                style={{
                  color: "#2f1878",
                  fontFamily: "PPMedium",
                  fontSize: 17,
                }}
              >
                <IoFilterSharp
                  style={{
                    marginRight: 10,
                    fontSize: 23,
                    opacity: 0.7,
                    marginTop: -2.8,
                  }}
                />
                Filters
              </p>
            </div>
          </>
        );
      case "/my-projects":
        return (
          <>
            <div
              style={{
                width: 180,
                height: 40,
                border: "0px solid black",
                marginRight: 60,
                marginTop: 20,
                cursor: "pointer",
                paddingTop: 7,
                boxShadow:
                  "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                borderRadius: 4,
              }}
              className="d-flex justify-content-center"
              onClick={() => setCreateProjectOpen(true)}
            >
              <p
                style={{
                  color: "#2f1878",
                  fontFamily: "PPMedium",
                  fontSize: 17,
                }}
              >
                <FiPlus
                  style={{
                    marginRight: 10,
                    fontSize: 23,
                    opacity: 0.7,
                    marginTop: 0,
                  }}
                />
                Create project
              </p>
            </div>
          </>
        );
      case "/my-assets":
        return (
          <>
            <div
              style={{
                width: 180,
                height: 40,
                border: "0px solid black",
                marginRight: 60,
                marginTop: 20,
                cursor: "pointer",
                paddingTop: 7,
                boxShadow:
                  "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                borderRadius: 4,
              }}
              className="d-flex justify-content-center"
              onClick={() => setAssetModalOpen(true)}
            >
              <p
                style={{
                  color: "#2f1878",
                  fontFamily: "PPMedium",
                  fontSize: 17,
                }}
              >
                <FiPlus
                  style={{
                    marginRight: 10,
                    fontSize: 23,
                    opacity: 0.7,
                    marginTop: 0,
                  }}
                />
                Create asset
              </p>
            </div>
          </>
        );

      case "/my-clones":
        return (
          <>
            <div
              style={{
                width: 180,
                height: 40,
                border: "0px solid black",
                marginRight: 60,
                marginTop: 20,
                cursor: "pointer",
                paddingTop: 7,
                boxShadow:
                  "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
                borderRadius: 4,
              }}
              className="d-flex justify-content-center"
              onClick={() => setCloneModalOpen(true)}
            >
              <p
                style={{
                  color: "#2f1878",
                  fontFamily: "PPMedium",
                  fontSize: 17,
                }}
              >
                <FiPlus
                  style={{
                    marginRight: 10,
                    fontSize: 23,
                    opacity: 0.7,
                    marginTop: 0,
                  }}
                />
                Create clone
              </p>
            </div>
          </>
        );
    }
  };
  return (
    <>
      <CreateProject
        createProjectOpen={createProjectOpen}
        setCreateProjectOpen={setCreateProjectOpen}
      />

      <div
        style={{
          width: "83vw",
          height: 80,
          backgroundColor: "white",
          marginTop: 76,
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          marginLeft: 1,
        }}
        className="d-flex justify-content-between"
      >
        {renderText(window.location.pathname)}
        <div style={{ display: "flex" }}>
          {renderButtons(window.location.pathname)}
        </div>
      </div>
    </>
  );
};

export default withRouter(TopBar);
