import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { NavLink, withRouter } from "react-router-dom";
import { firebase } from "../../../firebase/config";

const CheckoutMobile = ({}) => {
  return <></>;
};

export default withRouter(CheckoutMobile);
