import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";
import { RiCloseLine } from "react-icons/ri";
import { TailSpin } from "react-loader-spinner";
import { FaArrowLeft, FaShippingFast } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { MdOutlinePhonelink } from "react-icons/md";
import { useAuthKit } from "@integrationos/authkit";
import { IoIosCheckmarkCircle } from "react-icons/io";

const Step4Digital = ({
  uid,
  setSection,
  loginEmail,
  setLoginEmail,
  loginPassword,
  setLoginPassword,
  createCampaign,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <MDBCol size="12">
      <div>
        {/* <FaArrowLeft
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 0,
            marginBottom: 0,
            color: "grey",
            cursor: "pointer",
          }}
          onClick={() => setSection("Step3Digital")}
        />

        <p
          style={{
            fontFamily: "SSRegular",
            fontSize: 13,
            marginTop: 5,
            marginBottom: 0,
            color: "grey",
          }}
        >
          Step 4 of 4
        </p> */}
      </div>
      <MDBRow>
        <MDBCol className="d-flex justify-content-center" size="12">
          <IoIosCheckmarkCircle
            style={{
              marginTop: 20,
              fontSize: 40,
            }}
            color="blue"
          />
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <p style={{ fontFamily: "SSMedium", fontSize: 23, marginTop: 10 }}>
            You're ready to go!
          </p>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" size="12">
          <p
            style={{
              fontFamily: "SSRegular",
              fontSize: 17,
              textAlign: "center",
              width: 350,
            }}
          >
            You're now ready to start making videos. You can close this window
            and return back to Telegram to continue to the next steps.
          </p>
        </MDBCol>
      </MDBRow>
    </MDBCol>
  );
};

export default withRouter(Step4Digital);
