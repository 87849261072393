import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import DashboardNavBar from "../Layout/DashboardNavBar";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import {
  FaClone,
  FaRegCompass,
  FaRegFolder,
  FaRegStar,
  FaTiktok,
  FaUpload,
  FaYoutube,
} from "react-icons/fa";
import { RiRedPacketLine, RiUploadCloud2Fill } from "react-icons/ri";
import { BiSolidVideos } from "react-icons/bi";
import { AiOutlineCloudUpload } from "react-icons/ai";

const SideNav = () => {
  return (
    <div
      style={{
        width: "18%",
        backgroundColor: "white",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
      }}
    >
      <MDBRow style={{ marginTop: 100, marginLeft: 12 }}>
        <MDBCol size="12">
          <p
            style={{
              fontSize: 15,
              color: "#2f1878",
              fontFamily: "PPBold",
              opacity: 0.4,
            }}
          >
            VIDEO ADS
          </p>
        </MDBCol>
        <MDBCol size="12">
          <NavLink exact to="/">
            <div
              style={{
                height: 45,
                width: "90%",
                backgroundColor:
                  window.location.pathname === "/" ? "#eee9ff" : "transparent",
                borderRadius: 6,
                marginTop: 0,
                paddingTop: 11,
                paddingLeft: 17,
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  color: "#2f1878",
                  fontFamily: "PPMedium",
                  fontSize: 15,
                }}
              >
                <FaRegCompass
                  style={{
                    marginRight: 10,
                    fontSize: 22,
                    opacity: 0.7,
                    marginTop: -1.6,
                  }}
                />
                Discover
              </p>
            </div>
          </NavLink>
        </MDBCol>
        {/* <MDBCol size="12">
          <NavLink exact to="/packs">
            <div
              style={{
                height: 45,
                width: "90%",
                backgroundColor:
                  window.location.pathname === "/packs"
                    ? "#eee9ff"
                    : "transparent",
                borderRadius: 6,
                marginTop: 10,
                paddingTop: 11,
                paddingLeft: 17,
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  color: "#2f1878",
                  fontFamily: "PPMedium",
                  fontSize: 15,
                }}
              >
                <RiRedPacketLine
                  style={{
                    marginRight: 10,
                    fontSize: 22,
                    opacity: 0.7,
                    marginTop: -1.9,
                  }}
                />
                Packs
              </p>
            </div>
          </NavLink>
        </MDBCol> */}
        <MDBCol size="12">
          <NavLink exact to="/my-projects">
            <div
              style={{
                height: 45,
                width: "90%",
                backgroundColor:
                  window.location.pathname === "/my-projects"
                    ? "#eee9ff"
                    : "transparent",
                borderRadius: 6,
                marginTop: 10,
                paddingTop: 11,
                paddingLeft: 17,
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  color: "#2f1878",
                  fontFamily: "PPMedium",
                  fontSize: 15,
                }}
              >
                <FaRegFolder
                  style={{
                    marginRight: 10,
                    fontSize: 22,
                    opacity: 0.7,
                    marginTop: -1.6,
                  }}
                />
                Projects
              </p>
            </div>
          </NavLink>
        </MDBCol>
        <MDBCol size="12">
          <NavLink exact to="/my-videos">
            <div
              style={{
                height: 45,
                width: "90%",
                // backgroundColor: "rgba(71, 71, 67, 0.1)",
                borderRadius: 6,
                marginTop: 10,
                paddingTop: 11,
                paddingLeft: 17,
                cursor: "pointer",
                backgroundColor:
                  window.location.pathname === "/my-videos"
                    ? "#eee9ff"
                    : "transparent",
              }}
            >
              <p
                style={{
                  color: "#2f1878",
                  fontFamily: "PPMedium",
                  fontSize: 15,
                }}
              >
                <BiSolidVideos
                  style={{
                    marginRight: 10,
                    fontSize: 22,
                    opacity: 0.7,
                    marginTop: -1.6,
                  }}
                />
                Videos
              </p>
            </div>
          </NavLink>
        </MDBCol>
        <MDBCol size="12">
          <NavLink exact to="/my-assets">
            <div
              style={{
                height: 45,
                width: "90%",
                // backgroundColor: "rgba(71, 71, 67, 0.1)",
                borderRadius: 6,
                marginTop: 10,
                paddingTop: 11,
                paddingLeft: 17,
                cursor: "pointer",
                backgroundColor:
                  window.location.pathname === "/my-assets"
                    ? "#eee9ff"
                    : "transparent",
              }}
            >
              <p
                style={{
                  color: "#2f1878",
                  fontFamily: "PPMedium",
                  fontSize: 15,
                }}
              >
                <RiUploadCloud2Fill
                  style={{
                    marginRight: 10,
                    fontSize: 22,
                    opacity: 0.7,
                    marginTop: -1.6,
                  }}
                />
                Assets
              </p>
            </div>
          </NavLink>
        </MDBCol>
        <MDBCol size="12">
          <NavLink exact to="/my-clones">
            <div
              style={{
                height: 45,
                width: "90%",
                // backgroundColor: "rgba(71, 71, 67, 0.1)",
                borderRadius: 6,
                marginTop: 10,
                paddingTop: 11,
                paddingLeft: 17,
                cursor: "pointer",
                backgroundColor:
                  window.location.pathname === "/my-clones"
                    ? "#eee9ff"
                    : "transparent",
              }}
            >
              <p
                style={{
                  color: "#2f1878",
                  fontFamily: "PPMedium",
                  fontSize: 15,
                }}
              >
                <FaClone
                  style={{
                    marginRight: 10,
                    fontSize: 22,
                    opacity: 0.7,
                    marginTop: -1.6,
                  }}
                />
                Clones
              </p>
            </div>
          </NavLink>
        </MDBCol>
        {/* <MDBCol size="12">
          <p
            style={{
              fontSize: 15,
              color: "#2f1878",
              fontFamily: "PPBold",
              opacity: 0.4,
              marginTop: 30,
            }}
          >
            ORGANIC CONTENT
          </p>
        </MDBCol>
        <MDBCol size="12">
          <div
            style={{
              height: 45,
              width: "90%",
              backgroundColor:
                window.location.pathname === "/long-form"
                  ? "#eee9ff"
                  : "transparent",
              borderRadius: 6,
              marginTop: 0,
              paddingTop: 11,
              paddingLeft: 17,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#2f1878",
                fontFamily: "PPMedium",
                fontSize: 15,
              }}
            >
              <FaYoutube
                style={{
                  marginRight: 10,
                  fontSize: 22,
                  opacity: 0.7,
                  marginTop: -1.6,
                }}
              />
              Long form
            </p>
          </div>
        </MDBCol>
        <MDBCol size="12">
          <div
            style={{
              height: 45,
              width: 210,
              // backgroundColor: "rgba(71, 71, 67, 0.1)",
              borderRadius: 6,
              marginTop: 10,
              paddingTop: 11,
              paddingLeft: 17,
              cursor: "pointer",
              backgroundColor:
                window.location.pathname === "/short-form"
                  ? "#eee9ff"
                  : "transparent",
            }}
          >
            <p
              style={{
                color: "#2f1878",
                fontFamily: "PPMedium",
                fontSize: 15,
              }}
            >
              <FaTiktok
                style={{
                  marginRight: 10,
                  fontSize: 22,
                  opacity: 0.7,
                  marginTop: -1.6,
                }}
              />
              Short form
            </p>
          </div>
        </MDBCol> */}
      </MDBRow>
    </div>
  );
};

export default withRouter(SideNav);
