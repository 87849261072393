import React, { createContext, useState, useContext, useEffect } from "react";

// Create a context
const SignUpContext = createContext();

export const useSignUpModal = () => useContext(SignUpContext);

export const SignUpProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [creditsOpen, setCreditsOpen] = useState(false);
  const [invitesOpen, setInvitesOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [notiOpen, setNotiOpen] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);
  const [addProjectOpen, setAddProjectOpen] = useState(false);
  const [createProjectOpen, setCreateProjectOpen] = useState(false);
  const [cloneModalOpen, setCloneModalOpen] = useState(false);
  const [assetModalOpen, setAssetModalOpen] = useState(false);
  const [favMode, setFavMode] = useState(false);
  const [tab, setTab] = useState("template");

  return (
    <SignUpContext.Provider
      value={{
        isOpen,
        setIsOpen,
        creditsOpen,
        setCreditsOpen,
        invitesOpen,
        setInvitesOpen,
        filterOpen,
        setFilterOpen,
        notiOpen,
        setNotiOpen,
        accountOpen,
        setAccountOpen,
        addProjectOpen,
        setAddProjectOpen,
        createProjectOpen,
        setCreateProjectOpen,
        cloneModalOpen,
        setCloneModalOpen,
        assetModalOpen,
        setAssetModalOpen,
        favMode,
        setFavMode,
        tab,
        setTab,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};
