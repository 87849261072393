import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt, BiParty, BiRightArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { firebase } from "../../../firebase/config";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { useAuth } from "../Context/AuthContext";
import MuxPlayer from "@mux/mux-player-react";

const VideoModal = ({
  setVideoOpen,
  videoOpen,
  videoID,
  projectID,
  playbackID,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { workspaceID } = useAuth();

  return (
    <>
      <SkyLightStateless
        hideOnOverlayClicked={true}
        onOverlayClicked={async () => {
          setVideoOpen(false);
        }}
        onCloseClicked={async () => {
          setVideoOpen(false);
        }}
        isVisible={videoOpen}
        overlayStyles={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9000,
          backgroundColor: "#181616",
          opacity: 0.6,
          zIndex: 12000,
        }}
        dialogStyles={{
          zIndex: 12000,
          position: "fixed",
          width: "100vw",
          minHeight: "60px",
          marginTop: "0%",
          top: "0%",
          height: "100vh",
          boxShadow: "none",
          left: "0%",
          right: "0%",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "transparent",
          border: "none",
        }}
        closeButtonStyle={{
          color: "black",
          right: 650,
          position: "absolute",
          marginTop: 90,
          zIndex: 9600,
          fontSize: 50,
          height: 60,
          width: 60,
          display: "none",
        }}
      >
        <OutsideClickHandler
          onOutsideClick={async () => {
            setVideoOpen(false);
          }}
        >
          <div
            style={{
              width: 420,
              height: 746,
              backgroundColor: "white",
              borderRadius: 8,
              zIndex: 12000,
              paddingLeft: 0,
              top: "10%",
              position: "absolute",
              left: "0%",
              right: "0%",
              marginLeft: "auto",
              marginRight: "auto",
              border: "1px solid #505050",
            }}
            className="backy60"
          >
            <MuxPlayer
              streamType="on-demand"
              accent-color="#f8284e"
              playbackId={playbackID}
              paused={videoOpen ? null : true}
              style={{
                display: "inline-block",

                width: 420,
                height: 746,
                marginLeft: 0,
                borderRadius: 20,
                objectFit: "cover",
                verticalAlign: "top",
                backgroundColor: "transparent", // Added this line
                aspectRatio: 9 / 16,
              }}
            />
          </div>
        </OutsideClickHandler>
      </SkyLightStateless>
    </>
  );
};

export default withRouter(VideoModal);
