import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import MuxPlayer from "@mux/mux-player-react";
import mixpanel from "mixpanel-browser";

const CarouselReaction = ({ scrollLeft, scrollRight, carouselRef }) => {
  const [isHovered, setIsHovered] = useState(false); // New state variable
  const [videoStates, setVideoStates] = useState({}); // New state to hold play/pause states for each video
  const [muteStates, setMuteStates] = useState({}); // New state to hold mute states for each video
  const videoRefs = {}; // Object to hold refs for each video

  const togglePlayPause = (index) => {
    const video = videoRefs[index].current;
    if (videoStates[index]) {
      video.pause();
    } else {
      video.play();
    }
    setVideoStates({
      ...videoStates,
      [index]: !videoStates[index],
    });
  };

  const toggleMute = (index) => {
    const video = videoRefs[index].current;
    video.muted = !muteStates[index];
    setMuteStates({
      ...muteStates,
      [index]: !muteStates[index],
    });
  };

  const toggleFullscreen = (index) => {
    const video = videoRefs[index].current;
    if (video.requestFullscreen) {
      video.requestFullscreen();
    }
  };

  const colors = ["rgb(233, 192, 233)", "rgb(120, 0, 22)", "rgb(210, 232, 35)"];

  const muxID = [
    {
      reaction: "ICe01r4zmFJUz6IA58rszWcqOsNNEBHwkLWXjavTVGQI",
    },
  ];

  const items = [
    "9wYXXql3100SGRiHLMChf8WAXC81QXh4e2dEy3c3B8xc",
    "k5snr2i7JFtVeUx1kNlUMQyaxmplX02nA00HpZKagyi1Y",
    "02SX2MTJydiRgi01qiqMCAK6M6ngQ3Zg02Wg6TK3KT3027o",
    "NfIs18c2F5NPWsZPMumkGTZqOutMT02x88GnjLpzhveE",
  ];
  return (
    <div
      ref={carouselRef} // Use the ref passed from the parent component
      style={{
        marginBottom: 30,
        width: 870,
        overflowX: "scroll",
        paddingBottom: "20px",
        paddingRight: 50,
      }}
      className="carousel-container"
      onMouseEnter={() => setIsHovered(true)} // Set isHovered to true when mouse enters
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="carousel-content">
        {items.map((item, index) => {
          return (
            <div
              key={index}
              index={index}
              style={{
                position: "relative",
                display: "inline-block",
                height: 409,
                width: 230,
                marginLeft: 6,
                marginRight: 6,
                borderRadius: 5,
                overflow: "hidden",
                border: "3px solid black",
              }}
              onClick={() =>
                mixpanel.track("Played example video on landing page")
              }
            >
              <MuxPlayer
                streamType="on-demand"
                accent-color="#f8284e"
                playbackId={item}
                style={{
                  display: "inline-block",
                  height: 409,
                  width: 230,
                  marginLeft: 0,
                  borderRadius: 20,
                  marginRight: 9,
                  objectFit: "cover",
                  verticalAlign: "top",
                  backgroundColor: "transparent", // Added this line
                  aspectRatio: 9 / 16,
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CarouselReaction;
