import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import TemplateSection from "./TemplateSection";
import ActorSection from "./ActorSection";
import { useSignUpModal } from "../Context/SignUpModal";
import TextSection from "./TextSection";
import ElementSection from "./ElementSection";
import AssetSection from "./AssetSection";
import { firebase } from "../../../firebase/config";
import { createClient } from "pexels";
import axios from "axios";
const client = createClient(
  "coH5syRJPC9wUWVZJes69rmt24k46YVsey0HtMbJtLFThle7iYAsZNex"
);

// free pik api key FPSX716cecb287d44d0cb89f7934c0f513f4
// import ElementSection from "./ElementSection";
// import AssetSection from "./AssetSection";
// pexels api key -  coH5syRJPC9wUWVZJes69rmt24k46YVsey0HtMbJtLFThle7iYAsZNex
const SideNav = ({
  location,
  addItem,
  updateActorFields,
  setVoiceID,
  videoItems,
  setVideoItems,
  lastTime,
  updateProjectThumbnail,
}) => {
  const { tab, setTab } = useSignUpModal();
  const [publicActors, setPublicActors] = useState([]);
  const [initImages, setInitImages] = useState([]);
  const [initVideos, setInitVideos] = useState([]);
  const [initIcons, setInitIcons] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [music, setMusic] = useState([]);

  useEffect(() => {
    const query = "beach";
    client.photos.search({ query, per_page: 30 }).then((photos) => {
      setInitImages(photos);
    });
    client.videos.search({ query, per_page: 30 }).then((videos) => {
      setInitVideos(videos);
      console.log("Init videos are", initVideos);
    });
  }, []);

  useEffect(() => {
    var getIcons = firebase.functions().httpsCallable("getIcons");
    getIcons({
      query: "Waves",
      page: 1,
    }).then(async (result) => {
      const icons = result.data.icons;
      setInitIcons(icons.slice(0, 15));
    });
  }, []);

  useEffect(() => {
    firebase
      .firestore()
      .collection("music")
      .get()
      .then((querySnapshot) => {
        let initArray = [];
        querySnapshot.forEach((doc) => {
          initArray.push(doc.data());
        });
        setMusic(initArray);
        console.log("MUsic is", music);
      });
  }, []);

  useEffect(() => {
    firebase
      .firestore()
      .collection("ugc")
      .where("private", "==", false)
      .get()
      .then((querySnapshot) => {
        let initArray = [];
        querySnapshot.forEach((doc) => {
          initArray.push(doc.data());
        });
        setPublicActors(initArray);
      });
  }, []);

  useEffect(() => {
    firebase
      .firestore()
      .collection("templates")
      .where("private", "==", false)
      .get()
      .then((querySnapshot) => {
        let initArray = [];
        querySnapshot.forEach((doc) => {
          initArray.push(doc.data());
        });
        setTemplates(initArray);
      });
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const newtab = searchParams.get("tab") || "template";
    setTab(newtab);
  }, [window.location]);

  let section;

  switch (tab) {
    case "template":
      section = <TemplateSection templates={templates} />;
      break;
    case "actor":
      section = (
        <ActorSection
          updateActorFields={updateActorFields}
          addItem={addItem}
          publicActors={publicActors}
          setVoiceID={setVoiceID}
          videoItems={videoItems}
          updateProjectThumbnail={updateProjectThumbnail}
        />
      );
      break;
    case "text":
      section = (
        <TextSection
          videoItems={videoItems}
          setVideoItems={setVideoItems}
          addItem={addItem}
          lastTime={lastTime}
        />
      );
      break;
    case "element":
      section = (
        <ElementSection
          music={music}
          initVideos={initVideos}
          initImages={initImages}
          initIcons={initIcons}
          addItem={addItem}
          lastTime={lastTime}
        />
      );
      break;
    case "asset":
      section = <AssetSection lastTime={lastTime} addItem={addItem} />;
      break;
    default:
      section = <TemplateSection />;
  }

  return (
    <div
      style={{
        width: "23%",
        backgroundColor: "rgb(22, 23, 26)",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        overflowY: "hidden",
        overflowX: "hidden",
      }}
    >
      {section}
    </div>
  );
};

export default withRouter(SideNav);
