import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import {
  FaCreditCard,
  FaFacebookSquare,
  FaLink,
  FaPlus,
  FaRegStar,
} from "react-icons/fa";
import { useSignUpModal } from "../Context/SignUpModal";
import { useAuth } from "../Context/AuthContext";
import { MdEdit } from "react-icons/md";
import {
  IoIosArrowDown,
  IoIosInformationCircleOutline,
  IoMdCloseCircle,
} from "react-icons/io";
import MuxPlayer from "@mux/mux-player-react";
import { v4 as uuidv4 } from "uuid";
import { IoInformationCircleOutline } from "react-icons/io5";
import Filter from "./Filter";
import { BsPlusLg, BsThreeDots } from "react-icons/bs";
import BriefModal from "./BriefModal";
import { CircularProgressbar } from "react-circular-progressbar";
import { TailSpin } from "react-loader-spinner";
import Switch from "react-switch";
import ProjectModal from "./ProjectModal";
import PriorityModal from "./PriorityModal";
import VerifyingModal from "./VerifyingModal";

const Categories = () => {
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [active, setActive] = useState(true);
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [priorityModalOpen, setPriorityModalOpen] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [currentCampaign, setCurrentCampaign] = useState("");
  const [verifyingModalOpen, setVerifyingModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const {
    favorites,
    workspaceID,
    fbConnected,
    verifying,
    fbApproved,
    currentPlan,
    activeCard,
    paymentMethodId,
  } = useAuth();

  const history = useHistory();

  const project = { status: "active" };

  useEffect(() => {
    if (verifying || fbApproved === false) {
      setVerifyingModalOpen(true);
    }
  }, [verifying, fbApproved]);

  useEffect(() => {
    if (firebase.auth().currentUser) {
      const uid = firebase.auth().currentUser.uid;
      firebase
        .firestore()
        .collection("campaigns")
        .where("uid", "==", uid)
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            let initCampaigns = [];
            querySnapshot.forEach((doc) => {
              initCampaigns.push(doc.data());
            });
            console.log("campaigns are", initCampaigns);
            setCampaigns(initCampaigns);
          } else {
            setCampaigns([]);
          }
        });
    }
  }, [firebase.auth().currentUser]);

  function truncateString(str) {
    if (str.length <= 24) {
      return str;
    }
    return str.slice(0, 24) + "...";
  }

  if (fbConnected === null) {
    return null;
  }

  return (
    <>
      <BriefModal
        isOpen={isOpen}
        currentCampaign={currentCampaign}
        setIsOpen={setIsOpen}
      />
      <VerifyingModal
        verifyingModalOpen={verifyingModalOpen}
        setVerifyingModalOpen={setVerifyingModalOpen}
      />
      <MDBRow
        style={{
          width: "100%",
          paddingLeft: 30,
          paddingBottom: 20,
        }}
      >
        {fbConnected === false ? (
          <MDBCol className="d-flex justify-content-center" size="12">
            <div
              style={{
                height: 69,
                border: "1px solid rgb(227, 11, 92)",
                fontFamily: "SSBold",
                fontSize: 15,
                paddingTop: 8,
                backgroundColor: "#F8F8F8",
                color: "rgb(227, 11, 92)",
                borderRadius: 4,
                minWidth: 160,
                cursor: "pointer",
                width: 1400,
                marginTop: 20,
                borderRadius: 14,
              }}
              className="d-flex justify-content-between"
            >
              <div style={{ marginTop: 15, marginLeft: 20 }}>
                <p>
                  <img
                    src={require("./images.png")}
                    style={{
                      fontSize: 22,
                      marginRight: 10,
                      marginTop: -2,
                      height: 20,
                    }}
                  />
                  Connect your Meta Ad account to start receiving videos.
                </p>
              </div>
              <div>
                <NavLink
                  exact
                  to="/settings"
                  style={{
                    height: 48,
                    background: "#ed1165",
                    borderRadius: 11,
                    fontFamily: "SSMedium",
                    paddingTop: 12,
                    fontSize: 17,
                    cursor: "pointer",
                    color: "white",
                    paddingRight: 25,
                    paddingLeft: 25,
                    marginRight: 20,
                    marginTop: 2,
                  }}
                  className="d-flex justify-content-center"
                >
                  <p>Connect Ad Account</p>
                </NavLink>
              </div>
            </div>
          </MDBCol>
        ) : !paymentMethodId ? (
          <MDBCol className="d-flex justify-content-center" size="12">
            <div
              style={{
                height: 69,
                border: "1px solid rgb(143, 143, 243)",
                fontFamily: "SSBold",
                fontSize: 15,
                paddingTop: 8,
                backgroundColor: "#fafaff",
                color: "rgb(227, 11, 92)",
                borderRadius: 4,
                minWidth: 160,
                cursor: "pointer",
                width: 1400,
                marginTop: 20,
                borderRadius: 14,
              }}
              className="d-flex justify-content-between"
            >
              <div style={{ marginTop: 15, marginLeft: 20 }}>
                <p>
                  <FaCreditCard
                    style={{
                      fontSize: 22,
                      marginRight: 10,
                      marginTop: -2,
                      color: "#ed1165",
                    }}
                  />
                  Add your billing details to start receiving videos.
                </p>
              </div>
              <div>
                <NavLink
                  exact
                  to="/settings"
                  style={{
                    height: 48,
                    background: "#ed1165",
                    borderRadius: 11,
                    fontFamily: "SSMedium",
                    paddingTop: 12,
                    fontSize: 17,
                    cursor: "pointer",
                    color: "white",
                    paddingRight: 25,
                    paddingLeft: 25,
                    marginRight: 20,
                    marginTop: 2,
                  }}
                  className="d-flex justify-content-center"
                >
                  <p>Add Billing Details</p>
                </NavLink>
              </div>
            </div>
          </MDBCol>
        ) : null}

        <MDBCol style={{ marginTop: 20 }} size="12">
          <MDBRow>
            <MDBCol size="3">
              <div
                style={{
                  height: 222,
                  width: 360,
                  border: "2px dashed #E30B5C",
                  borderRadius: 12,
                  backgroundColor: "#F8F8F8",
                  marginTop: 20,
                  color: "#E30B5C",
                  cursor: "pointer",
                }}
                onClick={() => setIsOpen(true)}
              >
                <MDBRow>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <BsPlusLg style={{ marginTop: 63, fontSize: 23 }} />
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        marginTop: 6,
                        fontSize: 18,
                        textAlign: "center",
                        fontFamily: "SSBold",
                      }}
                    >
                      Create your campaign
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        marginTop: -9,
                        fontSize: 16,
                        textAlign: "center",
                        fontFamily: "SSRegular",
                      }}
                    >
                      Start receiving videos
                    </p>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCol>
            {campaigns.map((campaign) => (
              <MDBCol size="3">
                <div
                  style={{
                    height: 222,
                    width: 360,
                    backgroundColor: "white",
                    borderRadius: 9,
                    marginTop: 20,
                    color: "rgb(227, 11, 92)",
                    border: "2px solid rgb(234, 236, 240)",
                    paddingLeft: 16,
                  }}
                  onClick={() => setCurrentCampaign(campaign.campaignID)}
                >
                  <MDBRow>
                    <MDBCol size="12">
                      <div
                        style={{ marginTop: 10 }}
                        className="d-flex justify-content-between"
                      >
                        <BsThreeDots
                          style={{
                            fontSize: 25,
                            color: "black",
                            opacity: 0.8,
                            cursor: "pointer",
                          }}
                          onClick={() => setProjectModalOpen(true)}
                        />

                        {currentCampaign === campaign.campaignID && (
                          <ProjectModal
                            campaignID={campaign.campaignID}
                            setCurrentCampaign={setCurrentCampaign}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            projectModalOpen={projectModalOpen}
                            setProjectModalOpen={setProjectModalOpen}
                          />
                        )}

                        <div style={{ marginRight: 15 }}>
                          <Switch
                            onChange={async (checked) => {
                              if (checked && fbApproved && paymentMethodId) {
                                console.log(checked);
                                await firebase
                                  .firestore()
                                  .collection("campaigns")
                                  .doc(campaign.campaignID)
                                  .update({ status: "ACTIVE" });
                              } else {
                                await firebase
                                  .firestore()
                                  .collection("campaigns")
                                  .doc(campaign.campaignID)
                                  .update({ status: "INACTIVE" });
                              }
                            }}
                            checked={campaign.status === "ACTIVE"}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                            onColor="#32de84"
                          />
                        </div>
                      </div>
                      <div>
                        <p
                          style={{
                            fontFamily: "SSMedium",
                            fontSize: 23,
                            color: "black",
                            marginTop: 20,
                          }}
                        >
                          {truncateString(campaign.campaignName)}
                        </p>
                        <div style={{ marginTop: -10 }}>
                          {currentCampaign === campaign.campaignID && (
                            <PriorityModal
                              priorityModalOpen={priorityModalOpen}
                              setPriorityModalOpen={setPriorityModalOpen}
                              campaignID={campaign.campaignID}
                            />
                          )}
                          <div
                            style={{
                              height: 30,
                              width: 120,
                              backgroundColor:
                                campaign.priority === "HIGH"
                                  ? "#f8ecf0"
                                  : campaign.priority === "LOW"
                                  ? "#F0F0F0"
                                  : "rgb(250, 250, 255)",
                              borderRadius: 2,
                              marginTop: 0,
                              textAlign: "center",
                              fontFamily: "SSMedium",
                              paddingTop: 5,
                              fontSize: 12,
                              color:
                                campaign.priority === "HIGH"
                                  ? "red"
                                  : campaign.priority === "LOW"
                                  ? "#696969"
                                  : "#ed1165",
                              display: "inline-block",
                              cursor: "pointer",
                            }}
                            onClick={() => setPriorityModalOpen(true)}
                          >
                            {campaign.priority} PRIORITY
                          </div>
                          <div
                            style={{
                              height: 30,
                              width: 70,
                              backgroundColor:
                                campaign.status === "ACTIVE"
                                  ? "#f0f7f4"
                                  : "rgb(250, 250, 255)",
                              borderRadius: 2,
                              marginTop: 0,
                              textAlign: "center",
                              fontFamily: "SSMedium",
                              paddingTop: 5,
                              fontSize: 12,
                              color:
                                campaign.status === "ACTIVE"
                                  ? "green"
                                  : "#ed1165",
                              display: "inline-block",
                              marginLeft: 10,
                            }}
                          >
                            {campaign.status}
                          </div>
                        </div>
                        <div
                          style={{
                            position: "relative",
                            height: "10px",
                            width: "70%",
                            backgroundColor: "#e0e0e0",
                            borderRadius: "5px",
                            marginTop: 30,
                          }}
                        >
                          <div
                            style={{
                              height: "100%",
                              width: `${
                                campaign.quota
                                  ? (
                                      (campaign.videos / campaign.quota) *
                                      100
                                    ).toFixed(0)
                                  : 0
                              }%`,
                              backgroundColor: "#ed1165",
                              borderRadius: "5px",
                            }}
                          ></div>
                          <span
                            style={{
                              position: "absolute",
                              right: "-60px",
                              top: "-5.3px",
                              fontSize: "14px",
                              color: "#000",
                              fontFamily: "SSMedium",
                            }}
                          >
                            {campaign.videos} /{" "}
                            {campaign.quota ? campaign.quota : 0}
                          </span>
                        </div>
                        <p
                          style={{
                            fontFamily: "SSMedium",
                            color: "black",
                            fontSize: 13,
                            textAlign: "center",
                            marginLeft: -110,
                            marginTop: 10,
                          }}
                        >
                          videos created this month
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(Categories);
