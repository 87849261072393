import React, { useState, useEffect, useRef } from "react";
import { FaPlay, FaPause, FaMusic } from "react-icons/fa";
import { Rnd } from "react-rnd";

const PlayerControl = ({
  duration,
  onTimelineClick,
  isPlaying,
  onPlayClick,
  videoItems,
  onItemsChange,
  setIsPlaying,
  currentTime,
  setSelectedIndex,
  selectedIndex,
}) => {
  const [progress, setProgress] = useState(0);
  const [timelineDuration, setTimelineDuration] = useState(0);
  const timelineRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [subtitleWidth, setSubtitleWidth] = useState(0);

  const SNAPPING_THRESHOLD = -1; // Adjust this value as needed

  useEffect(() => {
    const handleResize = () => {
      // Reset the component when the page is resized
      setProgress(0);
      setTimelineDuration(0);
      setIsDragging(false);
      setSelectedIndex(null);

      // Recalculate the timeline width
      const timelineWidth = timelineRef.current.clientWidth;
      setTimelineDuration(timelineWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTimelineClick = (event) => {
    setIsPlaying(false);
    videoItems.forEach((item) => {
      if (
        (item.type === "video" || item.type === "music") &&
        item.videoRef.current
      ) {
        item.videoRef.current.pause();
      }
    });
    if (!isDragging) {
      const timelineRect = timelineRef.current.getBoundingClientRect();
      const scrollLeft = timelineRef.current.scrollLeft;
      const clickPosition = event.clientX - timelineRect.left + scrollLeft;
      const newProgress = (clickPosition / timelineDuration) * 100;
      onTimelineClick(newProgress);
      
    }
  };

  const handleItemDragStop = (e, data, index) => {
    setIsPlaying(false);
    setSelectedIndex(index);
    const updatedItems = [...videoItems];
    const currentItem = updatedItems[index];
    const startTime = (data.x / timelineDuration) * duration;
    const endTime = startTime + (currentItem.endTime - currentItem.startTime);

    updatedItems[index] = {
      ...currentItem,
      startTime: startTime,
      endTime: endTime,
    };

    onItemsChange(updatedItems);
  };
  const handleItemResizeStop = (index, direction, ref, delta, position) => {
    if (videoItems.type !== "subtitle") {
      setSelectedIndex(index);
      const updatedItems = [...videoItems];
      const item = updatedItems[index];
      const initialDuration = item.endTime - item.startTime;

      // Calculate the new startTime and endTime based on the item's position
      const newStartTime = (position.x / timelineDuration) * duration;
      const newEndTime =
        ((position.x + ref.offsetWidth) / timelineDuration) * duration;

      // Adjust the startTime and endTime based on the resizing direction
      if (direction.includes("left") || direction === "left") {
        const oldStartTime = item.startTime;
        item.startTime = newStartTime;
        item.trimStart += newStartTime - oldStartTime;
        item.trimStart = Math.max(0, item.trimStart);
      } else if (direction.includes("right") || direction === "right") {
        const oldEndTime = item.endTime;
        item.endTime = newEndTime;
        item.trimEnd += newEndTime - oldEndTime; // Subtract the difference from trimEnd
        item.trimEnd = Math.min(initialDuration, item.trimEnd);
      }

      console.log("Item trim end is", item.trimEnd);
      console.log("Item end time is", item.endTime);

      // Update the item with the new positions and sizes
      onItemsChange(updatedItems);
    }
  };
  const getTimelineMarks = () => {
    const marks = [];
    const timelineWidth = timelineDuration;
    const interval = 5; // Interval in seconds
    const intervalWidth = (interval * timelineWidth) / duration;

    // Find the maximum end time among all video items
    const maxEndTime = videoItems.reduce(
      (max, item) => Math.max(max, item.endTime),
      duration
    );

    const totalMarks = Math.ceil(maxEndTime / interval);

    for (let i = 0; i <= totalMarks; i++) {
      const time = i * interval;
      const position = i * intervalWidth;
      marks.push(
        <div
          key={i}
          style={{
            position: "absolute",
            top: -55,
            left: position,
            width: 1,
            height: 10,
            backgroundColor: "black",
            fontFamily: "SSMedium",
            zIndex: 5000,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 10,
              left: -12,
              fontSize: 12,
              color: "black",
            }}
          >
            {time}s
          </div>
        </div>
      );
    }

    return marks;
  };
  useEffect(() => {
    const timelineWidth = timelineRef.current.clientWidth;
    setTimelineDuration(timelineWidth);
  }, []);

  useEffect(() => {
    if (duration > 0) {
      setProgress((currentTime / duration) * 100);
    }
  }, [currentTime, duration]);

  const getItemWidth = (startTime, endTime) => {
    const timelineWidth = timelineDuration;
    const itemDuration = endTime - startTime;
    const itemWidth = (itemDuration * timelineWidth) / duration;
    return itemWidth;
  };

  const renderVideoItem = (type) => {
    switch (type) {
      case "Asset":
    }
  };

  const sortedVideoItems = [...videoItems].sort((a, b) => {
    if (a.type === "actor" && b.type !== "actor") {
      return -1;
    }
    if (a.type !== "actor" && b.type === "actor") {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <div style={{ marginTop: "3.4%" }}>
        <div
          style={{
            height: 50,
            width: 50,
            backgroundColor: "blue",
            borderRadius: 100,
            cursor: "pointer",
          }}
          className="d-flex justify-content-center"
          onClick={() => onPlayClick(progress)}
        >
          {isPlaying ? (
            <FaPause
              style={{
                color: "white",
                marginTop: 14,
                fontSize: 20,
                marginLeft: 1,
              }}
            />
          ) : (
            <FaPlay
              style={{
                color: "white",
                marginTop: 14,
                fontSize: 20,
                marginLeft: 2.4,
              }}
            />
          )}
        </div>
        <p
          style={{
            fontFamily: "PPBold",
            fontSize: 12,
            marginTop: 10,
            marginLeft: -9,
            width: 100,
          }}
        >
          {`00:${currentTime
            .toFixed(0)
            .toString()
            .padStart(2, "0")}/00:${duration
            .toFixed(0)
            .toString()
            .padStart(2, "0")}`}
        </p>
      </div>
      <div
        ref={timelineRef}
        style={{
          marginTop: 15,
          width: "90%",
          overflowX: "scroll",
          marginLeft: 30,
          overflowY: "scroll",
          borderTop: "1px solid #D8D8D8",
          paddingTop: 50,
          position: "relative",
          overflowAnchor: "none",
        }}
        onClick={handleTimelineClick}
      >
        <div style={{ position: "relative", height: 20 }}>
          {getTimelineMarks()}
        </div>
        {videoItems.map((item, index) => (
          <>
            <Rnd
              key={index}
              bounds="parent"
              position={{
                x: (item.startTime / duration) * timelineDuration,
                y: 30 + index * 50,
              }}
              size={{
                width: getItemWidth(item.startTime, item.endTime),
                height: 40,
              }}
              maxWidth={getItemWidth(
                item.initialStartTime,
                item.initialEndTime
              )}
              onResizeStart={() => setIsPlaying(false)}
              onDragStart={() => setIsPlaying(false)}
              onResizeStop={(e, direction, ref, delta, position) => {
                return handleItemResizeStop(
                  index,
                  direction,
                  ref,
                  delta,
                  position
                );
              }}
              disableDragging={item.type === "subtitle" ? true : false}
              enableResizing={{
                top: false,
                right: item.type === "subtitle" ? false : true,
                bottom: false,
                left: item.type === "subtitle" ? false : true,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              dragAxis="x"
              style={{
                paddingBottom: 20,
                marginTop: 30 + index * 10,
                borderRadius: 8,
                overflow: "hidden",
              }}
              onDragStop={(e, data) => handleItemDragStop(e, data, index)}
            >
              <div
                style={{
                  height: 40,
                  width: "100%",
                  border:
                    selectedIndex === index
                      ? item.type !== "actor"
                        ? item.type === "music"
                          ? "2px solid #00BF8F"
                          : "2px solid rgb(255, 121, 26)"
                        : "2px solid blue"
                      : "none",
                  borderRadius: 10,
                  backgroundColor:
                    item.type !== "actor"
                      ? item.type === "music"
                        ? "rgb(232, 255, 245)"
                        : "rgb(255, 228, 201)"
                      : "#B2FFFF",
                  position: "relative",
                  display: "flex",
                  overflow: "hidden",
                  ...(item.type !== "actor" &&
                    item.type !== "music" &&
                    item.type !== "subtitle" && {
                      backgroundImage: `url(${item.thumbnail})`,
                      backgroundRepeat: "repeat-x", // Repeat the background image horizontally
                      backgroundSize: "30px 53px", // Size of the image. Adjust as needed.
                      backgroundPosition: "left center", // Position the background image
                    }),
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedIndex(index);
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: 4,
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "2px", // Adjust the width of the line as needed
                    height: "20px", // Adjust the height of the line as needed
                    backgroundColor:
                      item.type !== "actor"
                        ? item.type === "music"
                          ? "#00BF8F"
                          : "rgb(255, 121, 26)"
                        : "blue", // Adjust the color as needed
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    right: 4,
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "2px", // Adjust the width of the line as needed
                    height: "20px", // Adjust the height of the line as needed
                    backgroundColor:
                      item.type !== "actor"
                        ? item.type === "music"
                          ? "#00BF8F"
                          : "rgb(255, 121, 26)"
                        : "blue", // Adjust the color as needed
                  }}
                ></div>

                {item.type === "text" && (
                  <>
                    <p
                      style={{
                        marginTop: 9,
                        marginLeft: 10,
                        fontFamily: "SSMedium",
                        color: "rgb(255, 121, 26)",
                        fontSize: 14,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.textContent}
                    </p>
                  </>
                )}

                {item.type === "subtitle" && (
                  <>
                    <p
                      style={{
                        marginTop: 9,
                        marginLeft: 10,
                        fontFamily: "SSMedium",
                        color: "rgb(255, 121, 26)",
                        fontSize: 14,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Subtitle
                    </p>
                  </>
                )}

                {item.type === "music" && (
                  <>
                    <p
                      style={{
                        marginTop: 9,
                        marginLeft: 16,
                        fontFamily: "SSMedium",
                        color: "#00BF8F",
                        fontSize: 14,
                        whiteSpace: "nowrap",
                      }}
                    >
                      <FaMusic style={{ marginRight: 7, marginTop: -2 }} />
                      {item.name}
                    </p>
                  </>
                )}

                {item.type === "actor" && (
                  <>
                    <img
                      src={item.thumbnail}
                      style={{
                        height: 30,
                        width: 30,
                        borderRadius: 1000,
                        objectFit: "cover",
                        marginTop: 5,
                        marginLeft: 10,
                        maxWidth: "none",
                        whiteSpace: "nowrap",
                        // Override any max-width that might cause resizing
                      }}
                    />
                    <p
                      style={{
                        marginTop: 9,
                        marginLeft: 10,
                        fontFamily: "SSMedium",
                        color: "blue",
                        fontSize: 14,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.name}
                    </p>
                  </>
                )}
              </div>
            </Rnd>
          </>
        ))}
        <div
          style={{
            position: "absolute",
            top: `${10 + (videoItems.length - 1) * 80}px`,
            height: "1px",
            width: "100%",
            visibility: "hidden",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            left: `${progress}%`,
            width: 2,
            height: 85 * videoItems.length,
            backgroundColor: "orange",
            zIndex: 1,
            top: 5,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: -5,
              left: -4,
              width: 10,
              height: 10,
              borderRadius: "50%",
              backgroundColor: "orange",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default PlayerControl;
