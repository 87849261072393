import React from "react";
import "./index.css"; // Make sure to create a corresponding CSS file
import { useTranslation } from "react-i18next";

const VideoInstructions = ({ setSection }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        paddingBottom: 10, // Added this line
        background: "transparent",
      }}
      className="video-instructions-container"
    >
      <div style={{ marginTop: 40 }} className="content">
        <div className="recommendations">
          <p style={{ fontFamily: "PPBold", color: "black", fontSize: 22 }}>
            ✅ {t("Recommended")}
          </p>
          <ul
            style={{
              fontFamily: "PPMedium",
              color: "black",
              width: 300,
              marginLeft: -20,
            }}
          >
            <li>{t("submit_minimum_footage")}</li>
            <li style={{ marginTop: 5 }}>{t("use_high_resolution_camera")}</li>
            <li style={{ marginTop: 5 }}>{t("record_well_lit_environment")}</li>
            <li style={{ marginTop: 5 }}>{t("look_directly_camera")}</li>
            <li style={{ marginTop: 5 }}>{t("pause_between_sentences")}</li>
            <li style={{ marginTop: 5 }}>{t("use_natural_gestures")}</li>
          </ul>
        </div>

        <div style={{ marginLeft: 20 }} className="avoid">
          <p style={{ fontFamily: "PPBold", color: "black", fontSize: 22 }}>
            ❌ {t("Avoid")}
          </p>
          <ul
            style={{
              fontFamily: "PPMedium",
              color: "black",
              width: 300,
              marginLeft: -20,
            }}
          >
            <li>{t("avoid_stitches_cuts")}</li>
            <li style={{ marginTop: 5 }}>
              {t("avoid_talking_without_pauses")}
            </li>
            <li style={{ marginTop: 5 }}>{t("avoid_changing_positions")}</li>
            <li style={{ marginTop: 5 }}>{t("avoid_loud_background_noise")}</li>
            <li style={{ marginTop: 5 }}>
              {t("avoid_shadows_overexposure_face")}
            </li>
            <li style={{ marginTop: 5 }}>{t("avoid_diverting_gaze")}</li>
            <li style={{ marginTop: 5 }}>
              {t("avoid_hand_gestures_above_chest")}
            </li>
            <li style={{ marginTop: 5 }}>{t("avoid_pointing_gestures")}</li>

            {/* Add the rest of the recommendations here */}
          </ul>
        </div>
      </div>

      <div className="tips">
        <p style={{ fontFamily: "PPMedium" }}>💡{t("cloningtip")}😊</p>
      </div>

      <div onClick={() => setSection(2)} className="next-step">
        <button>{t("nextStepText")}</button>
      </div>
    </div>
  );
};

export default VideoInstructions;
