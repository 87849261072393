import React, { Component, useState, useEffect } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { firebase } from "../../../firebase/config";
import { HiOutlineMailOpen, HiSwitchHorizontal } from "react-icons/hi";
import { BiColorFill, BiHelpCircle, BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useSignUpModal } from "../Context/SignUpModal";
import { RiCloseFill, RiRedPacketLine, RiTrophyLine } from "react-icons/ri";
import {
  FiCheck,
  FiCopy,
  FiCreditCard,
  FiHeadphones,
  FiMail,
  FiPlus,
  FiShoppingBag,
} from "react-icons/fi";
import { FaLink, FaRegBuilding, FaRegImage } from "react-icons/fa";
import { useDetectClickOutside } from "react-detect-click-outside";
import { AiOutlineLogout } from "react-icons/ai";
import { useAuth } from "../Context/AuthContext";
import { HexColorPicker } from "react-colorful";
import { TbAlignCenter, TbAlignLeft, TbAlignRight } from "react-icons/tb";

const TextAlignPicker = ({
  textAlignPickerOpen,
  setTextAlignPickerOpen,
  selectedTextAlign,
  setSelectedTextAlign,
  selectedIndex,
  videoItems,
}) => {
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (textAlignPickerOpen) {
        setTextAlignPickerOpen(false);
      }
    },
  });

  return (
    <>
      <div
        ref={ref}
        style={{
          width: 140,
          borderRadius: 8,
          boxShadow:
            "0 0.5px 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1)",
          display: "flex",
          animation: textAlignPickerOpen ? "popUp 0.3s ease-in-out" : "none",
          visibility: textAlignPickerOpen ? "visible" : "hidden",
          position: "absolute",
          marginTop: 100,
          zIndex: 9000,
          border: "none",
          marginLeft: -55,
          backgroundColor: "white",
          height: 45,
          display: "flex",
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 5,
          paddingRight: 5,
        }}
        className={`backy60 ${textAlignPickerOpen ? "popUpAnimation" : ""}`}
      >
        <div
          style={{
            width: 40,
            height: "100%",
            textAlign: "center",
            fontWeight: "700",
            paddingTop: 7,
            borderRadius: 8,
            marginRight: 3,
            color:
              videoItems[selectedIndex].textAlign === "left" ? "white" : null,
            backgroundColor:
              videoItems[selectedIndex].textAlign === "left"
                ? "rgb(117, 89, 255)"
                : null,
          }}
          onClick={() => setSelectedTextAlign("left")}
          className="d-flex justify-content-center"
        >
          <TbAlignLeft />
        </div>
        <div
          style={{
            width: 40,
            height: "100%",
            textAlign: "center",
            fontStyle: "italic",
            paddingTop: 0,
            fontWeight: "700",
            borderRadius: 8,
            marginRight: 3,
            color:
              videoItems[selectedIndex].textAlign === "center" ? "white" : null,
            backgroundColor:
              videoItems[selectedIndex].textAlign === "center"
                ? "rgb(117, 89, 255)"
                : null,
          }}
          onClick={() => setSelectedTextAlign("center")}
        >
          <TbAlignCenter />
        </div>
        <div
          style={{
            width: 40,
            height: "100%",
            textAlign: "center",
            paddingTop: 0,
            fontWeight: "700",
            textDecoration: "underline",
            borderRadius: 8,
            marginRight: 3,
            color:
              videoItems[selectedIndex].textAlign === "right" ? "white" : null,
            backgroundColor:
              videoItems[selectedIndex].textAlign === "right"
                ? "rgb(117, 89, 255)"
                : null,
          }}
          onClick={() => setSelectedTextAlign("right")}
        >
          <TbAlignRight />
        </div>
      </div>
    </>
  );
};

export default withRouter(TextAlignPicker);
