import React, { Component, useState, useEffect, useRef } from "react";
import "../index.css";
import "./index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { firebase } from "../../../firebase/config";
import { FaPause, FaPlay } from "react-icons/fa";
import { BiRightArrowAlt } from "react-icons/bi";
import { TailSpin } from "react-loader-spinner";
import { useSignUpModal } from "../Context/SignUpModal";
import { useRates } from "../Context/RatesContext";
import { useAuth } from "../Context/AuthContext";
import VideoCarousel from "./VideoCarousel";
import MuxPlayer from "@mux/mux-player-react";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

const Explainer = ({ name, playbackID, profileID }) => {
  const { isOpen, setIsOpen } = useSignUpModal();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { paid, credits } = useAuth();

  console.log("The playback id is", playbackID);

  const submitVideo = async () => {
    if (inputValue) {
      setError(null);
      setLoading(true);
      if (inputValue) {
        var createVideo = firebase.functions().httpsCallable("createVideo");
        createVideo({
          inputValue: inputValue,
          profileID: profileID,
        }).then(async (result) => {
          if (result.data.error) {
            mixpanel.track("Failed to create a video", {
              reason: result.data.message,
            });
            console.log(result.data.message);
            setLoading(false);
            return setError(result.data.message);
          } else {
            const videoID = result.data.videoID;

            if (videoID) {
              mixpanel.track("User created a video", {
                profile: name,
                length: inputValue.length,
              });
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "viewContent",
                content_id: "Video",
                event_id: uuidv4(), // Replace 'currency' with the transaction currency code
              });
              const uid = firebase.auth().currentUser.uid;
              const email = firebase.auth().currentUser.email;
              const userDoc = await firebase
                .firestore()
                .collection("users")
                .doc(uid)
                .get();
              const userData = userDoc.data();
              const hashedEmail = userData.hashedEmail;

              //navigate to the success page
              console.log(
                "Successfully generated a video with video id",
                videoID
              );
              return history.push("/videos");
            }
          }
        });
      }
    }
  };

  const handleChange = (event) => {
    setInputValue(event.target.value.substr(0, limit));
  };

  const limit = paid ? 5000 : 100;

  function creditCalculator(inputString) {
    // Calculate the length of the string
    const length = inputString.length;

    // Determine the output based on the length
    if (length < 1000) {
      return 1;
    } else {
      // Calculate the output for lengths greater than or equal to 1000
      return Math.ceil(length / 1000);
    }
  }

  return (
    <>
      <MDBRow
        style={{
          marginTop: 0,
          paddingTop: 0,
          paddingBottom: "10vh",
          paddingLeft: 0,
        }}
      >
        <MDBCol
          style={{ paddingLeft: 30 }}
          className="d-flex justify-content-center"
          size="12"
        >
          <MDBRow style={{ width: 850, marginTop: 0 }}>
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "MAHeavy",
                  fontSize: 21,
                  color: "black",
                }}
              >
                {t("createVideoTitle")} {name}
              </p>
              <p
                style={{
                  fontFamily: "MABold",
                  fontSize: 17,
                  marginTop: -16,
                  color: "black",
                }}
              >
                Watch an example video of {name} below
              </p>

              <div
                style={{
                  height: 450,
                  width: 800,
                  borderRadius: 9,
                  overflow: "hidden",
                  border: "2px solid black",
                }}
                onClick={() => {
                  mixpanel.track("User played the video on profile page", {
                    profile: name,
                  });
                }}
              >
                <MuxPlayer
                  streamType="on-demand"
                  accent-color="#8036e0"
                  playbackId={playbackID}
                  style={{
                    display: "inline-block",
                    height: 450,
                    width: 800,
                    borderRadius: 20,
                    objectFit: "cover",
                    verticalAlign: "top",
                    backgroundColor: "transparent", // Added this line
                    aspectRatio: 9 / 16,
                  }}
                />
              </div>
            </MDBCol>
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "MAHeavy",
                  fontSize: 21,
                  color: "black",
                  marginTop: 20,
                }}
              >
                What do you want {name} to say?
              </p>
              <p
                style={{
                  fontFamily: "MADemiBold",
                  fontSize: 17,
                  marginTop: -16,
                  color: "black",
                }}
              >
                Works in any language.
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: -10,
                }}
              >
                <textarea
                  style={{
                    width: "800px", // Adjust this width to prevent wrapping if necessary
                    height: "150px",
                    marginTop: "10px",
                    padding: "13px",
                    fontFamily: "MABold", // Make sure this font is loaded in your project
                    fontSize: "19px",
                    color: "black",
                    backgroundColor: "white",
                    border: "2px solid black",
                    borderRadius: "4px",
                    resize: "none",
                    display: "inline-block",
                    marginLeft: 0,
                  }}
                  value={inputValue}
                  onChange={handleChange}
                  placeholder="Type your message here..."
                  maxLength={limit}
                />
              </div>
              <div
                style={{
                  textAlign: "right",
                  fontSize: "14px",
                  color: "black",
                  marginTop: "10px",
                  marginRight: 35,
                  fontFamily: "MABold",
                  opacity: 0.7,
                }}
              >
                {inputValue
                  ? `${inputValue.length}` + "/" + `${limit}`
                  : `${0}` + "/" + `${limit}`}{" "}
                {t("characterCount")}
              </div>
              {!paid && (
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    color: "black",
                    marginTop: "-22px",
                    marginRight: 35,
                    fontFamily: "MADemiBold",
                    opacity: 1,
                    textDecoration: "underline",
                    zIndex: 20,
                    cursor: "pointer",
                    position: "absolute",
                    whiteSpace: "normal",
                    width: 400,
                  }}
                  onClick={() => {
                    history.push("/checkout");
                  }}
                >
                  {t("upgradeCharacters")}
                </div>
              )}
            </MDBCol>
            <MDBCol className="d-flex justify-content-center" size="12">
              {firebase.auth().currentUser ? (
                !paid && credits <= 0 ? (
                  <NavLink
                    to="/checkout"
                    exact
                    style={{
                      height: 54,
                      width: 800,
                      border: "2px solid rgb(248, 40, 78)",
                      borderRadius: 8,
                      marginTop: 19,
                      backgroundColor: "rgb(248, 40, 78)",
                      cursor: loading ? null : "pointer",
                      marginLeft: -16,
                    }}
                    className="d-flex justify-content-center"
                  >
                    {loading ? (
                      <TailSpin
                        height={30}
                        width={30}
                        color="white"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{ marginTop: 10 }}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      <p
                        style={{
                          fontFamily: "MABold",
                          fontSize: 16,
                          display: "inline-block",
                          color: "white",
                          marginLeft: 0,
                          marginTop: 13,
                          zIndex: 300,
                        }}
                      >
                        You've used your free credit - Purchase credits
                        <BiRightArrowAlt
                          style={{
                            marginTop: -1.9,
                            fontSize: 18,
                            color: "white",
                          }}
                          className="d-inline"
                        />
                      </p>
                    )}
                  </NavLink>
                ) : (
                  <div
                    style={{
                      height: 54,
                      width: 800,
                      border: "2px solid rgb(248, 40, 78)",
                      borderRadius: 8,
                      marginTop: 19,
                      backgroundColor: "rgb(248, 40, 78)",
                      cursor: loading ? null : "pointer",
                      marginLeft: -16,
                    }}
                    onClick={() => {
                      if (!loading) {
                        setError(null);
                        submitVideo();
                      }
                      if (!inputValue) {
                        setError("Script is empty");
                      }
                    }}
                    className="d-flex justify-content-center"
                  >
                    {loading ? (
                      <TailSpin
                        height={30}
                        width={30}
                        color="white"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{ marginTop: 10 }}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      <p
                        style={{
                          fontFamily: "MABold",
                          fontSize: 16,
                          display: "inline-block",
                          color: "white",
                          marginLeft: 0,
                          marginTop: 13,
                          zIndex: 300,
                        }}
                      >
                        Create your video of {name}
                        <BiRightArrowAlt
                          style={{
                            marginTop: -1.9,
                            fontSize: 18,
                            color: "white",
                          }}
                          className="d-inline"
                        />
                      </p>
                    )}
                  </div>
                )
              ) : (
                <div
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  style={{
                    height: 54,
                    width: 800,
                    border: "2px solid rgb(248, 40, 78)",
                    borderRadius: 8,
                    marginTop: 19,
                    backgroundColor: "rgb(248, 40, 78)",
                    cursor: "pointer",
                    marginLeft: -16,
                  }}
                  className="d-flex justify-content-center"
                >
                  <p
                    style={{
                      fontFamily: "MABold",
                      fontSize: 16,
                      display: "inline-block",
                      color: "white",
                      marginLeft: 0,
                      marginTop: 13,
                      zIndex: 300,
                    }}
                  >
                    Sign up to create video
                    <BiRightArrowAlt
                      style={{
                        marginTop: -1.9,
                        fontSize: 18,
                        color: "white",
                      }}
                      className="d-inline"
                    />
                  </p>
                </div>
              )}
            </MDBCol>
            {!error && (
              <MDBCol size="12" className="d-flex justify-content-center">
                <p
                  style={{
                    marginTop: 19,
                    color: "black",
                    fontFamily: "MABold",
                  }}
                >
                  Only takes a few minutes to generate
                </p>
              </MDBCol>
            )}
            <MDBCol size="12" className="d-flex justify-content-center">
              {error && (
                <p
                  style={{
                    marginTop: 19,
                    color: "red",
                    fontFamily: "CSMedium",
                  }}
                >
                  {error}
                </p>
              )}
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default withRouter(Explainer);
