import React, { Component, useState, useEffect } from "react";
import "../index.css";
import { MDBRow, MDBCol } from "mdbreact";
import { firebase } from "../../../firebase/config";
import "react-phone-number-input/style.css";
import { NavLink, withRouter, useLocation, useHistory } from "react-router-dom";
import { SkyLightStateless } from "react-skylight";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiLeftArrowAlt } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { FaChevronLeft } from "react-icons/fa";
import Logo from "./Logo10.svg";
import { TailSpin } from "react-loader-spinner";

const SignUp = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [finishedReset, setFinishedReset] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [signUpMode, setSignUpMode] = useState(true);
  const [confirmMode, setConfirmMode] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState("");
  const [confirmationError, setConfirmationError] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();

  const googleProvider = new firebase.auth.GoogleAuthProvider();

  useEffect(() => {
    setLoginEmail("");
    setPassword("");
    setError("");
  }, []);

  const signIn = async (Email, Password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(Email, Password)
      .then(() => {
        mixpanel.track("User signed in", {
          "Sign in method": "Email",
        });
        if (firebase.auth()?.currentUser?.emailVerified === true) {
          history.push("/");
        } else {
          setConfirmMode(true);
          setError(null);
        }
      })
      .catch((signUpError) => {
        // Sign up failed
        setLoading(false);
        setError(formatErrorMessage(signUpError.message));
      });
  };

  const signUp = async (Email, Password) => {
    setLoading(true);
    return firebase
      .auth()
      .createUserWithEmailAndPassword(Email, Password)
      .then(async (userCredential) => {
        // Send verification email
        mixpanel.track("User created", {
          "Sign up method": "Email",
        });

        userCredential.user
          .sendEmailVerification()
          .then(() => {
            console.log("Verification email sent.");
          })
          .catch((verificationError) => {
            console.error(
              "Error sending verification email:",
              verificationError
            );
          });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "completeRegistration",
          event_id: uuidv4(), // Replace 'currency' with the transaction currency code
        });

        // Save user data to Firestore
        const uid = userCredential.user.uid;

        if (window.tolt) {
          window.tolt.signup(Email);
        }

        await firebase.firestore().collection("users").doc(uid).set({
          email: Email,
          uid: uid,
          monthlyQuota: 20,
        });

        setLoading(false);
      })
      .then(() => {
        setLoading(false);
        setConfirmMode(true);
      })
      .catch((signUpError) => {
        // Sign up failed
        setLoading(false);
        setError(formatErrorMessage(signUpError.message));
      });
  };

  const handleAuthentication = async (user) => {
    setLoading(true);
    const uid = user.uid;

    try {
      const doc = await firebase.firestore().collection("users").doc(uid).get();

      if (!doc.exists) {
        // New user logic
        mixpanel.track("User created", { "Sign up method": "Google" });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "completeRegistration",
          event_id: uuidv4(),
        });

        if (window.tolt) {
          window.tolt.signup(user.email);
        }

        await firebase.firestore().collection("users").doc(uid).set({
          email: user.email,
          uid: uid,
          monthlyQuota: 20,
        });

        history.push("/");
      } else {
        // Existing user logic
        mixpanel.track("User signed in", { "Sign in method": "Google" });
        history.push("/");
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitGoogle = async () => {
    try {
      const result = await firebase.auth().signInWithPopup(googleProvider);
      if (result.user) {
        await handleAuthentication(result.user);
      }
    } catch (error) {
      console.log(error);
      setError(error.message);
      setLoading(false);
    }
  };

  function formatErrorMessage(errorMessage) {
    // Remove "Firebase:" prefix if it exists
    let formattedMessage = errorMessage.startsWith("Firebase:")
      ? errorMessage.replace("Firebase:", "").trim()
      : errorMessage;

    // Remove error code in parentheses at the end
    formattedMessage = formattedMessage.replace(/\(.*?\)\.?$/, "").trim();

    return formattedMessage;
  }

  return (
    <div
      style={{
        overflowX: "hidden",
        backgroundColor: "white",
        width: "100%",
        display: "flex",
        height: "100%",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          maxWidth: 400,
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 0,
          marginBottom: "auto",
          paddingTop: 80,
          fontFamily: "SSBold",
          fontSize: 27,
          paddingBottom: 30,
        }}
        size="6"
      >
        {resetPassword ? (
          <>
            <p>
              <img
                style={{
                  height: 27,
                  marginTop: -5.4,
                  marginRight: 5,
                }}
                src={Logo}
              />
              ROWADS
            </p>
            <p
              style={{ marginTop: 110, fontFamily: "SSSemiBold", fontSize: 29 }}
            >
              {finishedReset ? "Check your inbox" : "Forgot your password?"}
            </p>
            {finishedReset ? (
              <p
                style={{
                  marginTop: -9,
                  fontFamily: "SSRegular",
                  fontSize: 16,
                  opacity: 0.8,
                }}
              >
                Check your inbox a reset email has been
                <br /> sent to your inbox. Check your spam folder.
              </p>
            ) : (
              <p
                style={{
                  marginTop: -9,
                  fontFamily: "SSRegular",
                  fontSize: 16,
                  opacity: 0.8,
                }}
              >
                Enter your email address and we will send you
                <br /> instructions on how to reset your password.
              </p>
            )}
            {!finishedReset && (
              <div>
                <p
                  style={{
                    fontFamily: "SSRegular",
                    fontSize: 16,
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  Email
                </p>
                <input
                  style={{
                    height: 55,
                    width: 370,
                    marginLeft: 0,
                    border: "1px solid rgba(155, 158, 163, 0.5)",
                    borderRadius: 7,
                    display: "inline-block",
                    color: "black",
                    marginTop: 5,
                    backgroundColor: "white",
                    fontFamily: "SSMedium",
                    paddingLeft: 17,
                    fontSize: 15,
                    paddingTop: 3,
                  }}
                  value={loginEmail}
                  name="Email"
                  onChange={(event) => setLoginEmail(event.target.value)}
                  placeholder="Enter your email"
                />
              </div>
            )}
            {!finishedReset && (
              <div
                style={{
                  height: 55,
                  width: 370,
                  backgroundColor: "#ed1165",
                  borderRadius: 7,
                  marginTop: 20,
                  textAlign: "center",
                  fontFamily: "SSRegular",
                  color: "white",
                  fontSize: 18,
                  paddingTop: 14,
                  cursor: "pointer",
                  opacity: loginEmail ? 1 : 0.8,
                }}
                onClick={() => {
                  if (loginEmail) {
                    firebase.auth().sendPasswordResetEmail(loginEmail);
                    setFinishedReset(true);
                  }
                }}
              >
                <p>Confirm</p>
              </div>
            )}
            <p
              style={{
                marginTop: 20,
                fontSize: 14,
                fontFamily: "SSRegular",
                cursor: "pointer",
              }}
              onClick={() => {
                setResetPassword(false);
                setFinishedReset(false);
              }}
            >
              <FaChevronLeft style={{ marginRight: 5, marginTop: -1.9 }} />
              Back to Sign In
            </p>
          </>
        ) : confirmMode ? (
          <>
            <p>
              <img
                style={{
                  height: 27,
                  marginTop: -5.4,
                  marginRight: 5,
                }}
                src={Logo}
              />
              ROWADS
            </p>
            <p
              style={{ marginTop: 110, fontFamily: "SSSemiBold", fontSize: 29 }}
            >
              Confirm your email
            </p>
            <p
              style={{
                marginTop: -9,
                fontFamily: "SSRegular",
                fontSize: 16,
                opacity: 0.8,
              }}
            >
              Check your inbox a confirmation link has been
              <br /> sent to your inbox. Click on this link and come
              <br /> back when done.
            </p>

            <div
              style={{
                height: 55,
                width: 370,
                backgroundColor: "#ed1165",
                borderRadius: 7,
                marginTop: 20,
                textAlign: "center",
                fontFamily: "SSRegular",
                color: "white",
                fontSize: 18,
                paddingTop: 14,
                cursor: "pointer",
              }}
              onClick={() => {
                if (firebase.auth().currentUser?.emailVerified) {
                  console.log("Clicked on confirm");
                  history.push("/");
                } else {
                  setConfirmationError(true);
                }
              }}
            >
              <p>I've confirmed my email</p>
            </div>
            {confirmationError && (
              <p
                style={{
                  fontFamily: "SSMedium",
                  fontSize: 14,
                  color: "red",
                  marginTop: 10,
                }}
              >
                Please click on the link in your inbox to continue.
              </p>
            )}

            <p
              style={{
                marginTop: 20,
                fontSize: 14,
                fontFamily: "SSRegular",
                cursor: "pointer",
              }}
              onClick={() => {
                if (firebase.auth().currentUser) {
                  firebase
                    .auth()
                    .signOut()
                    .then(() => {
                      setConfirmMode(false);
                    });
                } else {
                  setConfirmMode(false);
                }
              }}
            >
              <FaChevronLeft style={{ marginRight: 5, marginTop: -1.9 }} />
              Back to Sign In
            </p>
          </>
        ) : (
          <>
            <p>
              {" "}
              <img
                style={{
                  height: 27,
                  marginTop: -5.4,
                  marginRight: 5,
                }}
                src={Logo}
              />
              ROWADS
            </p>
            <p style={{ marginTop: 0, fontFamily: "SSSemiBold" }}>
              {signUpMode ? "Welcome to RowAds" : "Sign in to RowAds"}
            </p>
            <p style={{ marginTop: -9, fontFamily: "SSRegular", fontSize: 16 }}>
              Pay-for-performance UGC videos
            </p>
            <div
              style={{
                height: 52,
                width: 370,
                borderRadius: 6,
                border: "1px solid rgb(208, 213, 221)",
                marginTop: 30,
                fontFamily: "SSRegular",
                textAlign: "center",
                fontSize: 17,
                paddingTop: 14,
                paddingLeft: 25,
                cursor: "pointer",
                boxShadow: "rgba(16, 24, 40, 0.05) 0px 1px 2px",
                display: "flex",
              }}
              onClick={() => onSubmitGoogle()}
            >
              <img
                style={{
                  height: 33,
                  top: 0,
                  marginLeft: 70,
                  marginRight: 5,
                  marginTop: -4,
                }}
                src={require("./Google.png")}
              />
              <p style={{ color: "rgb(52, 64, 84)" }}>
                {!signUpMode ? "Sign in with Google" : "Sign up with Google"}
              </p>
            </div>
            <hr
              style={{
                width: 370,
                backgroundColor: "#98a2b3",
                marginLeft: 0,
                marginTop: 38,
              }}
            />
            <div
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                height: 40,
                backgroundColor: "white",
                width: 180,
                marginTop: -28,
                marginLeft: 100,
                fontFamily: "SSRegular",
                fontSize: 14,
                textAlign: "center",
                color: "rgb(102, 112, 133)",
              }}
            >
              {signUpMode ? "Or Sign Up with E-mail" : "Or Sign In with E-mail"}
            </div>
            <div>
              <p
                style={{
                  fontFamily: "SSRegular",
                  fontSize: 16,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Email
              </p>
              <input
                style={{
                  height: 55,
                  width: 370,
                  marginLeft: 0,
                  border: "1px solid rgba(155, 158, 163, 0.5)",
                  borderRadius: 7,
                  display: "inline-block",
                  color: "black",
                  marginTop: 5,
                  backgroundColor: "white",
                  fontFamily: "SSMedium",
                  paddingLeft: 17,
                  fontSize: 15,
                  paddingTop: 3,
                }}
                value={loginEmail}
                name="Email"
                onChange={(event) => setLoginEmail(event.target.value)}
                placeholder="Enter your email"
              />
            </div>
            <div>
              <p
                style={{
                  fontFamily: "SSRegular",
                  fontSize: 16,
                  marginTop: 16,
                  marginBottom: 0,
                }}
              >
                Password
              </p>
              <input
                style={{
                  height: 55,
                  width: 370,
                  marginLeft: 0,
                  border: "1px solid rgba(155, 158, 163, 0.5)",
                  borderRadius: 7,
                  display: "inline-block",
                  color: "black",
                  marginTop: 5,
                  backgroundColor: "white",
                  fontFamily: "SSMedium",
                  paddingLeft: 17,
                  fontSize: 15,
                  paddingTop: 3,
                }}
                type="password"
                value={password}
                name="Password"
                onChange={(event) => setPassword(event.target.value)}
                placeholder="Password"
              />
            </div>
            <p
              style={{
                fontFamily: "SSMedium",
                color: "#ed1165",
                fontSize: 15,
                marginTop: 15,
                cursor: "pointer",
              }}
              onClick={() => setResetPassword(true)}
            >
              Forgot password?
            </p>
            <div
              style={{
                height: 55,
                width: 370,
                backgroundColor: "#ed1165",
                borderRadius: 7,
                marginTop: 20,
                textAlign: "center",
                fontFamily: "SSRegular",
                color: "white",
                fontSize: 18,
                paddingTop: 14,
                cursor: "pointer",
                opacity: loginEmail && password ? 1 : 0.6,
              }}
              className="d-flex justify-content-center"
              onClick={() => {
                if (loginEmail && password) {
                  if (signUpMode) {
                    signUp(loginEmail, password);
                  } else {
                    signIn(loginEmail, password);
                  }
                }
              }}
            >
              {loading ? (
                <TailSpin
                  height="25"
                  width="25"
                  color="#fff"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{ marginTop: 0 }}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                <p>{signUpMode ? "Sign up" : "Sign in"}</p>
              )}
            </div>
            {error && (
              <p
                style={{
                  marginTop: 20,
                  fontSize: 14,
                  fontFamily: "SSMedium",
                  color: "red",
                }}
              >
                {error}
              </p>
            )}
            <p style={{ marginTop: 20, fontSize: 14, fontFamily: "SSMedium" }}>
              {!signUpMode
                ? "Don't have an account?"
                : "Already have an account?"}
              <p
                style={{
                  color: "#ed1165",
                  fontFamily: "SSMedium",
                  cursor: "pointer",
                  marginLeft: 5,
                }}
                className="d-inline"
                onClick={() => setSignUpMode(!signUpMode)}
              >
                {!signUpMode ? "Sign up" : "Sign in"}
              </p>
            </p>
            <p
              style={{
                marginTop: 20,
                fontSize: 14,
                fontFamily: "SSRegular",
              }}
            >
              By signing up you agree to RowAds's privacy policy and terms of
              service
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(SignUp);
